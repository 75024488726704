@import "../../../../global-style.scss";

#get-the-full-sugar-experience-dialog {
    max-width: 320px;

    .dialog-header {
        padding: 10px 10px 0 10px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .padding-container {
        padding: 0 20px 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .premium-unlock-icon-container {

            .premium-unlock-icon {
                width: 60px;
            }
        }

        .title {
            text-align: center;
            font: 600 1.6em Lato;
        }

        .subtitle {
            font: 500 1em Lato;
            color: #676767;
        }

        .list-container {
            font: 600 1em Lato;
        }

        .upgrade-button {
            padding: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1.2em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}