#profile-more-about-me-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .more-about-me-label {
            font: 600 1.5em Lato;
        }

        .more-about-me-container {
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            border-radius: 20px;
            background: white;

            .more-about-me-item-container {
                padding: 8px 15px;
                display: flex;
                align-items: center;
                gap: 5px;
                border: 1px solid #d8d4d4;
                border-radius: 50px;
                font: 600 1em Lato;
                background: #f0ecec;

                .selected-question-icon-container {
                    display: flex;
                    justify-content: center;

                    .selected-question-icon {
                        height: 17px;
                    }
                }
            }

            // Utility CSS
            .non-clickable {
                cursor: default;
            }
        }
    }
}

@media (max-width: 720px) {
    #profile-more-about-me-subcomponent {

        .padding-container {

            .more-about-me-container {

                .more-about-me-item-container {
                    font: 600 0.9em Lato;
                }
            }
        }
    }
}