@import "../../../../../../global-style.scss";

#user-profile-tab-photos-tab-subcomponent {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .public-photo-main-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .public-photo-label {
            font: 600 1.3em Lato;
        }

        .public-photo-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .public-photo-image-container {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                .public-image-container {
                    display: flex;
                    cursor: pointer;

                    .public-image {
                        width: 200px;
                        max-height: 500px;
                        border-radius: 15px;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    .private-photo-main-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .private-photo-label-container {
            display: flex;
            align-items: center;
            gap: 10px;

            .private-photo-label {
                font: 600 1.3em Lato;
            }

            .requested-label {
                padding: 5px 10px;
                border-radius: 25px;
                font: 600 0.7em Lato;
                background-color: darkgreen;
                color: white;
            }
        }

        .private-photo-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .label-container {
                display: flex;
                align-items: center;
                gap: 15px;
            }

            .request-button {
                padding: 8px 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                text-transform: none;
                text-decoration: none;
                cursor: pointer;
                font: 600 1.2em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .disabled-button {
                cursor: not-allowed;
                pointer-events: initial;
                background-color: hsla(0, 0%, 100%, .12);
                color: hsla(0, 0%, 100%, .3);
            }

            .private-photo-image-container {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                .private-image-container {
                    position: relative;
                    display: flex;
                    cursor: pointer;

                    .private-image {
                        width: 200px;
                        min-height: 200px;
                        max-height: 500px;
                        position: relative;
                        border-radius: 15px;
                        object-fit: cover;
                    }

                    .private-photo-overlay-container {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 15px;
                        /* Add border-radius to the container */
                        overflow: hidden;
                        /* Ensure the overlay respects the border-radius */

                        .blur-filter {
                            height: 100%;
                            width: 100%;
                            backdrop-filter: blur(15px);
                        }

                        .background-filter {
                            height: 100%;
                            width: 100%;
                            background-color: rgba(0, 0, 0, 1);
                        }

                        .lock-container {
                            height: 55%;
                            width: 55%;
                            position: absolute;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            gap: 7px;
                            border-radius: 25px;
                            background-color: #33333357;

                            .lock-icon-container {

                                .lock-icon {
                                    height: 35px;
                                }
                            }

                            .private-photo-label {
                                font: 300 0.9em Lato;
                                color: white;
                            }
                        }
                    }

                    .blur-filter-container {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        border-radius: 15px;
                        align-items: center;
                        gap: 10px;
                        backdrop-filter: blur(50px);

                        .sensitive-icon-container {

                            .sensitive-icon {
                                width: 35px;
                            }
                        }

                        .blur-filter-title {
                            font: bold 1em Lato;
                            color: white;
                        }

                        .blur-filter-description {
                            padding: 0 5px;
                            text-align: center;
                            font: 500 0.8em/1.3 Lato;
                            color: white;
                        }

                        .blur-filter-cta {
                            padding: 5px 15px;
                            border: 1px solid white;
                            border-radius: 50px;
                            cursor: pointer;
                            font: 500 0.9em Lato;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}