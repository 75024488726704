@import "../../../../global-style.scss";

#livestream-kicked-dialog {
    max-width: 250px;

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;

        .kick-icon-container {

            .kick-icon {
                height: 70px;
            }
        }

        .title {
            font: 600 1.4em Lato;
        }

        .description {
            text-align: center;
        }

        .behave-text {
            text-align: center;
        }

        .back-button {
            height: 50px;
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1.1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}