@import "../../../../../../global-style.scss";

#viewer-info-live-tab-subcomponent {
    height: 100%;

    .padding-container {
        height: calc(100% - 40px);
        padding: 20px;
        overflow: auto;
    }
}