#custom-avatar-subcomponent {
    position: relative;

    .custom-avatar {}

    .fallback-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .reduce-profile-photo {
        transform: scale(80%);
    }

    .profile-border-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        pointer-events: none;

        .profile-border {
            height: 100%;
            width: 100%;
        }
    }

    .lock-icon-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .lock-icon {
            height: 20px;
        }
    }
}

// Utility CSS
.blur-avatar {

    img {
        filter: blur(5px);
    }
}

.scale-avatar {

    img {
        transform: scale(1.3);
        position: absolute;
        // bottom: 10px;
    }
}