@import "../../../../../global-style.scss";

#mobile-edit-profile-financial-information-subcomponent {

    .financial-information-label-container {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 50px;
        // background-color: $mui-text-field-outline-background-color;

        .financial-information-label {
            font: 600 1.5em Lato;
        }
    }

    .form-field-padding-container {
        padding: 0 12px 0 20px;

        .form-field-container {
            height: 35px;
            display: flex;
            align-items: center;

            .label-container {
                width: 40%;

                .label {
                    color: #919191;
                }

                .info-icon {
                    padding-left: 10px;
                    font-size: 19px;
                }

                // Utility CSS
                .incomplete-field {
                    color: red;
                }
            }

            .output {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
            }

            .toggle {
                display: flex;
                justify-content: flex-end;
                flex: 1;
            }

            .form-container {
                width: 60%;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                cursor: pointer;

                .filled-form {
                    width: 100%;
                    font: 600 1em Lato;
                }

                .empty-form {
                    width: 100%;
                    padding-right: 10px;
                    font: 600 1em Lato;
                    color: #CCCCCC;
                }

                .right-icon {
                    display: flex;

                    .select-form-field-dropdown-icon {
                        color: #d0cccc;
                    }
                }
            }
        }
    }
}

#desktop-edit-profile-financial-information-subcomponent {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .financial-information-static-label-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .financial-information-label {
            font: 600 1.2em Lato;
        }

        .short-divider {
            width: 30px;
        }
    }

    .form-field-padding-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .form-field-container {
            height: 50px;
            display: flex;
            align-items: center;

            .label-container {
                width: 40%;

                .label {
                    font: 600 1em Lato;
                }

                .info-icon {
                    padding-left: 10px;
                    font-size: 19px;
                }

                // Utility CSS
                .incomplete-field {
                    color: red;
                }
            }

            .form-container {
                width: 60%;

                .form-field {

                    .form-select {
                        font: 600 1em Lato;

                        .MuiSelect-select {
                            padding-top: 11px;
                            padding-bottom: 9px;
                            padding-left: 20px;
                        }

                        .default-select-value {
                            font: 500 1em Lato;
                            color: #CCCCCC;
                        }
                    }
                }
            }
        }
    }

    .bottom-section-container {
        margin-left: 40%;

        .save-button {
            width: 130px;
            padding: 10px 0;
            border-radius: 25px;
            text-align: center;
            cursor: pointer;
            background-color: $sugarbook-main-color;
            color: white;
            transition: all 0.5s;
        }

        // Utility CSS
        .disabled-button {
            cursor: not-allowed;
            background-color: #D0D0D0;
            color: #B1B1B1;
        }
    }

    // Utility CSS
    .none-clickable {
        pointer-events: none;
    }
}