@import "../../global-style.scss";

#login-page {
    height: 100%;
    overflow: auto;
    display: grid;
    place-items: center;

    .max-width-container {
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: table-cell;
        vertical-align: middle;
        z-index: 1;

        .tab-container {

            .login-tabs {
                padding-bottom: 20px;
            }
        }

        .sugarbook-logo-container {
            margin-bottom: 50px;
            text-align: center;

            .sugarbook-logo {
                width: 70%;
                // filter: brightness(0) invert(1);
            }
        }

        .email-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .email-form-field {
                width: 100%;

                .MuiInput-underline:before {
                    border-bottom: 2px solid rgba(255, 255, 255, 0.4);
                }

                .MuiInput-underline:after {
                    border-bottom-color: white;
                }

                .MuiInput-input {
                    padding: 4px 0 7px 5px;
                    color: white;
                }

                .MuiInputLabel-root {
                    color: white;
                }

                .MuiFormHelperText-root {
                    font-weight: bold;
                    color: #FF0000;
                }
            }
        }

        .phone-container {
            display: flex;
            gap: 10px;

            .country-form-field-container {
                height: 50px;
                width: 30%;
                padding-bottom: 30px;
                margin-left: auto;
                margin-right: auto;

                .country-form-field {
                    width: 100%;
                }
            }

            .phone-form-field-container {
                height: 50px;
                width: 70%;
                padding-bottom: 30px;
                margin-left: auto;
                margin-right: auto;

                .phone-form-field {
                    width: 100%;
                }
            }
        }

        .password-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            .password-form-field {
                width: 100%;

                .show-password,
                .hide-password {
                    color: white;
                }

                .MuiInput-underline:before {
                    border-bottom: 2px solid rgba(255, 255, 255, 0.4);
                }

                .MuiInput-underline:after {
                    border-bottom-color: white;
                }

                .MuiInput-input {
                    padding: 4px 0 7px 5px;
                    color: white;
                }

                .MuiInputLabel-root {
                    color: white;
                }

                .MuiFormHelperText-root {
                    font-weight: bold;
                    color: #FF0000;
                }
            }
        }

        .forgot-password-button-container {
            margin-bottom: 20px;
            display: flex;
            justify-content: flex-end;

            .forgot-password-button {
                cursor: pointer;
                color: white;
            }
        }

        .recaptcha-container {
            padding: 15px 0;
            display: flex;
            justify-content: center;
        }

        .button-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .login-button {
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $sugarbook-main-color;
                border-radius: 25px;
                text-transform: none;
                font: 600 1.1em Lato;
                background: $sugarbook-main-color;
                color: white;
                transition: all 0.5s;
            }

            .or-container {
                display: flex;
                justify-content: space-around;
                align-items: center;

                .or-divider {
                    width: 40%;
                    color: white;
                    background: white;
                }

                .or-label {
                    color: white;
                }
            }

            .create-account-label {
                cursor: pointer;
                text-align: center;
                font-style: italic;
                color: white;
            }

            .join-button-container {

                .join-button {
                    height: 43px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid white;
                    border-radius: 25px;
                    cursor: pointer;
                    text-decoration: none;
                    font: 600 1.1em Lato;
                    color: white;
                }
            }
        }
    }

    .version-container {
        width: 100%;
        padding-bottom: 30px;
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .app-icon {
            width: 50px;
        }

        .version {
            font: 500 0.7em Lato;
            color: $sugarbook-grey-text-color;
        }
    }

    // Utility CSS
    .disabled-button {
        border: 2px solid transparent !important;
        cursor: not-allowed !important;
        background: #8C8283 !important;
        color: $sugarbook-main-color !important;
    }

    .inactive-button {
        background-color: rgba(0, 0, 0, .12) !important;
        border-color: transparent !important;
        color: rgba(0, 0, 0, .26) !important;
    }

    .active-button {
        background-color: rgb(113, 13, 13) !important;
        border-color: rgb(113, 13, 13) !important;
        color: white !important;
    }
}