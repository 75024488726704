@import "../../../../global-style.scss";

#pk-info-dialog {
    margin-top: auto;

    .padding-container {
        padding: 30px;

        .header {
            padding-bottom: 15px;
            font: 600 1.7em Lato;
        }

        .text {
            padding-bottom: 20px;
        }

        .content-body {
            padding-bottom: 15px;
            display: flex;

            .badge-container {

                .badge {
                    padding-top: 5px;
                    height: 30px;
                }
            }

            .description-container {
                padding-left: 15px;

                .subheader-container {
                    display: flex;

                    .subheader {
                        font: 600 1em Lato;
                    }

                    .diamond-count-description {
                        padding-left: 10px;
                        display: flex;
                    }
                }

                .description {}
            }
        }
    }

    .close-button {
        height: 45px;
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        text-transform: none;
        text-decoration: none;
        font: 600 1.1em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }

    .diamond-container {
        padding-left: 5px;
        padding-right: 3px;
        position: relative;
        top: 2px;

        .diamond {
            height: 15px;
        }
    }
}