@import "../../../../global-style.scss";

#details-signup-about-me-details-subcomponent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-container {
        height: calc(100% - 100px - 10px);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .about-me-details-label {
            padding: 0 20px;
            text-align: center;
            font: 900 2em Lato;
            color: white;
        }

        .about-me-details-description-label {
            padding: 0 20px;
            text-align: center;
            font: 500 0.9em/1.4 Lato;
            color: white;
        }

        .about-me-manual-text-section {
            width: 85%;
            max-width: 420px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .about-me-manual-text {
                padding-left: 12px;
                font: bold 1.1em Lato;
                color: white;

                .optional-label {
                    font: italic 500 0.7em Lato;
                }
            }

            .about-me-manual-text-filled {
                padding: 10px 20px;
                display: flex;
                justify-content: space-between;
                border-radius: 20px;
                font: 600 1em Lato;
                color: $sugarbook-main-color;
                background: white;

                .about-me-text {}

                .close-button-container {

                    .close-button {
                        font-size: 15px;
                    }
                }
            }

            .about-me-manual-text-container {
                min-height: 50px;
                width: calc(100% - 30px - 2px);
                padding: 15px;
                display: flex;
                flex-direction: column;
                gap: 5px;
                border: 2px solid rgba(255, 255, 255, 0.4);
                border-radius: 20px;
                font-style: italic;
                font: 500 0.8em Lato;
                color: white;
            }
        }

        .about-me-details-select-up-to-description {
            text-align: center;
            font: 500 0.9em Lato;
            color: rgba(255, 255, 255, 0.5);
        }

        .about-me-body-container {
            width: 100%;
            max-width: 800px;
            padding: 5% 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // flex-wrap: wrap;
            gap: 20px;

            .about-me-item-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                // gap: 20px;

                .about-me-item {
                    // width: 150px;
                    padding: 10px 10px;
                    border: 2px solid rgba(255, 255, 255, 0.4);
                    border-radius: 50px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    color: white;
                    transition: max-height 0.3s ease-in-out;

                    .about-me-item-label {

                        .question-label {
                            font: 600 1em Lato;
                        }

                        .item-label {
                            font: italic 500 0.8em Lato;
                        }
                    }

                    .about-me-item-icon {}
                }

                .about-me-item-disable {
                    color: rgba(255, 255, 255, 0.4);
                }

                .about-me-selected-question-container {
                    width: 80%;
                    max-width: 400px;
                    display: flex;
                    flex-direction: column;
                    // justify-content: space-around;
                    gap: 15px;

                    .about-me-selected-question-label {
                        width: 100%;
                        text-align: left;
                        cursor: pointer;
                        font: 600 1.1em Lato;
                        color: white;
                    }

                    .about-me-selected-answers-container {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 7px;

                        .about-me-selected-answer-container {
                            // height: 17px;
                            padding: 5px 10px;
                            display: flex;
                            align-items: center;
                            gap: 3px;
                            border-radius: 50px;
                            cursor: pointer;
                            font: 600 0.9em Lato;
                            background: white;
                            color: $sugarbook-main-color;

                            .selected-answer-close-icon {
                                font-size: 15px;
                            }
                        }
                    }

                    // Utility CSS
                    .selected-question-full-width {
                        width: 100%;
                    }

                    .selected-question-no-width {
                        width: 0%;
                    }
                }

                .about-me-item-panel {
                    margin-top: 10px;
                    transition: all 0.2s ease-out;

                    .about-me-item-panel-item {
                        padding: 7px 0;
                        cursor: pointer;
                        font: 500 1.1em Lato;
                        color: rgba(255, 255, 255, 0.5);
                    }
                }

                .about-me-answer-description {
                    // height: 50px;
                    width: 80%;
                    max-width: 400px;
                    padding: 15px;
                    border-radius: 10px;
                    background: rgba(255, 255, 255, 0.4);
                    color: white;
                }

                .about-me-max-item {
                    width: 80%;
                    max-width: 400px;
                    font: italic 500 0.9em Lato;
                    color: rgba(255, 255, 255, 0.5);
                }

                .about-me-answer-container {
                    max-width: 400px;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    transition: max-height 0.3s ease-in-out;

                    .about-me-answer-panel-item {
                        width: fit-content;
                        padding: 10px 20px;
                        border: 2px solid white;
                        border-radius: 50px;
                        cursor: pointer;
                        font: 600 1em Lato;
                        transition: all 0.3s ease-in-out;
                    }
                }

                .selected {
                    color: $sugarbook-main-color;
                    background: white;
                }

                .open-panel {
                    max-height: 225px;
                    overflow: auto;
                    // display: block;
                }

                .close-panel {
                    max-height: 0;
                    overflow: hidden;
                    // display: none;
                }
            }
        }
    }

    .bottom-container {
        height: 100px;
        width: calc(100% - 40px);
        padding: 10px 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .continue-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .skip-button {
            cursor: pointer;
            color: #988484;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .full-width {
        width: calc(100% - 160px);
    }

    .partial-width {
        width: 80%;
    }

    .fix-width {
        width: 400px;
    }

    .fit-content {
        width: fit-content;
    }

    .add-gap {
        padding-top: 10px;
        gap: 15px;
    }

    .selected-answer {
        color: $sugarbook-main-color;
        background: white;
    }

    .unselected-answer {
        color: white;
    }
}

// Response Design
@media (min-width: 720px) {
    #details-signup-about-me-details-subcomponent {

        .top-container {

            .about-me-details-label {
                font: 600 2em Lato;
            }

            .about-me-details-description-label {
                font: 500 1em Lato;
            }

            .about-me-details-select-up-to-description {
                font: 500 1em Lato;
            }

            .about-me-body-container {
                padding: 1% 0;
            }
        }
    }
}