@import "../../../../../global-style.scss";

#occupation-subcomponent {
    height: 100%;

    .padding-container {
        height: calc(100% - 80px - 40px);
        padding: 20px;
        display: flex;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .top-container {
            width: 100%;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .occupation-icon-container {
                display: flex;
                justify-content: center;

                .occupation-icon {
                    height: 25px;
                }
            }

            .occupation-title {
                text-align: center;
                font: 600 1.6em Lato;
            }

            .occupation-body-container {

                .position-body-container {
                    padding: 10px 0;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 10px;
                    overflow: auto;
                    mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
                    -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

                    .position-form-field-container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .position-select-form-field-container {
                            width: 100%;

                            .position-select-form-field {
                                width: 100%;

                                .MuiInputLabel-root {
                                    color: #b0acac;
                                }

                                .MuiInputBase-root {

                                    .MuiSelect-select {
                                        // color: white;
                                    }
                                }

                                .MuiInputBase-root:before {
                                    border-bottom-color: #e8e4e4;
                                }

                                .MuiInputBase-root:after {
                                    border-bottom-color: #e8e4e4 !important;
                                }

                                .MuiSvgIcon-root {
                                    // color: white;
                                }
                            }
                        }
                    }

                    .selected-position {
                        width: 85%;
                        padding: 10px 20px;
                        border: 1px solid #e8cccc;
                        border-radius: 50px;
                        text-align: center;
                        font: 600 1em Lato;
                        background: #f8ecec;
                        color: $sugarbook-main-color;
                    }

                    .position-item {
                        width: 85%;
                        padding: 10px 20px;
                        border: 1px solid #d8d8d8;
                        border-radius: 50px;
                        cursor: pointer;
                        text-align: center;
                        font: 600 1em Lato;
                        background: white;
                        color: #A2A2A2;
                        transition: all 0.3s;
                    }

                    .selected {
                        border-color: #e8cccc;
                        background: #f8ecec;
                        color: $sugarbook-main-color;
                    }
                }

                .industry-body-container {
                    padding: 10px 0;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 10px;
                    overflow: auto;
                    mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
                    -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

                    .industry-form-field-container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .industry-select-form-field-container {
                            width: 100%;

                            .industry-select-form-field {
                                width: 100%;

                                .MuiInputLabel-root {
                                    color: #b0acac;
                                }

                                .MuiInputBase-root {

                                    .MuiSelect-select {
                                        // color: white;
                                    }
                                }

                                .MuiInputBase-root:before {
                                    border-bottom-color: #e8e4e4;
                                }

                                .MuiInputBase-root:after {
                                    border-bottom-color: #e8e4e4 !important;
                                }

                                .MuiSvgIcon-root {
                                    // color: white;
                                }
                            }
                        }
                    }

                    .selected-industry {
                        width: 85%;
                        padding: 10px 20px;
                        border: 1px solid #e8cccc;
                        border-radius: 50px;
                        text-align: center;
                        font: 600 1em Lato;
                        background: #f8ecec;
                        color: $sugarbook-main-color;
                    }

                    .industry-item {
                        width: 85%;
                        padding: 10px 20px;
                        border: 1px solid #d8d8d8;
                        border-radius: 50px;
                        cursor: pointer;
                        text-align: center;
                        font: 600 1em Lato;
                        background: white;
                        color: #A2A2A2;
                        transition: all 0.3s;
                    }

                    .selected {
                        border-color: #e8cccc;
                        background: #f8ecec;
                        color: $sugarbook-main-color;
                    }
                }
            }
        }
    }

    .bottom-container {
        height: 80px;
        width: calc(100% - 40px);
        padding: 0 20px;

        .save-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .add-edit-padding-top {
        padding-top: 35px;
    }
}