#leveling-center-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .max-width-container {
        height: 100%;
        width: 100%;
        max-width: 1000px;
        display: flex;
        background-color: white;

        .tab-container {
            height: 100%;
            width: 100%;

            .top-static-container.padding-container {
                height: 230px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .exp-icon-container {
                    height: 50px;
                    width: 110px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    font: 600 1.8em Lato;
                    background: linear-gradient(90deg, rgba(245, 187, 25, 1) 0%, rgba(255, 55, 103, 1) 100%);
                    color: white;
                }

                .level-exp-container {
                    height: 50px;
                    width: 100%;
                    padding-top: 25px;
                    display: flex;

                    .level-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1;

                        .level-label {
                            height: 40%;
                            font: 400 0.8em Lato;
                        }

                        .badge-container {
                            height: 60%;
                            display: flex;
                            align-items: center;

                            .badge {
                                height: 25px;
                            }
                        }
                    }

                    .divider-vertical {
                        height: 70%;
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    .exp-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1;

                        .exp-label {
                            height: 40%;
                            font: 400 0.8em Lato;
                        }

                        .exp {
                            height: 60%;
                            display: flex;
                            align-items: center;
                            font: 600 1.5em Lato;
                        }
                    }
                }

                .progress-bar-container {
                    width: 100%;
                    padding-top: 20px;
                    padding-bottom: 15px;

                    .diamond-count-labels-container {
                        display: flex;
                        justify-content: space-between;

                        .start-diamond-label {
                            font: 400 0.9em Lato;
                        }

                        .end-diamond-label {
                            font: 400 0.9em Lato;
                        }
                    }

                    .progress-bar {
                        height: 30px;
                        margin-top: 5px;
                        margin-bottom: 10px;
                        position: relative;
                        display: grid;
                        align-items: center;
                        border-radius: 25px;
                        background-color: #e9e9e9;

                        .progress-bar-load {
                            height: 30px;
                            width: calc(0% - 40px);
                            position: absolute;
                            border-radius: 25px;
                            z-index: 10;
                            background: linear-gradient(90deg, rgba(173, 210, 21, 1) 0%, rgba(252, 246, 98, 1) 100%);
                            transition: width 1s ease;
                        }

                        .progress-bar-value {
                            text-align: center;
                            z-index: 20;
                        }
                    }

                    .your-progress-label {
                        text-align: center;
                        font: 400 0.9em Lato;
                    }
                }
            }

            .embla {
                height: 100%;
                width: 100%;
                overflow-x: hidden;

                .embla__container {
                    height: 100%;
                    display: flex;

                    .embla__slide {
                        flex: 0 0 100%;
                        min-width: 0;
                    }
                }
            }

            .with-exp-container {
                height: calc(100% - 48px - 270px - 1px); // Tab height + top static container + divider
            }

            .without-exp-container {
                height: calc(100% - 48px - 1px); // Tab height + divider
            }
        }
    }
}

// Utility CSS
.tab-children {
    height: 100%;
}

// Responsive Design
@media (min-width: 1000px) {
    #leveling-center-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}