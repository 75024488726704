#profile-view-terms-of-relationship-subcomponent {

    .padding-container {
        // padding: 20px 0;
    }

    .tor-label {
        font: 600 1.5em Lato;
        color: #333;

        .trade-mark-label {
            font: 600 0.4em Lato;
        }
    }

    .tor-list-container {
        width: calc(100% - 20px);
        // padding: 10px;
        padding: 15px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
}

@media (max-width: 720px) {
    #profile-view-terms-of-relationship-subcomponent {

        .padding-container {
            padding: 20px;
        }

        .tor-list-container {
            // padding: 15px 0 0 0;
            padding: 20px 10px 10px 10px;
        }
    }
}