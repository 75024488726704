#last-stream-viewers-viewer-list-subcomponent {
    padding: 10px 0;
    display: flex;
    align-items: center;

    .user-profile-photo-container {
        width: 50px;

        .user-profile-photo {
            height: 50px;
            width: 50px;
        }
    }

    .viewer-username {
        padding: 0 10px;
        font: 600 1.1em Lato;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}