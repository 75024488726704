#debug-info-subcomponent {
    position: fixed;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    background-color: lightblue;
    // opacity: 0.5;
    z-index: 1000;
    cursor: pointer;
    font-size: 0.8em;

    .debug-header {
        display: flex;
        justify-content: flex-end;
    }

    .pusher-container {

        .pusher-label {
            font: 600 1.1em Lato;
        }

        .global-pusher-container {

            .global-pusher {

                .pusher-state {
                    padding-left: 5px;
                    text-transform: uppercase;
                    font: 600 1em Lato;
                }
            }

            .global-channel {
                padding-left: 10px;

                .pusher-state {
                    padding-left: 5px;
                    text-transform: uppercase;
                    font: 600 1em Lato;
                }
            }
        }

        .live-pusher-container {

            .live-pusher {

                .pusher-state {
                    padding-left: 5px;
                    text-transform: uppercase;
                    font: 600 1em Lato;
                }
            }

            .live-channel {
                padding-left: 10px;

                .pusher-state {
                    padding-left: 5px;
                    text-transform: uppercase;
                    font: 600 1em Lato;
                }
            }
        }

        .video-pusher-container {

            .video-pusher {

                .pusher-state {
                    padding-left: 5px;
                    text-transform: uppercase;
                    font: 600 1em Lato;
                }
            }

            .video-channel {
                padding-left: 10px;

                .pusher-state {
                    padding-left: 5px;
                    text-transform: uppercase;
                    font: 600 1em Lato;
                }
            }
        }

        .messaging-pusher-container {

            .messaging-pusher {

                .pusher-state {
                    padding-left: 5px;
                    text-transform: uppercase;
                    font: 600 1em Lato;
                }
            }

            .messaging-channel {
                padding-left: 10px;

                .pusher-state {
                    padding-left: 5px;
                    text-transform: uppercase;
                    font: 600 1em Lato;
                }
            }
        }

        // Utility CSS
        .state-connected {
            color: darkgreen;
        }

        .state-disconnected {
            color: darkred;
        }
    }

    .livestream-container {

        .live-label {
            font: 600 1.1em Lato;
        }

        .hls {
            padding-left: 10px;

            .hls-state {
                padding-left: 5px;
            }
        }
    }
}