@import "../../../../global-style.scss";

#settings-language-settings-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .language-label {
            font: 600 1.1em Lato;
        }

        .language-description-container {
            display: flex;
            align-items: center;
            gap: 3px;

            .info-icon {
                font-size: 20px;
                color: $sugarbook-grey-text-color;
            }

            .language-description {
                padding-bottom: 1px;
                color: $sugarbook-grey-text-color;
            }
        }

        .toggle-button-container {
            height: 30px;
            display: flex;
            justify-content: center;
        }

        .radio-button-container {
            padding-left: 10px;

            .MuiRadio-colorPrimary.Mui-checked {
                color: $sugarbook-main-color;
            }

            .MuiFormControlLabel-label {
                color: $sugarbook-grey-text-color;
            }
        }
    }
}