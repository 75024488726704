#video-splash-screen-dialog {
    height: 100%;
    position: relative;

    .dialog-header {
        width: 100%;
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        justify-content: flex-end;
        z-index: 1;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
                color: white;
            }
        }
    }

    .splash-screen-video-player {
        height: 100%;
        width: 100%;
        display: block;
        object-fit: cover;
    }

    .splash-screen-max-width {
        max-width: 400px;
    }
}