@import "../../../../global-style.scss";

#global-toast-container {

    .custom-toast {

        .Toastify__toast {
            margin: 6px 6px 0 6px;
            padding: 2px 6px;
            border-radius: 15px;
            backdrop-filter: blur(6px);
            background-color: rgb(51, 51, 51, 0.7);
        }

        .Toastify__toast--success {
            margin: 6px 6px 0 6px;
            padding: 2px 6px;
            border-radius: 15px;
            // backdrop-filter: blur(6px);
            background-color: #96D367;
        }

        .Toastify__toast--error {
            margin: 6px 6px 0 6px;
            padding: 2px 6px;
            border-radius: 15px;
            // backdrop-filter: blur(6px);
            background-color: #EB6258;
        }

        .Toastify__toast--warning {
            margin: 6px 6px 0 6px;
            padding: 2px 6px;
            border-radius: 15px;
            // backdrop-filter: blur(6px);
            background-color: #FCCD28;
        }

        .Toastify__toast--info {
            margin: 6px 6px 0 6px;
            padding: 2px 6px;
            border-radius: 15px;
            // backdrop-filter: blur(6px);
            background-color: #27CBFF;
        }
    }
}