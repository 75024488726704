@import "../../../../global-style.scss";

#desktop-profile-edit-page {
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #F2F2F2;

    .max-width-container {
        height: 100%;
        width: 100%;
        max-width: 1000px;
        display: flex;

        .edit-menu-container {
            height: calc(100% - 100px);
            width: 270px;
            padding: 50px 45px;
            display: flex;
            flex-direction: column;
            gap: 27px;
            overflow: auto;
            background-color: white;

            .profile-photo-container {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .default-circular-progress-bar {
                    height: 150px !important;
                    width: 150px !important;
                    stroke: #ebebeb;
                    color: #ebebeb;
                }

                .circular-progress-bar {
                    height: 150px !important;
                    width: 150px !important;
                    position: absolute;
                    bottom: 0;
                    transform: rotate(-270deg) !important;
                    color: #FFC700;
                }

                .profile-photo {
                    height: 130px;
                    width: 130px;

                }

                .profile-photo-absolute {
                    position: absolute;
                    bottom: 5px;
                }

                .profile-completed-percentage-container {
                    padding: 5px 20px;
                    position: absolute;
                    bottom: -8px;
                    border-radius: 50px;
                    cursor: pointer;
                    font: 600 1.2em Lato;
                    background: #333333;
                    color: white;
                }
            }

            .settings-label-container {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .settings-label {
                    font: 600 1.5em Lato;
                    transition: all 0.3s;
                }

                .short-divider {
                    width: 30px;
                    transition: all 0.3s;
                }
            }

            .basic-information-button-container {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;

                .basic-information-icon-container {
                    width: 30px;
                    transition: all 0.3s;

                    .user-icon {
                        width: 80%;
                    }
                }

                .basic-information-label {
                    width: 180px;
                    color: #979797;
                    transition: all 0.3s;
                }
            }

            .terms-of-relationship-button-container {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;

                .terms-of-relationship-icon-container {
                    width: 30px;
                    transition: all 0.3s;

                    .price-tag-icon {
                        width: 80%;
                    }
                }

                .terms-of-relationship-label {
                    width: 180px;
                    color: #979797;
                    transition: all 0.3s;

                    .trade-mark-label {
                        font: 600 0.5em Lato;
                    }
                }
            }

            .financial-information-button-container {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;

                .financial-information-icon-container {
                    width: 30px;
                    transition: all 0.3s;

                    .bank-icon {
                        width: 80%;
                    }
                }

                .financial-information-label {
                    width: 180px;
                    color: #979797;
                    transition: all 0.3s;
                }
            }

            .location-button-container {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;

                .location-icon-container {
                    width: 30px;
                    transition: all 0.3s;

                    .location-icon {
                        width: 80%;
                    }
                }

                .location-label {
                    width: 180px;
                    color: #979797;
                    transition: all 0.3s;
                }
            }

            .appearance-button-container {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;

                .appearance-icon-container {
                    width: 30px;
                    transition: all 0.3s;

                    .smiley-icon {
                        width: 80%;
                    }
                }

                .appearance-label {
                    width: 180px;
                    color: #979797;
                    transition: all 0.3s;
                }
            }

            .personal-information-button-container {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;

                .personal-information-icon-container {
                    width: 30px;
                    transition: all 0.3s;

                    .lock-icon {
                        width: 80%;
                    }
                }

                .personal-information-label {
                    width: 180px;
                    color: #979797;
                    transition: all 0.3s;
                }
            }

            .description-button-container {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;

                .description-icon-container {
                    width: 30px;
                    transition: all 0.3s;

                    .description-icon {
                        width: 80%;
                    }
                }

                .description-label {
                    width: 180px;
                    color: #979797;
                    transition: all 0.3s;
                }
            }

            .photos-button-container {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;

                .photos-icon-container {
                    width: 30px;
                    transition: all 0.3s;

                    .frame-icon {
                        width: 80%;
                    }
                }

                .photos-label {
                    width: 180px;
                    color: #979797;
                    transition: all 0.3s;
                }
            }

            // Utility CSS
            .complete-status-container {
                display: flex;
                align-items: center;

                .done-icon {
                    color: #63b854;
                }

                .warning-icon {
                    color: #780c0c;
                }
            }
        }

        .edit-content-container {
            height: calc(100% - 100px);
            width: calc(100% - 270px);
            padding: 50px 50px;
            overflow: auto;
            background-color: #F8F4F4;
        }

        // Utility CSS
        .selected-edit-menu {
            filter: brightness(0%);
            font: 600 1em Lato;
        }
    }
}