@import "../../../../global-style.scss";

#update-registration-about-me-dialog {
    height: 100%;
    overflow: hidden;

    .dialog-header {
        padding: 15px;
        display: flex;
        align-items: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .left-container {
            display: flex;
            align-items: center;
            flex: 1;

            .back-icon {
                cursor: pointer;
                font-size: 30px;
            }
        }

        .middle-container {
            flex: 10;
            text-align: center;
            font: 600 1.2em Lato;
        }

        .right-container {
            flex: 1;
        }
    }

    .select-view {
        height: calc(100% - 60px);
        position: relative;
        overflow: auto;

        .embla-navigation {
            width: calc(100% - 40px);
            padding: 30px 20px;
            position: absolute;
            top: 0;
            display: flex;
            justify-content: center;
            gap: 10px;

            .embla-dot-button {
                height: 5px;
                width: 7px;
                border-radius: 50px;
                background: #d8d4d4;
                transition: all 0.3s;
            }

            .active-embla {
                width: 30px; // Increased width
                background: #383434;
            }
        }

        .embla {
            height: 100%;
            width: 100%;
            overflow-x: hidden;

            .embla__container {
                height: 100%;
                display: flex;

                .embla__slide {
                    flex: 0 0 100%;
                    min-width: 0;
                }
            }
        }
    }
}

// Utility CSS
.apply-profile-fullscreen-min-width {
    min-width: 600px;
}