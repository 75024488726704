#onboarding-video-call-room-dialog {
    height: 100%;
    width: 100%;
    position: relative;

    .background-overlay {
        overflow: hidden;
        filter: blur(10px);
    }

    .full-background {
        height: 100%;
        width: 100%;
    }

    .fix-background {
        height: 700px;
        width: 400px;
    }

    .overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        object-fit: contain;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .interactable-container {
        max-width: 330px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 35%;
        left: 0;
        right: 0;

        .button-container {
            height: 25px;
            padding-bottom: 10px;
            display: flex;
            justify-content: flex-end;

            .close-icon {
                cursor: pointer;
                font-size: 28px;
                color: white;
            }
        }

        .description-container {
            border-radius: 25px;
            background-color: white;

            .padding-container {
                padding: 20px;

                .subheader {
                    padding-bottom: 10px;
                    font: bold 1.5em Lato;
                    line-height: 1.4;
                }

                .text {
                    font: 400 1em Lato;
                    line-height: 1.5;
                }

                .live-title {
                    font: 700 1.2em Lato;

                    .x5-text {
                        font: 700 1.5em Lato;
                        color: #FFD80D;
                    }
                }

                .live-description {
                    font: 500 1em/1.4 Lato;

                    .highlight-green {
                        color: #07FF20;
                    }
                }
            }
        }
    }

    .push-down {
        top: 68%;
    }

    .footer {
        width: calc(100% - 60px);
        position: absolute;
        bottom: 0;
        padding: 30px;
        display: flex;
        justify-content: flex-end;

        .done-button {
            cursor: pointer;
            font: 600 1.1em Lato;
            color: white;
        }
    }
}

// Utility CSS
.max-width-container {
    max-width: 400px;
}

// Responsive Design
@media (min-width: 720px) {
    #onboarding-video-call-dialog {

        .footer {
            position: relative;
        }
    }
}