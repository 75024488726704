#interactable-overlay-pin-chat-subcomponent {
    height: 40px;
    margin-bottom: 10px;

    .padding-container {
        padding: 0 10px;
        position: relative;

        .pin-chat-container {
            height: 40px;
            padding-left: 10px;
            display: flex;
            align-items: center;
            border-radius: 15px;
            overflow: hidden;
            background-color: #ffa62099;

            .slider-track-container {
                margin: 0 10px;
                display: flex;
                align-items: center;
                overflow: hidden;

                .slider-track {
                    // width: calc(600px);
                    // min-width: calc(600px);
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .pin-chat-icon-container {

                        .pin-chat-icon {
                            height: 30px;
                        }
                    }

                    .pin-chat-message {
                        white-space: nowrap;
                        font: 500 1em Lato;
                        color: white;
                    }
                }
            }
        }

        .close-button-container {
            height: 15px;
            width: 15px;
            position: absolute;
            top: -18px;
            right: 20px;
            display: flex;
            border-radius: 50px;
            background-color: #00000080;

            .close-button {
                font-size: 15px;
                color: white;
            }
        }
    }
}

// Utility CSS
.scrolling-animation {
    -moz-animation: pin-chat-animation 10s linear infinite;
    -webkit-animation: pin-chat-animation 10s linear infinite;
    animation: pin-chat-animation 10s linear infinite;
}

// @keyframes pin-chat-animation {
//     0% {
//         -moz-transform: translateX(0);
//         -webkit-transform: translateX(0);
//         transform: translateX(0);
//     }

//     100% {
//         -moz-transform: translateX(calc(-150px * 4));
//         -webkit-transform: translateX(calc(-150px * 4));
//         transform: translateX(calc(-150px * 4));
//     }
// }