#last-stream-summary-last-stream-top-gifters-subcomponent {

    .padding-container {
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .top-gifters-label {
            font: 600 1.5em Lato;
        }

        .top-gifters-container {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .gifter-container {
                position: relative;

                .gifter-photo {
                    height: 70px;
                    width: 70px;
                }

                .gifter-position {
                    width: 100%;
                    position: absolute;
                    bottom: 22px;
                    text-align: center;

                    .badge-ranking {
                        height: 20px;
                    }
                }

                .gifter-username {
                    padding-top: 17px;
                    text-align: center;
                    font: 600 1em Lato;
                }
            }
        }
    }
}