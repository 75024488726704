@import "../../../../global-style.scss";

#livestream-memory-leak-alert-component {
    height: 60px;
    background-color: #FF8F92;

    .padding-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .refresh-logo-container {
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;

            .refresh-outer-circle {
                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50px;

                .refresh-logo {
                    font-size: 30px;
                }
            }
        }

        .refresh-description-container {
            width: calc(100% - 70px - 90px);
            display: flex;
            flex-direction: column;
            gap: 2px;

            .update-available-label {
                font: bold 1em Lato;
            }

            .click-to-update-label {
                font: 500 0.7em Lato;
            }
        }

        .update-button-container {
            padding: 0 10px;

            .update-button {
                width: 70px;
                padding: 7px 0;
                border-radius: 50px;
                text-align: center;
                font: 500 0.7em Lato;
                background: $sugarbook-main-color;
                color: white;
            }
        }
    }
}