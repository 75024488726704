@import "../../../../global-style.scss";

#prevent-landscape-dialog {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .landscape-body-container {
        position: relative;
        right: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .first-row {
            display: flex;
            align-items: center;
            gap: 20px;

            .flip-icon-container {
                display: flex;

                .flip-icon {}
            }

            .landscape-title {
                font: 600 3em Lato;
                color: $sugarbook-main-color;
            }
        }

        .second-row {
            font: 500 1.7em Lato;
        }
    }

    .landscape-image-container {
        height: 80%;
        position: absolute;
        bottom: 0;
        right: 5%;

        .landscape-image {
            height: 100%;
            display: block;
        }
    }
}