#result-end-card-shared-component {
    padding: 80px 0 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    .end-card-title {
        text-align: center;
        font: 700 1.8em Lato;
    }

    .end-card-description {
        text-align: center;
        font: 500 1em Lato;
    }
}