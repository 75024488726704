#livestream-report-dialog {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .header {
            display: flex;
            justify-content: space-between;
            font: 600 1.5em Lato;

            .close-icon-container {

                .close-icon {
                    cursor: pointer;
                    font-size: 35px;
                }
            }
        }

        .subheader {
            font: 400 0.8em Lato;
        }

        .buttons-list-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .button-container {
                padding: 14px 20px;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background-color: rgb(239, 239, 239);
            }
        }
    }
}