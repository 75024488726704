#video-call-video-gradient-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient(0deg,
            rgb(0, 0, 0, 0.85) 0%,
            rgba(0, 0, 0, 0) 55%,
            rgba(0, 0, 0, 0) 80%,
            rgba(0, 0, 0, 0.6) 100%);
}