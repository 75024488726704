#top-fans-tab-daily-tab-subcomponent {
    height: 100%;

    .top-fans-container {
        height: 100%;
        overflow: auto;

        .top-fans-list-container {
            height: 100%;
            padding: 0 20px;
        }
    }
}