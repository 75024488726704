@import "./global-style.scss";

#App {
    /* Fallback */
    height: 100%;
    // height: -moz-available;
    // height: -webkit-fill-available;
    // height: fill-available;
    /* Latest specification */
    // height: stretch;

    // height: 100vh; // cause issue for iOS
    width: 100vw;
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
    background-color: $app-background-color;

    .body-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
    }
}

/// Global CSS
.spacer {
    flex: 1 1 auto;
}

.pull-to-refresh-container {
    width: 100%;

    .ptr__pull-down {

        .ptr__pull-down--loading {
            text-align: center;
            color: grey;
        }

        .ptr__pull-down--pull-more {
            text-align: center;
            color: grey;
        }
    }

    .ptr__children {
        overflow: hidden !important;
    }
}

.live-tc-player-dimensions,
.video-js {
    height: unset;
    width: unset;
}

.vjs-menu,
.safe-check-element,
.vjs-text-track-display,
.tcp-loading-spinner,
.vjs-big-play-button,
.vjs-control-bar,
.vjs-hidden,
.vjs-control-bar,
.vjs-error-display {
    display: none;
}

// Animation
@keyframes luminousGradientPosition {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@-webkit-keyframes goPrivatePulse {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(255, 64, 156, 0.7);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 10px rgba(255, 64, 156, 0);
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(255, 64, 156, 0);
    }
}

@-moz-keyframes goPrivatePulse {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(255, 64, 156, 0.7);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 10px rgba(255, 64, 156, 0);
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(255, 64, 156, 0);
    }
}

@-o-keyframes goPrivatePulse {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(255, 64, 156, 0.7);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 10px rgba(255, 64, 156, 0);
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(255, 64, 156, 0);
    }
}

@-ms-keyframes goPrivatePulse {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(255, 64, 156, 0.7);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 10px rgba(255, 64, 156, 0);
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(255, 64, 156, 0);
    }
}

@keyframes goPrivatePulse {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(255, 64, 156, 0.7);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@-webkit-keyframes slideInAndOutLeft {
    0% {
        transform: translateX(-900px);
    }

    20% {
        transform: translateX(0);
    }

    80% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-900px);
    }
}

@-moz-keyframes slideInAndOutLeft {
    0% {
        transform: translateX(-900px);
    }

    20% {
        transform: translateX(0);
    }

    80% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-900px);
    }
}

@keyframes slideInAndOutLeft {
    0% {
        transform: translateX(-900px);
    }

    20% {
        transform: translateX(0);
    }

    80% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-900px);
    }
}

@-webkit-keyframes slideInAndOutRight {
    0% {
        transform: translateX(900px);
    }

    20% {
        transform: translateX(0);
    }

    80% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(900px);
    }
}

@-moz-keyframes slideInAndOutRight {
    0% {
        transform: translateX(900px);
    }

    20% {
        transform: translateX(0);
    }

    80% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(900px);
    }
}

@keyframes slideInAndOutRight {
    0% {
        transform: translateX(900px);
    }

    20% {
        transform: translateX(0);
    }

    80% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(900px);
    }
}

@keyframes spinner-alt {

    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
        opacity: 0.4;
    }

    50% {
        transform: scale(2.5);
        opacity: 1.0;
    }
}

@-webkit-keyframes spinner-fade {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.25;
    }
}

@keyframes spinner-fade {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.25;
    }
}

@keyframes pulse {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1.0;
    }

    100% {
        opacity: 0.5;
    }
}

@keyframes pulse-scale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

// Scrolling Text | px must be quarter of the div total width
@keyframes scroll-left {
    0% {
        -moz-transform: translateX(0);
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -moz-transform: translateX(calc(-97.5px * 4));
        -webkit-transform: translateX(calc(-97.5px * 4));
        transform: translateX(calc(-97.5px * 4));
    }
}

@keyframes scroll-down {
    0% {
        -moz-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -moz-transform: translateY(calc(-10px * 4));
        -webkit-transform: translateY(calc(-10px * 4));
        transform: translateY(calc(-10px * 4));
    }
}

// Switch colors to main color
.custom-switch {

    .MuiSwitch-switchBase.Mui-checked {
        color: $sugarbook-main-color;
    }

    .MuiSwitch-switchBase.Mui-disabled {
        color: rgb(113, 13, 13, 0.5) !important;
    }

    .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
        background-color: $sugarbook-main-color;
    }
}

// Input colors to main color
.custom-text-field {

    .MuiInput-root {
        color: $sugarbook-default-text-color;
    }

    .MuiInput-underline:before {
        border-bottom: $mui-text-field-underline-border-color;
    }

    .MuiInput-underline:after {
        border-bottom-color: $sugarbook-main-text-color !important;
    }

    .MuiInputLabel-root {
        color: $sugarbook-default-text-color;
    }

    label.Mui-focused {
        color: $sugarbook-default-text-color !important;
    }
}

.custom-text-field-label-color {

    .MuiInputLabel-root {
        font-weight: 600 !important;
        color: #9F9F9F;
    }

    .MuiInputLabel-root.Mui-focused {
        font-weight: 600 !important;
        color: $sugarbook-main-color !important;
    }
}

.custom-text-field-no-margin {

    .MuiSelect-select {
        margin: 0 !important;
    }
}

.custom-text-field-15px-radius {

    .MuiInputBase-root {
        border-radius: 15px;
    }
}

.custom-text-field-max-radius {

    .MuiInputBase-root {
        border-radius: 50px;
    }
}

.custom-text-field-outline-default {

    .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
        transition: all 0.09s;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $sugarbook-main-color !important;
    }
}

.custom-text-field-outline-success {

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $mui-text-field-outline-success-border-color !important;
    }
}

.custom-text-field-outline-background-color {
    border-radius: 50px;

    .MuiOutlinedInput-root {
        background-color: $mui-text-field-outline-background-color;
    }
}

.custom-text-field-outline-white-background-color {
    border-radius: 50px;

    .MuiOutlinedInput-root {
        background-color: $app-background-color;
    }
}

// Select colors to main color
.custom-switch-field {

    // Switch with Switch Field
    &.MuiOutlinedInput-root {

        &.Mui-focused fieldset {
            border-color: $sugarbook-main-color !important;
        }
    }

    // Switch with Text Field
    .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $sugarbook-main-color !important;
    }
}

// Custom Toggle Button
.custom-toggle-button {
    width: 100%;

    .MuiToggleButton-root {
        // width: 50%;
        padding: 0;
        flex: 1;
        border: none;
        text-transform: none;
        border-radius: 6px;
        font: 600 0.9em Lato;
        background-color: #f0ecec !important;
    }

    .MuiToggleButton-root.Mui-selected {
        background-color: $sugarbook-main-color !important;
        color: white;
    }
}

// Custom Tabs
.custom-tabs {

    .MuiTab-root {
        min-height: 48px;
        text-transform: none;
        font: 400 0.9em Lato;
    }

    .MuiTab-root.Mui-selected {
        text-transform: none;
        font: 600 0.9em Lato;
        color: $sugarbook-main-color !important;
    }
}

// Custom Tooltip
.MuiTooltip-tooltip {
    border-radius: 10px !important;
    background-color: $sugarbook-main-color !important;
}

// Custom Dialogs
.custom-bottom-sheet-dialog {

    .MuiPaper-root {
        height: auto;
        margin-top: auto;
        border-radius: 20px 20px 0 0;
    }
}

.custom-bottom-sheet-no-radius-fullscreen-dialog {

    .MuiPaper-root {
        margin-top: auto;
        border-radius: 0;
    }
}

.custom-radius10-dialog {

    .MuiDialog-paper {
        border-radius: 10px;
    }
}

.custom-radius25-dialog {

    .MuiDialog-paper {
        border-radius: 25px;
    }
}

.custom-radius35-dialog {

    .MuiDialog-paper {
        border-radius: 35px;
    }
}

.custom-top-radius20-dialog {

    .MuiDialog-paper {
        border-radius: 20px 20px 0 0;
    }
}

.custom-fullscreen-transparent-dialog {

    .MuiDialog-paper {
        background: transparent !important;
        box-shadow: none !important;
    }
}

.custom-dialog-transparent {

    .MuiDialog-paper {
        background: transparent;
        box-shadow: none;
    }
}

.custom-dialog-always-top {
    z-index: 1400 !important;
}

.custom-dialog-margin-top {
    margin-top: 70px;
}

.custom-dialog-height-inherit {

    .MuiDialog-paper {
        height: inherit;
    }
}

.custom-dialog-width-full {

    .MuiDialog-paper {
        width: 100%;
    }
}

// Custom Slider
.custom-slider {

    .MuiSlider-rail {
        background-color: rgb(222, 225, 232);
        background: rgba(0, 0, 0, 0.26);
    }

    .MuiSlider-track {
        color: $sugarbook-main-color;
    }

    .MuiSlider-thumb {
        color: $sugarbook-main-color;
    }

    .Mui-active.MuiSlider-thumb::after {
        opacity: 0.2;
        box-shadow: none;
        background-color: $sugarbook-main-color;
        transition: 1s;
    }
}

.custom-slider-white {

    .MuiSlider-rail {
        background: rgb(222, 225, 232);
        // background: rgba(0, 0, 0, 0.26);
    }

    .MuiSlider-track {
        color: rgba(255, 255, 255, 0.8);
    }

    .MuiSlider-thumb {
        color: white;
    }

    .Mui-active.MuiSlider-thumb::after {
        opacity: 0.2;
        box-shadow: none;
        background-color: $sugarbook-main-color;
        transition: 1s;
    }
}

.custom-slider-red {

    .MuiSlider-rail {
        background: $sugarbook-main-color;
        // background: rgba(0, 0, 0, 0.26);
    }

    .MuiSlider-track {
        color: $sugarbook-main-color;
    }

    .MuiSlider-thumb {
        color: $sugarbook-main-color;
    }

    .Mui-active.MuiSlider-thumb::after {
        opacity: 0.2;
        box-shadow: none;
        background-color: $sugarbook-main-color;
        transition: 1s;
    }
}

.disabled-slider {

    .MuiSlider-rail {
        background-color: rgb(222, 225, 232);
        background: rgb(222, 225, 232);
    }

    .MuiSlider-track {
        color: rgb(222, 225, 232);
    }

    .MuiSlider-thumb {
        color: rgb(222, 225, 232);
    }

    .Mui-active.MuiSlider-thumb::after {
        opacity: 0.2;
        box-shadow: none;
        background-color: rgb(222, 225, 232);
        transition: 1s;
    }
}

// Custom Checkbox
.custom-checkbox {

    &.Mui-checked {
        color: $sugarbook-main-color !important;
    }
}

.custom-checkbox-font {

    .MuiFormControlLabel-label {
        font: 600 0.8em Lato;
    }
}

.custom-checkbox-no-padding {
    padding: 3px !important;
}

// Custom Rating
.custom-rating {

    label {
        padding: 0 10px;
    }

    .MuiRating-label {
        padding: 0;
    }
}

// Custom Menu
.custom-dark-transparent-menu {

    .MuiMenu-paper {
        background-color: #333333CC;
    }
}

// Custom Badge
.custom-badge {

    .MuiBadge-dot {
        background-color: #FF0000;
    }
}

.custom-badge-position {

    .MuiBadge-dot {
        position: relative;
        top: 8px;
        right: 12px;
    }
}

// Custom Speed Dial
.custom-speed-dial-color {

    & .MuiFab-primary {
        backdrop-filter: blur(6px);
        background-color: rgb(51, 51, 51, 0.4) !important;
    }
}

.custom-speed-dial-margin-bottom {

    .MuiSpeedDial-actions {
        margin-bottom: 75px !important;
    }
}