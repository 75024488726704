#video-call-room-page {
    height: 100%;
    width: 100%;
    position: relative;

    .stripped-down-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .stripped-down-top-container {
            padding: 20px;
            display: flex;
            justify-content: flex-end;

            .hide-ui-button-container {
                display: flex;
                justify-content: center;

                .hide-ui-icon {
                    color: white;
                }
            }
        }

        .stripped-down-bottom-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .gifts-section-container {
            width: 100%;
        }
    }

    .interactable-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}