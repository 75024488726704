@import "../../../global-style.scss";

#phone-authentication-page {
    height: 100%;

    .max-width-container {
        height: calc(100% - 50px);
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;

        .navbar-container {
            height: 30px;
            display: flex;
            align-items: center;

            .back-button {
                width: 50px;
                cursor: pointer;

                .signup-back-icon {
                    height: 30px;
                }
            }
        }

        .top-container {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 10px;

            .create-account-label {
                padding-left: 10px;
                font: 700 1.6em Lato;
                color: white;
            }

            .phone-container {
                padding-top: 16px;
                display: flex;
                gap: 10px;

                .country-form-field-container {
                    width: 30%;
                    margin-left: auto;
                    margin-right: auto;

                    .country-form-field {
                        width: 100%;

                        .MuiInput-underline:before {
                            border-bottom: 2px solid rgba(255, 255, 255, 0.4);
                        }

                        .MuiInput-underline:after {
                            border-bottom-color: $sugarbook-main-text-color;
                        }

                        .MuiInput-input {
                            padding: 4px 0 7px 5px;
                            color: white;
                        }

                        .MuiSelect-select {
                            margin-top: 0;
                        }
                    }
                }

                .phone-form-field-container {
                    height: 50px;
                    width: 70%;
                    margin-left: auto;
                    margin-right: auto;

                    .phone-form-field {
                        width: 100%;

                        .MuiInput-underline:before {
                            border-bottom: 2px solid rgba(255, 255, 255, 0.4);
                        }

                        .MuiInput-underline:after {
                            border-bottom-color: $sugarbook-main-text-color;
                        }

                        .MuiInput-input {
                            padding: 4px 0 7px 5px;
                            color: white;
                        }
                    }
                }
            }

            .number-exist-container {
                margin-top: 5px;
                padding: 20px 20px 20px 10px;
                display: flex;
                border-radius: 15px;
                background: rgba(255, 255, 255, 0.4);

                .caution-icon-container {
                    width: 40px;

                    .caution-icon {
                        width: 20px;
                    }
                }

                .number-exist-description {
                    margin-top: 0;
                    margin-bottom: 0;
                    font: 500 0.9em Lato;
                    color: white;
                }
            }

            .terms-and-condition {
                padding: 15px 20px 20px 10px;
                font: 500 0.8em/1.6 Lato;
                color: white;
            }
        }

        .bottom-container {
            height: 150px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .next-button {
                height: calc(47px - 2px);
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid $sugarbook-main-color;
                border-radius: 25px;
                cursor: pointer;
                text-decoration: none;
                font: 600 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
                transition: all 0.5s;
            }

            .disabled-button {
                height: 47px;
                border: none;
                cursor: not-allowed;
                background-color: #8C8283;
                color: $sugarbook-main-color;
            }

            .skip-button {
                cursor: pointer;
                text-decoration: underline;
                text-transform: capitalize;
                text-align: center;
                font: 500 1em Lato;
                color: white;
            }
        }
    }
}