#chat-section-joining-element-subcomponent {
    width: fit-content;
    padding: 2px 5px;
    margin: 0px 10px;
    display: flex;
    border-radius: 10px;

    .profile-picture-container {
        width: 45px;
        min-width: 45px;
        display: flex;
        align-items: center;

        .profile-picture {
            height: 30px;
            width: 30px;
        }
    }

    .chat-info-container {
        width: calc(100% - 45px);
        display: flex;
        align-items: center;

        .chat-info {
            width: 100%;
            align-items: center;
            gap: 7px;

            .livestreaming-achievement-badge-container {

                .livestreaming-achievement-badge {
                    height: 13px;
                    margin-right: 3px;
                }
            }

            .username {
                max-width: calc(100% - 40px - 153px - 14px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .levelling-badge-container {
                margin: 0 3px;

                .levelling-badge {
                    height: 13px;
                    position: relative;
                    top: 2px;
                }
            }

            .joined-text {}
        }
    }
}