@import "../../../../global-style.scss";

#blocked-members-blocked-list-subcomponent {

    .padding-container {
        padding-left: 15px;
        padding-right: 15px;

        .user-container {
            height: 90px;
            display: flex;

            .user-image-container {
                width: 75px;
                display: flex;
                align-items: center;

                .user-image {
                    height: 75px;
                    width: 75px;
                }
            }

            .user-name-container {
                width: calc(100% - 75px - 100px - 15px); // user-image-container - button-container - padding-left
                padding: 15px 0 15px 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                flex: 1;

                .user-name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font: 500 1.1em Lato;
                }
            }

            .button-container {
                width: 100px;
                display: flex;
                align-items: center;

                .unblock-button {
                    height: 35px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    cursor: pointer;
                    font: 600 0.9em Lato;
                    background-color: $sugarbook-main-color;
                    color: white;
                }
            }
        }
    }

    .divider {
        width: calc(100% - 100px);
        margin-left: auto;
    }
}