@import "../../../../global-style.scss";

#dual-pk-end-result-dialog {
    background: #333333;

    .padding-container {
        max-width: 265px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        padding: 40px 30px;

        .pk-end-label {
            font: 600 1.3em Lato;
            color: white;
        }

        .dual-pk-winner-username-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .dual-pk-winner-label {
                font: 600 1.3em Lato;
                color: #FFF000;
            }

            .dual-pk-winner-username {
                font: 600 1.3em Lato;
                color: #FFF000;
            }
        }

        .dual-pk-contestant-profile-photo-container {
            width: 100%;
            display: flex;
            align-items: flex-start;

            .dual-pk-contestant-1-profile-photo-container {
                width: calc(50% - 55px);
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;

                .dual-pk-gradient-padding {
                    padding: 5px;
                    border-radius: 50px;
                    background: linear-gradient(90deg, #1F93FF 0%, rgba(31, 147, 255, 0.3) 100%);
                }

                .dual-pk-contestant-1-username {
                    text-align: center;
                    font: 500 1em Lato;
                    color: white;
                }
            }

            .dual-pk-icon-container {
                width: 110px;
                display: flex;
                justify-content: center;

                .dual-pk-icon {
                    width: 95px;
                }
            }

            .dual-pk-contestant-2-profile-photo-container {
                width: calc(50% - 55px);
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;

                .dual-pk-gradient-padding {
                    padding: 5px;
                    border-radius: 50px;
                    background: linear-gradient(270deg, #FF1EA5 0%, rgba(255, 30, 165, 0.3) 100%);
                }

                .dual-pk-contestant-2-username {
                    text-align: center;
                    font: 500 1em Lato;
                    color: white;
                }
            }
        }

        .dual-pk-results-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .dual-pk-rounds-result-container {
                display: flex;
                justify-content: space-evenly;

                .n-round-score {
                    flex: 1;
                    text-align: center;
                    font: 600 1em Lato;
                    color: white;
                }

                .n-round-container {
                    width: 95px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    .dual-pk-medal-container {

                        .dual-pk-medal-icon {
                            width: 8px;
                        }

                        // Utility CSS
                        .grey-medal {
                            filter: grayscale(100%);
                        }
                    }

                    .dual-pk-round-label {
                        font: 600 0.8em Lato;
                        color: white;
                    }
                }
            }

            .dual-pk-final-result-container {
                display: flex;
                justify-content: space-evenly;
                align-items: center;

                .final-score {
                    flex: 1;
                    text-align: center;
                    font: 600 1em Lato;
                    color: white;
                }

                .total-diamonds-container {
                    width: 95px;
                    text-align: center;
                    font: 600 0.8em Lato;
                    color: white;
                }
            }

            .white-divider {
                background: white;
            }
        }

        .top-gifters-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .top-gifters-label {
                font: 600 1.3em Lato;
                color: white;
            }

            .top-gifters-list-container {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .top-gifter-container {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 1;
                    gap: 10px;

                    .top-gifter-profile-photo {}

                    .dual-pk-placement-medal-icon-container {
                        position: absolute;
                        top: 30px;
                        display: flex;

                        .dual-pk-placement-medal-icon {
                            width: 13px;
                        }
                    }

                    .top-gifter-username {
                        text-align: center;
                        font: 500 0.8em Lato;
                        color: white;
                    }
                }
            }
        }

        .close-button {
            padding: 10px 50px;
            margin-top: 30px;
            display: flex;
            align-items: center;
            border-radius: 50px;
            cursor: pointer;
            font: 600 1em Lato;
            background: white;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .highlighted-yellow {
        color: #FFF000 !important;
    }
}