@import "../../../../../global-style.scss";

#about-me-subcomponent {
    height: 100%;

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .top-container {
            // height: calc(100% - 20px);
            padding: 30px 30px 20px 30px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex: 1;

            .about-me-title {
                text-align: center;
                font: 600 1.9em Lato;
            }

            .about-me-description {
                text-align: center;
                font: 500 1em Lato;
                color: #A2A2A2;
            }

            .selected-question-icon-container {
                display: flex;
                justify-content: center;

                .selected-question-icon {
                    height: 25px;
                }
            }

            .selected-question-label {
                text-align: center;
                font: 600 1.5em Lato;
            }

            .selected-question-max-label {
                text-align: center;
                font: 500 1em Lato;
                color: #A2A2A2;
            }

            .about-me-choose-options {
                padding-top: 10px;
                font: 500 1em Lato;
                color: #A2A2A2;
            }

            .about-me-body-container {
                padding-top: 30px;
                display: flex;
                flex-wrap: wrap;
                // flex: 1;
                gap: 10px;
                overflow: auto;

                .about-me-item-container {
                    width: 100%;
                    padding: 15px 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 2px solid #d8d8d8;
                    border-radius: 10px;
                    cursor: pointer;
                    transition: all 0.3s;

                    .about-me-item-label {
                        font: 600 1em Lato;
                    }

                    .about-me-selected {
                        height: 10px;
                        width: 10px;
                        border: 3px solid #a8a4a4;
                        border-radius: 50px;
                        transition: all 0.3s;
                    }
                }

                .selected-about-me {
                    border-color: #e0bcbc;
                    background: #f8e4ec;

                    .about-me-selected {
                        border-color: #780c14;
                        background: #a86464;
                    }
                }

                .about-me-manual-text-container {
                    width: 100%;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .about-me-manual-text-field {
                        width: 100%;

                        .MuiOutlinedInput-root {
                            border-radius: 20px;
                        }

                        .Mui-focused {

                            fieldset.MuiOutlinedInput-notchedOutline {
                                border-color: $sugarbook-main-color !important;
                                transition: border-color 0.3s;
                            }
                        }
                    }

                    .about-me-status-not-approved {

                        .MuiOutlinedInput-root {
                            padding-top: 40px;
                        }
                    }

                    .about-me-status-container {
                        width: fit-content;
                        padding: 5px 7px;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        border-radius: 50px;
                        text-transform: uppercase;
                        white-space: nowrap;
                        font: 700 0.6em Lato;
                    }

                    .rejected-status-container {
                        color: #FF0000;
                        background: #FFDCDC;
                    }

                    .in-review-status-container {
                        color: $sugarbook-grey-text-color;
                        background: #D9D9D9;
                    }

                    .about-me-word-count {
                        padding-right: 5px;
                        text-align: right;
                        font: bold 0.8em Lato;
                        color: #B8B8B8;
                    }

                    .about-me-manual-text-footer {
                        padding-top: 5px;
                        text-align: center;
                        font: bold 0.8em Lato;
                    }
                }

                .about-me-answer-container {
                    // width: fit-content;
                    padding: 7px 20px;
                    border: 1px solid #d8d8d8;
                    border-radius: 50px;
                    cursor: pointer;
                    text-align: center;
                    font: 600 1em Lato;
                    background: white;
                    color: #A2A2A2;
                    transition: all 0.3s;
                }

                .about-me-answer-hobby-container {
                    width: 100%;
                    padding-bottom: 10px;

                    .sub-category-about-me-label {
                        padding: 10px 5px;
                        font: 600 1.2em Lato;
                        color: black;
                        transition: all 0.3s ease-in-out;
                    }

                    .sub-category-about-me-content-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;

                        .about-me-content {
                            width: fit-content;
                            padding: 7px 20px;
                            border: 1px solid #d8d8d8;
                            border-radius: 50px;
                            cursor: pointer;
                            text-align: center;
                            font: 600 1em Lato;
                            background: white;
                            color: #A2A2A2;
                            transition: all 0.3s;
                        }

                        .selected {
                            border-color: #e8cccc;
                            background: #f8ecec;
                            color: $sugarbook-main-color;
                        }
                    }
                }

                .selected {
                    border-color: #e8cccc;
                    background: #f8ecec;
                    color: $sugarbook-main-color;
                }
            }
        }

        .description-container {
            min-height: 50px;
            padding: 10px;
            border-radius: 15px;
            overflow: auto;
            font: 500 0.9em Lato;
            color: $sugarbook-main-color;
            background: #f8e4ec;
        }
    }

    .apply-edit-about-me-height {
        height: calc(100% - 80px - 40px);
    }

    .bottom-container {
        height: 80px;
        width: calc(100% - 40px);
        padding: 0 20px;

        .save-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .add-edit-padding-top {
        padding-top: 35px;
    }

    .partial-width {
        width: 100%;
    }

    .fix-width {
        width: 150px;
    }

    .fit-content {
        width: fit-content;
    }

    .flex-start {
        justify-content: flex-start;
    }

    .justify-center {
        justify-content: center;
    }
}

// Responsive Design
@media (max-width: 720px) {
    #about-me-subcomponent {

        .padding-container {

            .top-container {
                padding: 30px 0 20px 0;

                .about-me-description {
                    font: 500 0.9em Lato;
                }

                .about-me-body-container {
                    padding-top: 10px;
                }
            }
        }
    }
}