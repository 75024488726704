@import "../../../../global-style.scss";

#sugarbook-verified-camera-dialog {
    height: 100%;
    // background-color: #333333e6;
    background-color: black;

    .dialog-header {
        height: 33px;
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: space-between;

        .flip-image-button-container {
            cursor: pointer;

            .flip-image-button {
                font-size: 30px;
                color: white;
            }
        }

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
                color: white;
            }
        }
    }

    .padding-container {
        height: calc(100% - 53px - 20px);
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        gap: 25px;

        .aspect-ratio-container {
            width: 100%;
            position: relative;
            padding-bottom: 115%;

            #sugarbook-verified-video {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                object-fit: cover;
                background-color: black;
                transform: scaleX(-1);
            }

            .captured-image {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                object-fit: cover;
                transform: scaleX(-1);
            }

            .countdown-overlay {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font: 500 6em Lato;
                color: white;
            }
        }

        .button-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            .outer-circle {
                height: 60px;
                width: 60px;
                border-radius: 50px;
                cursor: pointer;
                background-color: white;

                .inner-circle {
                    height: calc(100% - 8px);
                    width: calc(100% - 8px);
                    margin: 2px;
                    border: 2px solid black;
                    border-radius: 50px;
                    background-color: white;
                }
            }

            .use-image-container {
                width: calc(100% - 40px);
                padding: 0 20px;
                display: flex;
                justify-content: space-between;

                .use-photo-label,
                .retake-label {
                    cursor: pointer;
                    font: 500 1em Lato;
                    color: white;
                }
            }

            .proceed-button {
                padding: 10px 0;
                border-radius: 25px;
                cursor: pointer;
                text-align: center;
                font: 600 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .cancel-button {
                padding: 9px 0;
                border: 1px solid white;
                border-radius: 25px;
                cursor: pointer;
                text-align: center;
                font: 600 1em Lato;
                color: white;
            }
        }
    }
}