@import "../../../global-style.scss";

#mobile-verification-page {
    height: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        height: 100%;
        width: 100%;
        max-width: 450px;
        position: relative;

        .padding-container {
            height: calc(100% - 50px);
            padding: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 30px;

            .navbar-container {
                height: 30px;
                display: flex;
                align-items: center;

                .back-button {
                    width: 50px;
                    cursor: pointer;

                    .signup-back-icon {
                        height: 30px;
                    }
                }
            }

            .top-container {
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 65px;

                .mobile-verification-top-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;

                    .enter-verification-label {
                        font: 600 1.7em Lato;
                        color: white;
                    }

                    .code-sent-label {
                        text-align: center;
                        font: 500 0.9em Lato;
                        color: white;
                    }

                    .update-mobile-label {
                        cursor: pointer;
                        text-decoration: underline;
                        font: 600 0.9em Lato;
                        color: white;
                    }

                    .mobile-label {
                        font: 600 0.9em Lato;
                        color: white;
                    }
                }

                .mobile-verification-middle-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 2;
                    gap: 20px;

                    .code-input-container {

                        .mobile-code-input {
                            height: 2rem;
                            width: 150px;
                            margin: 0 0.3rem;
                            padding: 5px;
                            border: 2px solid #D0D0D0;
                            border-radius: 10px;
                            outline: none;
                            text-align: center;
                            letter-spacing: 5px;
                            font: 600 2em Lato;
                            caret-color: $sugarbook-main-color;
                        }
                    }

                    .invalid-code-label {
                        font: 500 0.8em Lato;
                        color: #FF0000;
                    }

                    .resend-code-button-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 10px;

                        .resend-code-description {
                            text-align: center;
                            font: 500 0.8em Lato;
                            color: white;
                        }

                        .resend-code-label-container {
                            display: flex;
                            align-items: center;
                            gap: 3px;

                            .resend-code-label {
                                cursor: pointer;
                                text-decoration: underline;
                                font: 600 0.8em Lato;
                                color: white;
                            }

                            .resend-countdown-label {
                                font: 600 0.8em Lato;
                                color: white;
                            }
                        }
                    }

                    .mobile-verification-or-container {
                        width: calc(100% - 80px);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .or-divider {
                            width: calc(50% - 40px);
                            color: white;
                        }

                        .or-label {
                            padding: 0 40px;
                            color: white;
                        }
                    }

                    .phone-call-button {
                        cursor: pointer;
                        text-decoration: underline;
                        font: 600 0.9em Lato;
                        color: white;
                    }

                    .incoming-call-container {
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 5px;

                        .incoming-call-icon-container {
                            padding-right: 10px;
                            display: flex;

                            .incoming-call-icon {
                                height: 30px;
                                animation: ringing 1s infinite;
                            }
                        }

                        .incoming-call-label {
                            font: 600 1em Lato;
                            color: white;
                        }

                        .incoming-call-seconds-label {
                            color: white;
                        }
                    }

                    // Utility CSS
                    .disable-button {
                        cursor: not-allowed !important;
                        color: rgba(255, 255, 255, 0.5) !important;
                    }
                }
            }

            .bottom-container {
                height: 140px;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .mobile-verification-bottom-container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 1;
                    gap: 15px;

                    .submit-button {
                        height: 45px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 25px;
                        cursor: pointer;
                        font: 600 1.1em Lato;
                        background-color: $sugarbook-main-color;
                        color: white;
                        transition: all 0.5s;
                    }

                    .skip-button {
                        height: 47px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 25px;
                        cursor: pointer;
                        font: 600 1em Lato;
                        color: $sugarbook-main-color;
                        transition: all 0.5s;
                    }

                    .mobile-verification-utility-container {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;

                        .contact-support-button-container {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            .need-help-label {
                                font: 500 0.8em Lato;
                                color: white;
                            }

                            .contact-us-label {
                                cursor: pointer;
                                text-decoration: underline;
                                font: 600 0.8em Lato;
                                color: white;
                            }
                        }
                    }

                    // Utility CSS
                    .disable-submit-button {
                        cursor: not-allowed;
                        background: #8C8283;
                        color: $sugarbook-main-color;
                    }
                }
            }
        }

        .lottie-overlay-container {
            height: 300px;
            width: 250px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 35px;
            z-index: 30;
            background-color: white;

            .general-success-lottie,
            .general-error-lottie {
                width: 100px;
            }

            .verification-status-label {
                text-align: center;
                font: 600 1.4em Lato;
            }
        }
    }
}

@keyframes ringing {
    0% {
        transform: rotate(-15deg);
    }

    50% {
        transform: rotate(15deg);
    }

    100% {
        transform: rotate(-15deg);
    }
}

// Responsive Design
@media (min-width: 720px) {
    #mobile-verification-page {

        .max-width-container {

            .padding-container {
                margin-top: 20%;
                justify-content: flex-start;

                .top-container {
                    flex: none;
                }

                .bottom-container {

                    .email-verification-bottom-container {
                        padding-top: 20%;
                    }
                }
            }
        }
    }
}