#search-card-container-newest-view-subcomponent {

    .newest-list-container {
        height: 100%;

        >div {
            height: 100%;

            .infinite-scroller-container {
                padding-bottom: 10px;
                grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
                gap: 10px;
            }
        }
    }
}

// Utility CSS
.full-height {
    height: 100%;
}

.grid {
    display: grid;
}

.list {
    display: block;
}