#ongoing-events-pk-container-subcomponent {
    cursor: pointer;

    .pk-event-container {
        width: 119px;
        padding: 6px 8px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        border-radius: 18px;
        line-height: 1.7;
        background: linear-gradient(180.34deg, rgba(0, 71, 255, 0.57) 15.76%, rgba(72, 16, 60, 0.57) 77.35%, #5200FF 148.56%);

        .pk-contestant-container {
            display: flex;
            justify-content: center;
            align-items: center;

            .pk-contestant-1 {
                position: relative;
                left: 10px;
                z-index: 10;

                .pk-contestant-img {
                    height: 35px;
                    width: 35px;
                    object-fit: cover;
                    border-radius: 25px;
                }
            }

            .pk-contestant-2 {
                position: relative;
                right: 10px;
                z-index: 10;

                .pk-contestant-img {
                    height: 35px;
                    width: 35px;
                    object-fit: cover;
                    border-radius: 25px;
                }
            }

            .pk-logo-container {
                z-index: 20;

                .pk-logo {
                    max-width: 45px;
                }
            }
        }

        .score-container {
            height: 3px;
            position: relative;
            bottom: 15px;
            display: flex;
            justify-content: space-around;
            z-index: 10;

            .contestant-one-score {
                height: 15px;
                width: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                font: 600 12px Lato;
                background-color: #FF0000;
                color: white;
            }

            .contestant-two-score {
                height: 15px;
                width: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                font: 600 12px Lato;
                background-color: #FF0000;
                color: white;
            }
        }

        .pk-round {
            height: 20px;
            text-align: center;
            font: 800 15px Lato;
            color: #00FF19;
        }

        .pre-start-timer {
            text-align: center;
            font: 500 13px Lato;
            color: #FFF000;
        }

        .pk-timer {
            text-align: center;
            font: 600 13px Lato;
            color: white;
        }

        .pk-ended-label {
            text-align: center;
            font: 500 13px Lato;
            color: #FF0000;
        }

        .pk-winner {
            line-height: 1.2;
            font: 700 12px Lato;

            .pk-winner-label {
                text-align: center;
                color: #FFF000;
            }

            .winner-label {
                text-align: center;
                color: #FFF000;
            }
        }

        .pk-contestant {
            display: flex;
            font: 700 11px Lato;

            .medal-logo-container {
                width: 15%;
                display: flex;
                justify-content: center;
                align-items: center;

                .individual-medal-logo-container {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .medal-logo {
                        max-width: 7px;
                    }
                }
            }

            .contestant-name {
                width: 50px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font: 600 12px Lato;
                color: white;
            }

            .colon {
                margin-right: 5px;
                color: white;
            }

            .contestant-diamond {
                color: #00DBF9;
            }

            .diamond-logo-container {
                display: flex;
                justify-content: center;
                align-self: center;

                .diamond-logo {
                    max-width: 12px;
                    margin-left: 5px;
                }
            }
        }
    }

    // Utility CSS
    .timer-green {
        color: #00FF19;
    }

    .timer-red {
        color: #FF0000;
    }

    .pulsing-animation {
        animation: pulse 1s infinite ease-out;
        animation-play-state: running;
    }

    .winner {
        color: #FFF000 !important;
    }

    .winner-card-border {
        border: 3px solid transparent;
        border-radius: 20px;
        background: linear-gradient(90deg, #ffa927 0%, #ff409c 70%, #9534b4 100%) padding-box,
            linear-gradient(90deg, #ffa927 0%, #ff409c 70%, #9534b4 100%) border-box;
        background-size: 600% 600%;
        -webkit-animation: luminousGradientPosition 1s ease infinite;
        -moz-animation: luminousGradientPosition 1s ease infinite;
        animation: luminousGradientPosition 1s ease infinite;
    }
}

// Utility CSS
.pulsing-scale-animation {
    animation: pulse-scale 1s infinite ease-out;
    animation-play-state: running;
}