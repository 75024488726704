// Sugarbook main color
$sugarbook-main-color: var(--sugarbook-main-color);

// App background color
$app-background-color: var(--app-background-color);

// Desktop background color
$desktop-background-color: var(--desktop-background-color);

// Octopus navbar
$octopus-navbar-color: var(--octopus-navbar-color);

// Text Color
$sugarbook-main-text-color: var(--sugarbook-main-text-color);
$sugarbook-default-text-color: var(--sugarbook-default-text-color);
$sugarbook-grey-text-color: var(--sugarbook-grey-text-color);

// MUI Text Field Color
$mui-text-field-underline-border-color: var(--mui-text-field-underline-border-color);
$mui-text-field-outline-success-border-color: var(--mui-text-field-outline-success-border-color);
$mui-text-field-outline-background-color: var(--mui-text-field-outline-background-color);

// MUI Icon Color
$mui-icon-default-color: var(--mui-icon-default-color);

// MUI Button Color
$mui-disabled-button-background-color: var(--mui-disabled-button-background-color);
$mui-disabled-button-color: var(--mui-disabled-button-color);

// MUI Tabs Color
$mui-tabs-border-bottom-color: #D8D8D8;

// Join Page
$join-page-border-color: var(--join-page-border-color);
$join-page-text-color: var(--join-page-text-color);