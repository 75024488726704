@import "../../../../global-style.scss";

#edit-profile-unsaved-changes-dialog {
    max-width: 280px;

    .padding-container {
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .top-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;

            .unsaved-changes-title {
                text-align: center;
                font: 600 1.7em Lato;
            }

            .unsaved-changes-description {
                text-align: center;
                font: 500 1em Lato;
                color: #a8a4a4;
            }
        }

        .button-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .bring-me-back-button {
                width: 100%;
                padding: 15px 0;
                border-radius: 50px;
                text-align: center;
                cursor: pointer;
                font: 600 1em Lato;
                background: $sugarbook-main-color;
                color: white;
            }

            .discard-changes-button {
                width: 100%;
                padding: 15px 0;
                text-align: center;
                cursor: pointer;
                font: 600 1em Lato;
                color: $sugarbook-main-color;
            }
        }
    }
}