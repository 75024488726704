#settings-dark-mode-settings-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .dark-mode-label {
            font: 600 1.1em Lato;
        }

        .dark-mode-switch-container {
            display: flex;
            justify-content: center;
        }

        .dark-theme-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .toggle-button-container {
                display: flex;
                justify-content: center;
            }
        }
    }
}