.rejected-container {
    max-width: 500px;
    padding: 15px;
    // margin: 10px;
    display: flex;
    border-radius: 10px;
    background-color: #EB6258;

    .left-container {
        display: flex;
        align-items: center;
        flex: 2;

        .moderation-icon-container {
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background-color: white;

            .error-icon {
                border-radius: 50px;
                font-size: 27px;
                background-color: #EB6258;
                color: white;
            }
        }
    }

    .right-container {
        display: flex;
        flex-direction: column;
        flex: 8;
        gap: 5px;

        .moderation-title {
            font: 600 1em Lato;
            color: #333333;
        }

        .moderation-desc {
            font: 500 0.8em Lato;
            color: #333333;
        }
    }
}

.in-review-container {
    max-width: 500px;
    padding: 10px;
    // margin: 10px;
    display: flex;
    border-radius: 10px;
    background-color: #27CBFF;

    .left-container {
        width: 60px;
        padding-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        .moderation-icon-container {
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background-color: white;

            .info-icon {
                font-size: 35px;
                color: #27CBFF;
            }
        }
    }

    .right-container {
        display: flex;
        flex-direction: column;
        flex: 8;
        gap: 5px;

        .moderation-title {
            font: 600 1em Lato;
        }

        .moderation-desc {
            font: 500 0.8em Lato;
        }
    }
}

// Utility CSS
.full-moderation-width {
    width: calc(100% - 20px);
}

.apply-margin {
    margin: 10px;
}