@import "../../../../global-style.scss";

#missing-tor-dialog {
    height: 100%;
    position: relative;

    .main-view {
        height: 100%;

        .tor-background-image-container {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;

            .tor-background-image {
                height: 100%;
                width: 100%;
                display: block;
                object-fit: cover;
            }
        }

        .padding-container {
            height: calc(100% - 40px);
            position: relative;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;

            .title {
                font: 700 2em Lato;
                color: white;
            }

            .description {
                font: 400 0.9em/1.4 Lato;
                color: white;
            }

            .tags-container {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                .tag-container {
                    width: fit-content;
                    padding: 7px 10px;
                    border: 1px solid white;
                    border-radius: 50px;
                    font: 600 1em Lato;
                    color: white;
                }
            }

            .next-button {
                padding: 15px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background: linear-gradient(171.74deg, #ED0E0E 19.04%, #860C0C 94.62%);
                color: white;
            }
        }
    }

    .select-view {
        height: 100%;

        .tor-background-image-container {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;

            .tor-background-image {
                height: 100%;
                width: 100%;
                display: block;
                object-fit: cover;
            }
        }

        .padding-container {
            height: calc(100% - 40px);
            position: relative;
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .title {
                font: 700 1.2em Lato;

                .smaller-font {
                    font-size: 10px;
                }
            }

            .selected-label {
                text-align: center;
                font: 500 0.9em Lato;
                color: #979797;
            }

            .description {
                font: 400 0.9em/1.4 Lato;
            }

            .tags-container {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                .tag-container {
                    width: fit-content;
                    padding: 6px 14px;
                    border: 1.2px solid rgba(113, 13, 13, 0.2);
                    border-radius: 50px;
                    cursor: pointer;
                    font: 500 0.8em Lato;
                    color: $sugarbook-main-color;
                }

                .selected-tag {
                    background-color: #f4ecec;
                }
            }

            .tag-details {
                padding: 12px 24px;
                border-radius: 12px;
                font-size: 14px;
                line-height: 1.4;
                background-color: #F6F6F6;
            }

            .save-button {
                width: 90%;
                margin: 20px auto;
                padding: 15px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background: $sugarbook-main-color;
                color: white;
            }

            // Utility CSS
            .disable-button {
                cursor: not-allowed;
                background-color: rgba(0, 0, 0, 0.12);
            }
        }
    }

    // Utility CSS
    .max-tags {
        color: #ff0000 !important;
    }
}

// Utility CSS
.max-width-tor-container {
    max-width: 350px;
}