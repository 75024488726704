@import "../../../global-style.scss";

#account-signup-page {
    height: 100%;

    .max-width-container {
        // height: calc(90% - 50px);
        max-width: 530px;
        margin-left: auto;
        margin-right: auto;
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;

        .navbar-container {
            height: 30px;
            display: flex;
            align-items: center;

            .back-button {
                width: 50px;
                cursor: pointer;

                .signup-back-icon {
                    height: 30px;
                }
            }
        }

        .create-account-label {
            font: 600 1.5em Lato;
            color: white;
        }

        .gender-looking-for-container {
            display: flex;
            align-items: flex-start;
            gap: 5px;

            .gender-container {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .i-am-label {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .i-am-a-label {
                        font: 500 1.1em Lato;
                        color: white;
                    }

                    .selected-gender {
                        text-decoration: underline;
                        text-transform: capitalize;
                        font: 600 1.3em Lato;
                        color: white;
                    }

                    .switch-gender-container {
                        padding-left: 5px;
                        display: flex;
                        gap: 5px;
                        cursor: pointer;

                        .switch-icon {
                            width: 15px;
                        }

                        .switch-gender-label {
                            font: 500 0.7em Lato;
                            color: #ffffffa2;
                        }
                    }
                }

                .non-binary-gender-options-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .i-am-a-label {
                        font: 500 1.1em Lato;
                        color: transparent;
                    }

                    .selected-gender {
                        text-decoration: underline;
                        text-transform: capitalize;
                        font: 600 1.3em Lato;
                        color: white;
                    }

                    .switch-role-container {
                        padding-left: 5px;
                        display: flex;
                        gap: 5px;
                        cursor: pointer;

                        .switch-icon {
                            width: 15px;
                        }

                        .switch-gender-label {
                            font: 500 0.7em Lato;
                            color: #ffffffa2;
                        }
                    }
                }
            }

            .looking-for-container {
                display: flex;
                align-items: center;
                gap: 5px;

                .looking-for-a-label {
                    font: 500 1.1em Lato;
                    color: white;
                }

                .selected-looking-for {
                    text-decoration: underline;
                    text-transform: capitalize;
                    font: 600 1.3em Lato;
                    color: white;
                }

                .switch-role-container {
                    padding-left: 5px;
                    display: flex;
                    gap: 5px;
                    cursor: pointer;

                    .switch-icon {
                        width: 15px;
                    }

                    .switch-gender-label {
                        font: 500 0.7em Lato;
                        color: #ffffffa2;
                    }
                }
            }
        }

        .account-signup-form-field-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .email-form-field-container {
                height: 40px;
                width: 100%;

                .email-form-field {
                    width: 70%;

                    .MuiInput-underline:before {
                        border-bottom: 2px solid rgba(255, 255, 255, 0.4);
                    }

                    .MuiInput-underline:after {
                        border-bottom-color: white;
                    }

                    .MuiInput-input {
                        padding: 4px 0 7px 5px;
                        color: white;
                    }

                    .MuiFormHelperText-root {
                        font-weight: bold;
                        color: #FF0000;
                    }
                }
            }

            .password-form-field-container {
                height: 40px;
                width: 100%;

                .password-form-field {
                    width: 70%;

                    .MuiInput-underline:before {
                        border-bottom: 2px solid rgba(255, 255, 255, 0.4);
                    }

                    .MuiInput-underline:after {
                        border-bottom-color: white;
                    }

                    .MuiInput-input {
                        padding: 4px 0 7px 5px;
                        color: white;
                    }

                    .visibility-icon {
                        color: white;
                    }

                    .MuiFormHelperText-root {
                        font-weight: bold;
                        color: #FF0000;
                    }
                }
            }
        }

        .agreement-checkbox-container {

            .checkbox {
                // color: rgba(0, 0, 0, 0.6);

                .agreement-checkbox {
                    color: white;

                    &.Mui-checked {
                        // color: $sugarbook-main-color !important;
                    }
                }

                .MuiTypography-root {
                    font: 400 0.9em/1.4 Lato;
                    color: white;
                }
            }
        }

        .continue-button {
            width: fit-content;
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }

        .tnc-label {
            font: 500 0.9em/1.5 Lato;
            color: white;

            .terms-label {
                cursor: pointer;
                text-decoration: underline;
                font-weight: 600;
            }

            .privacy-label {
                cursor: pointer;
                text-decoration: underline;
                font-weight: 600;
            }
        }
    }
}

// Utility CSS
.MuiMenuItem-root.Mui-selected {
    background-color: #ffb7b777 !important;
}

// Responsive Design
@media (max-width: 768px) {
    #account-signup-page {

        .max-width-container {
            flex-direction: column;

            .gender-looking-for-container {
                flex-direction: column;
                gap: 15px;
            }

            .account-signup-form-field-container {
                gap: 25px;

                .email-form-field-container {
                    height: 40px;
                    width: 100%;

                    .email-form-field {
                        width: 100%;
                    }
                }

                .password-form-field-container {
                    height: 40px;
                    width: 100%;

                    .password-form-field {
                        width: 100%;
                    }
                }
            }

            .continue-button {
                width: calc(100% - 160px);
                text-align: center;
            }
        }
    }
}