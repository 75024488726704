@import "../../../../../global-style.scss";

#mobile-edit-profile-terms-of-relationship-subcomponent {

    .terms-of-relationship-label-container {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 50px;
        // background-color: $mui-text-field-outline-background-color;

        .tor-label {
            font: 600 1.5em Lato;

            .trade-mark-label {
                font: 600 0.5em Lato;
            }
        }

        .expand-icon-container {
            display: flex;

            .expand-icon {
                color: #CCCCCC;
            }
        }
    }

    .tor-list-container {
        min-height: 30px;
        margin: 0 5px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #e8e4e4;
        border-radius: 10px;
        cursor: pointer;

        .left-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;
        }

        .right-container {
            display: flex;

            .right-icon {
                color: #d0cccc;
            }
        }
    }

    // Utility CSS
    .blur {
        filter: blur(4px);
        background-color: white !important;
    }
}

#desktop-edit-profile-terms-of-relationship-subcomponent {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .terms-of-relationship-static-label-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .terms-of-relationship-label {
            font: 600 1.2em Lato;

            .trade-mark-label {
                font: 600 0.6em Lato;
            }
        }

        .short-divider {
            width: 30px;
        }
    }

    .tor-list-container {
        min-height: 30px;
        margin: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .to-be-completed-text {
            margin-top: 10px;
            margin-left: 10px;
            color: grey;
        }

        .tor-edit {
            height: 28px;
            width: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $sugarbook-main-color;
            border-radius: 25px;
            cursor: pointer;
            background-color: #f4ecec;

            .edit-icon {
                font-size: 20px;
                color: $sugarbook-main-color;
            }
        }

        .tor-add {
            height: 28px;
            width: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid #d8d4d4;
            border-radius: 25px;
            cursor: pointer;
            background: #f0ecec;

            .add-icon {
                color: black;
            }
        }
    }

    // Utility CSS
    .blur {
        filter: blur(4px);
        background-color: white !important;
    }
}