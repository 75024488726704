@import "../../../global-style.scss";

#lifestyle-signup-page {
    height: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        height: calc(100% - 50px);
        width: 100%;
        max-width: 400px;
        padding: 25px;
        display: flex;
        flex-direction: column;

        .navbar-container {
            height: 30px;
            display: flex;
            align-items: center;

            .back-button {
                width: 50px;
                cursor: pointer;

                .signup-back-icon {
                    height: 30px;
                }
            }
        }

        .overflow-container {
            height: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 20px;
            overflow: auto;

            .stepper-container {
                width: 100%;

                .stepper-max-width-container {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .stepper-max-width-inner-container {
                        width: 100%;
                        max-width: 230px;
                    }
                }
            }

            .step-description-label {
                padding-left: 10px;
                font: 700 1.8em Lato;
            }

            .form-field-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 15px;

                .flex-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }

                .monthly-habit-form-field-container {
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .monthly-habit-label {
                        padding: 0 15px;
                        font: 600 1em Lato;
                    }

                    .monthly-habit-form-field {
                        width: 100%;

                        .MuiInputBase-root {
                            font: 600 1em Lato;
                        }
                    }
                }

                .net-worth-form-field-container {
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .net-worth-label {
                        padding: 0 15px;
                        font: 600 1em Lato;
                    }

                    .net-worth-form-field {
                        width: 100%;

                        .MuiInputBase-root {
                            font: 600 1em Lato;
                        }
                    }
                }

                .annual-income-form-field-container {
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .annual-income-label {
                        padding: 0 15px;
                        font: 600 1em Lato;
                    }

                    .annual-income-form-field {
                        width: 100%;

                        .MuiInputBase-root {
                            font: 600 1em Lato;
                        }
                    }
                }

                .height-form-field-container {
                    width: 45%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .height-label {
                        padding: 0 15px;
                        font: 600 1em Lato;
                    }

                    .height-form-field {
                        width: 100%;

                        .MuiInputBase-root {
                            font: 600 1em Lato;
                        }
                    }
                }

                .body-type-form-field-container {
                    width: 45%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .body-type-label {
                        padding: 0 15px;
                        font: 600 1em Lato;
                    }

                    .body-type-form-field {
                        width: 100%;

                        .MuiInputBase-root {
                            font: 600 1em Lato;
                        }
                    }
                }

                .ethnicity-form-field-container {
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .ethnicity-label {
                        padding: 0 15px;
                        font: 600 1em Lato;
                    }

                    .ethnicity-form-field {
                        width: 100%;

                        .MuiInputBase-root {
                            font: 600 1em Lato;
                        }
                    }
                }

                .race-form-field-container {
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .race-label {
                        padding: 0 15px;
                        font: 600 1em Lato;
                    }

                    .race-form-field {
                        width: 100%;

                        .MuiInputBase-root {
                            font: 600 1em Lato;
                        }
                    }
                }

                .eye-color-form-field-container {
                    width: 45%;
                    // margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .eye-color-label {
                        padding: 0 15px;
                        font: 600 1em Lato;
                    }

                    .eye-color-form-field {
                        width: 100%;

                        .MuiInputBase-root {
                            font: 600 1em Lato;
                        }
                    }
                }

                .hair-color-form-field-container {
                    width: 45%;
                    // margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .hair-color-label {
                        padding: 0 15px;
                        font: 600 1em Lato;
                    }

                    .hair-color-form-field {
                        width: 100%;

                        .MuiInputBase-root {
                            font: 600 1em Lato;
                        }
                    }
                }
            }
        }

        .overflow-container::-webkit-scrollbar {
            display: none;
        }

        .bottom-container {
            height: 80px;
            padding-top: 10px;

            .button-container {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .next-button {
                    height: 47px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    text-decoration: none;
                    text-transform: capitalize;
                    font: 600 1.1em Lato;
                    background-color: $sugarbook-main-color;
                    color: white;
                }

                .disabled-button {
                    cursor: not-allowed;
                    background-color: rgba(0, 0, 0, 0.12);
                    color: rgba(0, 0, 0, 0.26);
                }

                .skip-button {
                    text-decoration: none;
                    text-transform: capitalize;
                    font: 500 0.8em Lato;
                    color: $sugarbook-grey-text-color;
                }
            }
        }
    }
}

// Responsive Design
@media (max-height: 780px) {
    #lifestyle-signup-page {

        .max-width-container {
            justify-content: unset;
        }
    }
}