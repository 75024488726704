@import "../../../../global-style.scss";

#go-private-permission-tutorial-dialog {
    overflow-y: auto;
    background-color: #333333;

    .padding-container {
        padding: 50px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        .title {
            text-align: center;
            font: 600 1.5em Lato;
            color: white;
        }

        .first-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .instruction {
                font: 500 1.1em Lato;
                color: white;

                span {
                    color: #FFC92D;
                }
            }

            .background-image-container {
                width: 100%;

                .background-image {
                    border-radius: 10px;
                    width: 100%;
                }
            }
        }

        .second-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .instruction {
                font: 500 1.1em Lato;
                color: white;

                span {
                    color: #FFC92D;
                }

                .one {
                    height: 20px;
                    width: 20px;
                    margin-left: 3px;
                    display: inline-block;
                    border-radius: 25px;
                    text-align: center;
                    font: 600 0.8em Lato;
                    background-color: #FF0000;
                    color: white;
                }

                .two {
                    height: 20px;
                    width: 20px;
                    margin-left: 3px;
                    display: inline-block;
                    border-radius: 25px;
                    text-align: center;
                    font: 600 0.8em Lato;
                    background-color: #FF0000;
                    color: white;
                }
            }

            .background-image-container {
                width: 100%;

                .background-image {
                    border-radius: 10px;
                    width: 100%;
                }
            }
        }

        .third-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .instruction {
                font: 500 1.1em Lato;
                color: white;

                span {
                    color: #FFC92D;
                }
            }

            .background-image-container {
                width: 100%;

                .background-image {
                    border-radius: 10px;
                    width: 100%;
                }
            }
        }

        .got-it-button {
            height: 45px;
            width: 100%;
            margin: 15px auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
            cursor: pointer;
            font: 600 1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}