@import "../../../../global-style.scss";

#go-private-dont-switch-app-dialog {
    max-width: 300px;
    background-color: #C30006;

    .dialog-header {
        padding: 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
                color: white;
            }
        }
    }

    .padding-container {
        padding: 0 30px 35px 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .alert-icon-container {
            display: flex;
            justify-content: center;

            .alert-icon {
                width: 50px;
                filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.15));
                -webkit-filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.15));
            }
        }

        .title {
            text-align: center;
            font: 700 1.2em Lato;
            color: white;
        }

        .description {
            text-align: center;
            font: 500 1em Lato;
            color: white;

            .diamond-icon {
                height: 13px;
            }
        }

        .proceed-button {
            height: 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}