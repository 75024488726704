@import "../../../global-style.scss";

#settings-page {

    .max-width-container {
        max-width: 900px;
        margin: 0 auto;
        background-color: white;

        .logout-button-container {
            padding: 20px;

            .logout-button {
                cursor: pointer;
                font: 600 1em Lato;
                color: $sugarbook-main-color;
            }
        }

        .version-container {
            padding-top: 10px;
            padding-bottom: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .app-icon {
                width: 50px;
            }

            .version {
                font: 500 1em Lato;
                color: #7c7c7c;
            }
        }
    }
}

@media (min-width: 900px) {
    #settings-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}