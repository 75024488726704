#private-standby-caller-camera-feed-subcomponent {
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: black;

    #caller-video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .camera-disabled-container {
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .camera-disabled-icon {
            color: white;
        }
    }
}