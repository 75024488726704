@import "../../../../global-style.scss";

#levels-description-dialog {
    height: 100%;
    background-color: #333333;
    overflow: auto;

    .padding-container {
        padding: 20px;

        .level-label {
            text-align: center;
            font: 800 2em Lato;
            color: white;
        }

        .flex-label {
            text-align: center;
            font: 700 2em Lato;
            color: white;
        }

        .level-background-container {
            padding: 40px 0;
            width: 100%;
            padding-bottom: 30px;

            .level-background {
                width: 100%;
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        .what-are-levels-label {
            text-align: center;
            font: 800 2em Lato;
            color: white;
        }

        .level-points-container {
            padding: 40px 0;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .level-point-1-container {
                display: flex;
                align-items: center;
                gap: 20px;

                .level-point-1-image-container {
                    width: 20%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .level-point-1-image {
                        width: 50px;
                    }
                }

                .level-point-1 {
                    width: 70%;
                    font: 400 0.9em/1.4 Lato;
                    color: white;
                }
            }

            .level-point-2-container {
                display: flex;
                align-items: center;
                gap: 20px;

                .level-point-2-image-container {
                    width: 20%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .level-point-2-image {
                        width: 50px;
                    }
                }

                .level-point-2 {
                    width: 70%;
                    font: 400 0.9em/1.4 Lato;
                    color: white;
                }
            }
        }

        .got-it-button {
            height: 45px;
            width: 100%;
            margin: 15px auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
            cursor: pointer;
            font: 600 1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}

// Utility CSS
.max-width-container {
    max-width: 400px;
}