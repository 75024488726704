@import "../../../../../global-style.scss";

#mobile-edit-profile-about-me-subcomponent {

    .about-me-label-container {
        padding: 15px 20px 5px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 50px;
        // background-color: $mui-text-field-outline-background-color;

        .about-me-label {
            font: 600 1.5em Lato;
        }
    }

    .about-me-description {
        padding: 0 20px 20px 20px;
        font: 500 1em Lato;
        color: #CCCCCC;
    }

    .about-me-topic-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .topic-container {
            margin: 0 5px;
            padding: 15px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #e8e4e4;
            border-radius: 10px;
            cursor: pointer;

            .left-container {
                width: calc(100% - 5px - 25px);
                padding-left: 5px;
                display: flex;
                flex-direction: column;
                gap: 5px;

                .add-topic-label-container {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                .add-topic-label {
                    font: 600 1.1em Lato;
                }

                .approval-status-container {
                    width: fit-content;
                    padding: 5px 7px;
                    display: flex;
                    align-items: center;
                    // border: 1px solid $sugarbook-grey-text-color;
                    border-radius: 50px;
                    text-transform: uppercase;
                    white-space: nowrap;
                    font: 700 0.6em Lato;
                }

                .rejected-status-container {
                    color: #FF0000;
                    background: #FFDCDC;
                }

                .in-review-status-container {
                    color: $sugarbook-grey-text-color;
                    background: #D9D9D9;
                }

                .add-topic-description {
                    font: 500 0.9em Lato;
                    color: #CCCCCC;
                }

                .about-me-manual-text {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font: 500 0.9em Lato;
                    // color: #A2A2A2;
                }

                .add-topic-wrap-container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;

                    .add-topic-answer {
                        padding: 7px 13px;
                        border: 2px solid #d8d4d4;
                        border-radius: 50px;
                        font: 600 0.9em Lato;
                        background: #f0ecec;
                    }
                }
            }

            .right-container {
                display: flex;

                .right-icon {
                    color: #d0cccc;
                }
            }
        }
    }
}

#desktop-edit-profile-about-me-subcomponent {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .about-me-static-label-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .about-me-label {
            font: 600 1.2em Lato;
        }

        .short-divider {
            width: 30px;
        }
    }

    .about-me-description {
        padding: 0 20px 20px 20px;
        font: 500 1em Lato;
        color: #737373;
    }

    .about-me-topic-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .topic-container {
            margin: 0 5px;
            padding: 15px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #e8e4e4;
            border-radius: 10px;
            cursor: pointer;

            .left-container {
                width: calc(100% - 5px - 25px);
                padding-left: 5px;
                display: flex;
                flex-direction: column;
                gap: 5px;

                .add-topic-label-container {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                .add-topic-label {
                    font: 600 1.1em Lato;
                }

                .approval-status-container {
                    width: fit-content;
                    padding: 5px 7px;
                    display: flex;
                    align-items: center;
                    // border: 1px solid $sugarbook-grey-text-color;
                    border-radius: 50px;
                    text-transform: uppercase;
                    white-space: nowrap;
                    font: 700 0.6em Lato;
                }

                .rejected-status-container {
                    color: #FF0000;
                    background: #FFDCDC;
                }

                .in-review-status-container {
                    color: $sugarbook-grey-text-color;
                    background: #D9D9D9;
                }

                .add-topic-description {
                    font: 500 0.9em Lato;
                    color: #737373;
                }

                .about-me-manual-text {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font: 500 0.9em Lato;
                    // color: #A2A2A2;
                }

                .add-topic-wrap-container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;

                    .add-topic-answer {
                        padding: 7px 13px;
                        border: 2px solid #d8d4d4;
                        border-radius: 50px;
                        font: 600 0.9em Lato;
                        background: #f0ecec;
                    }
                }
            }

            .right-container {
                display: flex;

                .right-icon {
                    color: #d0cccc;
                }
            }
        }
    }
}