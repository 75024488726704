@import "../../../../global-style.scss";

#registration-profile-almost-complete-dialog {
    height: 100%;
    // max-width: 400px;
    overflow: auto;
    background: url("../../../../assets/background/shared/registration-mobile-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    /* Hide scrollbar for WebKit (Safari, Chrome) browsers */
    &::-webkit-scrollbar {
        display: none;
    }

    .dialog-header {}

    .restart-journey-container {
        // height: calc(100% - 100px - 50px);
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        gap: 25px;

        .profile-photo-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .default-circular-progress-bar {
                height: 110px !important;
                width: 110px !important;
                stroke: #ebebeb;
                color: #ebebeb;
            }

            .circular-progress-bar {
                height: 110px !important;
                width: 110px !important;
                position: absolute;
                bottom: 0;
                transform: rotate(-270deg) !important;
                color: #FFC700;
            }

            .profile-photo {
                height: 90px;
                width: 90px;
            }

            .profile-photo-absolute {
                position: absolute;
                bottom: 5px;
            }

            .profile-completed-percentage-container {
                padding: 3px 15px;
                position: absolute;
                bottom: -8px;
                border-radius: 50px;
                cursor: pointer;
                font: 600 1em Lato;
                background: #333333;
                color: white;
            }
        }

        .restart-journey-title {
            text-align: center;
            font: 900 2em Lato;
            color: #FFC700;
        }

        .restart-journey-description {
            text-align: center;
            font: 500 0.9em Lato;
            color: white;
        }

        .restart-journey-body-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .option-1-container,
            .option-2-container,
            .option-3-container {
                display: flex;
                gap: 15px;

                .option-icon-container {

                    .option-icon {
                        height: 35px;
                    }
                }

                .option-label-container {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .option-title {
                        font: 600 1.2em Lato;
                        color: white;
                    }

                    .option-description {
                        font: 500 0.9em Lato;
                        color: white;
                    }
                }
            }
        }

        .restart-journey-footer {
            color: white;
        }
    }

    .bottom-container {
        height: 100px;
        width: calc(100% - 40px);
        padding: 10px 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .continue-button {
            width: 100%;
            padding: 13px 0;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .skip-button {
            cursor: pointer;
            color: #988484;
        }
    }
}

// Utility CSS
.add-max-width {
    max-width: 400px;
}