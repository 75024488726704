#chat-section-tipping-receive-element-subcomponent {
    width: fit-content;
    padding: 10px 20px 10px 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 0 25px 25px 0;
    font: 600 0.9em Lato;
    background-color: rgba(13, 113, 65, 0.59);
    color: #ffe18c;

    .earned-minute-top {
        // display: flex;
        gap: 5px;

        .diamond-icon {
            height: 11px;
            padding: 0 5px;
        }
    }

    .earned-minute-bottom {}
}