#top-leaderboard-list-page {
    height: 100%;
    overflow: auto;

    .max-width-container {
        max-width: 1000px;
        margin: 0 auto;
        background-color: white;

        .padding-container {
            padding: 0 20px 20px 20px;

            .recurrence-label {
                padding: 10px 0;
                text-transform: capitalize;
                font: 600 1.2em Lato;
            }

            .infinite-scroll-container {}
        }
    }
}

// Responsive Design
@media (min-width: 1000px) {
    #top-leaderboard-list-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}