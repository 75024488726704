#follows-empty-list-subcomponent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty-live-list-image-container {

        .empty-live-list-image {
            width: 300px;
        }
    }

    .title {
        padding: 0 20px;
        text-align: center;
        font: 600 1.5em Lato;
    }

    .description {
        margin-top: 15px;
        margin-bottom: 30px;
        padding: 0 30px;
        text-align: center;
        font: 400 1em Lato;
    }
}