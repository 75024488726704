#leaderboard-casino-tab-subcomponent {
    position: relative;

    #casino-tab-lottie-container {
        width: 100%;
        position: absolute;
        top: 175px;
        display: flex;
        justify-content: center;
        pointer-events: none;

        .lottie-center-container {
            width: 100%;
            max-width: 400px;
        }
    }
}