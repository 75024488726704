#bottom-user-container-user-profile-tab-subcomponent {
    // width: calc(100% - 350px);
    width: calc(100%);
    padding: 10px 20px;
    border-radius: 15px;
    background: white;

    .user-profile-tab-label {
        text-transform: uppercase;
    }

    .tab-photo-icon {
        height: 11px;
    }

    .tab-achievement-icon {
        height: 13px;
    }

    .embla {
        height: 100%;
        width: 100%;
        overflow-x: hidden;

        .embla__container {
            height: 100%;
            display: flex;

            .embla__slide {
                flex: 0 0 100%;
                min-width: 0;
            }
        }
    }
}