#video-call-camera-tester-dialog {
    background-color: #333333e6;

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
                color: white;
            }
        }
    }

    .padding-container {
        padding: 10px 40px 20px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        #callee-tester-video {
            height: 200px;
            width: 200px;
            border-radius: 100px;
            object-fit: cover; // contain if desktop
            background-color: black;
        }

        .camera-tester-title {
            font: 700 1.8em Lato;
            color: white;
        }

        .camera-tester-description {
            font: 500 0.9em Lato;
            color: white;

            .red-underline {
                text-decoration: underline;
                font: 900 1.2em Lato;
                color: #FF0000;
            }
        }

        .button-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .proceed-button {
                width: 252px;
                padding: 10px 0;
                border-radius: 25px;
                cursor: pointer;
                text-align: center;
                font: 600 1em Lato;
                background-image: linear-gradient(to right, #ffa927, #ff903a, #ff784f, #ff6165, #ff4f7b, #fb4488, #f33c96, #e937a4, #d733a9, #c332ae, #ad32b1, #9534b4);
                color: white;
            }

            .cancel-button {
                width: 250px;
                padding: 10px 0;
                border: 1px solid white;
                border-radius: 25px;
                cursor: pointer;
                text-align: center;
                font: 600 1em Lato;
                color: white;
            }
        }
    }
}