#profile-more-options-button-shared-component {

    .info-icon-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        .info-icon {
            height: 30px;
        }
    }

    .reduced-height {
        height: 40px;
        width: 40px;
    }

    .extra-height {
        height: 50px;
        width: 50px;
    }
}