@import "../../../global-style.scss";

#customer-support-manual-verification-info-page {
    height: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        max-width: 400px;

        .padding-container {
            height: calc(100% - 50px);
            padding: 25px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            overflow: auto;

            .navbar-container {
                height: 10%;
                display: flex;
                align-items: center;

                .back-button {
                    width: 50px;
                    cursor: pointer;

                    .signup-back-icon {
                        height: 30px;
                    }
                }
            }

            .top-container {
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 40px;

                .customer-support-title {
                    font: 600 1.7em Lato;
                    color: white;
                }

                .customer-support-description {
                    font: 500 1em/1.4 Lato;
                    padding-bottom: 40px;
                    // color: $sugarbook-grey-text-color;
                    color: white;
                }

                .customer-support-points-container {
                    padding: 20px 20px 20px 15px;
                    display: flex;
                    border-radius: 15px;
                    // background-color: #DCF2FF;
                    background: rgba(255, 255, 255, 0.4);

                    .bulb-icon-container {

                        .bulb-icon {
                            height: 25px;
                            // color: white;
                            // change to white color with filter
                            filter: invert(1) brightness(0) invert(1);
                        }
                    }

                    .customer-support-unordered-list-container {
                        margin-top: 0;
                        padding-left: 12px;
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                    }

                    .unordered-list-points {
                        font: 500 1.1em Lato;
                        color: white;
                    }
                }
            }

            .bottom-container {
                height: 100px;
                display: flex;
                flex-direction: column;
                gap: 15px;

                .done-button {
                    height: 47px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    cursor: pointer;
                    text-align: center;
                    font: 600 1em Lato;
                    background-color: $sugarbook-main-color;
                    color: white;
                }

                .skip-button {
                    text-align: center;
                    color: white;
                }

                .underline {
                    text-decoration: underline;
                    font-weight: 600;
                }
            }
        }
    }
}