#product-purchase-dialog-gifts-tab-subcomponent {
    width: calc(100% - 3px);
    padding: 5px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

// Utility CSS
.screen-height {
    height: 42vh;
}

.fix-height {
    height: 100%;
}