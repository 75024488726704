@import "../../../../global-style.scss";

#diamonds-info-dialog {
    margin-top: auto;

    .padding-container {
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .header {
            font: 600 1.7em Lato;
        }

        .diamonds-info-description {}

        .close-button {
            height: 45px;
            width: 100%;
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-transform: none;
            text-decoration: none;
            font: 500 1.1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}