#verified-caller-description-dialog {
    max-width: 300px;

    .dialog-header {
        padding: 10px 20px 0 10px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 20px;
            }
        }
    }

    .padding-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 30px 30px 30px;

        .verified-caller-title-container {
            display: flex;
            align-items: center;
            gap: 15px;

            .verified-caller-title {
                font: 600 1.6em Lato;
            }

            .verified-caller-badge-container {

                .verified-caller-badge {
                    height: 30px;
                }
            }
        }

        .verified-caller-description {
            font: 500 1em/1.4 Lato;
        }
    }
}