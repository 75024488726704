#streamer-center-last-stream-summary-subcomponent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .max-width-container {
        height: 100%;
        width: 100%;
        max-width: 700px;
    }
}