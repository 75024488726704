#wallet-buy-coins-subcomponent {
    height: calc(100% - 191px);
    overflow: auto;

    .padding-container {
        padding: 20px;

        .buy-coins-label {
            padding-bottom: 15px;
            font: 600 1.5em Lato;
        }

        .description {
            font: 400 0.8em Lato;
            line-height: 1.5;
        }

        .top-gifter-prize-description {
            padding-top: 50px;
        }
    }
}