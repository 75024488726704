#logout-page {
    height: 100%;

    .padding-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;

        .logout-label {
            font: 600 1.3em Lato;
            color: grey;
        }
    }
}