#report-dialog {
    min-width: 360px;
    padding-bottom: 20px;

    .dialog-header {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;

        .blank-container {
            flex: 1;
        }

        .title {
            font: 600 1.4em Lato;
        }

        .close-button-container {
            display: flex;
            justify-content: flex-start;
            flex: 1;
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }
}

// Utility CSS
.max-width-container {
    max-width: 360px;
}