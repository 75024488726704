@import "../../../../../global-style.scss";

#search-filter-height-range-subcomponent {

    .filter-padding-container {
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .subheader-container {
            display: flex;
            gap: 5px;

            .subheader {
                font: 600 1.1em Lato;
            }
        }

        .slider-container {
            display: flex;
            align-items: center;

            .min-label {
                width: 10%;
                text-align: center;
                font: 600 1.2em Lato;
                color: $sugarbook-main-color;
            }

            .slider {
                width: 80%;
                margin: 2px 20px 0 20px;
            }

            .max-label {
                width: 10%;
                text-align: center;
                font: 600 1.2em Lato;
                color: $sugarbook-main-color;
            }
        }
    }

    // Utility CSS
    .no-gap {
        gap: 0;
    }
}

// Utility CSS
.locked {
    cursor: not-allowed;
}