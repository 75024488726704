@import "../../../../../global-style.scss";

#search-filter-top-bar-subcomponent {

    .padding-container {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;

        .left-container {
            display: flex;
            align-items: center;
            flex: 1;

            .back-icon-container {
                width: 50px;
                display: flex;
                justify-content: center;

                .back-icon {
                    width: 10px;
                }
            }
        }

        .middle-container {
            flex: 1;

            .middle-label {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font: 600 1.3em Lato;
            }
        }

        .right-container {
            display: flex;
            align-items: center;
            flex: 1;
            text-align: right;

            .reset-button {
                width: 100%;
                font: 600 1.2em Lato;
                color: $sugarbook-main-color;
            }
        }
    }
}