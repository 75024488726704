@import "../../../../../global-style.scss";

#mobile-edit-profile-alcohol-preference-subcomponent {

    .alcohol-label-container {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 50px;
        // background-color: $mui-text-field-outline-background-color;

        .alcohol-label {
            font: 600 1.5em Lato;
        }
    }

    .alcohol-preference-list-container {
        min-height: 30px;
        margin: 0 5px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #e8e4e4;
        border-radius: 10px;
        cursor: pointer;

        .left-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;

            .alcohol-item-container {
                padding: 4px 13px 4px 9px;
                display: flex;
                align-items: center;
                gap: 3px;
                border: 2px solid #d8d4d4;
                border-radius: 50px;
                cursor: pointer;
                background: #f0ecec;

                .alcohol-icon-container {
                    height: 25px;
                    display: flex;
                    align-items: center;

                    .alcohol-icon {
                        height: 100%;
                        filter: invert(0.8);
                    }

                    .whiskey-icon {
                        height: 80%;
                        filter: invert(0.8);
                    }
                }

                .alcohol-item {
                    font: 600 0.9em Lato;
                }
            }

            .to-be-completed-text-container {
                display: flex;
                align-items: center;
                gap: 3px;

                .add-icon {
                    font-size: 16px;
                    color: red;
                }

                .to-be-completed-text {
                    padding-bottom: 2px;
                    color: red;
                }
            }
        }

        .right-container {
            display: flex;

            .right-icon {
                color: #d0cccc;
            }
        }
    }

    // Utility CSS
    .blur {
        filter: blur(4px);
        background-color: white !important;
    }
}