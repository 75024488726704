@import "../../../../global-style.scss";

#login-page-troubleshoot-dialog {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .clear-local-cache-button {
        padding: 10px;
        border-radius: 50px;
        text-align: center;
        font: 500 1em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }

    .remove-service-worker-button {
        padding: 10px;
        border-radius: 50px;
        text-align: center;
        font: 500 1em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }

    .add-service-worker-button {
        padding: 10px;
        border-radius: 50px;
        text-align: center;
        font: 500 1em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }

    .remove-firebase-sw-button {
        padding: 10px;
        border-radius: 50px;
        text-align: center;
        font: 500 1em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }

    .add-firebase-sw-button {
        padding: 10px;
        border-radius: 50px;
        text-align: center;
        font: 500 1em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }
}