@import "../../../../global-style.scss";

#geolocation-permission-prompt-dialog {
    max-width: 300px;

    .padding-container {
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .location-permission-icon-container {

            .location-permission-icon {
                height: 90px;
            }
        }

        .geolocation-prompt-title {
            text-align: center;
            font: 600 1.5em Lato;
        }

        .geolocation-prompt-desc {
            text-align: center;
            font: 500 1em Lato;
            color: #7C7C7C;
        }

        .buttons-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .allow-button {
                width: 200px;
                padding: 10px 0;
                border-radius: 25px;
                text-align: center;
                cursor: pointer;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .learn-more-button {
                width: 200px;
                text-align: center;
                cursor: pointer;
                font: 600 1em Lato;
            }
        }
    }
}