@import "../../../../global-style.scss";

#video-call-info-dialog {

    .min-width-container {
        min-width: 300px;

        .top-header {
            padding: 20px 20px 0 0;
            display: flex;
            justify-content: flex-end;

            .close-button-container {
                cursor: pointer;
            }
        }

        .padding-container {
            padding: 0 40px 40px 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;

            .profile-photo {
                height: 70px;
                width: 70px;
            }

            .private-call-title {
                font: 700 1.4em Lato;
            }

            .first-row {
                width: 100%;
                display: flex;

                .type-container {
                    display: flex;
                    flex-direction: column;
                    flex: 60;

                    .type-header {
                        font: 400 0.9em Lato;
                    }

                    .type-text {
                        text-transform: capitalize;
                        font: 600 1em Lato;
                    }
                }

                .duration-container {
                    display: flex;
                    flex-direction: column;
                    flex: 40;

                    .duration-header {
                        font: 400 0.9em Lato;
                    }

                    .duration-text {
                        font: 600 1em Lato;
                    }
                }
            }

            .second-row {
                width: 100%;
                display: flex;

                .date-container {
                    display: flex;
                    flex-direction: column;
                    flex: 60;

                    .date-header {
                        font: 400 0.9em Lato;
                    }

                    .date-text {
                        font: 600 1em Lato;
                    }
                }

                .time-container {
                    display: flex;
                    flex-direction: column;
                    flex: 40;

                    .time-header {
                        font: 400 0.9em Lato;
                    }

                    .time-text {
                        font: 600 1em Lato;
                    }
                }
            }

            .buttons-container {
                width: 100%;
                display: flex;
                justify-content: space-between;
                gap: 10px;

                .send-message-button-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 1;
                    gap: 20px;
                    cursor: pointer;

                    .send-message-button {
                        height: 50px;
                        width: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 25px;
                        background-color: $sugarbook-main-color;
                        color: white;

                        .send-message-icon {
                            width: 36px;
                        }
                    }

                    .send-message-label {
                        text-align: center;
                        font: 600 0.7em Lato;
                    }
                }

                .video-call-button-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 1;
                    gap: 20px;
                    cursor: pointer;

                    .video-call-button {
                        height: 50px;
                        width: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 25px;
                        background-color: $sugarbook-main-color;
                        color: white;

                        .video-call-icon {
                            width: 29px;
                        }
                    }

                    .video-call-label {
                        text-align: center;
                        font: 600 0.7em Lato;
                    }
                }

                .view-profile-button-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 1;
                    gap: 20px;
                    cursor: pointer;

                    .view-profile-button {
                        height: 50px;
                        width: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 25px;
                        background-color: $sugarbook-main-color;
                        color: white;

                        .view-profile-icon {
                            width: 32px;
                        }
                    }

                    .view-profile-label {
                        text-align: center;
                        font: 600 0.7em Lato;
                    }
                }
            }
        }
    }
}