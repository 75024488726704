#vip-entrance-container {
    height: 140px;
    width: 250px;
    position: relative;
    z-index: 30;

    .vip-entrance-gif-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;

        .vip-entrance-asset {
            width: 100%;
        }
    }

    .vip-entrance-description-container {
        height: 100%;
        position: relative;
        z-index: 20;
        display: flex;
        justify-content: center;
        align-items: center;
        // font: 600 1em Lato;
        color: black;

        .vip-entrance-description {
            max-width: 140px;
            display: flex;
            gap: 5px;

            .profile-photo-container {
                display: flex;
                align-items: center;

                .vip-profile-photo {
                    height: 35px;
                    width: 35px;
                }
            }

            .vip-description-container {
                width: calc(140px - 40px);

                .vip-top-description-container {

                    .vip-username {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font: 600 1em Lato;
                    }

                    .vip-levelling-badge {}
                }

                .vip-bottom-description-container {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font: 500 0.7em Lato;
                }
            }
        }
    }

    .vip-entrance-center-line-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 20;
        display: flex;
        justify-content: center;
        align-items: center;

        .vip-center-line {
            width: 100%;
            background-color: red;
        }
    }
}