#chat-section-gift-request-element-subcomponent {
    width: fit-content;
    margin-left: 20px;
    padding: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    font: 600 0.9em Lato;
    background-color: rgb(113, 13, 13);
    color: white;

    .gift-icon-container {
        padding-left: 5px;
        display: flex;
        align-items: center;

        .gift-icon {
            height: 30px;
        }
    }
}