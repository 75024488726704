@import "../../../../global-style.scss";

#search-verify-email-subcomponent {
    height: 70px;
    max-width: 500px;
    display: flex;
    gap: 5px;
    border-radius: 10px;
    text-decoration: none;
    background-color: #f2f2f2;

    .left-container {

        .profile-icon-container {
            height: 50px;
            width: 50px;
            margin: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background-color: white;

            .profile-icon {
                width: 30px;
            }
        }
    }

    .description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .subheader {
            font: 600 1em Lato;
            color: #333333;
        }

        .text {
            font: 500 0.7em Lato;
            color: #676767;
        }
    }

    .right-container {
        min-width: 65px;
        padding-right: 10px;
        display: flex;
        align-items: center;

        .resend-button {
            padding: 7px 10px;
            border-radius: 25px;
            cursor: pointer;
            font: 600 0.7em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}