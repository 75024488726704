#private-room-onboarding-baby-dialog {
    height: 100%;

    .dialog-header {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .private-room-label {
            font: 600 1.4em Lato;
        }

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .private-room-onboarding-container {
        height: calc(100% - 63px);
        overflow: auto;

        .private-room-image-container {
            display: flex;

            .private-room-image {
                width: 100%;
            }
        }
    }
}