@import "../../../../../global-style.scss";

#looking-for-ethnicity-subcomponent {
    height: 100%;

    .padding-container {
        height: calc(100% - 80px - 40px);
        padding: 20px;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .top-container {
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 50px;

            .looking-for-ethnicity-title {
                text-align: center;
                font: 600 1.6em Lato;
            }

            .looking-for-ethnicity-description {
                text-align: center;
                font: 500 0.9em Lato;
                color: #A2A2A2;
            }

            .ethnicity-body-container {
                height: calc(100% - 50px);
                width: 100%;
                display: flex;
                flex-direction: column;
                // justify-content: center;
                align-items: center;
                gap: 20px;

                .ethnicity-form-field-container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .ethnicity-select-form-field-container {
                        width: 100%;

                        .ethnicity-select-form-field {
                            width: 100%;

                            .MuiInputLabel-root {
                                color: #b0acac;
                            }

                            .MuiInputBase-root {

                                .MuiSelect-select {
                                    // color: white;
                                }
                            }

                            .MuiInputBase-root:before {
                                border-bottom-color: #e8e4e4;
                            }

                            .MuiInputBase-root:after {
                                border-bottom-color: #e8e4e4 !important;
                            }

                            .MuiSvgIcon-root {
                                // color: white;
                            }
                        }
                    }

                    .race-select-form-field-container {
                        width: 100%;

                        .race-select-form-field {
                            width: 100%;

                            .MuiInputLabel-root {
                                color: #b0acac;
                            }

                            .MuiInputBase-root {

                                .MuiSelect-select {
                                    // color: white;
                                }
                            }

                            .MuiInputBase-root:before {
                                border-bottom-color: #e8e4e4;
                            }

                            .MuiInputBase-root:after {
                                border-bottom-color: #e8e4e4 !important;
                            }

                            .MuiSvgIcon-root {
                                // color: white;
                            }
                        }
                    }
                }

                .add-ethnicity-race-button {
                    padding: 5px 20px;
                    border-radius: 50px;
                    font: 600 1em Lato;
                    color: $sugarbook-main-color;
                    background: white;
                }

                .selected-ethnicity-race-container {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;

                    .ethnicity-race-container {
                        width: fit-content;
                        padding: 5px 10px;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        border: 1px solid #e0bcbc;
                        border-radius: 50px;
                        font: 600 1em Lato;
                        background: #f8e4ec;
                        color: $sugarbook-main-color;

                        .remove-selected-ethnicity-race-icon {
                            cursor: pointer;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    .bottom-container {
        height: 80px;
        width: calc(100% - 40px);
        padding: 0 20px;

        .save-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .add-edit-padding-top {
        padding-top: 30px;
    }
}