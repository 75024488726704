@import "../../../../global-style.scss";

#video-call-error-dialog {

    .padding-container {
        max-width: 280px;
        padding: 40px 20px 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .alert-icon-container {
            display: flex;
            justify-content: center;

            .alert-icon {
                height: 70px;
            }
        }

        .error-title {
            text-align: center;
            font: 700 1.4em Lato;
        }

        .error-message {
            text-align: center;
            font: 500 1.2em Lato;
        }

        .got-it-button {
            height: 50px;
            width: 90%;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1.3em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}