#profile-wallet-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        max-width: 1000px;
        margin: 0 auto;
        background-color: white;

        .coins-balance-container {
            height: 170px;
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;

            .coin-image-container {

                .coin-image {
                    width: 60px;
                }
            }

            .coin-balance-label {}

            .coin-balance {
                font: 600 2em Lato;
            }
        }
    }
}

// Responsive Design
@media (min-width: 1000px) {
    #profile-wallet-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}