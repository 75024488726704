#settings-hidden-settings-subcomponent {

    .padding-container {
        padding: 20px;

        .hidden-label {
            font: 600 1.1em Lato;
        }

        .buttons-list-container {
            width: 100%;
            padding-top: 20px;

            .button-container {
                height: 50px;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                cursor: pointer;
                color: black;

                .button-label {
                    font: 500 1em Lato;
                    color: #676767;
                }

                .suffix {
                    display: flex;
                    text-transform: uppercase;
                    font: 600 1em Lato;
                }
            }
        }
    }

    // Utility CSS
    .state-connected {
        color: darkgreen;
    }

    .state-disconnected {
        color: darkred;
    }
}