@import "../../../../global-style.scss";

#preview-photo-dialog {
    height: 100%;
    display: flex;
    flex-direction: column;

    .dialog-header {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        background: grey;

        .close-button-container {
            display: flex;
            align-items: center;
            cursor: pointer;

            .close-button {
                font-size: 30px;
                color: white;
            }
        }

        .delete-button-container {
            display: flex;
            align-items: center;
            cursor: pointer;

            .delete-button {
                font-size: 30px;
                color: white;
            }
        }
    }

    .profile-photo-container {
        height: calc(90% - 68px);
        display: flex;

        .previous-button-container {
            width: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .previous-button {
                cursor: pointer;
            }
        }

        .profile-photo {
            border-radius: 15px;
            object-fit: contain;
        }

        .forward-button-container {
            width: 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .next-button {
                cursor: pointer;
            }
        }
    }

    .set-profile-photo-container {
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;

        .set-profile-photo-button {
            padding: 10px 20px;
            border-radius: 25px;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }

    .photo-rejected-reason-container {
        margin: 20px;
        padding: 15px;
        display: flex;
        gap: 10px;
        border-radius: 15px;
        background: #FFDCDC;
        color: #FF0000;

        .reject-icon-container {

            .reject-icon {}
        }

        .reject-reason-container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .reject-reason-title {
                font: bold 1em Lato;
            }

            .reject-reason-description {
                font: 500 1em Lato;
            }
        }
    }

    // Utility CSS
    .default-button {
        color: white;
    }

    .transparent-button {
        color: transparent;
    }

    .dynamic-width {
        width: calc(100% - 48px);
    }

    .fixed-width {
        width: 520px;
    }
}

// Responsive Design
@media (min-width: 720px) {
    #preview-photo-dialog {

        .dialog-header {
            background: transparent;
        }
    }
}