@import "../../../../global-style.scss";

#go-private-permission-dialog {
    max-width: 350px;

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .padding-container {
        padding: 0 30px 35px 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .alert-icon-container {
            display: flex;
            justify-content: flex-start;

            .alert-icon {
                width: 50px;
                filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.15));
                -webkit-filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.15));
            }
        }

        .title {
            font: 800 1.5em Lato;
        }

        .description {
            font: 500 1.1em Lato;
        }

        .guide-container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .guide {
                text-decoration: underline;
                cursor: pointer;
                color: #0578FF;
            }

            .please-read-label {
                font: 600 0.9em Lato;
                color: #FF0000;
            }
        }

        .proceed-button {
            height: 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
            transition: all 0.5s;
        }

        .disable-button {
            cursor: not-allowed;
            background-color: #D0D0D0;
            color: #B1B1B1;
        }
    }
}