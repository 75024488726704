@import "../../../../global-style.scss";

#sugarbook-verified-status-approved,
#sugarbook-verified-status-rejected,
#sugarbook-verified-status-duplicate {
    height: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        max-width: 400px;
        position: relative;
        background: white;

        .padding-container {
            height: calc(100% - 50px);
            padding: 25px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            overflow: auto;

            .top-container {
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 15px;

                .verified-approved-image-container,
                .verified-rejected-image-container {
                    height: 235px;
                    height: 300px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .verified-approved-image,
                    .verified-rejected-image {
                        width: 288px;
                    }

                    .user-details-container {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;

                        .own-user-profile-photo {
                            height: 50px;
                            width: 50px;
                        }

                        .username {
                            font: 600 0.9em Lato;
                        }
                    }

                    .caution-icon-container {
                        position: absolute;
                        bottom: 0;
                        // left: 0;
                        padding-right: 250px;
                    }

                    // Utility CSS
                    .apply-opacity {
                        opacity: 0.5;
                    }
                }

                .verified-approved-image-container {
                    height: 250px;
                    position: relative;
                    top: 25px;
                }

                .verified-approved-label,
                .verified-rejected-label {
                    padding-left: 10px;
                    font: 600 1.9em Lato;
                    z-index: 20;

                    .verified-profile-badge-container {
                        padding-left: 8px;
                        position: relative;
                        top: 3px;
                        display: inline;

                        .verified-profile-badge {
                            width: 30px;
                        }
                    }
                }

                .verified-approved-description,
                .verified-rejected-description {
                    padding-left: 10px;
                    color: $sugarbook-grey-text-color;
                }

                .verified-approved-points-container {
                    padding: 20px 20px 20px 15px;
                    border-radius: 15px;
                    background-color: #DCF2FF;

                    .verified-approved-unordered-list-container {
                        margin-top: 0;
                        margin-bottom: 0;
                        padding-left: 15px;
                        font: 500 0.9em Lato;
                        color: $sugarbook-grey-text-color;

                        li {
                            padding: 3px 0;
                            font: 500 1em Lato;
                        }
                    }
                }

                .verified-rejected-points-container {
                    padding: 10px;
                    display: flex;
                    border-radius: 15px;
                    background-color: #FFDCDC;

                    .caution-icon-container {
                        width: 40px;

                        .caution-icon {
                            width: 20px;
                        }
                    }

                    .verified-rejected-unordered-list-container {
                        margin-top: 0;
                        margin-bottom: 0;
                        padding-left: 15px;
                        font: 500 0.9em Lato;
                        color: $sugarbook-grey-text-color;

                        li {
                            padding: 3px 0;
                            font: 500 1em Lato;
                        }
                    }
                }
            }

            .bottom-container {
                height: 100px;

                .button-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;

                    .done-button {
                        height: 47px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 25px;
                        cursor: pointer;
                        text-align: center;
                        font: 600 1.1em Lato;
                        background-color: $sugarbook-main-color;
                        color: white;
                    }

                    .skip-button {
                        cursor: pointer;
                        color: $sugarbook-grey-text-color;
                    }
                }
            }
        }

        .lottie-overlay-container {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 30;
            background-color: white;

            .general-success-lottie,
            .general-error-lottie {
                width: 100px;
            }

            .verification-status-label {
                text-align: center;
                font: 600 1.4em Lato;
            }
        }
    }
}