#overview-profile-stats-subcomponent {
    width: 100%;
    max-width: 700px;
    margin: 30px 0;
    display: flex;
    justify-content: space-evenly;

    .followers-container {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .followers {
            font: 600 1.6em Lato;
            color: rgb(113, 13, 13);
        }

        .followers-label {
            margin-top: 5px;
        }
    }

    .following-container {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .following {
            font: 600 1.6em Lato;
            color: rgb(113, 13, 13);
        }

        .following-label {
            margin-top: 5px;
        }
    }

    .gifters-container {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .gifters {
            font: 600 1.6em Lato;
            color: rgb(113, 13, 13);
        }

        .gifters-label {
            margin-top: 5px;
        }
    }

    // Utility CSS
    .skeleton-follower-label {
        height: 30px;
        width: 30px;
    }
}