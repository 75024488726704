#chat-section-tipping-coin-element-subcomponent {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 0 25px 25px 0;
    font: 600 0.9em Lato;
    color: #ffe18c;

    .sender-container {
        display: flex;
        padding: 2px 5px;
        margin: 0px 10px;
        border-radius: 10px;

        .profile-picture-container {
            width: 45px;

            .profile-picture {
                height: 30px;
                width: 30px;
            }
        }

        .chat-info-container {
            width: 88%;
            display: flex;
            gap: 5px;

            .user-info-container {
                display: flex;
                align-items: center;
                gap: 7px;

                .livestreaming-achievement-badge-container {
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    .livestreaming-achievement-badge {
                        height: 13px;
                    }
                }

                .username {
                    font: 700 1em Lato;
                }

                .levelling-badge-container {
                    display: flex;

                    .levelling-badge {
                        height: 13px;
                    }
                }

                .sent-a {}
            }

            .coin-icon-container {
                display: flex;
                align-items: center;

                .coin-icon {
                    height: 15px;
                }
            }
        }
    }

    .receiver-container {
        padding: 10px 20px 10px 30px;

        .coin-top {
            // display: flex;
            gap: 5px;

            .diamond-icon {
                height: 11px;
                padding: 0 5px;
            }

            .coin-icon {
                height: 13px;
                padding: 0 2px;
            }
        }

        .coin-bottom {
            display: flex;
            gap: 5px;

            .coin-icon-container {
                display: flex;
                align-items: center;

                .coin-icon {
                    height: 15px;
                }
            }
        }
    }
}

// Utility CSS
.apply-background {
    background-color: rgba(13, 113, 65, 0.59);
}