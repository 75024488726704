#profile-view-achievements-subcomponent {

    .padding-container {
        padding: 20px;

        .achievements-label {
            font: 600 1.1em Lato;
            color: #333;
        }

        .achievements-container {
            padding-top: 15px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            .achievement-image-container {

                .achievement-image {
                    height: 25px;
                }

                .larger-image {
                    height: 35px;
                }
            }
        }

        .achievement-info-container {
            margin-top: 20px;
            padding: 20px;
            display: flex;
            gap: 20px;
            border-radius: 10px;
            background-color: #a0a0a01a;

            .achievement-image-container {
                display: flex;
                align-items: center;

                .achievement-image {
                    height: 30px;
                }
            }

            .badge-description {
                color: rgb(103, 103, 103);
            }
        }
    }
}

// Utility CSS
.livestream-achievement-empty {
    padding: 20px;
    font: 400 1em Lato;
}

.achievement-empty-title {
    text-align: center;
    font: 600 1.3em Lato;
}

.achievement-empty-description {
    text-align: center;
    margin-top: 20px;
    font: 500 0.8em Lato;
    color: #A0A0A0;
}

.ongoing-events-label {
    margin-top: 30px;
    font: 600 1.1em Lato;
}

.user-profile-banners-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

// Responsive Design
@media (min-width: 720px) {
    .ongoing-events-label {
        padding-left: 50px;
    }

    .achievement-empty-title {
        font: 600 1.4em Lato;
    }

    .achievement-empty-description {
        font: 500 1em Lato;
    }

    .livestream-achievement-empty {
        padding: 80px 20px 20px 20px;
    }

    .ongoing-events-label {
        margin-top: 50px;
        font: 600 1.1em Lato;
    }
}