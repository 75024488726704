@import "../../../global-style.scss";

#info-signup-page {
    height: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        height: calc(100% - 50px);
        width: 100%;
        max-width: 400px;
        padding: 25px;
        display: flex;
        flex-direction: column;

        .navbar-container {
            height: 30px;
            display: flex;
            align-items: center;

            .back-button {
                width: 50px;
                cursor: pointer;

                .signup-back-icon {
                    height: 30px;
                }
            }
        }

        .overflow-container {
            height: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 20px;
            overflow: auto;

            .stepper-container {
                width: 100%;

                .stepper-max-width-container {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .stepper-max-width-inner-container {
                        width: 100%;
                        max-width: 230px;
                    }
                }
            }

            .started-label {
                text-align: center;
                font: 700 1.8em Lato;
            }

            .upload-profile-photo-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;

                .profile-img-container {
                    display: flex;
                    justify-content: center;

                    .profile-img {}
                }

                .profile-img-button {
                    position: relative;
                    display: flex;
                    justify-content: center;

                    .profile-img {
                        height: 120px;
                        width: 120px;
                        border-radius: 70px;
                        object-fit: cover;
                    }

                    .add-icon-container {
                        height: 30px;
                        width: 30px;
                        position: absolute;
                        bottom: 10px;
                        margin-left: 90px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 25px;
                        background-color: $sugarbook-main-color;

                        .add-icon {
                            font-size: 20px;
                            color: white;
                        }
                    }
                }

                .description {
                    text-align: center;
                    font: 500 1em Lato;
                }
            }

            .info-signup-input-container {
                display: flex;
                flex-direction: column;
                gap: 25px;

                .name-form-field-container {
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .display-name-label {
                        padding: 0 15px;
                        font: 600 1em Lato;
                    }

                    .name-form-field {
                        height: 60px;
                        width: 100%;

                        .MuiInputBase-root {
                            font: 600 1em Lato;
                        }
                    }
                }

                .dob-form-field-container {
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .birthday-label {
                        padding: 0 15px;
                        font: 600 1em Lato;
                    }

                    .dob-form-field {
                        width: 100%;
                    }

                    .react-date-picker {
                        width: 100%;

                        .react-date-picker__wrapper {
                            padding: 14px;
                            border: none;
                            border-radius: 50px;
                            background-color: $mui-text-field-outline-background-color;

                            .react-date-picker__inputGroup {
                                font: 600 1em Lato;
                                // color: $sugarbook-main-text-color;

                                .react-date-picker__inputGroup__month {
                                    // width: fit-content !important;
                                }

                                .react-date-picker__inputGroup__day {
                                    // width: fit-content !important;
                                }

                                .react-date-picker__inputGroup__year {
                                    width: 50px !important;
                                }
                            }
                        }
                    }

                    .react-calendar {
                        border: none;
                        border-radius: 5px;
                        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);

                        .react-calendar__navigation__label__labelText {
                            font: 600 1em Lato;
                        }

                        .react-calendar__navigation__arrow {
                            font-size: 25px;
                        }

                        .react-calendar__tile {
                            font: 600 1em Lato;
                        }

                        // Arrow buttons
                        .react-calendar__navigation__arrow {
                            border-radius: 50px;
                        }

                        // Navigation label
                        .react-calendar__navigation__label {
                            border-radius: 50px;
                        }

                        // Day view
                        .react-calendar__month-view__days__day {
                            height: 47px;
                            width: 50px;
                            border-radius: 50px;
                        }

                        // Month view
                        .react-calendar__year-view__months__month {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 10px 0;
                            margin: 10px 0;
                            border-radius: 50px;
                        }

                        // Year view
                        .react-calendar__decade-view__years__year {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 10px 0;
                            margin: 10px 0;
                            border-radius: 50px;
                        }

                        // Decade view
                        .react-calendar__century-view__decades__decade {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 10px 0;
                            margin: 10px 0;
                            border-radius: 50px;
                        }

                        .react-calendar__tile--active {
                            background-color: $sugarbook-main-color;
                        }

                        .react-calendar__tile--hasActive {
                            background-color: $sugarbook-main-color;
                            color: white;
                        }
                    }
                }
            }

            .location-form-field-container {
                height: 50px;
                width: 100%;
                padding-bottom: 20px;
                margin-left: auto;
                margin-right: auto;

                .autocomplete-wrapper {
                    width: 100%;

                    .form-field {
                        width: 100%;
                    }
                }
            }
        }

        .bottom-container {
            height: 80px;
            padding-top: 10px;

            .continue-button {
                height: 47px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                text-decoration: none;
                text-transform: capitalize;
                cursor: pointer;
                font: 600 1.1em Lato;
                background-color: rgb(113, 13, 13);
                color: white;
            }

            .disabled-button {
                cursor: not-allowed;
                background-color: rgba(0, 0, 0, 0.12);
                color: $mui-disabled-button-color;
            }
        }
    }
}