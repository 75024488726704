@import "../../../../global-style.scss";

#user-is-live-dialog {
    max-width: 290px;

    .padding-container {
        padding: 20px 20px 40px 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .live-icon-container {
            display: flex;
            justify-content: center;
            align-items: center;

            .live-icon {
                font-size: 100px;
                color: lightgrey;
            }
        }

        .user-is-live-title {
            text-align: center;
            font: 600 1.4em Lato;
        }

        .user-is-live-description {
            padding: 0 10px;
            text-align: center;
        }

        .button-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;

            .continue-button {
                height: 40px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 30px;
                cursor: pointer;
                font: 500 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .close-button {
                cursor: pointer;
            }
        }
    }
}