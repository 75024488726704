#last-stream-summary-livestream-stats-subcomponent {
    width: 100%;
    margin: 30px 0;
    display: flex;
    justify-content: space-evenly;

    .viewers-container {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .viewers {
            font: 600 1.6em Lato;
        }

        .viewers-label {
            margin-top: 5px;
        }
    }

    .following-container {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .following {
            font: 600 1.6em Lato;
        }

        .following-label {
            margin-top: 5px;
        }
    }

    .diamonds-container {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .diamonds {
            font: 600 1.6em Lato;
        }

        .diamonds-label {
            margin-top: 5px;
        }
    }
}