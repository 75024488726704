#details-signup-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .details-signup-topbar-container {
        width: 0%;
        padding: 6px 50%;
        display: flex;
        justify-content: center;
        justify-content: flex-start;
        overflow-x: auto;
        scroll-behavior: smooth;
        gap: 20px;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        .navigation-item-container {
            min-width: 47px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s ease;

            .navigation-item-icon-container {
                height: 55px;
                display: flex;
                align-items: center;

                .navigation-item-icon {
                    font-size: 40px;
                    color: white;
                }
            }

            .navigation-item-name {
                height: 15px;
                white-space: nowrap;
                font: 500 0.7em Lato;
                color: white;
            }

            .empty-navigation-item {
                height: 8px;
                width: 8px;
                border-radius: 50px;
                background-color: white;
            }
        }
    }

    .details-signup-body-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        // overflow: auto;

        .embla {
            height: 100%;
            width: 100%;
            overflow-x: hidden;

            .embla__container {
                height: 100%;
                display: flex;

                .embla__slide {
                    flex: 0 0 100%;
                    min-width: 0;
                }
            }
        }
    }
}

// Utility CSS
.details-signup-body-desktop-height {
    height: calc(100% - 162px);
}

.details-signup-body-mobile-height {
    height: calc(100% - 80px);
    padding-top: 10px;
}