@import "../../../../global-style.scss";

#upgrade-promo-dialog {
    width: 300px;

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .promo-text-field-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .enter-code-label {
                font: 600 1.2em Lato;
            }

            .promo-form-field {
                width: 100%;

                .MuiFormHelperText-root {
                    color: red;
                }
            }
        }

        .buttons-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .submit-button {
                width: 100%;
                padding: 10px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 500 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .cancel-button {
                width: 100%;
                padding: 10px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 500 1.1em Lato;
                background-color: white;
            }
        }
    }
}