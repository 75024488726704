#livestreamer-interactable-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 20;

    .top-section {

        .padding-container {
            // margin-top: 25px;
            padding: 10px 10px 0 10px;
        }
    }

    .bottom-section {}
}