#video-call-recent-tab-recent-list-subcomponent {
    cursor: pointer;

    .padding-container {
        padding-left: 15px;
        padding-right: 15px;

        .recent-container {
            height: 50px;
            display: flex;

            .user-image-container {
                width: 50px;
                position: relative;
                display: flex;
                align-items: center;

                .user-image {
                    height: 50px;
                    width: 50px;
                }

                .video-call-available-icon-container {
                    height: 20px;
                    width: 20px;
                    position: absolute;
                    bottom: 0;
                    right: 0;

                    .video-call-available-icon {
                        height: 100%;
                        width: 100%;
                    }
                }

                .video-call-missed-icon-container {
                    height: 18px;
                    width: 18px;
                    position: absolute;
                    bottom: 0;
                    right: 0;

                    .video-call-missed-icon {
                        height: 100%;
                        width: 100%;
                    }
                }
            }

            .user-description-container {
                width: calc(100% - 50px - 100px - 25px);
                padding: 15px 0 15px 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .user-top-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .livestreaming-achievement-badge-container {
                        display: flex;
                        gap: 3px;

                        .livestreaming-achievement-badge {
                            height: 13px;
                        }
                    }

                    .user-name {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font: 500 1.1em Lato;
                    }

                    .user-badge-container {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        .verified-badge-container {
                            display: flex;
                            align-items: center;

                            .verified-badge {
                                width: 15px;
                            }
                        }

                        .admin-badge-container {
                            height: 13px;
                            width: 50px;
                            padding: 0 3px 0 1px;
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            border-radius: 25px;
                            background-color: rgba(205, 79, 79, .78);
                            color: white;

                            .person-icon {
                                font-size: 10px;
                            }

                            .admin-label {
                                display: flex;
                                align-items: center;
                                font: 500 0.7em Lato;
                            }
                        }

                        .premium-mini-badge-container {
                            display: flex;
                            align-items: center;

                            .premium-mini-badge {
                                height: 13px;
                            }
                        }

                        .level-badge-container {
                            display: flex;
                            align-items: center;

                            .level-badge {
                                height: 13px;
                            }
                        }
                    }
                }

                .user-bottom-container {

                    .user-last-message {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: rgba(0, 0, 0, .6);
                    }
                }
            }

            .user-last-updated-container {
                width: 100px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .last-updated {
                    white-space: nowrap;
                    text-align: right;
                    font: 500 0.8em Lato;
                    color: rgba(0, 0, 0, .6);
                }
            }

            .info-icon-container {
                width: 25px;
                display: flex;
                align-items: center;

                .info-icon {
                    padding-left: 5px;
                    font-size: 20px;
                }
            }
        }
    }
}