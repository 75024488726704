#coins-list-shared-subcomponent {
    // width: calc(100% - 1px);
    padding: 10px 0 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 9px;

    .coins-container {
        height: 100px;
        width: 30%;
        min-width: 106px;
        max-width: 150px;
        position: relative;
        margin-bottom: 15px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border: 2px solid #EEEEEE;
        border-radius: 25px;
        cursor: pointer;
        background-color: #EEEEEE;
        transition: all 0.3s;

        .first-row {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-end;

            .limited-time-badge {
                position: absolute;
                left: -5px;
                bottom: 10px;
                display: flex;

                .limited-time-image {
                    width: 50px;
                }
            }

            .savings-container {
                position: relative;
                left: 5px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-end;
                flex-wrap: wrap;
                gap: 4px;

                .note-1 {
                    padding: 3px 5px;
                    display: flex;
                    align-items: center;
                    border-radius: 25px;
                    font: 600 0.8em Lato;
                    color: white;
                }

                .discount-text {
                    padding: 2px 7px;
                    border-radius: 25px;
                    font: 800 0.6em Lato;
                }
            }
        }

        .second-row {
            display: flex;

            .coin-image-container {
                display: flex;

                .coin-image {
                    width: 20px;
                }
            }

            .quantity-label {
                padding-left: 5px;
                display: flex;
                align-items: center;
                font: 600 1em Lato;
            }
        }

        .third-row {

            .discounted-price-label {
                // color: rgb(4, 209, 0);
            }
        }

        .fourth-row {

            .original-price-label {
                text-decoration: line-through;
                font: 600 0.7em Lato;
                // color: rgb(255, 0, 0);
            }
        }

        .price-label {
            font: 600 0.9em Lato;
            // color: rgb(113, 13, 13);
        }

        .bottom-floating-text {
            position: absolute;
            bottom: -11px;
            padding: 3px 5px;
            border-radius: 25px;
            font: 700 0.6em Lato;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .selected-coin {
        border: 2px solid rgb(255, 226, 146);
    }
}

// Utility CSS
.custom-orange-to-red-gradient {
    background: radial-gradient(1269.72% 105.37% at 105.37% 133.33%, rgba(255, 55, 103, 0.98) 5.45%, #F5BB19 100%);
}

.custom-green-gradient {
    background: radial-gradient(2263.82% 187.87% at 150.4% 10.28%, rgba(59, 255, 55, 0.98) 0%, #00AD26 100%);
}

.custom-pink-gradient {
    background: radial-gradient(2351.8% 131.98% at 104.48% 51.94%, rgba(255, 55, 139, 0.98) 5.45%, rgba(223, 54, 166, 0.99) 100%);
}

.custom-purple-gradient {
    background: radial-gradient(1269.72% 105.37% at 105.37% 133.33%, rgba(203, 55, 255, 0.98) 5.45%, #6D19F5 100%);
}

.custom-blue-gradient {
    background: linear-gradient(50deg, rgba(71, 152, 227, 1) 0%, rgba(40, 190, 239, 1) 40%, rgba(0, 240, 255, 1) 100%);
}

.custom-yellow-gradient {
    background: radial-gradient(2328.48% 143.61% at 113.28% 23.56%, rgba(255, 247, 55, 0.98) 5.45%, rgba(223, 145, 54, 0.99) 100%);
}

.custom-dark-purple-gradient {
    background: linear-gradient(90deg, rgba(30, 12, 5, 1) 0%, rgba(77, 27, 113, 1) 40%, rgba(123, 41, 220, 1) 100%);
}

.custom-grey-to-black-gradient {
    // background: linear-gradient(90deg, rgba(93,93,93,1) 20%, rgba(60,60,60,1) 100%);
    background: linear-gradient(90deg, rgba(93, 93, 93, 1) 20%, rgba(39, 39, 39, 1) 90%, rgba(0, 0, 0, 1) 100%);
}

.custom-green-to-black-gradient {
    background: linear-gradient(90deg, rgba(34,57,43,1) 0%, rgba(30,12,5,1) 50%);
}