#chat-section-following-element-subcomponent {
    width: fit-content;
    padding: 2px 5px;
    margin: 0px 10px;
    display: flex;
    border-radius: 10px;

    .profile-picture-container {
        width: 45px;
        min-width: 45px;
        display: flex;
        align-items: center;

        .profile-picture {
            height: 30px;
            width: 30px;
        }
    }

    .chat-info-container {
        width: 88%;
        display: flex;
        align-items: center;

        .livestreaming-achievement-badge-container {
            display: flex;
            align-items: center;
            gap: 3px;

            .livestreaming-achievement-badge {
                height: 13px;
            }
        }

        .levelling-badge-container {
            padding-top: 3px;
            display: flex;

            .levelling-badge {
                height: 13px;
            }
        }
    }
}