#private-room-minimize-bar {
    cursor: pointer;

    .padding-container {
        height: 50px;
        padding: 5px 10px;
        display: flex;
        box-shadow: 0px -6px 4px rgb(0 0 0 / 25%);
        background-color: rgba(51, 51, 51, 0.9);

        .left-container {
            width: 65%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .timer-container {
                text-align: center;
                font: 500 1.4em Lato;
                color: #06E043;
            }

            .text {
                text-align: center;
                font: 500 0.8em Lato;
                color: white;
            }
        }

        .right-container {
            width: 35%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .claim-button-container {
                padding: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                border-radius: 50px;

                .claim-button {
                    padding: 10px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    transition: 1s;
                }

                .diamond-count {
                    font: 600 1em Lato;
                    color: #00FF38;
                }

                .diamond-icon-container {
                    display: flex;
                    align-items: center;

                    .diamond-icon {
                        height: 13px;
                    }
                }
            }

            .claim-diamonds-button {
                background: linear-gradient(90.38deg, #50B343 5.04%, #148205 119.21%);
            }

            .no-diamonds {
                background-color: #979797;
            }

            .close-button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .close-button {}
            }
        }
    }
}