#live-live-card-subcomponent {
    cursor: pointer;

    .live-card-container {
        width: 100%;
        position: relative;
        padding-top: 147%;
        border-radius: 10px;

        .profile-image-container {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            border-radius: inherit;
            z-index: 10;

            .profile-image {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: inherit;
            }

            .no-profile-image {
                height: 100%;
                width: 100%;
                object-fit: contain;
                border-radius: inherit;
                background-color: #e0e0e0;
            }
        }

        .description-container {
            height: calc(100% - 20px);
            width: calc(100% - 20px);
            position: absolute;
            top: 0;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 10px;
            z-index: 20;
            background: linear-gradient(186.69deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.3) 100%);

            .top-description-container {
                width: 110px;
                display: flex;

                .live-status {
                    height: 20px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3em;
                    font: 800 0.7em Lato;
                    background-color: #ff1694;
                    color: #fff;
                }

                .viewer-count-container {
                    height: 20px;
                    min-width: 40px;
                    margin-left: 5px;
                    padding-left: 6px;
                    padding-right: 8px;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    border-radius: 3em;
                    background-color: rgba(196, 196, 196, 0.6);
                    color: white;

                    .eye-logo-container {
                        display: flex;

                        .eye-logo {
                            width: 16px;
                        }
                    }

                    .viewer-count {
                        padding-left: 2px;
                        font: 800 0.8em Lato;
                        color: white;
                    }
                }
            }

            .livestreaming-achievements-badge-container {
                display: flex;
                gap: 3px;

                .livestreaming-achievements-badge {
                    height: 13px;
                }
            }

            .bottom-description-container {

                .name-age-status-container {
                    display: flex;
                    font: 600 1em Lato;
                    color: white;

                    .username {
                        max-width: 50%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .age {
                        padding-left: 5px;
                    }

                    .status {
                        height: 10px;
                        width: 10px;
                        margin: auto 0 3px 5px;
                        border-radius: 50px;
                        background-color: rgb(28, 189, 32);
                    }
                }

                .location-description {
                    display: flex;
                    font: 500 0.8em Lato;
                    color: white;

                    .location {}

                    .badge-container {
                        margin-left: 5px;
                        margin-bottom: 1px;
                        display: flex;
                        align-items: flex-end;

                        .badge {
                            height: 12px;
                        }
                    }
                }
            }
        }
    }
}