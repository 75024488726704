@import "../../../global-style.scss";

#username-signup-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-container {
        height: calc(75% - 100px - 10px);
        width: calc(100% - 40px);
        max-width: 500px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 50px;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .username-description-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .username-description-one {
                font: 600 2em Lato;
                color: white;
            }

            .username-description-two {
                font: 500 1em Lato;
                color: white;
            }
        }

        .form-fields-container {
            height: 20%;
            min-height: 160px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .username-form-field-container {
                width: 100%;
                min-height: 60px;

                .username-form-field {
                    width: 100%;

                    .MuiInput-underline:before {
                        border-bottom: 2px solid rgba(255, 255, 255, 0.4);
                    }

                    .MuiInput-underline:after {
                        border-bottom-color: white;
                    }

                    .MuiInput-input {
                        padding: 4px 0 7px 5px;
                        color: white;
                    }

                    .MuiFormHelperText-root {
                        font-weight: bold;
                        color: #FF0000;
                    }
                }
            }

            .dob-form-field-container {
                width: 100%;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                gap: 6px;
                color: white;

                .react-date-picker {
                    width: 100%;

                    .react-date-picker__wrapper {
                        padding: 4px 0 4px 5px;
                        border: none;
                        border-bottom: 2px solid rgba(255, 255, 255, 0.4);

                        .react-date-picker__inputGroup {
                            padding: 0;
                            font: 500 1em Lato;

                            .react-date-picker__inputGroup__month {
                                // width: fit-content !important;
                            }

                            .react-date-picker__inputGroup__day {
                                // width: fit-content !important;
                            }

                            .react-date-picker__inputGroup__year {
                                width: 50px !important;
                            }

                            input {
                                caret-color: transparent;
                            }
                        }

                        .react-date-picker__calendar-button {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            svg {
                                stroke: white;
                            }
                        }
                    }
                }

                .react-calendar {
                    border: none;
                    border-radius: 5px;
                    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);

                    .react-calendar__navigation__label__labelText {
                        font: 600 1em Lato;
                    }

                    .react-calendar__navigation__arrow {
                        font-size: 25px;
                    }

                    .react-calendar__tile {
                        font: 600 1em Lato;
                    }

                    // Arrow buttons
                    .react-calendar__navigation__arrow {
                        border-radius: 50px;
                    }

                    // Navigation label
                    .react-calendar__navigation__label {
                        border-radius: 50px;
                    }

                    // Day view
                    .react-calendar__month-view__days__day {
                        height: 47px;
                        width: 50px;
                        border-radius: 50px;
                    }

                    // Month view
                    .react-calendar__year-view__months__month {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 0;
                        margin: 10px 0;
                        border-radius: 50px;
                    }

                    // Year view
                    .react-calendar__decade-view__years__year {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 0;
                        margin: 10px 0;
                        border-radius: 50px;
                    }

                    // Decade view
                    .react-calendar__century-view__decades__decade {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 0;
                        margin: 10px 0;
                        border-radius: 50px;
                    }

                    .react-calendar__tile--active {
                        background-color: $sugarbook-main-color;
                    }

                    .react-calendar__tile--hasActive {
                        background-color: $sugarbook-main-color;
                        color: white;
                    }
                }

                .dob-error-helper {
                    font: 400 0.75rem Lato;
                    color: #FF0000;
                }
            }
        }
    }

    .bottom-container {
        height: 100px;
        width: calc(100% - 40px);
        max-width: 500px;
        padding: 10px 20px 0 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .continue-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .full-width {
        width: 100%;
    }

    .partial-width {
        width: 60%;
    }

    .fix-width {
        width: 150px;
    }

    .fit-content {
        width: fit-content;
    }
}

// Responsive Design
@media (max-width: 768px) {
    #username-signup-page {

        .top-container {
            height: calc(100% - 100px - 10px);
        }

        .bottom-container {
            justify-content: center;
        }
    }
}