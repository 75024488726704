#search-search-list-container-subcomponent {
    height: 100%;
    width: 100%;

    .search-list-body-container {
        height: 100%;

        .category-tab-view {
            // padding: 5px 0;
            display: flex;
            justify-content: flex-end;
        }

        .search-view-container {
            height: 100%;
        }

        .end-card-description-container {
            height: 220px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background-color: rgb(239, 239, 239);

            .end-card-title {
                padding-bottom: 15px;
                text-align: center;
                font: 600 1.4em Lato;
            }

            .end-card-description {
                width: 80%;
                text-align: center;
                font: 400 1em Lato;
            }
        }
    }
}

// Responsive Design
@media (min-width: 720px) {
    #search-search-list-container-subcomponent {

        .search-list-body-container {
            border-radius: 20px;
            box-shadow: 0 0px 6px 0px rgba(0, 0, 0, 0.1);
            background-color: white;

            .search-view-container {
                padding: 10px;
            }
        }
    }
}