@import "../../../../global-style.scss";

#request-private-photo-dialog {
    max-width: 470px;

    .padding-container {
        padding: 40px 40px 20px 40px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .title {
            text-align: center;
            font: 600 1.5em Lato;
        }

        .description {
            text-align: center;
            color: #7D7D7D;
        }

        .button-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .request-button {
                padding: 15px 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 500 1.2em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .cancel-button {
                width: 70%;
                padding: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                color: #A3A3A3;
            }
        }
    }
}