#more-info-tab-streamer-stats-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .livestreaming-data-header {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .livestreaming-data-label {
                font: 600 1.5em Lato;
            }

            .livestreaming-data-description {
                font: 500 1em Lato;
            }
        }

        .livestreaming-data-container {
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .livestreaming-month-container,
            .livestreaming-total-hours-container,
            .livestreaming-total-days-container,
            .livestreaming-total-diamonds-container {
                display: flex;
                align-items: center;
                gap: 5px;

                .livestreaming-month-label,
                .livestreaming-total-hours-label,
                .livestreaming-total-days-label,
                .livestreaming-total-diamonds-label {
                    font: 600 1.2em Lato;
                }

                .livestreaming-month-value,
                .livestreaming-total-hours-value,
                .livestreaming-total-days-value,
                .livestreaming-total-diamonds-value {
                    flex-grow: 1;
                    font: 500 1em Lato;

                    .livestreaming-month-value-select-form {
                        width: 100%;

                        .MuiInputBase-input {
                            padding-top: 0;
                            padding-bottom: 0;
                            display: flex;
                            align-items: center;
                            font: 600 1.2em Lato;
                        }
                    }
                }
            }
        }
    }
}