@import "../../../../global-style.scss";

#vpn-block-dialog {
    height: 100%;
    background-image: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .dialog-header {
        width: calc(100% - 30px);
        padding: 15px 15px 0 15px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .vpn-body-container {
        display: flex;
        flex-direction: column;

        .vpn-desktop-background-container {
            padding-top: 20px;

            .vpn-desktop-background {
                width: 100%;
            }
        }

        .padding-container {
            padding: 40px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .title {
                font: 600 1.7em Lato;
            }

            .description {
                font: 500 0.9em Lato;

                a {
                    color: #2400FF;
                }
            }

            .no-vpn-fake-button {
                height: 50px;
                width: 200px;
                margin-top: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: not-allowed;
                font: 600 1.3em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }
        }
    }
}

// Responsive Design
@media (max-width: 500px) {
    #vpn-block-dialog {
        background-image: url("../../../../assets/background/onboarding/vpn-block-mobile-background.jpg");
    }
}