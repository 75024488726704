#casino-games-page {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        height: 100%;
        width: 100%;
        max-width: 1000px;

        .dialog-header {
            padding: 10px 20px 3px 20px;
            display: flex;
            justify-content: space-between;

            .game-selection-button-container {
                width: 40px;
                display: flex;
                // align-items: center;
                cursor: pointer;

                .game-selection-button {
                    height: 27px;
                }
            }

            .game-title-container {
                display: flex;
                align-items: center;
                font: 600 1.1em Lato;
            }

            .close-button-container {
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                cursor: pointer;

                .close-button {
                    font-size: 30px;
                }
            }
        }

        .game-label-container {
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .game-label {
                font: 600 1.1em Lato;
            }

            .game-selection-button-container {
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                .game-selection-button {
                    height: 27px;
                }
            }
        }

        .game-list-container {
            width: calc(100% - 20px);
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            gap: 10px;

            .game-container {
                // height: 150px;
                width: 100%;
                max-width: 400px;
                cursor: pointer;

                .game-logo {
                    width: 100%;
                    border-radius: 20px;
                }
            }
        }

        .casino-game-mobile-container {
            height: 100%;
            display: flex;
            justify-content: center;
            border-radius: 0 0 15px 15px;
            background-color: black;

            .casino-game-inner-container {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;

                .casino-game-iframe {
                    height: 100%;
                    width: 100%;
                    border: 0;
                }
            }
        }

        .casino-game-desktop-container {
            height: calc(100% - 47px);
            display: flex;
            justify-content: center;
            border-radius: 0 0 15px 15px;
            background-color: black;

            .casino-game-inner-container {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;

                .casino-game-iframe {
                    height: 100%;
                    width: 100%;
                    border: 0;
                }
            }
        }

        // Utility CSS
        .casino-fix-height {
            height: 390px;
        }

        .casino-fix-width {
            width: 390px;
        }

        .casino-max-width {
            max-width: 390px;
        }

        .casino-apply-radius {
            // border-radius: 15px; // Not using yet
        }
    }
}