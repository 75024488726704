#interactable-overlay-top-banner-subcomponent {
    // height: 40px;
    // background-color: #E2C000;

    .top-banner-padding-container {
        // height: 40px;
        padding: 6px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font: 600 0.69em Lato;
        background-color: #E2C000;
    }

    .free-minutes-timer-container {
        padding: 6px 15px;
        text-align: center;
        font: 600 0.7em lato;
        background-color: #b7f7f5;
        color: black;

        .coins-label {
            color: #0094FF
        }
    }

    .callee-timer-container {
        height: 40px;
        padding: 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font: 600 0.7em Lato;
        color: white;
        background-color: red;

        .real-money-text {
            display: contents;
            color: #33FF00;
        }
    }
}