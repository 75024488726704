#livestream-interactable-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 20;

    .top-section {
        pointer-events: none;

        .padding-container {
            // margin-top: 25px;
            padding: 10px 10px 0 10px;
        }
    }

    .bottom-section {
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        // flex: 1;
    }

    // Utility CSS
    .extra-top-padding {
        padding-top: 25px; // remove padding if there is banner
    }
}

// Utility CSS
.darken-background {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0) 100%) !important;
}