#settings-notification-settings-subcomponent {

    .padding-container {
        padding: 20px;

        .label-container {
            display: flex;
            justify-content: space-between;

            .notification-label {
                font: 600 1.1em Lato;
            }

            .right-container {
                display: flex;
                align-items: center;

                .push-label {
                    width: 58px;
                    text-align: center;
                    font-size: 0.8em;
                    color: #7c7c7c;
                }

                .email-label {
                    width: 58px;
                    text-align: center;
                    font-size: 0.8em;
                    color: #7c7c7c;
                }
            }
        }

        .switch-list-container {
            width: 100%;
            padding-top: 20px;

            .switch-container {
                height: 50px;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                color: black;

                .switch-label {
                    font: 500 1em Lato;
                    color: #676767;
                }

                .suffix {
                    display: flex;
                }
            }
        }
    }
}