#go-private-button-shared-subcomponent {
    max-width: 116px;
    padding: 13px 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 5px;
    border-radius: 25px;
    cursor: pointer;
    font: 700 1em Lato;
    background: linear-gradient(90.18deg, #FF1694 20.88%, #000000 81.7%);
    color: white;

    .private-icon-container {
        display: flex;
        align-items: center;

        .private-icon {}
    }

    .label {}
}