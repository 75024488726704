@import "../../../../global-style.scss";

#payment-error-dialog {
    max-width: 320px;
    background-color: #FFDDDD;

    .error-title-container {
        padding: 40px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;

        .error-icon-container {

            .error-icon {
                width: 50px;
            }
        }

        .error-title {
            font: 600 1.5em Lato;
            color: #FF0000;
        }

        .error-message {
            font: 600 1em Lato;
        }
    }

    .error-body-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;
        background-color: rgb(215, 13, 13);
        color: white;

        .warning-icon-container {

            .warning-icon {
                width: 50px;
            }
        }

        .error-subtitle {
            text-decoration: underline;
            font: 500 1.1em Lato;
        }

        .error-solution-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .solution-container {
                display: flex;
                gap: 3px;
                font: 400 0.9em Lato;

                .solution-index {}

                .error-solution {}
            }
        }
    }

    .error-button-container {
        padding: 20px 20px 40px 20px;

        .got-it-button {
            height: 40px;
            width: 200px;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1em Lato;
            background: $sugarbook-main-color;
            color: white;
        }
    }
}