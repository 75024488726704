@import "../../../../global-style.scss";

#go-live-info-dialog {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .popup-action-icon-container {

            .action-icon {
                height: 60px;
            }
        }

        .heads-up-title {
            font: 600 1.3em Lato;
        }

        .join-private-android-warning-description {
            text-align: center;
            font: 500 1em/1.2 Lato;
        }

        .buttons-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .download-button {
                height: 47px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .close-button {
                height: 47px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
            }
        }
    }
}