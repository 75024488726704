#interests-photo-permissions-tab-subcomponent {
    height: 100%;

    .photo-permission-list-container {
        height: 100%;
        padding: 0 20px;
        overflow: auto;

        >div {
            height: 100%;

            .infinite-scroller-container {}
        }
    }
}