#settings-your-activity-settings-subcomponent {

    .padding-container {
        padding: 20px;

        .activity-label-container {
            display: flex;
            gap: 10px;

            .activity-label {
                font: 600 1.1em Lato;
            }
        }

        .switch-list-container {
            width: 100%;
            padding-top: 20px;

            .switch-container {
                height: 50px;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                color: black;

                .switch-label {
                    font: 500 1em Lato;
                    color: #676767;
                }

                .suffix {
                    display: flex;
                }
            }
        }
    }

    // Utility CSS
    .disabled {
        opacity: 0.5;
    }
}