#incoming-private-call-dialog {
    height: 100%;
    width: 100%;
    padding: 20px 0;
    // display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    overflow: auto;
    background-color: rgba(51, 51, 51, 0.9);

    .call-request-container {
        width: calc(100% - 40px);
        margin: 0 20px 20px 20px;

        border-radius: 25px;
        background-color: rgba(255, 255, 255, 0.2);

        .padding-container {
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 15px;

            .call-request-animation-container {
                display: flex;
                justify-content: center;

                .call-request-animation {
                    width: 70px;
                }
            }

            .profile-photos-container {
                display: flex;
                justify-content: center;

                .caller-picture {
                    height: 60px;
                    width: 60px;
                    position: relative;
                    left: 5px;
                    z-index: 2;
                }

                .callee-picture {
                    height: 60px;
                    width: 60px;
                    position: relative;
                    right: 5px;
                    z-index: 1;
                }
            }

            .call-request-header {
                text-align: center;
                font: 600 1.4em Lato;
                color: #fff;
            }

            .call-request-rate-description {
                text-align: center;
                font: 600 1.2em/1.4 Lato;
                color: white;

                .amount-text {
                    color: #11A904;
                }

                .diamond-text {
                    color: #4DC5FF
                }

                .diamond-icon {
                    height: 13px;
                }

                .coin-text {
                    color: #FFE18C;
                }

                .coin-icon {
                    padding-right: 3px;
                    height: 13px;
                }
            }

            .tips-title {
                font: 600 1.1em Lato;
                color: white;
            }

            .tips-list {
                font: 500 0.9em/1.5 Lato;
                color: white;
            }
        }
    }

    .button-container {
        width: calc(100% - 40px);
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .decline-button {
            padding: 15px 25px;
            display: flex;
            justify-content: center;
            flex: 1;
            border-radius: 25px;
            text-transform: uppercase;
            cursor: pointer;
            font: 600 1.1em Lato;
            background: linear-gradient(180deg, #FA000A 0%, #AC0007 100%);
            color: white;
        }

        .accept-button {
            padding: 15px 25px;
            display: flex;
            justify-content: center;
            flex: 1;
            border-radius: 25px;
            text-transform: uppercase;
            cursor: pointer;
            font: 600 1.1em Lato;
            background: linear-gradient(90.38deg, #50B343 5.04%, #148205 119.21%);
            color: white;
        }
    }
}