#chat-section-tipping-element-subcomponent {
    width: fit-content;
    padding: 2px 5px;
    margin: 0px 10px;
    display: flex;
    border-radius: 10px;

    .profile-picture-container {
        width: 45px;
        display: flex;
        align-items: center;

        .profile-picture {
            height: 30px;
            width: 30px;
        }
    }

    .chat-info-container {
        display: flex;
        align-items: center;

        .chat-info {

            .username {
                font: 700 0.9em/1.4 Lato;
                color: #FFE18C;
            }

            .levelling-badge {
                height: 13px;
                margin-top: 3px;
                padding: 0 7px;
            }

            .joined-text {
                font: 700 0.9em/1.4 Lato;
                color: #FFE18C;
            }
        }
    }
}