@import "../../../../global-style.scss";

#utility-desktop-navbar-shared-component {

    .buttons-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-container {

            .back-icon-container {
                height: 50px;
                width: 50px;
                display: flex;
                justify-content: center;
                cursor: pointer;

                .back-icon {
                    width: 10px;
                }
            }
        }

        .right-container {

            .done-button {
                width: 75px;
                font: 500 1.2em Lato;
                cursor: pointer;
                color: $sugarbook-main-color;
            }

            .inbox-button-container {
                display: flex;
                align-items: center;
                cursor: pointer;

                .video-call-icon-container {
                    height: 46px;
                    width: 46px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .video-call-icon {}
                }

                .info-icon-container {
                    height: 46px;
                    width: 46px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .info-icon {}
                }
            }
        }
    }
}