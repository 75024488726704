@import "../../../../global-style.scss";

#chat-conversation-incomplete-profile-subcomponent {
    height: 150px;

    .sb-padding-container,
    .sd-padding-container {
        height: 100%;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .sbf-description {
            text-align: center;
            font: 400 1em Lato;
            color: rgba(0, 0, 0, 0.6);
        }

        .sbf-small-description {
            text-align: center;
            font: 400 0.8em Lato;
            color: rgba(0, 0, 0, 0.6);
        }

        .edit-profile-button {
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            font: 600 1.2em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }

        .upgrade-button {
            width: 100%;
            max-width: 350px;
            margin: 0 auto;
            padding: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1.2em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}

// Utility CSS
.rounded-corner {
    border-radius: 25px;
}