@import "../../../../global-style.scss";

#clear-messages-dialog {
    max-width: 350px;

    .padding-container {
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;

        .delete-message-icon-container {

            .delete-message-icon {}
        }

        .text {
            text-align: center;
            font: 500 1.1em/1.4 Lato;
            color: rgba(0, 0, 0, 0.87);
        }

        .button-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .confirm-button {
                height: 45px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $sugarbook-main-color;
                border-radius: 25px;
                cursor: pointer;
                font: 500 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .cancel-button {
                height: 45px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $sugarbook-main-color;
                border-radius: 25px;
                cursor: pointer;
                font: 500 1.1em Lato;
                background-color: white;
                color: $sugarbook-main-color;
            }
        }
    }
}