@import "../../global-style.scss";

#interests-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    align-items: center;

    .desktop-banner-container {}

    .max-width-container {
        height: calc(100% - 150px);
        width: calc(100% - 40px);
        max-width: 1000px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        display: flex;
        border: 1px solid #e5e7eb;
        border-radius: 25px;
        background-color: white;

        .tab-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;

            .add-underline {
                border-bottom: 1px solid $mui-tabs-border-bottom-color;
            }

            .mobile-banner-container {
                width: 100%;
                display: none;
            }

            .embla {
                height: 100%;
                width: 100%;
                overflow-x: hidden;

                .embla__container {
                    height: 100%;
                    display: flex;

                    .embla__slide {
                        flex: 0 0 100%;
                        min-width: 0;
                    }
                }
            }
        }
    }
}

// Responsive Design
@media (max-width: 1000px) {
    #interests-page {

        .max-width-container {

            .tab-container {}
        }
    }
}

@media (max-width: 720px) {
    #interests-page {

        .desktop-banner-container {
            display: none;
        }

        .max-width-container {
            height: 100%;
            width: 100%;
            margin: 0;
            border: none;
            border-radius: 0;

            .tab-container {

                .mobile-banner-container {
                    display: block;
                }
            }
        }
    }
}