@import "../../../../global-style.scss";

#livestream-report-success-dialog {
    max-width: 280px;

    .padding-container {
        padding: 50px 30px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .header {
            text-align: center;
            font: 600 1.2em Lato;
        }

        .text {
            text-align: center;
            font: 400 0.9em/1.7 Lato;
        }

        .close-button {
            height: 40px;
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            font: 600 1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}