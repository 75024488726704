@import "../../../../global-style.scss";

#signup-stepper-shared-component {
    width: 100%;
    display: flex;
    gap: 5px;

    .stepper-item {
        height: 3px;
        width: 100%;
        border-radius: 5px;
    }

    .selected-stepper {
        background-color: $sugarbook-main-color;
    }

    .unselected-stepper {
        background-color: #D9D9D9;
    }
}