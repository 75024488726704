#livestreaming-play-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 30;

    .play-icon {
        cursor: pointer;
        font-size: 4em;
        filter: invert(100%);
        color: white;
    }
}