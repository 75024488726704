#eye-color-filter-shared-component {

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font: 600 1.1em Lato;
        }

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .padding-container {
        padding: 20px;

        .checkbox-container {

            .checkbox {
                padding: 5px 0;

                .checkbox-font {
                    font: 500 0.9em Lato;
                }
            }
        }
    }
}

// Utility CSS
.flexwrap {
    display: flex;
    flex-wrap: wrap;
}