#video-call-video-call-tab-subcomponent {

    .padding-container {
        height: calc(100% - 20px);
        padding: 10px;
        overflow: auto;

        .video-call-list-container {
            height: calc(100% - 200px);

            >div {
                height: 100%;

                .infinite-scroller-container {
                    padding-bottom: 10px;
                    display: grid;
                    gap: 10px;
                }

                .multi-column {
                    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
                }
            }
        }
    }
}