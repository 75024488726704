#user-list-shared-subcomponent {
    cursor: pointer;

    .padding-container {
        padding-left: 15px;
        padding-right: 15px;

        .user-list {
            height: 90px;
            display: flex;

            .user-image-container {
                width: 70px;
                position: relative;
                display: flex;
                align-items: center;

                .user-image {
                    height: 70px;
                    width: 70px;
                }

                .lock-image-container {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .lock-image {
                        height: 22px;
                    }
                }
            }

            .user-description-container {
                width: calc(100% - 70px - 100px);
                padding: 15px 0 15px 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .user-top-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .livestreaming-achievement-badge {
                        height: 13px;
                    }

                    .user-name {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font: 500 1.1em Lato;
                    }

                    .misc-badge-container {
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        .verified-profile-badge-container {
                            display: flex;

                            .verified-profile-badge {
                                height: 16px;
                            }
                        }

                        .star-icon {
                            font-size: 20px;
                            color: rgba(244, 191, 37, 0.78);
                        }

                        .verified-caller-badge-container {
                            display: flex;

                            .verified-caller-badge {
                                height: 16px;
                            }
                        }

                        .special-badges-container {
                            display: flex;
                            align-items: center;
                            gap: 3px;

                            .special-badges {
                                height: 16px;
                            }
                        }
                    }
                }

                .user-bottom-container {

                    .user-location {
                        color: rgba(0, 0, 0, .6);
                    }
                }
            }

            .user-last-updated-container {
                width: 100px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .last-updated {
                    white-space: nowrap;
                    text-align: right;
                    font: 500 0.8em Lato;
                    color: rgba(0, 0, 0, .6);
                }
            }
        }
    }

    // Utility CSS
    .blur-image {

        img,
        svg {
            -webkit-filter: blur(8px);
            filter: blur(8px);
        }
    }
}