#add-payment-method-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        max-width: 720px;

        .padding-container {
            height: calc(100% - 100px);
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 25px;
            overflow: auto;

            .we-accept-label {
                text-align: center;
                font: 600 1.1em Lato;
            }

            .credit-card-company-image-container {
                display: flex;
                justify-content: center;
                gap: 8px;

                .visa-logo {
                    height: 30px;
                }

                .mastercard-logo {
                    height: 30px;
                }

                .maestro-logo {
                    height: 30px;
                }

                .electron-logo {
                    height: 30px;
                }
            }

            .agreement-checkbox-container {

                .checkbox {
                    color: rgba(0, 0, 0, 0.6);

                    .MuiTypography-root {
                        font: 400 0.9em/1.4 Lato;
                    }
                }
            }

            .discretion-container {
                padding: 20px 10px;
                border-radius: 20px;
                text-align: center;
                font: 500 0.9em/1.5 Lato;
                background-color: rgb(238, 238, 238);
                color: rgb(51, 51, 51);
            }
        }

        .pay-button {
            height: 60px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font: 600 1.4em Lato;
            background: linear-gradient(132.82deg, #3A0101 0%, #7C0E0E 100%);
            color: white;
            transition: all 1s;
        }

        // Utility CSS
        .disabled-button {
            cursor: not-allowed;
            background: #D0D0D0;
            color: #B1B1B1;
        }
    }
}