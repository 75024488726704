@import "../../../global-style.scss";

#profile-edit-page {

    .max-width-container {
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        background-color: white;

        .padding-container {
            padding: 10px;
        }

        .rejected-container {
            padding: 15px;
            margin: 10px;
            display: flex;
            border-radius: 10px;
            background-color: #EB6258;

            .left-container {
                display: flex;
                align-items: center;
                flex: 2;

                .moderation-icon-container {
                    height: 50px;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    background-color: white;

                    .error-icon {
                        border-radius: 50px;
                        font-size: 27px;
                        background-color: #EB6258;
                        color: white;
                    }
                }
            }

            .right-container {
                display: flex;
                flex-direction: column;
                flex: 8;
                gap: 5px;

                .moderation-title {
                    font: 600 1em Lato;
                    color: #333333;
                }

                .moderation-desc {
                    font: 400 0.8em Lato;
                    color: #333333;
                }
            }
        }

        .in-review-container {
            padding: 10px;
            margin: 10px;
            display: flex;
            border-radius: 10px;
            background-color: #27CBFF;

            .left-container {
                width: 60px;
                padding-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                .moderation-icon-container {
                    height: 50px;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    background-color: white;

                    .info-icon {
                        font-size: 35px;
                        color: #27CBFF;
                    }
                }
            }

            .right-container {
                display: flex;
                flex-direction: column;
                flex: 8;
                gap: 5px;

                .moderation-title {
                    font: 600 1em Lato;
                }

                .moderation-desc {
                    font: 400 0.8em Lato;
                }
            }
        }

        .top-container {
            height: 50px;
            padding: 0 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .edit-label {
                width: 50%;
                padding-bottom: 10px;
                display: flex;
                justify-content: center;
                border-bottom: 3px solid $sugarbook-main-color;
                text-transform: uppercase;
                font: 600 0.9em Lato;
                color: $sugarbook-main-color;
            }

            .view-profile-label {
                width: 50%;
                padding-bottom: 10px;
                display: flex;
                justify-content: center;
                border-bottom: 1px solid lightgrey;
                font: 600 0.9em Lato;
                color: #c8c4c4;
            }
        }

        .tab-container {
            padding: 0 15px;

            .add-underline {
                border-bottom: 1px solid $mui-tabs-border-bottom-color;
            }

            .tab-upper-case {
                text-transform: uppercase;
            }
        }
    }
}

#desktop-profile-edit-page {
    height: 100%;
    background-color: #F2F2F2;

    .edit-menu-container {
        height: 100%;
        width: 270px;
        padding: 50px 45px;
        display: flex;
        flex-direction: column;
        gap: 23px;
        background-color: white;

        .settings-label-container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .settings-label {
                font: 600 1.5em Lato;
            }

            .short-divider {
                width: 30px;
            }
        }

        .basic-information-button-container {
            display: flex;

            .basic-information-icon-container {
                width: 30px;
            }

            .basic-information-label {
                width: 190px;
                color: #979797;
            }

            .complete-status-container {}
        }

        .terms-of-relationship-button-container {
            display: flex;

            .terms-of-relationship-icon-container {
                width: 30px;
            }

            .terms-of-relationship-label {
                width: 190px;
                color: #979797;
            }

            .complete-status-container {}
        }

        .financial-information-button-container {
            display: flex;

            .financial-information-icon-container {
                width: 30px;
            }

            .financial-information-label {
                width: 190px;
                color: #979797;
            }

            .complete-status-container {}
        }

        .appearance-button-container {
            display: flex;

            .appearance-icon-container {
                width: 30px;
            }

            .appearance-label {
                width: 190px;
                color: #979797;
            }

            .complete-status-container {}
        }

        .personal-information-button-container {
            display: flex;

            .personal-information-icon-container {
                width: 30px;
            }

            .personal-information-label {
                width: 190px;
                color: #979797;
            }

            .complete-status-container {}
        }

        .description-button-container {
            display: flex;

            .description-icon-container {
                width: 30px;
            }

            .description-label {
                width: 190px;
                color: #979797;
            }

            .complete-status-container {}
        }
    }

    .edit-content-container {}
}