@import "../../../../global-style.scss";

#sugarbook-verified-page {
    height: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        max-width: 600px;
        background: white;

        .padding-container {
            padding: 25px;
            display: flex;
            flex-direction: column;
            gap: 15px;

            .navbar-container {
                height: 10%;
                display: flex;
                align-items: center;

                .back-button {
                    width: 50px;
                    cursor: pointer;

                    .signup-back-icon {
                        height: 30px;
                    }
                }
            }

            .sugarbook-verified-label-container {
                padding: 0 0 0 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                .sugarbook-verified-label {
                    font: 600 1.9em Lato;
                }

                .verified-profile-badge-container {
                    display: flex;

                    .verified-profile-badge {
                        height: 25px;
                    }
                }
            }

            .sugarbook-verified-description {
                padding-left: 10px;
                color: $sugarbook-grey-text-color;
            }

            .how-to-verify-container {
                padding: 20px 20px 20px 10px;
                border-radius: 15px;
                background-color: #DCF2FF;

                .how-to-verify-label {
                    font: 600 1.4em Lato;
                    color: $sugarbook-grey-text-color;
                }

                .how-to-verify-ordered-list-container {
                    margin-bottom: 0;
                    padding-left: 15px;
                    font: 500 0.9em Lato;
                    color: $sugarbook-grey-text-color;

                    li {
                        padding: 3px 0;
                    }

                    .verify-code {
                        font: 600 1.2em Lato;
                        color: #FF0000;
                    }
                }
            }

            .selfie-container {
                display: flex;
                justify-content: space-between;
                gap: 10px;

                .sample-selfie-container {
                    flex: 1;
                    border-radius: 10px;
                    background-color: lightblue;

                    .aspect-ratio-container {
                        width: 100%;
                        position: relative;
                        padding-bottom: 115%;

                        .sample-selfie {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            object-fit: cover;
                            border-radius: 10px;
                            /* Maintain aspect ratio and cover the container */
                        }

                        .code-overlay {
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font: 600 1.2em Lato;
                            color: #FF0000;
                        }
                    }
                }

                .own-selfie-container {
                    flex: 1;
                    border-radius: 10px;
                    background-color: #EFEFEF;

                    .aspect-ratio-container {
                        position: relative;
                        width: 100%;
                        padding-bottom: 115%;

                        .upload-photo-container {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            gap: 5px;
                            cursor: pointer;

                            .upload-photo-icon-container {

                                .upload-icon {
                                    width: 40px;
                                }
                            }

                            .upload-photo-label {
                                font: 500 1em Lato;
                                color: $sugarbook-grey-text-color;
                            }
                        }

                        .own-selfie {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            object-fit: cover;
                            border-radius: 10px;
                            /* Maintain aspect ratio and cover the container */
                        }

                        .code-overlay {
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font: 600 1.2em Lato;
                            color: #FF0000;
                        }
                    }
                }
            }

            .button-container {
                display: flex;
                justify-content: center;

                .next-button {
                    height: 47px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    cursor: pointer;
                    text-align: center;
                    font: 600 1.2em Lato;
                    background-color: $sugarbook-main-color;
                    color: white;
                }

                .disabled-button {
                    cursor: not-allowed;
                    background-color: rgba(0, 0, 0, 0.12);
                    color: rgba(0, 0, 0, 0.26);
                }
            }
        }
    }
}