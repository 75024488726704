@import "../../../../../global-style.scss";

#mobile-edit-profile-dating-style-subcomponent {

    .dating-style-label-container {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 50px;
        // background-color: $mui-text-field-outline-background-color;

        .dating-style-label {
            font: 600 1.5em Lato;
        }
    }

    .tor-list-container {
        min-height: 30px;
        margin: 0 5px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #e8e4e4;
        border-radius: 10px;
        cursor: pointer;

        .left-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;

            .dating-style-item-container {
                padding: 5px 13px;
                display: flex;
                align-items: center;
                gap: 5px;
                border: 2px solid #d8d4d4;
                border-radius: 50px;
                cursor: pointer;
                font: 600 0.9em Lato;
                background: #f0ecec;

                .dating-style-emoji-container {
                    display: flex;
                    align-items: center;
                }

                .dating-style-label {
                    font: 600 1em Lato;
                }
            }

            .to-be-completed-text-container {
                display: flex;
                align-items: center;
                gap: 3px;

                .add-icon {
                    font-size: 16px;
                    color: red;
                }

                .to-be-completed-text {
                    padding-bottom: 2px;
                    color: red;
                }
            }
        }

        .right-container {
            display: flex;

            .right-icon {
                color: #d0cccc;
            }
        }
    }

    // Utility CSS
    .blur {
        filter: blur(4px);
        background-color: white !important;
    }
}

#desktop-edit-profile-dating-style-subcomponent {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .dating-style-static-label-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .dating-style-label {
            font: 600 1.2em Lato;
        }

        .short-divider {
            width: 30px;
        }
    }

    .tor-list-container {
        min-height: 30px;
        margin: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .dating-style-item-container {
            padding: 5px 13px;
            display: flex;
            align-items: center;
            gap: 5px;
            border: 2px solid #d8d4d4;
            border-radius: 50px;
            cursor: pointer;
            font: 600 0.9em Lato;
            background: #f0ecec;

            .dating-style-emoji-container {
                display: flex;
                align-items: center;
            }

            .dating-style-label {
                font: 600 1em Lato;
            }
        }

        .to-be-completed-text-container {
            display: flex;
            align-items: center;
            gap: 3px;
            cursor: pointer;

            .add-icon {
                font-size: 16px;
                color: red;
            }

            .to-be-completed-text {
                padding-bottom: 2px;
                color: red;
            }
        }

        .tor-edit {
            height: 28px;
            width: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $sugarbook-main-color;
            border-radius: 25px;
            cursor: pointer;
            background-color: #f4ecec;

            .edit-icon {
                font-size: 20px;
                color: $sugarbook-main-color;
            }
        }

        .tor-add {
            height: 28px;
            width: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid #d8d4d4;
            border-radius: 25px;
            cursor: pointer;
            background: #f0ecec;

            .add-icon {
                color: black;
            }
        }
    }

    // Utility CSS
    .blur {
        filter: blur(4px);
        background-color: white !important;
    }
}

@media (max-width: 720px) {
    #desktop-edit-profile-dating-style-subcomponent {

        .tor-list-container {

            .dating-style-item-container {
                border: 1px solid #d8d4d4;
            }
        }
    }
}