@import "../../../../global-style.scss";

#my-wallet-dialog {
    margin-top: auto;

    .padding-container {
        padding: 30px;

        .header {
            font: 600 1.7em Lato;
        }

        .content-body {
            padding-top: 20px;
            display: flex;

            .icon-container {
                width: 15%;
                display: flex;
                align-items: center;

                .icon {
                    width: 30px;
                }
            }

            .description-container {
                width: 85%;

                .subheader {
                    font: 600 1.1em Lato;
                }

                .text {
                    padding-top: 5px;
                    font: 400 0.9em Lato;
                }
            }
        }

        .close-button {
            height: 45px;
            width: 100%;
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-transform: none;
            text-decoration: none;
            font: 600 1.1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}