@import "../../../../../global-style.scss";

#birthdate-subcomponent {
    height: 100%;

    .padding-container {
        height: calc(100% - 80px - 40px);
        padding: 20px;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .top-container {
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 50px;

            .birthdate-title {
                padding-top: 50px;
                text-align: center;
                font: 600 1.6em Lato;
            }

            .birthdate-description {
                text-align: center;
                font: 500 0.9em Lato;
                color: #A2A2A2;
            }

            .date-picker-container {
                width: 100%;

                .react-date-picker {
                    width: 100%;

                    .react-date-picker__wrapper {
                        border: none;
                        border-bottom: 1px solid $sugarbook-main-color;

                        .react-date-picker__inputGroup {
                            font: 600 1em Lato;
                            // color: $sugarbook-main-text-color;

                            .react-date-picker__inputGroup__month {
                                width: fit-content !important;
                            }

                            .react-date-picker__inputGroup__day {
                                width: fit-content !important;
                            }

                            .react-date-picker__inputGroup__year {
                                width: 50px !important;
                            }
                        }

                        .react-date-picker__calendar-button {

                            svg {
                                // stroke: $sugarbook-main-color;
                            }
                        }
                    }
                }

                .react-calendar {
                    border: none;
                    border-radius: 5px;
                    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);

                    button {
                        height: 50px;
                        width: 50px;
                        border-radius: 50px;
                    }

                    .react-calendar__tile--active {
                        background-color: $sugarbook-main-color;
                    }
                }
            }

            .form-container {
                width: 100%;

                .form-field {
                    width: 100%;
                    padding-top: 3px;
                    padding-right: 15px;

                    input {
                        font: 600 1em Lato;
                        // color: $sugarbook-main-text-color;
                    }
                }

                .rejected-reason-icon {
                    font-size: 20px;
                    color: #CCCCCC;
                }

                .rejected-value {

                    .MuiInput-root {
                        color: red;
                    }
                }
            }
        }
    }

    .bottom-container {
        height: 80px;
        width: calc(100% - 40px);
        padding: 0 20px;

        .save-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }
}