#livestream-mini-player {
    width: 150px;
    position: absolute;
    z-index: 20;

    .live-mini-tc-player-container {
        position: relative;

        #live-mini-tc-player_html5_api {
            max-width: 150px;
            border-radius: 15px;
        }
    }

    .mini-player-controls-container {
        width: calc(100% - 20px);
        position: absolute;
        top: 0;
        padding: 10px;
        display: flex;
        justify-content: space-between;

        .close-icon {
            cursor: pointer;
            color: white;
        }

        .fullscreen-icon {
            cursor: pointer;
            color: white;
        }
    }
}