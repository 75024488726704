@import "../../../../global-style.scss";

#choose-your-role-dialog {
    height: 100%;
    position: relative;

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .padding-container {
        padding-bottom: 25px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .choose-your-role-label {
            padding: 0 20px 0 40px;
            font: bold 1.5em Lato;
        }

        .want-to-be-selection-container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .sugar-daddy-container,
            .sugar-baby-container {
                padding: 15px 30px 15px 40px;
                display: flex;
                gap: 5px;
                cursor: pointer;

                .description-container {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    gap: 5px;
                    cursor: pointer;
                    transition: all 0.3s;

                    .sugar-daddy-title,
                    .sugar-baby-title {
                        font: bold 1.2em Lato;
                        transition: color 0.3s;
                    }

                    .sugar-daddy-subtitle,
                    .sugar-baby-subtitle {
                        font: bold 0.9em Lato;
                        transition: color 0.3s;
                    }

                    .sugar-daddy-description,
                    .sugar-baby-description {
                        font: 500 0.9em Lato;
                        color: #676767;
                        transition: color 0.3s;
                    }

                    .selected-title {
                        color: #D0D0D0;
                    }

                    .selected-description {
                        color: #D0D0D0;
                    }
                }

                .selected-container {
                    width: 35px;
                    display: flex;
                    align-items: center;

                    .selected-outer-ring {
                        height: 15px;
                        width: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 3px solid #E0E1E1;
                        border-radius: 50px;

                        .selected-inner-ring {
                            height: 15px;
                            width: 15px;
                            border-radius: 50px;
                            transition: background 0.3s;
                        }

                        .selected {
                            background: $sugarbook-main-color;
                        }
                    }
                }
            }

            .selected {
                background: #FFE7E8;
            }
        }
    }

    .bottom-container {
        padding: 0 20px 30px 20px;

        .continue-button {
            width: 100%;
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            text-transform: initial;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }
}