@import "../../../../../global-style.scss";

#search-list-container-premium-empty-list-subcomponent {
    height: 100%;

    .premium-empty-padding-container {
        height: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .empty-premium-list-image-container {

            .empty-premium-list-image {
                height: 100px;
            }
        }

        .title {
            text-align: center;
            font: 600 1.3em Lato;
        }

        .description {
            text-align: center;
            font: 400 1.1em Lato;
            color: #7C7C7C;
        }

        .upgrade-now-button {
            height: 50px;
            padding: 0 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            font: 600 1.3em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }

        .foot-note {
            padding: 0 20px;
            text-align: center;
            font: 400 0.9em/1.4 Lato;
            color: #7C7C7C;
        }
    }
}