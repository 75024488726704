@import "../../../global-style.scss";

#user-profile-page {
    height: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        width: 100%;
        max-width: 1000px;

        .desktop-view {
            padding-bottom: 50px;
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        .mobile-view {

            .tab-container {
                height: 100%;
                width: 100%;
                padding-bottom: 100px;
                display: flex;
                flex-direction: column;

                .user-profile-tab {
                    text-transform: uppercase;
                }

                .add-underline {
                    border-bottom: 1px solid $mui-tabs-border-bottom-color;
                }

                .add-padding {
                    padding: 0 20px;
                }

                .mobile-banner-container {
                    width: 100%;
                    display: none;
                }

                .embla {
                    height: 100%;
                    width: 100%;
                    overflow-x: hidden;

                    .embla__container {
                        height: 100%;
                        display: flex;

                        .embla__slide {
                            flex: 0 0 100%;
                            min-width: 0;
                        }
                    }
                }
            }
        }
    }

    .profile-padding-container {
        padding: 0 20px;
    }
}

#user-profile-error-page {
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .max-width-container {
        height: 100%;
        width: calc(100% - 40px);
        max-width: 1000px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .profile-error-image-container {

            .profile-error-image {
                width: 300px;
            }
        }

        .profile-not-found-label {
            font: 500 1.6em Lato;
        }

        .profile-not-found-body {
            text-align: center;
            font: 500 1em Lato;
            color: #7C7C7C;
        }
    }
}