@import "../../../../../../../global-style.scss";

#live-tab-live-viewer-subcomponent {
    padding-bottom: 10px;
    display: flex;
    align-items: center;

    .profile-photo {
        height: 40px;
        width: 40px;
        margin-right: 10px;
    }

    .user-description-container {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 3px;

        .livestreaming-achievement-badge-container {
            display: flex;
            align-items: center;
            gap: 3px;

            .livestreaming-achievement-badge {
                height: 13px;
            }
        }

        .username {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font: 600 0.9em Lato;
        }

        .levelling-badge-container {
            display: flex;
            align-items: center;

            .levelling-badge {
                height: 13px;
            }
        }
    }

    .follow-button {
        padding: 6px 20px;
        border: 1px solid $sugarbook-main-color;
        border-radius: 25px;
        text-align: center;
        cursor: pointer;
        font: 600 0.8em Lato;
        color: $sugarbook-main-color;
    }

    .unfollow-button {
        padding: 6px 20px;
        border: 1px solid $sugarbook-main-color;
        border-radius: 25px;
        text-align: center;
        cursor: pointer;
        font: 600 0.8em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }
}