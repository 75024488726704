#interactable-overlay-top-banner-subcomponent {

    .banner-image-container {
        width: 100%;
        display: flex;

        .banner-image {
            width: 100%;
        }
    }
}