@import "../../../../global-style.scss";

#details-signup-occupation-details-subcomponent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-container {
        height: calc(100% - 100px - 10px);
        width: 100%;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .occupation-details-label {
            text-align: center;
            font: 900 2em Lato;
            color: white;
        }

        .occupation-description-label {
            padding: 20px 20px 0 20px;
            font: 500 0.9em Lato;
            text-align: center;
            color: white;
        }

        .occupation-body-data-container {
            // max-width: 820px;
            margin-top: 30px;
            padding: 5% 0;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px;

            .occupation-container {
                width: 100%;
                min-height: 97px;
                max-width: 250px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .occupation-body-container {
                    height: calc(100% - 50px);
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;

                    .occupation-form-field-container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .occupation-select-form-field-container {
                            width: 100%;

                            .occupation-select-form-field {
                                width: 100%;

                                .MuiInputLabel-root {
                                    color: white;
                                }

                                .MuiInputBase-root {

                                    .MuiSelect-select {
                                        color: white;
                                    }
                                }

                                .MuiInputBase-root:before {
                                    border-bottom-color: white;
                                }

                                .MuiInputBase-root:after {
                                    border-bottom-color: white !important;
                                }

                                .MuiSvgIcon-root {
                                    color: white;
                                }
                            }
                        }
                    }

                    .add-occupation-button {
                        padding: 5px 20px;
                        border-radius: 50px;
                        font: 600 1em Lato;
                        color: $sugarbook-main-color;
                        background: white;
                    }

                    .selected-occupation-container {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;

                        .selected-occupation {
                            width: 100%;
                            padding: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;
                            border-radius: 50px;
                            font: 600 1em Lato;
                            background: white;
                            color: $sugarbook-main-color;

                            .remove-selected-occupation-icon {
                                cursor: pointer;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }

            .position-container {
                width: 100%;
                min-height: 170px;
                max-width: 250px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                // gap: 20px;
                border: 1px solid rgba(255, 255, 255, 0.2);
                border-radius: 35px;
                background: rgb(255 255 255 / 10%);

                .position-label {
                    height: 50px;
                    font: 600 1.7em Lato;
                    color: white;
                }

                .position-body-container {
                    height: calc(100% - 50px);
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    // justify-content: center;
                    align-items: center;
                    gap: 20px;

                    .position-form-field-container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .position-select-form-field-container {
                            width: 100%;

                            .position-select-form-field {
                                width: 100%;

                                .MuiInputLabel-root {
                                    color: white;
                                }

                                .MuiInputBase-root {

                                    .MuiSelect-select {
                                        color: white;
                                    }
                                }

                                .MuiInputBase-root:before {
                                    border-bottom-color: white;
                                }

                                .MuiInputBase-root:after {
                                    border-bottom-color: white !important;
                                }

                                .MuiSvgIcon-root {
                                    color: white;
                                }
                            }
                        }
                    }

                    .add-position-button {
                        padding: 5px 20px;
                        border-radius: 50px;
                        font: 600 1em Lato;
                        color: $sugarbook-main-color;
                        background: white;
                    }

                    .selected-position-container {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;

                        .selected-position {
                            width: 100%;
                            padding: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;
                            border-radius: 50px;
                            font: 600 1em Lato;
                            background: white;
                            color: $sugarbook-main-color;

                            .remove-selected-position-icon {
                                cursor: pointer;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }

            .industry-container {
                width: 100%;
                min-height: 170px;
                max-width: 250px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                // gap: 20px;
                border: 1px solid rgba(255, 255, 255, 0.2);
                border-radius: 35px;
                background: rgb(255 255 255 / 10%);

                .industry-label {
                    height: 50px;
                    font: 600 1.7em Lato;
                    color: white;
                }

                .industry-body-container {
                    height: calc(100% - 50px);
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    // justify-content: center;
                    align-items: center;
                    gap: 20px;

                    .industry-form-field-container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .industry-select-form-field-container {
                            width: 100%;

                            .industry-select-form-field {
                                width: 100%;

                                .MuiInputLabel-root {
                                    color: white;
                                }

                                .MuiInputBase-root {

                                    .MuiSelect-select {
                                        color: white;
                                    }
                                }

                                .MuiInputBase-root:before {
                                    border-bottom-color: white;
                                }

                                .MuiInputBase-root:after {
                                    border-bottom-color: white !important;
                                }

                                .MuiSvgIcon-root {
                                    color: white;
                                }
                            }
                        }
                    }

                    .add-industry-button {
                        padding: 5px 20px;
                        border-radius: 50px;
                        font: 600 1em Lato;
                        color: $sugarbook-main-color;
                        background: white;
                    }

                    .selected-industry-container {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;

                        .selected-industry {
                            width: 100%;
                            padding: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;
                            border-radius: 50px;
                            font: 600 1em Lato;
                            background: white;
                            color: $sugarbook-main-color;

                            .remove-selected-industry-icon {
                                cursor: pointer;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .bottom-container {
        height: 100px;
        width: calc(100% - 40px);
        padding: 10px 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .continue-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .skip-button {
            cursor: pointer;
            color: #988484;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .full-width {
        width: calc(100% - 160px);
    }

    .partial-width {
        width: 65%;
    }

    .fix-width {
        width: 150px;
    }

    .fit-content {
        width: fit-content;
    }
}

// Response Design
@media (min-width: 720px) {
    #details-signup-occupation-details-subcomponent {

        .top-container {

            .occupation-details-label {
                font: 600 2em Lato;
            }

            .occupation-description-label {
                font: 500 1em Lato;
            }
        }
    }
}