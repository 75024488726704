@import "../../../../global-style.scss";

#update-email-verification-page {

    .max-width-container {
        height: 100%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .verification-label {
            padding-bottom: 20px;
            text-align: center;
            font-size: 2em;
            font-weight: 500;
        }

        .verification-text {
            padding-bottom: 20px;
            text-align: center;
            line-height: 1.3;
            color: #7C7C7C;
        }

        .verification-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 40px;
            margin-left: auto;
            margin-right: auto;

            .verification-form-field {
                width: 100%;
            }
        }

        .submit-verification-button {
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-transform: none;
            text-decoration: none;
            font-size: 1.1em;
            font-weight: 500;
            background-color: $sugarbook-main-color;
            color: white;
            transition: all 0.5s;

            .button-spinner {
                color: white;
            }
        }

        .disabled-button {
            border: 2px solid transparent;
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
        }

        .resend-verification-button {
            height: 45px;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-transform: none;
            text-decoration: none;
            font-size: 0.9em;
            font-weight: 500;
            color: rgb(124, 124, 124);
            transition: all 0.5s;

            .button-spinner {
                color: white;
            }
        }
    }
}