#interests-favorited-me-tab-subcomponent {
    height: 100%;

    .favorited-me-list-container {
        height: 100%;
        overflow: auto;

        >div {
            height: 100%;

            .infinite-scroller-container {}
        }
    }
}

// Responsive Design
@media (max-width: 720px) {
    #interests-favorited-me-tab-subcomponent {}
}