@import "../../../../../global-style.scss";

#looking-for-age-range-subcomponent {
    height: 100%;

    .padding-container {
        height: calc(100% - 80px - 40px);
        padding: 20px;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .top-container {
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .looking-for-age-range-title {
                text-align: center;
                font: 600 1.6em Lato;
            }

            .age-range-body-container {
                height: calc(100% - 50px);
                width: 100%;
                padding-top: 35%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;

                .age-range-slider-container {
                    width: calc(100% - 40px);
                    padding: 0 20px;
                    display: flex;
                    justify-content: center;
                    gap: 20px;

                    .my-slider {
                        z-index: 20;
                    }
                }

                .selected-age-range-label {
                    font: 600 1.4em Lato;
                    color: #b0acac;
                }
            }
        }
    }

    .bottom-container {
        height: 80px;
        width: calc(100% - 40px);
        padding: 0 20px;

        .save-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .add-edit-padding-top {
        padding-top: 35px;
    }
}