#coins-discount-dialog {

    .dialog-header {
        width: calc(100% - 30px);
        position: absolute;
        padding: 15px 15px 0 15px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
                color: white;
            }
        }
    }

    .coins-discount-banner-container {
        display: flex;

        .coins-discount-banner {
            width: 275px;
        }

        .redirect-button {
            height: 50px;
            width: 100%;
            position: absolute;
            bottom: 30px;
        }
    }
}