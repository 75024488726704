@import "../../../global-style.scss";

#description-signup-page {
    height: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        height: calc(100% - 50px);
        width: 100%;
        max-width: 400px;
        padding: 25px;
        display: flex;
        flex-direction: column;

        .navbar-container {
            height: 30px;
            display: flex;
            align-items: center;

            .back-button {
                width: 50px;
                cursor: pointer;

                .signup-back-icon {
                    height: 30px;
                }
            }
        }

        .overflow-container {
            height: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 20px;
            overflow: auto;

            .stepper-container {
                width: 100%;

                .stepper-max-width-container {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .stepper-max-width-inner-container {
                        width: 100%;
                        max-width: 230px;
                    }
                }
            }

            .step-description-label {
                padding-left: 10px;
                font: 700 1.8em Lato;
            }

            .description-signup-input-container {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .about-me-form-field-container {
                    height: 165px;
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .about-me-label {
                        padding: 0 10px;
                        font: 600 1em Lato;
                    }

                    .form-field-container {
                        display: flex;
                        flex-direction: column;

                        .about-me-form-field {
                            width: 100%;
                        }
                    }

                    .helper-text-container {
                        padding: 0 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .error-text {
                            flex: 1;
                            font: 500 0.8em Lato;
                            color: red;
                        }

                        .character-limit-label {
                            text-align: right;
                            font: 500 0.8em Lato;
                            color: #979797;
                        }
                    }
                }

                .looking-for-form-field-container {
                    height: 165px;
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .looking-for-label {
                        padding: 0 10px;
                        font: 600 1em Lato;
                    }

                    .form-field-container {
                        display: flex;
                        flex-direction: column;

                        .looking-for-form-field {
                            width: 100%;
                        }
                    }

                    .helper-text-container {
                        padding: 0 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .error-text {
                            flex: 1;
                            font: 500 0.8em Lato;
                            color: red;
                        }

                        .character-limit-label {
                            text-align: right;
                            font: 500 0.8em Lato;
                            color: #979797;
                        }
                    }
                }
            }

            .button-container {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .next-button {
                    height: 47px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    text-decoration: none;
                    text-transform: capitalize;
                    font: 500 1.1em Lato;
                    background-color: $sugarbook-main-color;
                    color: white;
                    transition: all 0.5s;
                }

                .disabled-button {
                    cursor: not-allowed;
                    background-color: rgba(0, 0, 0, 0.12);
                    color: rgba(0, 0, 0, 0.26);
                }

                .skip-button {
                    text-decoration: none;
                    text-transform: capitalize;
                    font: 500 0.8em Lato;
                    color: $sugarbook-grey-text-color;
                }
            }
        }

        .overflow-container::-webkit-scrollbar {
            display: none;
        }

        .bottom-container {
            height: 80px;
            padding-top: 10px;

            .button-container {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .next-button {
                    height: 47px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    text-decoration: none;
                    text-transform: capitalize;
                    font: 600 1.1em Lato;
                    background-color: $sugarbook-main-color;
                    color: white;
                    transition: all 0.5s;
                }

                .disabled-button {
                    cursor: not-allowed;
                    background-color: rgba(0, 0, 0, 0.12);
                    color: $mui-disabled-button-color;
                }

                .skip-button {
                    text-decoration: none;
                    text-transform: capitalize;
                    font: 500 0.8em Lato;
                    color: $sugarbook-grey-text-color;
                }
            }
        }
    }
}