#top-fans-empty-list-shared-subcomponent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        text-align: center;
        font: 600 1.2em Lato;
    }

    .subtitle {
        padding-top: 10px;
        text-align: center;
        font: 500 0.9em Lato;
    }
}