@import "../../../../global-style.scss";

#onboarding-sugarbook-verified-dialog {
    height: 100%;
    position: relative;

    .dialog-header {
        width: calc(100% - 40px);
        padding: 20px 20px 0 20px;
        position: absolute;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .padding-container {
        height: calc(100% - 33px - 45px);
        padding: 28px 25px 50px 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        overflow: auto;

        .verified-profile-picture-container {
            position: relative;

            .own-profile-picture {
                height: 100px;
                width: 100px;
            }

            .verified-profile-badge-container {
                position: absolute;
                bottom: -3px;
                right: 0;

                .verified-profile-badge {
                    width: 25px;
                }
            }
        }

        .verified-top-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .verified-title {
                font: 600 1.8em Lato;
            }

            .verified-title-alt {
                font: 600 1.8em Lato;
                color: #1DA1F3;
            }
        }

        .sugarbook-verified-perks-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .verified-badge-container,
            .date-with-confidence,
            .safety-and-security {
                display: flex;
                gap: 10px;

                .verified-icons-container {
                    width: 20%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .verified-profile-outline-badge {
                        width: 30px;
                    }

                    .shield-icon {
                        width: 24px;
                        padding: 3px 0;
                    }

                    .safety-icon {
                        width: 28px;
                    }
                }

                .verified-content-container {
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                    gap: 7px;

                    .verified-label {
                        font: 600 1em Lato;
                        color: $sugarbook-grey-text-color;
                    }

                    .verified-description {
                        font: 500 0.9em Lato;
                        color: $sugarbook-grey-text-color;
                    }
                }
            }
        }

        .get-verified-now-button {
            width: 100%;
            max-width: 400px;
            padding: 13px 0;
            border-radius: 50px;
            text-align: center;
            cursor: pointer;
            font: 600 1.1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}