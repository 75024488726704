#restart-phone-description-dialog {
    height: 100%;
    width: 100%;
    background-color: #333333;

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
                color: white;
            }
        }
    }

    .padding-container {
        height: calc(100% - 83px);
        max-width: 300px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;

        #callee-tester-video {
            height: 200px;
            width: 200px;
            border-radius: 100px;
            object-fit: cover; // contain if desktop
            background-color: black;
        }

        .restart-phone-title {
            font: 700 2em/1.2 Lato;
            color: white;
        }

        .restart-phone-description {
            font: 500 1.1em/1.4 Lato;
            color: white;
        }

        .button-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .understand-button {
                width: 250px;
                padding: 10px 0;
                border: 1px solid white;
                border-radius: 25px;
                cursor: pointer;
                text-align: center;
                font: 600 1em Lato;
                color: white;
            }
        }
    }
}