@import "../../../global-style.scss";

#tor-signup-page {
    height: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        height: calc(100% - 50px);
        width: 100%;
        max-width: 400px;
        padding: 25px;
        display: flex;
        flex-direction: column;

        .navbar-container {
            height: 30px;
            display: flex;
            align-items: center;

            .back-button {
                width: 50px;
                cursor: pointer;

                .signup-back-icon {
                    height: 30px;
                }
            }
        }

        .overflow-container {
            height: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 20px;
            overflow: auto;

            .stepper-container {
                width: 100%;

                .stepper-max-width-container {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .stepper-max-width-inner-container {
                        width: 100%;
                        max-width: 230px;
                    }
                }
            }

            .title {
                text-align: center;
                font: 700 1.2em Lato;

                .smaller-font {
                    font-size: 10px;
                }
            }

            .selected-label {
                text-align: center;
                font: 500 0.9em Lato;
                color: #979797;
            }

            .description {
                font: 400 0.9em/1.4 Lato;
            }

            .tags-container {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                .tag-container {
                    width: fit-content;
                    padding: 6px 14px;
                    border: 1.2px solid rgba(113, 13, 13, 0.2);
                    border-radius: 50px;
                    cursor: pointer;
                    font: 500 0.8em Lato;
                    color: $sugarbook-main-color;
                }

                .selected-tag {
                    background-color: #f4ecec;
                }
            }
        }

        .overflow-container::-webkit-scrollbar {
            display: none;
        }

        .tag-details {
            height: 60px;
            margin-top: 10px;
            padding: 12px 24px;
            border-radius: 12px;
            font-size: 14px;
            line-height: 1.4;
            background-color: #F6F6F6;
        }

        .bottom-container {
            height: 80px;
            padding-top: 10px;

            .next-button {
                height: 47px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                text-decoration: none;
                text-transform: capitalize;
                cursor: pointer;
                font: 600 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
                transition: all 0.5s;
            }

            .disabled-button {
                cursor: not-allowed;
                background-color: rgba(0, 0, 0, 0.12);
                color: rgba(0, 0, 0, 0.26);
            }

            .disabled-button {
                cursor: not-allowed;
                background-color: rgba(0, 0, 0, 0.12);
                color: rgba(0, 0, 0, 0.26);
            }
        }
    }
}