#profile-view-indepth-info-subcomponent {

    .padding-container {
        padding: 20px 20px 100px 20px;
    }

    .detail-information-container {
        columns: 2;

        .information-container {
            margin-bottom: 20px;
            line-height: 1.6;

            .category-icon {

                .lifestyle-budget-icon,
                .height-icon,
                .body-type-icon,
                .ethnicity-icon,
                .eye-color-icon,
                .hair-color-icon,
                .education-icon,
                .relationship-icon,
                .children-icon,
                .smoking-icon,
                .drinking-icon {
                    width: 20px;
                }
            }

            .category-label {
                font: 600 1.1em Lato;
            }

            .data-label {
                color: #676767;
            }
        }
    }

    .page-detail-information-container {
        // padding-top: 50px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 15px;
        background: white;

        .indepth-item-container {
            display: flex;

            .category-icon {
                flex: 1;

                .lifestyle-budget-icon,
                .height-icon,
                .body-type-icon,
                .ethnicity-icon,
                .eye-color-icon,
                .hair-color-icon,
                .education-icon,
                .relationship-icon,
                .children-icon,
                .smoking-icon,
                .drinking-icon {
                    width: 20px;
                }
            }

            .indepth-label {
                flex: 4;
                color: #979797;
            }

            .indepth-data {
                flex: 4;
                font: 600 1em Lato;
            }
        }
    }
}

// Utility CSS
.apply-fix-width {
    width: 300px;
    min-width: 300px;
}