@import "../../../../global-style.scss";

#private-call-accepted-dialog {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.9);

    .padding-container {
        padding: 0 20px;

        .accept-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
            color: white;

            .call-accept-animation-container {

                .call-accept-animation {
                    width: 60px;
                }
            }

            .accept-label {
                font: 600 1.4em Lato;
            }

            .text {
                text-align: center;
                font: 500 1em Lato;
            }

            .call-timer {
                font: 500 1em Lato;
                color: rgb(80, 179, 67);
            }

            .end-call-button {
                margin-top: 30px;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}