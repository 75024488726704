@import "../../../../global-style.scss";

#upgrade-to-premium-dialog {
    height: 100%;
    position: relative;
    overflow: hidden;

    .pwa-background-container {
        height: 100%;
        width: 100%;
        overflow: auto;
        cursor: pointer;
        background-color: #a80c0c;

        .pwa-background {
            // height: 100%;
            width: 100%;
            display: block;
            object-fit: cover;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .header {
        width: calc(100% - 40px);
        padding: 20px;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
                // color: white;
            }
        }
    }
}

// Utility CSS
.upgrade-max-width-container {
    max-width: 300px;
}