@import "../../../../global-style.scss";

#update-terms-of-relationship-dialog {

    .dialog-header {
        padding: 15px;
        display: flex;
        align-items: center;

        .left-container {
            display: flex;
            align-items: center;
            flex: 1;

            .back-icon {
                font-size: 30px;
            }
        }

        .middle-container {
            flex: 10;
            text-align: center;
            font: 600 1.2em Lato;
        }

        .right-container {
            flex: 1;
        }
    }

    .select-view {
        height: 100%;

        .tor-background-image-container {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;

            .tor-background-image {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .title {
            padding: 20px;
            font: 700 1.2em Lato;
        }

        .choose-tor-label {
            padding: 15px 10px;
            background-color: #efefef;
        }

        .padding-container {
            height: 100%;
            position: relative;
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .selected-label {
                text-align: center;
                font: 500 0.9em Lato;
                color: #979797;
            }

            .description {
                font: 400 0.9em/1.4 Lato;
            }

            .tags-container {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                .tag-container {
                    width: fit-content;
                    padding: 6px 14px;
                    border: 1.2px solid rgba(113, 13, 13, 0.2);
                    border-radius: 50px;
                    font: 500 0.8em Lato;
                    color: $sugarbook-main-color;
                }

                .selected-tag {
                    background-color: #f4ecec;
                }
            }

            .tag-details {
                padding: 12px 24px;
                border-radius: 12px;
                font-size: 14px;
                line-height: 1.4;
                background-color: #F6F6F6;
            }

            .save-button {
                height: 50px;
                width: 90%;
                margin: 20px auto;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background: $sugarbook-main-color;
                color: white;
            }
        }
    }
}