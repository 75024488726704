#pk-graphic-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    pointer-events: none;
    z-index: 2;

    .dual-pk-countdown-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .dual-pk-countdown-icon {
            width: 100px;
            animation: dual-pk-countdown 1s infinite;
        }

        .dual-pk-countdown-1-icon {
            width: 100px;
            animation: dual-pk-countdown 1s infinite;
        }

        .dual-pk-countdown-2-icon {
            width: 100px;
            animation: dual-pk-countdown 1s infinite;
        }

        .dual-pk-countdown-3-icon {
            width: 100px;
            animation: dual-pk-countdown 1s infinite;
        }

        .dual-pk-round-icon {
            width: 250px;
            animation: dual-pk-round 2s infinite;
        }
    }

    .dual-pk-draw-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .dual-pk-draw-emoji-icon {
            width: 80px;
            animation: dual-pk-round 8s infinite;
        }

        .dual-pk-draw-icon {
            width: 200px;
            animation: dual-pk-round 8s infinite;
        }
    }

    .dual-pk-win-lose-container {
        height: 100%;
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        animation: dual-pk-round 8s infinite;

        .dual-pk-emoji-container {
            width: 100%;
            display: flex;

            .dual-pk-emoji-icon-container {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;

                .dual-pk-win-emoji-icon,
                .dual-pk-lose-emoji-icon {
                    width: 70px;
                }

                .dual-pk-win-icon-container {
                    display: flex;

                    .dual-pk-win-icon {
                        width: 120px;
                    }
                }

                .dual-pk-lose-icon-container {
                    display: flex;

                    .dual-pk-lose-icon {
                        width: 120px;
                    }
                }
            }
        }

        .dual-pk-current-round-icon-container {

            .dual-pk-round-icon {
                width: 200px;
            }
        }

        .finish-label {
            font: 600 1.5em Lato;
            color: white;
        }

        .dual-pk-winner-icon-container {
            width: 100%;
            display: flex;
            justify-content: center;

            .dual-pk-winner-icon {
                width: 30%;
                min-width: 200px;
                max-width: 300px;
            }
        }
    }
}

@keyframes dual-pk-countdown {
    0% {
        transform: scale(1.5);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes dual-pk-round {
    0% {
        opacity: 0;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}