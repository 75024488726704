@import "../../../../global-style.scss";

#tor-tag-shared-component {
    position: relative;
    padding: 5px 13px;
    border: 1px solid rgba(113, 13, 13, .2);
    border-radius: 50px;
    cursor: help;
    font: 500 0.9em Lato;
    color: $sugarbook-main-color;
}

// Utility CSS
.tor-tooltip {
    font: 400 1em/1.5 Lato;
    background-color: $sugarbook-main-color;
}

.blur {
    filter: blur(3px);
    background-color: white !important;
}