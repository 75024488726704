#search-filter-search-input-subcomponent {

    .filter-padding-container {
        padding: 15px 20px;
        display: flex;

        .form-container {
            height: 40px;
            width: 90%;
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            align-items: center;
            border-radius: 25px;
            background-color: #e8e4e4;

            .search-icon {
                color: #676767;
            }

            .form-field {
                width: 100%;
                padding-left: 5px;
            }
        }

        .close-button {
            width: 10%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .lock-icon-container {
            margin-bottom: 2px;
            display: flex;
            align-items: center;

            .lock-icon {}
        }
    }
}

// Utility CSS
.locked {
    cursor: not-allowed;

    input {
        cursor: not-allowed;
    }
}