@import "../../../../../global-style.scss";

#profile-view-photo-info-subcomponent {

    display: flex;
    flex-direction: column;

    .profile-image-container {
        height: 100%;
        overflow: hidden;

        .profile-image {
            height: 100%;
            width: 100%;
            max-height: 75vh;
            object-fit: cover;
        }
    }

    .carousel-container {
        height: 100%;
        position: relative;
        overflow: hidden;

        .swiper-container {
            height: 100%;

            // Pagination CSS
            --swiper-pagination-color: #333333;

            .image-container {
                height: 100%;
                position: relative;

                .carousel-image {
                    height: 100%;
                    width: 100%;
                    // max-height: 75vh;
                    object-fit: cover;
                }

                .blur-filter-container {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    backdrop-filter: blur(50px);

                    .sensitive-icon-container {

                        .sensitive-icon {
                            width: 50px;
                        }
                    }

                    .blur-filter-title {
                        font: bold 1.4em Lato;
                        color: white;
                    }

                    .blur-filter-description {
                        text-align: center;
                        font: 500 1em/1.7 Lato;
                        color: white;
                    }

                    .blur-filter-cta {
                        padding: 10px 20px;
                        border: 1px solid white;
                        border-radius: 50px;
                        cursor: pointer;
                        color: white;
                    }
                }
            }

            .private-container {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #292929;

                .private-body-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 15px;

                    .lock-icon-container {

                        .lock-icon {
                            width: 70px
                        }
                    }

                    .private-photo-label {
                        font: 600 1.8em Lato;
                        color: white;
                    }

                    .private-photo-count {
                        font: 500 1em Lato;
                        color: #7c7c7c;
                    }

                    .request-button {
                        height: 45px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 25px;
                        text-transform: none;
                        text-decoration: none;
                        cursor: pointer;
                        font: 600 1.2em Lato;
                        background-color: $sugarbook-main-color;
                        color: white;
                    }

                    .disabled-button {
                        cursor: not-allowed;
                        pointer-events: initial;
                        background-color: hsla(0, 0%, 100%, .12);
                        color: hsla(0, 0%, 100%, .3);
                    }
                }
            }

            .private-call-available {
                width: fit-content;
                padding: 4px 8px;
                border-radius: 50px;
                font: 600 1em Lato;
                background: linear-gradient(90.18deg, #ff1694 20.88%, #000000 81.7%);
                color: white;
            }

            .private-call-busy {
                width: fit-content;
                position: absolute;
                bottom: 40px;
                right: 30px;
                padding: 5px 15px;
                border-radius: 50px;
                font: 600 1em Lato;
                background: $sugarbook-main-color;
                color: white;
            }

            .video-call {
                width: fit-content;
                position: absolute;
                bottom: 40px;
                right: 30px;
                padding: 5px 15px;
                border-radius: 50px;
                font: 600 1em Lato;
                background: linear-gradient(90.38deg,
                        #ffa927 5.04%,
                        #ff409c 70.45%,
                        #9534b4 119.21%);
                color: white;
            }
        }

        .live-status {
            height: 28px;
            position: absolute;
            bottom: 30px;
            right: 25px;
            padding: 0 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            z-index: 1;
            font: 700 1em Lato;
            background-image: linear-gradient(to right, #F5BB19, #FF3767);
            color: #fff;
        }

        .private-call-available {
            height: 28px;
            width: fit-content;
            position: absolute;
            bottom: 30px;
            right: 25px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            gap: 5px;
            border-radius: 50px;
            z-index: 1;
            font: 700 1em Lato;
            background: linear-gradient(90.18deg, #ff1694 20.88%, #000000 81.7%);
            color: white;
        }

        .private-call-busy {
            height: 28px;
            width: fit-content;
            position: absolute;
            bottom: 30px;
            right: 25px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            gap: 5px;
            border-radius: 50px;
            z-index: 1;
            font: 700 1em Lato;
            background: $sugarbook-main-color;
            color: white;
        }

        .video-call {
            height: 28px;
            width: fit-content;
            position: absolute;
            bottom: 30px;
            right: 25px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            border-radius: 50px;
            z-index: 1;
            font: 700 1em Lato;
            background: linear-gradient(90.38deg,
                    #ffa927 5.04%,
                    #ff409c 70.45%,
                    #9534b4 119.21%);
            color: white;
        }

        .verified-caller-badge-container {
            display: flex;
            align-items: center;

            .verified-caller-badge {
                height: 13px;
            }
        }

        .special-badges-container {
            height: 28px;
            width: fit-content;
            position: absolute;
            bottom: 30px;
            left: 10px;
            z-index: 1;

            .special-badges {
                height: 100%;
            }
        }
    }

    .stacked-container {
        padding: 70px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .profile-photo-container {

            .profile-photo {
                width: 200px;
                max-height: 500px;
                border-radius: 10px;
                object-fit: cover;
            }
        }

        .public-photo-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .public-photo-label {
                font: 600 1.3em Lato;
            }

            .public-photo-image-container {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                .public-image-container {
                    display: flex;

                    .public-image {
                        width: 200px;
                        max-height: 500px;
                        border-radius: 10px;
                        object-fit: cover;
                    }
                }
            }
        }

        .private-photo-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .label-container {
                display: flex;
                align-items: center;
                gap: 15px;
            }

            .private-photo-label {
                font: 600 1.3em Lato;
            }

            .request-button {
                padding: 8px 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                text-transform: none;
                text-decoration: none;
                cursor: pointer;
                font: 600 1.2em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .disabled-button {
                cursor: not-allowed;
                pointer-events: initial;
                background-color: hsla(0, 0%, 100%, .12);
                color: hsla(0, 0%, 100%, .3);
            }

            .private-photo-image-container {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                .private-image-container {
                    display: flex;

                    .private-image {
                        width: 200px;
                        max-height: 500px;
                        border-radius: 10px;
                        object-fit: cover;
                    }
                }

                .private-container {
                    height: 200px;
                    width: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    background-color: #292929;

                    .private-body-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 15px;

                        .lock-icon-container {

                            .lock-icon {
                                width: 70px
                            }
                        }

                        .private-photo-label {
                            font: 500 1.2em Lato;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

// Utility CSS
.viewport-height {
    height: 70vh;
}

.dynamic-height {
    height: auto;
}