#gift-animation-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;

    #gift-animation-video-player {
        height: 100%;
        object-fit: cover;
    }

    .full-width-video {
        width: 100%;
    }
}

// Utility CSS
.split-video-container {
    display: flex;

    .full-width-video {
        width: 100%;
    }

    .half-width-video {
        width: 50%;
    }
}

.half-opacity {
    filter: opacity(50%);
}