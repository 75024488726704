@import "../../../../global-style.scss";

#search-filters-selected-subcomponent {
    border-radius: 10px;
    background-color: #DBDBDB;

    .filters-selected-padding-container {
        padding: 5px 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .filters-selected-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .filters-selected-label {
                font: 500 1.1em Lato;
            }

            .clear-filters-button {
                cursor: pointer;
                font: 500 0.8em Lato;
            }
        }

        .selected-filters-container {
            font: 500 0.8em/1.5 Lato;
            color: $sugarbook-main-color;
        }
    }
}