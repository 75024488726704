#whats-a-role-dialog {

    .dialog-header {
        padding: 15px 15px 0 15px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .padding-container {
        padding: 0 30px 60px 30px;
        display: flex;
        flex-direction: column;
        gap: 25px;

        .title {
            font: 600 1.4em Lato;
        }

        .sugardaddy-mommy-container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .sugardaddy-mommy-label {
                font: 600 1.1em Lato;
            }

            .description {
                font: 500 1em Lato;
                color: #676767;
            }
        }

        .sugarbaby-container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .sugarbaby-label {
                font: 600 1.1em Lato;
            }

            .description {
                font: 500 1em Lato;
                color: #676767;
            }
        }
    }
}