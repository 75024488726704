@import "../../../../../global-style.scss";

#mobile-edit-profile-looking-for-subcomponent {

    .looking-for-label-container {
        padding: 15px 20px 5px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 50px;
        // background-color: $mui-text-field-outline-background-color;

        .looking-for-label {
            font: 600 1.5em Lato;
        }
    }

    .looking-for-description {
        padding: 0 20px 20px 20px;
        font: 500 1em Lato;
        color: #CCCCCC;
    }

    .looking-for-topic-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .topic-container {
            margin: 0 5px;
            padding: 15px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #e8e4e4;
            border-radius: 10px;
            cursor: pointer;

            .left-container {
                padding-left: 5px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .add-topic-label {
                    font: 600 1.1em Lato;
                }

                .add-topic-description {

                    .add-topic-description-min-max {
                        padding: 7px 13px;
                        border: 2px solid #d8d4d4;
                        border-radius: 50px;
                        font: 600 0.9em Lato;
                        background: #f0ecec;
                    }

                    .add-topic-description-location-container,
                    .add-topic-description-ethnicity-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 5px;

                        .add-topic-location-container,
                        .add-topic-ethnicity-container {
                            padding: 7px 13px;
                            border: 2px solid #d8d4d4;
                            border-radius: 50px;
                            font: 600 0.9em Lato;
                            background: #f0ecec;
                        }
                    }

                    .add-topic-description-text {
                        font: 500 0.9em Lato;
                        color: #CCCCCC;
                    }
                }
            }

            .right-container {
                display: flex;

                .right-icon {
                    color: #d0cccc;
                }
            }
        }
    }
}

#desktop-edit-profile-looking-for-subcomponent {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .looking-for-static-label-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .looking-for-label {
            font: 600 1.2em Lato;
        }

        .short-divider {
            width: 30px;
        }
    }

    .looking-for-description {
        padding: 0 20px 20px 20px;
        font: 500 1em Lato;
        color: #737373;
    }

    .looking-for-topic-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .topic-container {
            margin: 0 5px;
            padding: 15px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #e8e4e4;
            border-radius: 10px;
            cursor: pointer;

            .left-container {
                padding-left: 5px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .add-topic-label {
                    font: 600 1.1em Lato;
                }

                .add-topic-description {

                    .add-topic-description-min-max {
                        padding: 7px 13px;
                        border: 2px solid #d8d4d4;
                        border-radius: 50px;
                        font: 600 0.9em Lato;
                        background: #f0ecec;
                    }

                    .add-topic-description-location-container,
                    .add-topic-description-ethnicity-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 5px;

                        .add-topic-location-container,
                        .add-topic-ethnicity-container {
                            padding: 7px 13px;
                            border: 2px solid #d8d4d4;
                            border-radius: 50px;
                            font: 600 0.9em Lato;
                            background: #f0ecec;
                        }
                    }

                    .add-topic-description-text {
                        font: 500 0.9em Lato;
                        color: #CCCCCC;
                    }
                }
            }

            .right-container {
                display: flex;

                .right-icon {
                    color: #d0cccc;
                }
            }
        }
    }
}