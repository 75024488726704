#error-nofitication-element {

    .padding-container {
        display: flex;
        gap: 10px;

        .center-container {
            display: flex;
            align-items: center;

            .error-icon-container {
                height: 45px;
                width: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                background-color: white;

                .error-inner-ring {
                    height: 22px;
                    width: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50px;
                    background-color: #EB6258;

                    .error-icon {
                        font-size: 20px;
                    }
                }
            }
        }

        .message-body-container {
            width: calc(100% - 45px - 24px - 20px);
            display: flex;
            align-items: center;

            .message-body {
                font: 500 1em Lato;
                color: black;
            }
        }

        .close-icon-container {

            .close-icon {}
        }
    }
}