@import "../../../../global-style.scss";

#customize-tips-dialog {

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .blank-container {
            flex: 1;
        }

        .title {
            font: 600 1.4em Lato;
        }

        .close-button-container {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .header {
            text-align: center;
            font: 600 1.4em Lato;
        }

        .description {
            font: 400 1em Lato;

            .highlight-yellow {
                font: 600 1em Lato;
                color: orange;
            }
        }

        .actions-container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .action-label {
                font: 600 1.1em Lato;
            }

            .action-container {
                height: 40px;
                display: flex;
                border: 1px solid lightgrey;
                border-radius: 25px;

                .action-description {
                    display: flex;
                    justify-self: center;
                    align-items: center;
                    flex: 3;

                    .action-description-input {
                        height: 90%;
                        width: 90%;
                        border: none;
                        border-radius: 25px;
                        outline: none;
                        text-align: center;
                        font: 400 1em Lato;
                    }
                }

                .action-value {
                    display: flex;
                    justify-self: center;
                    align-items: center;
                    flex: 2;

                    .action-value-input {
                        height: 90%;
                        width: 90%;
                        border: none;
                        border-radius: 25px;
                        outline: none;
                        text-align: center;
                        font: 400 1em Lato;
                    }
                }
            }
        }

        .footer-container {
            display: flex;
            justify-content: flex-end;

            .done-button {
                width: fit-content;
                margin: 10px 0;
                padding: 10px 30px;
                border-radius: 25px;
                cursor: pointer;
                font: 500 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }
        }
    }
}