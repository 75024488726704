#levelling-check-in-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .check-in-label {
            padding-bottom: 10px;
            font: 600 1.4em Lato;
        }

        .check-in-description {
            padding-bottom: 20px;
            font: 400 0.9em Lato;
        }

        .time-left-label {
            padding-top: 15px;
            font: 500 1em Lato;
            color: #710D0D;

            .time {
                color: #FF3767;
            }
        }
    }
}