@import "../../../../global-style.scss";

#details-signup-smoke-details-subcomponent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .padding-container {
        height: calc(100% - 100px - 10px);
        padding: 0 20px;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .top-container {
            height: 100%;

            .smoke-details-label {
                text-align: center;
                font: 900 2em Lato;
                color: white;
            }

            .smoke-body-container {
                max-width: 820px;
                padding: 5% 0;
                display: flex;
                flex-wrap: wrap;
                gap: 15px;

                .smoke-item {
                    // width: 170px;
                    padding: 6px 40px;
                    border: 2px solid rgba(255, 255, 255, 0.4);
                    border-radius: 50px;
                    text-align: center;
                    cursor: pointer;
                    font: 600 1em Lato;
                    color: white;
                    transition: all 0.3s ease-in-out;
                }

                .selected {
                    color: $sugarbook-main-color;
                    background: white;
                }
            }
        }
    }

    .bottom-container {
        height: 100px;
        width: calc(100% - 40px);
        padding: 10px 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .continue-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .skip-button {
            cursor: pointer;
            color: #988484;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .full-width {
        width: calc(100% - 160px);
    }

    .partial-width {
        width: 100%;
    }

    .fix-width {
        width: 150px;
    }

    .fit-content {
        width: fit-content;
    }

    .flex-start {
        justify-content: flex-start;
    }

    .justify-center {
        justify-content: center;
    }
}

// Response Design
@media (min-width: 720px) {
    #details-signup-smoke-details-subcomponent {

        .top-container {

            .smoke-details-label {
                font: 600 2em Lato;
            }
        }

        .partial-width {
            max-width: 320px;
        }
    }
}