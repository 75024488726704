@import "../../../../../global-style.scss";

#username-subcomponent {
    height: 100%;

    .padding-container {
        height: calc(100% - 80px - 40px);
        padding: 20px;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .top-container {
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 50px;

            .username-title-container {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .username-title {
                    padding-top: 30px;
                    text-align: center;
                    font: 600 1.6em Lato;
                }

                .username-description {
                    text-align: center;
                    font: 500 0.9em Lato;
                    color: #A2A2A2;
                }
            }

            .form-container {
                height: 60px;
                width: 100%;

                .form-field {
                    width: 100%;
                    padding-top: 3px;
                    padding-right: 15px;

                    input {
                        text-align: center;
                        font: 600 1.2em Lato;
                        color: $sugarbook-main-text-color;
                    }

                    .MuiInput-underline:before {
                        border-bottom-color: #e0dcdc;
                    }
                }

                .rejected-reason-icon {
                    font-size: 20px;
                    color: #CCCCCC;
                }

                .rejected-value {

                    .MuiInput-root {
                        color: red;
                    }
                }
            }

            .username-footer {
                text-align: center;
                font: italic 500 0.8em/1.7 Lato;
                color: #A2A2A2;
            }
        }
    }

    .bottom-container {
        height: 80px;
        width: calc(100% - 40px);
        padding: 0 20px;

        .save-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }
}