@import "../../../../global-style.scss";

#component-navbar-shared {
    width: 100%;
    z-index: 10;

    .navbar-container {
        height: 49px;
        padding: 10px 0;
        display: flex;
        justify-content: space-around;

        .space-around {
            width: 100%;
            // max-width: 1020px;
            padding: 0 12px;

            .mobile-view {
                display: flex;
                justify-content: space-between;

                .left-container {
                    width: 50px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .back-icon-container {
                        height: 50px;
                        width: 50px;
                        display: flex;
                        justify-content: center;

                        .back-icon {
                            width: 10px;
                        }
                    }
                }

                .middle-container {

                    .middle-label {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        font: 600 1.3em Lato;
                        color: #ff1694;

                        .leaderboard-icon-container {
                            padding-right: 7px;
                            display: flex;

                            .leaderboard-icon {
                                width: 25px;
                            }
                        }
                    }
                }

                .right-container {
                    width: 50px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .info-icon-container {
                        height: 50px;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        color: white;

                        .info-icon {
                            height: 30px;
                        }
                    }
                }
            }
        }
    }
}

// Utility CSS
.fixed {
    position: fixed;
}

.opaque-gradient {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.47), transparent);
}

.border-radius {
    border-radius: 19px;
}