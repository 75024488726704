#go-live-button-shared-subcomponent {
    max-width: 116px;
    padding: 13px 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 5px;
    border-radius: 25px;
    cursor: pointer;
    font: 700 1em Lato;
    background-image: linear-gradient(to right, #F5BB19, #FF3767);
    color: white;

    .live-icon-container {
        display: flex;
        align-items: center;

        .live-icon {
            height: 20px;
        }
    }

    .label {}
}