@import "../../../../../global-style.scss";

#alcohol-preference-subcomponent {
    height: 100%;

    .padding-container {
        height: calc(100% - 80px - 40px);
        padding: 20px;
        display: flex;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .top-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .drinking-icon-container {
                display: flex;
                justify-content: center;

                .drinking-icon {
                    height: 25px;
                }
            }

            .alcohol-preference-title {
                text-align: center;
                font: 600 1.6em Lato;
            }

            .alcohol-preference-choose-options {
                padding-top: 10px;
                text-align: center;
                font: 500 0.9em Lato;
                color: #A2A2A2;
            }

            .alcohol-preference-body-container {
                padding: 10px 0;
                display: flex;
                // justify-content: center;
                flex-wrap: wrap;
                gap: 8px;
                // gap: 10px; // Desktop
                overflow: auto;
                mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
                -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

                .alcohol-preference-item {
                    width: fit-content;
                    padding: 4px 17px 4px 9px;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    border: 1px solid #d8d8d8;
                    border-radius: 50px;
                    cursor: pointer;
                    font: 600 1em Lato;
                    background: white;
                    color: #A2A2A2;
                    transition: all 0.3s;

                    .alcohol-icon-container {
                        height: 25px;
                        display: flex;
                        align-items: center;

                        .alcohol-icon {
                            height: 100%;
                            filter: invert(0.8);
                        }

                        .whiskey-icon {
                            height: 80%;
                            filter: invert(0.8);
                        }
                    }

                    .alcohol-item {
                        font: 600 0.9em Lato;
                    }
                }

                .selected {
                    border-color: #e8cccc;
                    background: #f8ecec;
                    color: $sugarbook-main-color;
                }
            }
        }
    }

    .bottom-container {
        height: 80px;
        width: calc(100% - 40px);
        padding: 0 20px;

        .save-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .add-edit-padding-top {
        padding-top: 35px;
    }
}