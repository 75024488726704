@import "../../../../global-style.scss";

#private-call-callee-current-session-summary-dialog {

    .padding-container {
        max-width: 250px;
        padding: 40px 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .header {
            text-align: center;
            font: 600 1.3em Lato;
        }

        .top-stats-container {
            display: flex;

            .duration-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex: 1;

                .duration {
                    font: 600 1.1em Lato;
                }

                .duration-label {
                    text-align: center;
                    font: 600 0.8em Lato;
                }
            }

            .total-diamonds-earned-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex: 1;

                .total-diamonds-earned {
                    font: 600 1.1em Lato;
                }

                .total-diamonds-earned-label {
                    text-align: center;
                    font: 600 0.8em Lato;
                }
            }
        }

        .bottom-stats-container {

            .diamonds-earned-text {
                font: 600 1.3em Lato;
            }

            .diamonds-earned-category-container {
                padding-top: 30px;
                display: flex;
                justify-content: space-between;

                .diamonds-earned-category-label {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .category-label {
                        font: 600 0.9em Lato;
                    }
                }

                .diamonds-earned-category-output {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 15px;

                    .diamonds-earned {
                        display: flex;
                        font: 600 0.9em Lato;

                        .diamond-icon-container {
                            padding-right: 7px;
                            display: flex;

                            .diamond-icon {
                                height: 15px;
                            }
                        }
                    }
                }
            }
        }

        .close-button {
            height: 40px;
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}