@import "../../../global-style.scss";

#sugarbook-notice-component {
    max-height: 60px;
    background: $sugarbook-main-color;

    .padding-container {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .notice-container {
            width: 100%;
            text-align: center;
            color: white;

            p {
                display: contents;

                a {
                    padding-left: 5px;
                    font-weight: 900;
                    color: #4798dd;
                }

                b {
                    font-weight: 900;
                }

                span {
                    position: relative;
                    left: 5px;
                    top: 2px;
                }
            }
        }

        .close-button-container {
            display: flex;
            cursor: pointer;

            .close-button {
                color: white;
            }
        }
    }
}