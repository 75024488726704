@import "../../../../../global-style.scss";

#product-purchase-dialog-tip-menu-tab-subcomponent {

    .padding-container {
        padding: 20px;

        .tip-header {
            padding-bottom: 20px;
            font: 600 1.3em Lato;
        }

        .subheader {
            padding-bottom: 10px;
            font: 500 1em Lato;
        }

        .action-container {
            padding: 6px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid rgb(239, 239, 239);

            .action-description {
                width: 40%;
                font-style: italic;
                color: rgb(151, 151, 151);
            }

            .action-value {
                width: 40%;
                display: flex;

                .coin-icon-container {
                    padding-right: 5px;

                    .coin-icon {
                        height: 17px;
                    }
                }
            }

            .send-action-button {
                height: 30px;
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 0.9em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }
        }

        .request-header {
            padding-top: 20px;
            padding-bottom: 20px;
            font: 600 1.3em Lato;
        }

        .request-an-action-container {

            .request-label-container {
                padding-bottom: 10px;
                display: flex;
                gap: 6px;

                .action-request-label {
                    width: 40%;
                    text-align: center;
                    font: 600 0.9em Lato;
                }

                .coins-request-label {
                    width: 40%;
                    display: flex;
                    text-align: center;
                    font: 600 0.9em Lato;

                    .coin-icon-container {
                        padding-right: 5px;
                        display: flex;
                        align-items: center;

                        .coin-icon {
                            height: 15px;
                        }
                    }
                }

                .send-request-label {
                    width: 30px;
                    text-align: center;
                }
            }

            .request-item-container {
                padding-bottom: 10px;
                display: flex;
                align-items: center;
                gap: 10px;

                .action-form-field {
                    width: 40%;
                    padding: 6px;
                    border-radius: 10px;
                    background-color: rgba(0, 0, 0, 0.06);

                    .form-field {
                        text-align: center;

                        input {
                            text-align: center;
                        }
                    }
                }

                .coins-value-form-field {
                    width: 30%;
                    padding: 6px;
                    border-radius: 10px;
                    background-color: rgba(0, 0, 0, 0.06);

                    .form-field {
                        text-align: center;

                        input {
                            text-align: center;
                        }
                    }
                }

                .send-custom-action-button {
                    height: 30px;
                    width: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    cursor: pointer;
                    font: 600 0.9em Lato;
                    background-color: $sugarbook-main-color;
                    color: white;
                    transition: all 0.5s;
                }

                // Utility CSS
                .disabled-button {
                    cursor: not-allowed;
                    background-color: rgba(0, 0, 0, 0.12);
                    color: rgba(0, 0, 0, 0.26);
                }
            }
        }
    }
}