#private-standby-joining-call-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 40;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    background: rgb(33, 33, 33, 0.46);

    .username {
        font: 600 1.4em Lato;
        color: white;
    }

    .subheader {
        font: 600 1.3em Lato;
        color: white;
    }
}