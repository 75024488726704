@import "../../../../../global-style.scss";

#tor-tag-cny-tag-subcomponent {
    position: relative;
    // padding: 4px 0;
    display: flex;
    align-items: center;
    border: 2px solid rgba(113, 13, 13, .2);
    border-radius: 50px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font: 600 0.9em Lato;
    color: $sugarbook-main-color;

    .left-tor-asset-container,
    .right-tor-asset-container {
        padding: 0 5px;

        .tor-asset {
            height: 28px;
        }
    }
}

// Utility CSS
.cny-matched {
    background: url("../../../../../assets/background/profile/tor-cny-selected-background.png");
}

.cny-unmatched {
    background: url("../../../../../assets/background/profile/tor-cny-unselected-background.png");
}