#video-call-customize-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        max-width: 1000px;
        margin: 0 auto;
        background-color: white;

        .settings-label {
            padding: 20px;
            font: 600 1.2em Lato;
        }

        .setting-container {
            height: 50px;
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            align-items: center;

            .label-container {
                display: flex;
                flex: 1;

                .label {
                    color: #919191;
                }

                .info-icon {
                    padding-left: 10px;
                    cursor: pointer;
                    font-size: 19px;
                }
            }

            .output {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
            }

            .toggle {
                display: flex;
                justify-content: flex-end;
                flex: 1;
            }

            .form-container {
                display: flex;
                flex: 2;

                .form-field {
                    width: 100%;
                    padding-left: 30px;
                    padding-right: 15px;
                }
            }
        }

        .unavailable-text {
            padding-bottom: 10px;
            text-align: center;
            font: italic 400 0.8em Lato;
        }

        .timezone-label {
            padding-top: 20px;
            text-align: center;
            font: italic 500 1em Lato;
            color: #919191;
        }
    }
}

// Responsive Design
@media (min-width: 1000px) {

    #video-call-customize-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}