#search-incomplete-profile-subcomponent {
    width: 100%;
    max-width: 500px;
    display: flex;
    border-radius: 10px;
    text-decoration: none;
    background-color: #f2f2f2;

    .left-container {
        width: 20%;

        .profile-icon-container {
            height: 50px;
            width: 38px;
            margin: 10px;
            padding-left: 13px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            background-color: white;

            .profile-icon {
                width: 30px;
            }
        }
    }

    .description-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .subheader {
            font: 600 1em Lato;
            color: #333333;
        }

        .text {
            font: 500 0.7em Lato;
            color: #676767;
        }

        .complete-container {
            padding-top: 3px;
            display: flex;
            align-items: center;

            .progress-bar-container {
                height: 13px;
                width: 80%;
                position: relative;
                border-radius: 25px;
                background-color: white;

                .progress-bar {
                    height: 100%;
                    width: 70%;
                    position: absolute;
                    border-radius: 25px 0 0 25px;
                    background-color: rgb(169, 207, 96);
                }
            }

            .percentage {
                padding-left: 10px;
                color: #333333;
            }
        }
    }
}