@import "../../global-style.scss";

#forgot-password-page {
    height: 100%;

    .max-width-container {
        height: calc(100% - 25px);
        // width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        padding: 25px 25px 0 25px;

        .top-container {
            height: calc(100% - 100px);
            display: flex;
            flex-direction: column;
            gap: 30px;

            .navbar-container {
                height: 30px;
                display: flex;
                align-items: center;

                .back-button {
                    width: 50px;
                    cursor: pointer;

                    .signup-back-icon {
                        height: 30px;
                    }
                }
            }

            .forgot-password-label {
                font: 600 1.7em Lato;
                color: white;
            }

            .forgot-password-description {
                font: 400 1em Lato;
                color: white;
            }

            .email-form-field-container {
                height: 50px;
                width: 100%;
                padding-bottom: 30px;
                margin-left: auto;
                margin-right: auto;

                .email-form-field {
                    width: 100%;

                    .MuiInput-underline:before {
                        border-bottom: 2px solid rgba(255, 255, 255, 0.4);
                    }

                    .MuiInput-underline:after {
                        border-bottom-color: white;
                    }

                    .MuiInput-input {
                        padding: 4px 0 7px 5px;
                        color: white;
                    }
                }
            }
        }

        .button-container {
            height: 100px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .reset-button {
                width: 100%;
                padding: 13px 0;
                border-radius: 50px;
                cursor: pointer;
                font: 600 1em Lato;
                text-align: center;
                color: white;
                background-color: $sugarbook-main-color;
                transition: all 0.3s ease-in-out;
            }

            .disabled-button {
                cursor: not-allowed;
                background-color: #8C8283;
                color: $sugarbook-main-color;
            }
        }
    }

    // Utility CSS
    .inactive-button {
        background-color: rgba(0, 0, 0, .12) !important;
        border-color: transparent !important;
        color: rgba(0, 0, 0, .26) !important;
    }

    .active-button {
        background-color: rgb(113, 13, 13) !important;
        border-color: rgb(113, 13, 13) !important;
        color: white !important;
    }
}

// #forgot-password-page {
//     height: 100%;

//     .max-width-container {
//         height: calc(100% - 25px);
//         // width: 80%;
//         max-width: 400px;
//         margin-left: auto;
//         margin-right: auto;
//         padding: 25px 25px 0 25px;

//         .top-container {
//             height: calc(100% - 100px - 20px);
//             padding-bottom: 20px;
//             display: flex;
//             flex-direction: column;
//             gap: 30px;
//             overflow: auto;
//             mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
//             -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

//             .navbar-container {
//                 height: 30px;
//                 display: flex;
//                 align-items: center;

//                 .back-button {
//                     width: 50px;
//                     cursor: pointer;

//                     .signup-back-icon {
//                         height: 30px;
//                     }
//                 }
//             }

//             .forgot-password-container {
//                 display: flex;
//                 flex-direction: column;
//                 gap: 30px;

//                 .forgot-password-label {
//                     font: 600 1.7em Lato;
//                     color: white;
//                 }

//                 .forgot-password-description {
//                     font: 400 1em Lato;
//                     color: white;
//                 }

//                 .email-form-field-container {
//                     height: 50px;
//                     width: 100%;
//                     padding-bottom: 30px;
//                     margin-left: auto;
//                     margin-right: auto;

//                     .email-form-field {
//                         width: 100%;

//                         .MuiInput-underline:before {
//                             border-bottom: 2px solid rgba(255, 255, 255, 0.4);
//                         }

//                         .MuiInput-underline:after {
//                             border-bottom-color: white;
//                         }

//                         .MuiInput-input {
//                             padding: 4px 0 7px 5px;
//                             color: white;
//                         }
//                     }
//                 }
//             }

//             .reset-password-container {
//                 display: flex;
//                 flex-direction: column;
//                 gap: 30px;

//                 .reset-password-label {
//                     font: 600 1.7em Lato;
//                     color: white;
//                 }

//                 .reset-password-description {
//                     font: 400 1em Lato;
//                     color: white;
//                 }

//                 .reset-password-middle-container {
//                     display: flex;
//                     flex-direction: column;
//                     align-items: center;
//                     gap: 15px;

//                     .code-input-container {

//                         .email-code-input {
//                             height: 2rem;
//                             width: 150px;
//                             margin: 0 0.3rem;
//                             padding: 5px;
//                             border: 2px solid #D0D0D0;
//                             border-radius: 10px;
//                             outline: none;
//                             text-align: center;
//                             letter-spacing: 5px;
//                             font: 600 2em Lato;
//                             caret-color: $sugarbook-main-color;
//                         }
//                     }

//                     .invalid-code-label {
//                         font: 500 0.8em Lato;
//                         color: #FF0000;
//                     }

//                     .resend-code-button-container {
//                         display: flex;
//                         flex-direction: column;
//                         align-items: center;
//                         gap: 10px;

//                         .resend-code-description {
//                             text-align: center;
//                             font: 500 0.8em Lato;
//                             color: white;
//                         }

//                         .resend-code-label-container {
//                             display: flex;
//                             align-items: center;
//                             gap: 3px;

//                             .resend-code-label {
//                                 cursor: pointer;
//                                 text-decoration: underline;
//                                 font: 600 0.8em Lato;
//                                 color: white;
//                             }

//                             .resend-countdown-label {
//                                 font: 600 0.8em Lato;
//                                 color: white;
//                             }

//                             .disable-button {
//                                 cursor: not-allowed;
//                                 color: rgba(255, 255, 255, 0.5) !important;
//                             }
//                         }
//                     }

//                     .password-form-field-container {
//                         height: 50px;
//                         width: 100%;
//                         margin-left: auto;
//                         margin-right: auto;

//                         .password-form-field {
//                             width: 100%;

//                             .MuiInput-underline:before {
//                                 border-bottom: 2px solid rgba(255, 255, 255, 0.4);
//                             }

//                             .MuiInput-underline:after {
//                                 border-bottom-color: white;
//                             }

//                             .MuiInput-input {
//                                 padding: 4px 0 7px 5px;
//                                 color: white;
//                             }
//                         }
//                     }
//                 }

//                 .reset-password-info-container {
//                     padding: 15px;
//                     display: flex;
//                     align-items: flex-start;
//                     gap: 10px;
//                     border-radius: 15px;
//                     background: rgba(255, 255, 255, 0.4);

//                     .light-bulb-icon-container {

//                         .light-bulb-icon {
//                             max-width: 25px;
//                         }
//                     }

//                     .reset-password-info-label {
//                         font: 500 0.9em Lato;
//                         color: white;
//                     }
//                 }
//             }
//         }

//         .button-container {
//             height: 100px;
//             display: flex;
//             flex-direction: column;
//             gap: 20px;

//             .reset-button {
//                 width: 100%;
//                 padding: 13px 0;
//                 border-radius: 50px;
//                 cursor: pointer;
//                 font: 600 1em Lato;
//                 text-align: center;
//                 color: white;
//                 background-color: $sugarbook-main-color;
//                 transition: all 0.3s ease-in-out;
//             }

//             .disabled-button {
//                 cursor: not-allowed;
//                 background-color: #8C8283;
//                 color: $sugarbook-main-color;
//             }
//         }
//     }

//     // Utility CSS
//     .inactive-button {
//         background-color: rgba(0, 0, 0, .12) !important;
//         border-color: transparent !important;
//         color: rgba(0, 0, 0, .26) !important;
//     }

//     .active-button {
//         background-color: rgb(113, 13, 13) !important;
//         border-color: rgb(113, 13, 13) !important;
//         color: white !important;
//     }
// }