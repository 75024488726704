#low-power-mode-dialog {
    max-width: 340px;

    .padding-container {
        padding: 70px 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .header-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;

            .low-power-image-container {

                .low-power-image {
                    width: 50px;
                }
            }

            .text {}

            .low-power-label {
                font: 600 1.2em Lato;
            }
        }

        .low-power-description {
            padding: 20px 0;
            text-align: center;
            font: 400 0.9em Lato;
        }
    }

    .close-button {
        padding: 15px 0;
        cursor: pointer;
        text-align: center;
    }
}