@import "../../global-style.scss";

#feed-page {

    .own-user-post-container {
        height: 100px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        gap: 15px;
        background-color: white;

        .own-profile-picture-container {
            height: 60px;
            width: 60px;

            .own-profile-picture {
                height: 100%;
                width: 100%;
            }
        }

        .own-post-container {
            flex-grow: 1;
        }

        .post-button {
            padding: 7px 20px;
            border-radius: 25px;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }

    .feeds-container {

        .feed-container {
            margin: 5px;
            padding: 10px;
            border: 1px solid #333333;
            border-radius: 10px;

            .user-details-container {
                display: flex;
                gap: 20px;

                .profile-picture-container {}

                .name-location-container {

                    .username {}

                    .location {}
                }
            }

            .post-description-container {}

            .post-picture-container {

                .post-picture {}
            }

            .post-action-container {
                display: flex;
                justify-content: space-between;


                .post-action-left-container {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .like-post-icon-container {
                        display: flex;

                        .like-icon {
                            width: 30px;
                        }
                    }

                    .send-tip-container {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        .send-tip-icon-container {
                            display: flex;

                            .send-tip-icon {
                                width: 30px;
                            }
                        }

                        .send-tip-label {}
                    }
                }

                .post-action-right-container {

                    .message-icon-container {
                        display: flex;

                        .message-icon {
                            width: 30px;
                        }
                    }
                }
            }

            .likes-tip-container {
                display: flex;
                align-items: center;
                gap: 7px;

                .likes {}

                .circle-separator {
                    height: 5px;
                    width: 5px;
                    border-radius: 50px;
                    background-color: black;
                }

                .tips {}
            }
        }
    }
}