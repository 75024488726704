@import "../../../global-style.scss";

#introduction-signup-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-container {
        height: calc(50% - 100px - 10px);
        width: calc(100% - 60px);
        min-height: 350px;
        max-width: 500px;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .introduction-signup-title {
            font: 600 2em Lato;
            color: white;
        }

        .introduction-signup-description {
            font: 500 1em Lato;
            color: white;
        }
    }

    .bottom-container {
        height: 100px;
        width: calc(100% - 40px);
        max-width: 500px;
        padding: 10px 20px 0 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .continue-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .full-width {
        width: 100%;
    }

    .partial-width {
        width: 60%;
    }

    .fix-width {
        width: 150px;
    }

    .fit-content {
        width: fit-content;
    }
}

// Responsive Design
@media (max-width: 768px) {
    #introduction-signup-page {

        .top-container {
            height: calc(100% - 100px - 10px);
        }

        .bottom-container {
            justify-content: center;
        }
    }
}