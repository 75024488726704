#video-call-room-callee-camera-feed-overlay-subcomponent {
    height: 100%;
    overflow: hidden;
    background-color: black;

    #callee-video {
        height: 100%;
        width: 100%;
        object-fit: cover;

        div {
            border-radius: 10px;

            video {
                border-radius: 10px;
            }
        }
    }

    .camera-disabled-container {
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .camera-disabled-icon {
            color: white;
        }
    }
}