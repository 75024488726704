#phone-auth-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .header {
            padding-bottom: 25px;
            font: 400 18px Lato;
        }

        .phone-container {
            display: flex;
            gap: 10px;

            .country-form-field-container {
                height: 50px;
                width: 30%;
                padding-bottom: 30px;
                margin-left: auto;
                margin-right: auto;

                .country-form-field {
                    width: 100%;
                }
            }

            .phone-form-field-container {
                height: 50px;
                width: 70%;
                padding-bottom: 30px;
                margin-left: auto;
                margin-right: auto;

                .phone-form-field {
                    width: 100%;
                }
            }
        }

        .check-button {
            height: 47px;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-decoration: none;
            text-transform: none;
            cursor: pointer;
            font: 500 20px Lato;
            color: rgb(113, 13, 13);
        }

        .continue-button {
            height: 47px;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-decoration: none;
            text-transform: none;
            cursor: pointer;
            font: 500 20px Lato;
            background-color: rgb(113, 13, 13);
            color: white;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
        }

        .terms-and-condition {
            margin-top: 25px;
            line-height: 1.4;
            color: #676767;

            .terms,
            .privacy-policy {
                text-decoration: underline;
                color: rgb(113, 13, 13);
            }
        }
    }
}