@import "../../../../global-style.scss";

#skeleton-card-subcomponent {

    .search-card-container {
        width: 100%;
        position: relative;
        padding-top: 147%;
        border-radius: 10px;

        .profile-image-container {
            height: calc(100% - 20px);
            width: calc(100% - 20px);
            position: absolute;
            top: 0;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 10px;
            z-index: 20;
        }

        .description-container {
            height: calc(100% - 20px);
            width: calc(100% - 20px);
            position: absolute;
            top: 0;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 10px;
            z-index: 20;

            .top-description-container {
                flex-grow: 1;
                text-decoration: none;

                .live {
                    display: flex;
                    gap: 5px;

                    .live-status {
                        height: 20px;
                        padding: 0 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 3em;
                        font: 800 0.7em Lato;
                        background-color: #ff1694;
                        color: #fff;
                    }

                    .viewer-count-container {
                        height: 20px;
                        min-width: 40px;
                        padding-left: 6px;
                        padding-right: 8px;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        border-radius: 3em;
                        background-color: rgba(196, 196, 196, 0.6);
                        color: white;

                        .eye-logo-container {
                            display: flex;

                            .eye-logo {
                                width: 16px;
                            }
                        }

                        .viewer-count {
                            padding-left: 2px;
                            font: 800 0.8em Lato;
                            color: white;
                        }
                    }
                }

                .private-call-available {
                    width: fit-content;
                    padding: 4px 8px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    border-radius: 50px;
                    font: 600 0.6em Lato;
                    background: linear-gradient(90.18deg, #ff1694 20.88%, #000000 81.7%);
                    color: white;
                }

                .private-call-busy {
                    width: fit-content;
                    padding: 4px 8px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    border-radius: 50px;
                    font: 600 0.6em Lato;
                    background: $sugarbook-main-color;
                    color: white;
                }

                .video-call {
                    width: fit-content;
                    padding: 4px 8px;
                    border-radius: 50px;
                    font: 600 0.6em Lato;
                    background: linear-gradient(90.38deg,
                            #ffa927 5.04%,
                            #ff409c 70.45%,
                            #9534b4 119.21%);
                    color: white;
                }

                .verified-caller-badge-container {
                    display: flex;
                    align-items: center;

                    .verified-caller-badge {
                        height: 13px;
                    }
                }
            }

            .bottom-description-container {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .username-skeleton {
                    width: 30%;
                }

                .location-skeleton {
                    width: 50%;
                }
            }
        }
    }
}