#settings-release-channel-settings-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .release-channel-label {
            font: 600 1.1em Lato;
        }

        .toggle-button-container {
            display: flex;
            justify-content: center;
        }
    }
}