@import "../../../../global-style.scss";

#limited-time-coin-purchase-dialog {

    .padding-container {
        padding: 20px;

        .header-container {
            display: flex;
            align-items: center;

            .left-container {
                flex: 1;
            }

            .middle-container {
                display: flex;
                flex-direction: column;
                gap: 5px;
                flex: 9;
                text-align: center;

                .livestream-launch-label {
                    text-transform: uppercase;
                    font: 600 1.1em Lato;
                    letter-spacing: 1px;
                }

                .limited-time-label {
                    text-transform: uppercase;
                    font: 500 0.8em Lato;
                    letter-spacing: 3px;
                }
            }

            .right-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex: 1;

                .close-icon-container {
                    display: flex;
                    align-items: center;

                    .close-icon {
                        cursor: pointer;
                    }
                }
            }
        }

        .coins-tab-container {
            height: 42vh;
            margin: 10px 0;
            overflow-y: auto;
        }

        .bottom-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .balance-container {
                height: 20px;
                padding: 3px 10px 3px 7px;
                display: flex;
                align-items: center;
                gap: 4px;
                border-radius: 25px;
                background-color: #e4e5e7;

                .add-icon-container {
                    display: flex;

                    .add-icon {
                        height: 17px;
                    }
                }

                .balance-label {
                    font: 600 0.8em Lato;
                }

                .coin-icon-container {

                    .coin-icon {
                        height: 15px;
                        ;
                    }
                }

                .coin-value {
                    font: 600 0.8em Lato;
                }
            }
        }
    }
}