#video-call-room-caller-camera-feed-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    .padding-container {
        // height: calc(100% - 60px); Might not need
        position: relative;
        padding: 100px 10px 0 20px;

        #caller-video {
            height: 160px;
            width: 90px;
            border-radius: 10px;
            object-fit: cover; // contain if desktop

            div {
                border-radius: 10px;

                video {
                    border-radius: 10px;
                }
            }
        }

        .camera-disabled-container {
            height: 160px;
            width: 90px;
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .camera-disabled-icon {
                color: white;
            }
        }
    }
}