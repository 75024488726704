@import "../../../../../global-style.scss";

#looking-for-location-subcomponent {
    height: 100%;

    .padding-container {
        height: calc(100% - 80px - 40px);
        padding: 20px;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .top-container {
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 50px;

            .looking-for-location-title {
                text-align: center;
                font: 600 1.6em Lato;
            }

            .looking-for-location-description {
                text-align: center;
                font: 500 0.9em Lato;
                color: #A2A2A2;
            }

            .location-body-container {
                height: calc(100% - 50px);
                width: 100%;
                display: flex;
                flex-direction: column;
                // justify-content: center;
                align-items: center;
                gap: 20px;

                .location-form-field-container {
                    width: 100%;
                    padding-top: 8px;
                    position: relative;

                    .autocomplete-wrapper {

                        .MuiTextField-root {
                            width: 100%;
                        }

                        .MuiInputLabel-root {
                            // color: white;
                        }

                        .MuiInputBase-root {

                            input {
                                // color: white;
                            }

                            .MuiSelect-select {
                                // color: white;
                            }
                        }

                        .MuiInputBase-root:before {
                            border-bottom-color: $sugarbook-main-color;
                        }

                        .MuiInputBase-root:after {
                            border-bottom-color: $sugarbook-main-color !important;
                        }

                        .MuiSvgIcon-root {
                            // color: white;
                        }
                    }

                    .overlay-form-field-container {
                        height: calc(100% - 8px);
                        width: 100%;
                        position: absolute;
                        top: 7px;
                        background: white;

                        .overlay-form-field {
                            height: 100%;
                            width: 100%;

                            .MuiInput-root {
                                height: 100%;
                            }
                        }
                    }
                }

                .selected-locations-container {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;

                    .selected-location-container {
                        width: fit-content;
                        padding: 5px 10px;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        border: 1px solid #e0bcbc;
                        border-radius: 50px;
                        font: 600 1em Lato;
                        background: #f8e4ec;
                        color: $sugarbook-main-color;

                        .remove-selected-location-icon {
                            cursor: pointer;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    .bottom-container {
        height: 80px;
        width: calc(100% - 40px);
        padding: 0 20px;

        .save-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .add-edit-padding-top {
        padding-top: 30px;
    }
}