@import "../../../../../global-style.scss";

#body-type-subcomponent {
    height: 100%;

    .padding-container {
        height: calc(100% - 80px - 40px);
        padding: 20px;
        display: flex;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .top-container {
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 15px;

            .body-type-icon-container {
                display: flex;
                justify-content: center;

                .body-type-icon {
                    height: 25px;
                }
            }

            .body-type-title {
                text-align: center;
                font: 600 1.6em Lato;
            }

            .body-type-description {
                font: 500 0.9em Lato;
                color: #A2A2A2;
            }

            .body-type-choose-options {
                padding-top: 10px;
                font: 500 1em Lato;
                color: #A2A2A2;
            }

            .body-type-body-container {
                padding: 10px 0;
                display: flex;
                // justify-content: center;
                flex-wrap: wrap;
                gap: 10px;
                overflow: auto;
                mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
                -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

                .body-type-item {
                    // width: 85%;
                    padding: 7px 20px;
                    border: 1px solid #d8d8d8;
                    border-radius: 50px;
                    cursor: pointer;
                    text-align: center;
                    font: 600 1em Lato;
                    background: white;
                    color: #A2A2A2;
                    transition: all 0.3s;
                }

                .selected {
                    border-color: #e8cccc;
                    background: #f8ecec;
                    color: $sugarbook-main-color;
                }
            }
        }
    }

    .bottom-container {
        height: 80px;
        width: calc(100% - 40px);
        padding: 0 20px;

        .save-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .add-edit-padding-top {
        padding-top: 35px;
    }

    .partial-width {
        width: 100%;
    }

    .fix-width {
        width: 150px;
    }

    .fit-content {
        width: fit-content;
    }

    .flex-start {
        justify-content: flex-start;
    }

    .justify-center {
        justify-content: center;
    }
}