@import "../../../../../global-style.scss";

#leaderboard-section-user-list-subcomponent {

    .user-container {
        height: 65px;
        display: flex;
        align-items: center;

        .trophy-container {
            width: 30px;
            display: flex;
            align-items: center;

            .rank-container {
                width: 65%;
                text-align: center;
                font: bold 0.9em Lato;
            }
        }

        .profile-photo {
            height: 50px;
            width: 50px;
            margin-right: 10px;

            .fallback-image {
                height: 100%;
                width: 100%;
            }
        }

        .user-description-container {
            height: 100%;
            width: calc(100% - 30px - 50px - 90px - 20px);
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .top-container {
                display: flex;
                gap: 5px;

                .livestreaming-achievement-badge-container {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    .livestreaming-achievement-badge {
                        height: 13px;
                    }
                }

                .username {
                    font: 600 1em Lato;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .badge-container {
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    .premium-mini-badge {
                        height: 13px;
                    }

                    .verified-profile-container {

                        .verified-profile-badge {
                            height: 13px;
                        }
                    }

                    .verified-caller-container {

                        .verified-caller-badge {
                            height: 13px;
                        }
                    }

                    .level-container {
                        display: flex;
                        align-items: center;

                        .level-badge {
                            height: 13px;
                        }
                    }

                    .special-badges-container {
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        .special-badges {
                            height: 13px;
                        }
                    }
                }
            }

            .bottom-container {
                display: flex;

                .diamond-icon-container {
                    display: flex;
                    align-items: center;

                    .diamond-icon {
                        width: 18px;
                    }
                }

                .diamond-count {
                    padding-left: 5px;
                    font: 600 0.9em Lato;
                    color: rgb(77, 197, 255);
                }

                .coin-count {
                    padding-left: 5px;
                    font: 600 0.9em Lato;
                    color: rgb(253, 196, 83);
                }
            }
        }

        .follow-button {
            width: 80px;
            padding-top: 6px;
            padding-bottom: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $sugarbook-main-color;
            border-radius: 25px;
            cursor: pointer;
            color: $sugarbook-main-color;
        }

        .following-button {
            width: 90px;
            padding-top: 6px;
            padding-bottom: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $sugarbook-main-color;
            border-radius: 25px;
            cursor: pointer;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}