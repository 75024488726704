@import "../../../../global-style.scss";

#busy-in-private-dialog {

    .padding-container {
        max-width: 260px;
        padding: 50px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .busy-icon-container {
            display: flex;
            justify-content: center;

            .busy-icon {
                height: 50px;
            }
        }

        .busy-title {
            text-align: center;
            font: 600 1.2em Lato;
        }

        .busy-message {
            text-align: center;
            font: 500 1em Lato;
        }

        .button-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .back-button {
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .inbox-button {
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background-color: white;
            }
        }
    }
}