@import "../../../../../global-style.scss";

#mobile-edit-profile-location-subcomponent {

    .location-label-container {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 50px;
        // background-color: $mui-text-field-outline-background-color;

        .location-label {
            font: 600 1.5em Lato;
        }

        .expand-icon-container {
            display: flex;

            .expand-icon {
                color: #CCCCCC;
            }
        }
    }

    .form-field-container {
        height: 35px;
        padding-left: 20px;
        padding-right: 10px;
        display: flex;
        align-items: center;

        .label-container {
            width: 40%;

            .label {
                color: #919191;
            }

            .info-icon {
                padding-left: 10px;
                font-size: 19px;
            }
        }

        .output {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
        }

        .toggle {
            display: flex;
            justify-content: flex-end;
            flex: 1;
        }

        .form-container {
            width: 60%;
            position: relative;

            .autocomplete-wrapper {
                width: 100%;

                .form-field {
                    width: 100%;
                    padding-top: 3px;

                    .MuiInput-root {
                        font: 600 1em Lato;
                        // color: $sugarbook-main-text-color;

                        .MuiButtonBase-root {
                            padding-right: 0;
                        }
                    }
                }
            }

            .overlay-form-field-container {
                height: calc(100% - 3px);
                width: 100%;
                position: absolute;
                top: 0;
                padding-top: 3px;
                background: white;

                .overlay-form-field {
                    height: 100%;

                    .MuiInput-root {
                        height: 100%;
                        font: 600 1em Lato;
                        // color: $sugarbook-main-text-color;

                        .MuiButtonBase-root {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }

    .reload-location-container {
        height: 35px;
        padding: 5px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        .reload-icon {
            font-size: 20px;
            color: $sugarbook-main-color;
        }

        .reload-location-label {
            font: 600 1em Lato;
            color: $sugarbook-main-color;
        }
    }
}

#desktop-edit-profile-location-subcomponent {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .location-static-label-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .location-label {
            font: 600 1.2em Lato;
        }

        .short-divider {
            width: 30px;
        }
    }

    .form-field-padding-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .form-field-container {
            height: 50px;
            display: flex;
            align-items: center;

            .label-container {
                width: 40%;

                .label {
                    font: 600 1em Lato;
                }

                .info-icon {
                    padding-left: 10px;
                    font-size: 19px;
                }
            }

            .output {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
            }

            .toggle {
                display: flex;
                justify-content: flex-end;
                flex: 1;
            }

            .form-container {
                width: 60%;
                position: relative;

                .autocomplete-wrapper {
                    height: 30px;
                    width: 95%;
                    padding-top: 2px;
                    padding-left: 2px;

                    .form-field {
                        height: 100%;
                        width: 100%;

                        input {
                            padding: 5px 20px;
                            font: 600 1em Lato;
                        }
                    }
                }

                .overlay-form-field {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    padding: 1px 0;

                    .form-field {
                        width: 100%;

                        input {
                            padding: 10px 20px;
                            font: 600 1em Lato;
                        }
                    }
                }
            }
        }
    }

    .bottom-section-container {
        margin-left: 40%;

        .save-button {
            width: 130px;
            padding: 10px 0;
            border-radius: 25px;
            text-align: center;
            cursor: pointer;
            background-color: $sugarbook-main-color;
            color: white;
            transition: all 0.5s;
        }

        // Utility CSS
        .disabled-button {
            cursor: not-allowed;
            background-color: #D0D0D0;
            color: #B1B1B1;
        }
    }

    .reload-location-container {
        height: 35px;
        padding: 5px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        .reload-icon {
            font-size: 20px;
            color: $sugarbook-main-color;
        }

        .reload-location-label {
            font: 600 1em Lato;
            color: $sugarbook-main-color;
        }
    }
}