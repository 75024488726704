#last-stream-summary-last-stream-diamonds-earned-subcomponent {

    .padding-container {
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .diamonds-earned-label {
            font: 600 1.5em Lato;
        }

        .diamonds-earned-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .total-diamonds-earned-container,
            .gift-diamonds-container {
                display: flex;

                .label {
                    flex: 5;
                    font: 600 1em Lato;
                }

                .diamond-icon-container {
                    display: flex;
                    align-items: center;
                    flex: 1;

                    .diamond-icon {
                        height: 13px;
                    }
                }

                .diamond-label {
                    font: 600 1em Lato;
                }
            }
        }
    }
}