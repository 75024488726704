@import "../../../../global-style.scss";

#chat-conversation-chat-input-subcomponent {
    height: 80px;
    display: flex;
    background-color: #fff;

    .input-container {
        width: calc(100% - 70px);

        .form-container {
            height: calc(100% - 10px);
            padding-top: 10px;
            padding-left: 15px;

            .form-field {
                height: 100%;
                width: 90%;
                font: 500 1.1em Lato;

                .MuiInput-root {
                    height: 100%;

                    textarea {
                        height: 100% !important;
                        overflow: auto !important;
                    }
                }
            }
        }
    }

    .send-button-container {
        width: 70px;

        .send-button {
            width: 100%;
            padding-top: 13px;
            text-transform: none;
            font: 500 1.1em Lato;
            color: $sugarbook-main-color;
        }

        // Utility CSS
        .disabled-button {
            cursor: not-allowed;
            pointer-events: initial;
            color: lightgrey;
        }
    }
}

// Utility CSS
.rounded-corner {
    border-radius: 25px;
}