#interests-my-favorites-tab-subcomponent {
    height: 100%;

    .my-favorites-list-container {
        height: 100%;
        overflow: auto;

        >div {
            height: 100%;

            .infinite-scroller-container {}
        }
    }
}

// Responsive Design
@media (max-width: 720px) {
    #interests-my-favorites-tab-subcomponent {}
}