@import "../../../../global-style.scss";

#thank-you-for-review-dialog {
    min-width: 270px;

    .padding-container {
        padding: 40px 30px 30px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .action-success-icon-container {

            .action-success-icon {
                height: 60px;
            }
        }

        .thank-you-label {
            font: 600 1.1em Lato;
        }

        .close-button {
            height: 45px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            font: 600 1.3em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}