@import "../../../../../global-style.scss";

#mobile-edit-profile-basic-information-subcomponent {

    .basic-information-label-container {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 50px;
        // background-color: $mui-text-field-outline-background-color;

        .basic-information-label {
            font: 600 1.5em Lato;
        }

        .expand-icon-container {
            display: flex;

            .expand-icon {
                color: #CCCCCC;
            }
        }
    }

    .form-field-padding-container {
        padding: 0 20px;

        .form-field-container {
            height: 35px;
            display: flex;
            align-items: center;

            .label-container {
                width: 40%;

                .label {
                    font: 500 1em Lato;
                    color: #919191;
                }

                .info-icon {
                    padding-left: 10px;
                    font-size: 19px;
                }

                // Utility CSS
                .incomplete-field {
                    color: red;
                }
            }

            .form-container {
                width: 60%;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                cursor: pointer;

                .filled-form {
                    width: 100%;
                    font: 600 1em Lato;
                }

                .username-filled-form {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .empty-form {
                    width: 100%;
                    padding-right: 10px;
                    font: 600 1em Lato;
                    color: #CCCCCC;
                }

                .right-icon {
                    display: flex;

                    .select-form-field-dropdown-icon {
                        color: #d0cccc;
                    }
                }

                .email-label {
                    overflow: auto;
                    font: 600 1em Lato;
                }
            }

            .approval-status-container {
                width: fit-content;
                padding: 5px 7px;
                display: flex;
                align-items: center;
                // border: 1px solid $sugarbook-grey-text-color;
                border-radius: 50px;
                text-transform: uppercase;
                white-space: nowrap;
                font: 700 0.6em Lato;
            }

            .rejected-status-container {
                color: #FF0000;
                background: #FFDCDC;
            }

            .in-review-status-container {
                color: $sugarbook-grey-text-color;
                background: #D9D9D9;
            }

            .date-picker-container {
                width: 100%;

                .react-date-picker {
                    width: 100%;

                    .react-date-picker__wrapper {
                        border: none;

                        .react-date-picker__inputGroup {
                            font: 600 1em Lato;
                            // color: $sugarbook-main-text-color;

                            .react-date-picker__inputGroup__month {
                                // width: fit-content !important;
                            }

                            .react-date-picker__inputGroup__day {
                                // width: fit-content !important;
                            }

                            .react-date-picker__inputGroup__year {
                                width: 50px !important;
                            }
                        }

                        .react-date-picker__calendar-button {

                            svg {
                                // stroke: $sugarbook-main-color;
                            }
                        }
                    }
                }

                .react-calendar {
                    border: none;
                    border-radius: 5px;
                    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);

                    button {
                        height: 50px;
                        width: 50px;
                        border-radius: 50px;
                    }

                    .react-calendar__tile--active {
                        background-color: $sugarbook-main-color;
                    }
                }
            }

            // Utility CSS
            .reduced-width {
                width: 40%;
            }
        }
    }
}

#desktop-edit-profile-basic-information-subcomponent {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .basic-information-static-label-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .basic-information-label {
            font: 600 1.2em Lato;
        }

        .short-divider {
            width: 30px;
        }
    }

    .form-field-padding-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .form-field-container {
            height: 50px;
            display: flex;
            align-items: center;

            .label-container {
                width: 40%;

                .label {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font: 600 1em Lato;
                }

                .info-icon {
                    padding-left: 10px;
                    font-size: 19px;
                }

                .approval-status-container {
                    width: fit-content;
                    padding: 3px 5px;
                    display: flex;
                    align-items: center;
                    border: 1px solid $sugarbook-grey-text-color;
                    border-radius: 5px;
                    text-transform: uppercase;
                    font: 600 0.6em Lato;
                    color: $sugarbook-grey-text-color;
                }

                // Utility CSS
                .incomplete-field {
                    color: red;
                }
            }

            .form-container {
                width: 60%;

                .form-field {
                    width: 100%;
                    padding-top: 3px;
                    padding-right: 15px;

                    input {
                        padding: 10px 20px;
                        font: 600 1em Lato;
                    }
                }

                .email-label,
                .age-label {
                    padding: 10px 20px;
                    font: 600 1em Lato;
                    color: $sugarbook-main-text-color;
                }

                .rejected-reason-icon {
                    font-size: 20px;
                    color: #CCCCCC;
                }

                .rejected-value {

                    .MuiInput-root {
                        color: red;
                    }
                }
            }

            .date-picker-container {
                width: 100%;

                .react-date-picker {
                    width: 100%;

                    .react-date-picker__wrapper {
                        padding: 8px 20px;
                        border: none;
                        border-radius: 50px;
                        background-color: $app-background-color;
                    }
                }

                .react-calendar {
                    border: none;
                    border-radius: 5px;
                    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);

                    button {
                        height: 50px;
                        width: 50px;
                        border-radius: 50px;
                    }

                    .react-calendar__tile--active {
                        background-color: $sugarbook-main-color;
                    }
                }
            }
        }
    }

    .bottom-section-container {
        margin-left: 40%;

        .save-button {
            width: 130px;
            padding: 10px 0;
            border-radius: 25px;
            text-align: center;
            cursor: pointer;
            background-color: $sugarbook-main-color;
            color: white;
            transition: all 0.5s;
        }

        // Utility CSS
        .disabled-button {
            cursor: not-allowed;
            background-color: #D0D0D0;
            color: #B1B1B1;
        }
    }
}