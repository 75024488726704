@import "../../../../global-style.scss";

#premium-benefits-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-container {
        height: calc(100% - 130px - 10px);
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .premium-benefits-label {
            padding-top: 15%;
            text-align: center;
            font: 900 2em Lato;
            color: white;
        }

        .premium-benefits-description {
            padding: 20px 10px 0 10px;
            text-align: center;
            font: 500 0.9em/1.3 Lato;
            color: white
        }

        .premium-benefits-body-container {
            max-width: 820px;
            padding: 5% 0;
            display: flex;
            // flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
            gap: 15px;

            .premium-benefits-content-container {
                width: 150px;
                margin: 6px 0;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .premium-benefits-icon-container {
                    display: flex;
                    justify-content: center;

                    .heart-filled-icon,
                    .message-read-icon,
                    .profile-view-icon,
                    .profile-approval-icon,
                    .unlimited-messages-icon,
                    .white-lock-icon,
                    .white-search-filter-icon,
                    .location-sharp-icon,
                    .calendar-slash-icon {
                        height: 40px;
                    }

                    .user-secret-icon {
                        height: 55px;
                    }
                }

                .premium-benefits-content-label {
                    text-align: center;
                    font: 500 0.9em Lato;
                    color: white;
                }
            }
        }
    }

    .bottom-container {
        height: 130px;
        width: calc(100% - 40px);
        padding: 10px 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        .continue-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .limited-access-button {
            text-decoration: underline;
            cursor: pointer;
            font: 500 1em Lato;
            color: white;
        }
    }

    // Utility CSS
    .full-width {
        width: calc(100% - 160px);
    }

    .partial-width {
        width: 65%;
    }

    .fix-width {
        width: 150px;
    }

    .fit-content {
        width: fit-content;
    }
}

// Response Design
@media (min-width: 720px) {
    #premium-benefits-page {

        .top-container {
            height: calc(90% - 130px - 10px);
            display: flex;
            flex-direction: column;
            align-items: center;

            .premium-benefits-label {
                font: 600 2em Lato;
            }

            .premium-benefits-description {
                font: 500 1em/1.3 Lato;
            }

            .premium-benefits-body-container {
                max-width: 645px;
            }
        }
    }
}