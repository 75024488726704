@import "../../../../global-style.scss";

#follower-user-list-subcomponent {
    padding: 10px 20px;
    display: flex;
    align-items: center;

    .user-profile-photo-container {
        width: 50px;

        .user-profile-photo {
            height: 50px;
            width: 50px;
        }
    }

    .user-info-container {
        width: calc(100% - 50px - 100px - 20px);
        padding: 0 10px;
        display: flex;
        align-items: center;
        gap: 5px;

        .livestreaming-achievement-badge-container {
            display: flex;
            align-items: center;
            gap: 5px;

            .livestreaming-achievement-badge {
                height: 13px;
            }
        }

        .username {
            font: 500 1.1em Lato;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .level-badge-container {
            display: flex;
            align-items: center;

            .level-badge {
                height: 13px;
            }
        }
    }

    .remove-button {
        height: 35px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        font: 600 1em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }

    .follow-button {
        height: 35px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $sugarbook-main-color;
        border-radius: 25px;
        cursor: pointer;
        font: 600 1em Lato;
        color: $sugarbook-main-color;
    }

    .unfollow-button {
        height: 35px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $sugarbook-main-color;
        border-radius: 25px;
        cursor: pointer;
        font: 600 1em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }
}