#livestream-tablet-right-section-subcomponent {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .top-container {
        display: flex;
        justify-content: space-between;

        .pk-outer-container {
            padding-left: 10px;
            display: flex;
            align-items: center;
        }

        .lobby-container {
            height: calc(100% - 20px);
            // height: calc(100% - 20px - 2px);
            width: calc(100% - 20px);
            margin: 10px;
            border: 1px solid #dee2e6;
            border-radius: 15px 15px 0 0;

            .lobby-label {
                padding: 10px 0 10px 20px;
                font: 600 1.1em Lato;
            }
        }

        // Utility CSS
        .pk-width {
            width: calc(100% - 20px - 145px);
        }
    }

    .chat-container {
        height: calc(50% - 10px);
        margin: 0 10px 10px 10px;
        flex: 1;
        border: 1px solid #dee2e6;
        border-radius: 15px;
        background-image: url("../../../../assets/background/inbox/chat-background.png");
        background-size: contain;
    }

    .interaction-label {
        padding: 10px 0 10px 20px;
        font: 600 1.1em Lato;
    }

    .game-outlet-container {
        height: 437px;
        margin: 0 10px 10px 10px;
        border: 1px solid #dee2e6;
        border-radius: 15px;
        overflow: auto;

        .game-iframe-container {
            height: 390px;
        }
    }

    // Utility CSS
    .full-height {
        height: calc(100% - 20px);
        margin: 10px;
    }

    .minus-pk {
        height: calc(100% - 20px - 175px);
    }

    .minus-game {
        height: calc(100% - 20px - 410px);
    }

    .minus-pk-and-game {
        height: calc(100% - 20px - 175px - 410px);
    }

    .hide-game-container {
        display: none;
    }
}

// Utility CSS
.top-container-half-height {
    height: 50%;
}

.top-container-margin {
    margin: 10px auto auto auto;
}

.full-width {
    width: calc(100% - 20px - 145px);
}

.half-width {
    width: 50%;
}

.third-width {
    width: calc(100% / 3);
    max-width: 450px;
}

.hide-pk {
    display: none !important;
}