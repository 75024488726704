#settings-theme-settings-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .theme-label {
            font: 600 1.1em Lato;
        }

        .mobile-navbar-theme-container,
        .icon-theme-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .mobile-navbar-label,
            .icon-label {
                padding-left: 15px;
                font: 600 1em Lato;
            }

            .toggle-button-container {
                display: flex;
                justify-content: center;
            }
        }
    }
}