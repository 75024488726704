#video-call-tutorials-dialog {
    height: 100%;
    width: 100%;
    position: relative;

    .background-overlay {
        height: 100%;
        width: 100%;
        overflow: hidden;
        filter: blur(14px);
    }

    .overlay {
        height: 100%;
        width: 100%;
        top: 0;
        object-fit: contain;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .interactable-container {
        max-width: 330px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 35%;
        left: 0;
        right: 0;

        .button-container {
            height: 25px;
            padding-bottom: 10px;
            display: flex;
            justify-content: flex-end;

            .right-icon {
                font-size: 40px;
            }
        }

        .description-container {
            border-radius: 25px;
            background-color: white;

            .padding-container {
                padding: 20px;

                .subheader {
                    padding-bottom: 10px;
                    font: bold 1.5em Lato;
                    line-height: 1.4;
                }

                .text {
                    font: 400 1em Lato;
                    line-height: 1.5;
                }
            }
        }
    }

    .push-down {
        top: 68%;
    }
}

// Responsive Design
@media (max-width: 720px) {
    #video-call-tutorials-dialog {

        .overlay {
            position: absolute;
        }
    }
}