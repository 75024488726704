@import "../../../../global-style.scss";

#private-call-decline-dialog {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.9);

    .padding-container {
        padding: 0 20px;

        .call-rejected-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
            color: white;

            .call-reject-animation-container {

                .call-reject-animation {
                    width: 80px;
                }
            }

            .call-failed-label {
                font: 600 1.4em Lato;
            }

            .text {
                text-align: center;
                font: 500 1em Lato;
            }

            .close-button {
                height: 40px;
                padding: 5px 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }
        }
    }
}