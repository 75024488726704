#private-standby-interactable-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top-section {

        .padding-container {
            padding: 20px 10px 0 20px;
        }
    }

    .bottom-section {}
}