#pk-progress-bar-subcomponent {
    height: 20px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FF1EA5;

    .dual-pk-progress-bar-container {
        height: 100%;
        width: 100%;
        position: relative;

        .pk-left-bar {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            background: linear-gradient(90deg, rgb(30, 147, 255) 48%, rgb(255, 30, 165) 52%);
            background-size: 200% 200%;
            transition: all 0.5s;
        }

        .wet-emoji-container {
            height: 20px;
            font-size: 40px;
            z-index: 1;
            transition: all 0.5s;
            animation: miniScaleUpDown 1.5s infinite;

            .wet-emoji {
                position: relative;
                right: 10px;
                bottom: 10px;
            }
        }

        .pk-score-container {
            height: 100%;
            width: calc(100% - 14px);
            position: absolute;
            top: 0;
            padding: 0 7px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pk-left-score,
            .pk-right-score {
                font: 800 0.8em Lato;
                color: white;
            }
        }

        // Utility CSS
        .fixed-position-wet-emoji {
            position: fixed;
        }

        .absolute-position-wet-emoji {
            position: absolute;
        }
    }

    .dual-pk-countdown-container {
        height: 30px;
        width: 100px;
        position: absolute;
        top: -30px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        // z-index: 30;
        background: rgba(0, 0, 0, 0.7);

        .pk-icon-container {
            display: flex;
            align-items: center;

            .pk-icon {
                height: 20px;
            }

            .break-label {
                font: 500 0.9em Lato;
                color: white;
            }
        }

        .dual-pk-countdown {
            font: 600 1em Lato;
            color: white;
        }
    }
}

// Utility CSS
.scale-animation {
    animation: scaleUpDown 0.5s ease-in-out;
}

@keyframes scaleUpDown {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes miniScaleUpDown {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}