#private-call-pre-join-dialog {
    height: 100%;
    overflow: auto;
    background-image: url("../../../../assets/background/private-call/videocall-prejoin-layout.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .close-button-container {
        padding: 15px;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;

        .close-button {
            color: white;
        }
    }

    .padding-container {
        padding: 60px 80px;

        .pre-join-layout-container {
            max-width: 280px;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;

            .participants-container {
                display: flex;
                justify-content: center;

                .callee-container {
                    position: relative;
                    left: 5px;
                    z-index: 1;

                    .callee-avatar {
                        height: 50px;
                        width: 50px;
                    }
                }

                .caller-container {
                    position: relative;
                    right: 5px;

                    .caller-avatar {
                        height: 50px;
                        width: 50px;
                    }
                }
            }

            .header {
                text-align: center;
                font: 700 2em Lato;
                color: white;
            }

            .description-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                color: white;

                li {
                    font: 400 0.9em/1.5 Lato;
                }

                .yellow {
                    color: yellow;
                }
            }

            .cta-subheader {
                font: italic 700 1em Lato;
                color: white;
            }

            .cta-text {
                padding-top: 5px;
                font: italic 500 0.9em Lato;
                color: white;
            }

            .join-private-button {
                width: 100%;
                margin: auto;
                padding: 10px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                color: white;
                background: linear-gradient(90.38deg, #ffa927 5.04%, #ff409c 70.45%, #9534b4 119.21%);
            }

            .top-up-button {
                width: calc(100% - 2px);
                margin: auto;
                padding: 10px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid white;
                border-radius: 25px;
                cursor: pointer;
                color: white;
            }
        }

        .low-balance-layout-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .coin-icon-container {
                display: flex;
                justify-content: center;

                .coin-icon {
                    height: 50px;
                }
            }

            .header {
                text-align: center;
                font: 700 1.2em/1.5 Lato;
                color: white;
            }

            .description {
                text-align: center;
                font: 400 0.9em/1.5 Lato;
                color: white;
            }

            .top-up-button {
                width: 100%;
                margin-top: 50px;
                padding: 10px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid white;
                border-radius: 25px;
                cursor: pointer;
                color: white;
            }
        }
    }
}

// Utility CSS
.max-width-container {
    max-width: 360px;
}