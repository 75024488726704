@import "../../../../global-style.scss";

#gaming-card-shared-subcomponent {
    cursor: pointer;

    .gaming-card-container {
        width: 100%;
        position: relative;
        padding-top: 147%;
        border-radius: 10px;

        .gaming-image-container {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            border-radius: inherit;
            z-index: 10;

            .gaming-image {
                height: inherit;
                width: 100%;
                object-fit: cover;
                border-radius: inherit;
            }
        }
    }
}