@import "../../../../global-style.scss";

#tip-request-dialog {
    max-width: 300px;

    .padding-container {
        padding: 50px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .tip-image-container {

            .tip-image {
                width: 200px;
            }
        }

        .header {
            text-align: center;
            font: 600 1.2em Lato;
        }

        .description {
            text-align: center;
            font: 400 1em Lato;
        }

        .accept-button {
            height: 40px;
            width: 90%;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }

        .reject-button {
            height: 40px;
            width: 90%;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1em Lato;
        }
    }
}