@import "../../../../../global-style.scss";

#octopus-navbar-container {
    // height: 61px;
    padding-bottom: calc(env(safe-area-inset-bottom)); // For mobile devices
    display: flex;
    justify-content: center;

    a {
        text-decoration: none;
        color: inherit;
    }

    .octopus-navbar {
        // width: 95%;
        width: fit-content;
        max-width: 440px;
        position: fixed;
        bottom: 0;
        margin-bottom: env(safe-area-inset-bottom);
        border-radius: 50px;
        box-shadow: 0px 9px 31px 0px rgba(0, 0, 0, 0.56);
        overflow: hidden;
        z-index: 22;
        background-color: $octopus-navbar-color;

        ul.octopus-navbar-list {
            width: calc(100% - 20px);
            margin-bottom: 0;
            margin-top: 0;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            list-style: none;

            li {
                max-width: 70px;
                position: relative;
                flex: 1;

                a {
                    padding: 0.3em 0.6em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column-reverse;
                    font: 500 1.5em/1.4 Lato;

                    &:hover {
                        // color: $sugarbook-main-color;
                    }

                    span {
                        width: 30px;
                        text-align: center;
                        font: 500 10px Lato;
                    }
                }

                // Animation
                &::before,
                &::after {
                    height: 100%;
                    width: 20px;
                    content: "";
                    position: absolute;
                    top: 25px;
                    transition: top 0.3s;
                }

                &.active::before {
                    left: -10px;
                    border-radius: 0 0 30px 0;
                }

                &.active::after {
                    right: -10px;
                    border-radius: 0 0 0 30px;
                }

                &.active::before,
                &.active::after {
                    top: 1px;
                    background-color: $octopus-navbar-color;
                }

                &.active a::before,
                &.active a::after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    background-color: $sugarbook-main-color;
                }

                &.active a::before {
                    height: 100%;
                    width: calc(100% - 20px);
                    left: 10px;
                    border-radius: 30px 30px 0 0;
                }

                &.active a::after {
                    height: 19px;
                    width: 70px;
                    // left: -10px;
                    bottom: 0;
                }

                &.active a {
                    color: $octopus-navbar-color;
                }
            }
        }
    }
}