@import "../../../../global-style.scss";

#redirect-from-old-app-dialog {
    height: 620px;
    max-width: 350px;
    display: flex;
    align-items: flex-end;
    background: url("../../../../assets/background/landing/redirect-from-old-pwa.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .dialog-header {
        // width: calc(100% - 30px);
        padding: 25px 0;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
                color: white;
            }
        }
    }

    .padding-container {
        padding: 50px 40px 70px 40px;
        display: flex;
        flex-direction: column;
        // justify-content center;
        align-items: center;
        gap: 70px;

        .redirect-top-container {
            display: flex;
            flex-direction: column;
            // gap: 20px;

            .redirect-title {
                font: 600 2.5em Lato;
                color: white;
            }

            .redirect-subtitle {
                font: 500 1em Lato;
                color: white;
            }

            .redirect-description {
                font: 500 0.9em/1.4 Lato;
                color: white;
            }
        }

        .unordered-list-container {
            width: 100%;
            // padding: 0 0 0 10px;

            .unordered-title {
                font: 600 0.9em Lato;
                color: white;
            }

            .unordered-list {
                margin-top: 5px;
                padding-left: 24px;
                display: flex;
                flex-direction: column;
                gap: 5px;
                font: 500 0.8em Lato;
                color: white;
            }
        }

        .continue-button {
            width: 100%;
            padding: 15px 0;
            border-radius: 50px;
            text-align: center;
            font: 600 1em Lato;
            background-color: white;
            color: $sugarbook-main-color;
        }
    }
}