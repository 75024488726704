#live-empty-list-subcomponent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty-live-list-image-container {

        .empty-live-list-image {
            width: 300px;
        }
    }

    .title {
        margin-top: 15px;
        margin-bottom: 10px;
        padding: 0 50px;
        text-align: center;
        font: 600 1.6em Lato;
    }

    .description {
        margin-top: 15px;
        padding: 0 20px;
        text-align: center;
        font: 400 1.2em Lato;
        color: #7C7C7C;
    }
}