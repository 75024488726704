@import "../../../../global-style.scss";

#check-in-streak-dialog {
    width: 260px;

    .max-width-container {
        max-width: 260px;

        .padding-container {
            padding: 20px 20px 30px 20px;
            display: flex;
            flex-direction: column;

            .header {
                padding-bottom: 10px;
                text-align: center;
                font: 600 1.5em Lato;

                .days-label {
                    font: 600 1.7em Lato;
                    color: #FF3767;
                }
            }

            .subheader {
                padding-bottom: 20px;
                text-align: center;
                font: 400 1em Lato;
            }

            .exp-label {
                padding-bottom: 5px;
                text-align: center;
                font: italic 600 3em Lato;
                color: #FF3767;
            }

            .exp-icon-container {
                height: 25px;
                width: 60px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                box-shadow: 0px 2px 3px rgb(133 0 0 / 40%);
                font: 600 1em Lato;
                background: linear-gradient(90deg, rgba(245, 187, 25, 1) 0%, rgba(255, 55, 103, 1) 100%);
                color: white;
            }

            .description {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
                text-align: center;
                font: 400 0.9em 1.5 Lato;
            }

            .close-button {
                height: 40px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }
        }
    }
}