#video-call-video-call-tab-subcomponent {
    height: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        height: 100%;
        width: 100%;
        max-width: 1000px;

        .header-container {
            height: 60px;
            display: flex;
            align-items: center;
            background-color: rgb(250, 250, 250);

            .header {
                padding-left: 1em;
                font-size: 23px;
                font-weight: 500;
            }
        }

        .video-call-history-list-container {
            height: calc(100% - 60px);
            overflow: auto;

            >div {
                height: 100%;

                .infinite-scroller-container {}
            }
        }
    }
}

@media (max-width: 720px) {
    #inbox-video-call-tab-subcomponent {

        .recent-list-container {
            height: calc(100% - 167px);
        }
    }
}