#leaderboard-livestreaming-tab-subcomponent {
    height: calc(100% - 48px);

    .tab-container {
        height: 100%;

        .swipeable-container {
            height: calc(100% - 48px);
        }

        .embla {
            height: calc(100% - 48px);
            width: 100%;
            overflow-x: hidden;

            .embla__container {
                height: 100%;
                display: flex;

                .embla__slide {
                    flex: 0 0 100%;
                    min-width: 0;
                }
            }
        }
    }
}