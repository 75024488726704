@import "../../../../global-style.scss";

#private-call-pre-join-sugarbaby-warning-dialog {

    .padding-container {
        max-width: 250px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        border-radius: 25px;
        background-color: white;

        .warning-image-container {
            display: flex;
            justify-content: center;

            .warning-image {
                width: 180px;
                max-height: 120px;
            }
        }

        .header {
            text-align: center;
            font: 600 1.4em Lato;
        }

        .text {
            text-align: center;
            font: 400 1em Lato;

            .private-room-icon {
                height: 20px;
                position: relative;
                top: 5px;
            }
        }

        .close-button {
            height: 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}

.border-gradient {
    padding: 10px;
    border-radius: 15px;
    background: linear-gradient(90deg, #ffa927, #ff409c 70%, #9534b4) padding-box, linear-gradient(90deg, #ffa927, #ff409c 70%, #9534b4) border-box;
    background-size: 600% 600%;
    animation: luminousGradientPosition 1s ease infinite;
    -webkit-animation: luminousGradientPosition 1s ease infinite;
}