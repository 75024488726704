@import "../../../../../global-style.scss";

#search-filter-location-subcomponent {

    .filter-padding-container {
        padding: 5px 20px;
        display: flex;
        align-items: center;

        .pin-icon {
            color: #676767;
        }

        .location-form-container {
            height: 40px;
            width: 100%;
            padding-left: 5px;
            padding-right: 20px;
            position: relative;
            display: flex;
            align-items: center;

            .autocomplete-wrapper {
                width: 100%;
                opacity: 0;

                .form-field {
                    width: 100%;
                }
            }

            .overlay-form-field-container {
                height: calc(100% - 3px);
                width: 80%;
                position: absolute;
                top: 0;
                padding-top: 3px;
                background: white;

                .overlay-form-field {
                    height: 100%;
                    width: 100%;

                    .MuiInput-root {
                        height: 100%;
                        font: 600 1em Lato;
                        // color: $sugarbook-main-text-color;

                        .MuiButtonBase-root {
                            padding-right: 0;
                        }
                    }
                }
            }

            .full-width {
                width: 100%;
            }

            .search-icon {
                color: #676767;
            }
        }
    }

    .reload-location-container {
        height: 35px;
        padding: 5px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        .reload-icon {
            font-size: 20px;
            color: $sugarbook-main-color;
        }

        .reload-location-label {
            font: 600 1em Lato;
            color: $sugarbook-main-color;
        }
    }
}

// Google Maps CSS
// To ensure that if is infront of dialog
// Dialog z-index is 1299
.pac-container {
    z-index: 1300;
}

// Utility CSS
.locked {
    cursor: not-allowed;
}