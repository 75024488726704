#live-tab-empty-list-subcomponent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        text-align: center;
        font: 600 1.2em Lato;
    }
}