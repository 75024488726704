@import "../../../../global-style.scss";

#notification-permission-prompt-dialog {
    max-width: 330px;

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                width: 25px;
            }
        }
    }

    .padding-container {
        padding: 0px 15px 30px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .notification-permission-icon-container {
            display: flex;

            .notification-permission-icon {
                width: 275px;
            }
        }

        .geolocation-prompt-title {
            text-align: center;
            font: 600 1.3em Lato;
        }

        .geolocation-prompt-desc {
            text-align: center;
            font: 500 0.9em Lato;
            color: #7C7C7C;
        }

        .buttons-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .allow-button {
                width: 90%;
                padding: 14px 0;
                border-radius: 25px;
                text-align: center;
                cursor: pointer;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .cancel-button {
                width: 200px;
                padding: 15px 0;
                text-align: center;
                cursor: pointer;
                font: 600 1em Lato;
            }
        }

        .notification-note {
            text-align: center;
            font: italic 500 0.7em Lato;
            color: #7C7C7C;
        }
    }
}