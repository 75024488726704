#search-page {
    height: 100%;
    overflow: auto;

    .max-width-container {
        max-width: 1300px;
        margin: 0 auto;

        .padding-container {
            height: calc(100% - 55px);
            padding: 0 10px;
            display: flex;
            flex-direction: row;
            gap: 10px;

            .search-body {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5px;

                .set-current-location-container {
                    width: 100%;
                }
            }
        }
    }

    .add-height {
        height: 100%;
    }
}

// Responsive Design
@media (min-width: 1300px) {
    #search-page {

        .max-width-container {
            // border-left: 1px solid #e5e7eb;
            // border-right: 1px solid #e5e7eb;
        }
    }
}