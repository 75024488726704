#chat-section-gift-element-subcomponent {
    width: fit-content;
    max-width: 90%;
    display: flex;

    .profile-picture-container {
        width: 45px;
        min-width: 45px;

        .profile-picture {
            height: 30px;
            width: 30px;
        }
    }

    .chat-info-container {
        width: fit-content;
        max-width: calc(100% - 45px - 50px);
        // display: flex;
        flex-direction: column;
        gap: 3px;

        // .user-info-container {
        //     display: flex;
        //     align-items: center;
        //     gap: 7px;

        .livestreaming-achievement-badge-container {
            // display: flex;
            // align-items: center;
            // gap: 3px;
            margin-right: 3px;

            .livestreaming-achievement-badge {
                height: 13px;
            }
        }

        .username {}

        .levelling-badge-container {
            // display: flex;
            margin: 0 3px;

            .levelling-badge {
                height: 13px;
                position: relative;
                top: 2px;
            }
        }

        .sent-text {
            margin-right: 3px;
        }

        .gift-name {}

        .gift-diamond-count {
            display: flex;
            gap: 2px;
            font: 600 0.9em Lato;
            color: #00ff75;

            .diamond-icon-container {
                display: flex;
                align-items: center;

                .diamond-icon {
                    height: 13px;
                }
            }
        }

        .recipient-username-container {
            margin-left: 3px;
        }
    }

    .gift-icon-container {
        width: 50px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .gift-icon {
            width: 100%;
            max-height: 100%;
        }
    }
}