#one-on-one-video-call-dialog {
    height: 100%;

    .top-bar-container {
        height: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .close-icon-container {
            margin-left: 10px;

            .close-icon {
                cursor: pointer;
                font-size: 30px;
            }
        }
    }

    .dialog-body-container {
        height: calc(100% - 60px);
        overflow: auto;

        .first-section-container {
            display: flex;
            flex-direction: column;

            .first-section-image {
                width: 100%;
            }

            .first-section-clickable-image {
                width: 100%;
                cursor: pointer;
            }
        }

        .tab-container {

            .tab-image {
                width: 100%;
            }
        }

        .bottom-section-container {
            display: flex;
            flex-direction: column;

            .bottom-section-clickable-image {
                width: 100%;
            }
        }
    }
}