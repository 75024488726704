@import "../../../../global-style.scss";

#unfollow-user-dialog {
    max-width: 320px;

    .padding-container {
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        .profile-photo {
            height: 70px;
            width: 70px;
        }

        .unfollow-description {
            text-align: center;
            font: 500 1.1em/1.5 Lato;
        }

        .buttons-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .unfollow-button {
                height: 40px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .cancel-button {
                height: 40px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                font: 600 1.1em Lato;
                background-color: white;
                color: $sugarbook-main-color;
            }
        }
    }
}