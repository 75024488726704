#streamer-center-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .max-width-container {
        height: 100%;
        width: 100%;
        max-width: 700px;
        display: flex;
        border-radius: 25px;

        .tab-container {
            height: 100%;
            width: 100%;

            .embla {
                height: calc(100% - 48px);
                width: 100%;
                overflow-x: hidden;

                .embla__container {
                    height: 100%;
                    display: flex;

                    .embla__slide {
                        flex: 0 0 100%;
                        min-width: 0;
                    }
                }
            }
        }
    }
}