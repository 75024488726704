@import "../../../../global-style.scss";

#details-signup-bedroom-fantasies-details-subcomponent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-container {
        height: calc(100% - 100px - 10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .bedroom-fantasies-details-label {
            text-align: center;
            font: 600 1.5em Lato;
            color: white;
        }

        .bedroom-fantasies-details-description {
            padding: 0 20px;
            text-align: center;
            color: white;
        }

        .bedroom-fantasies-option-label {
            color: rgba(255, 255, 255, 0.4);
        }

        .public-private-bedroom-fantasies-container {
            width: 100%;

            .public-bedroom-fantasies-container {
                margin: 20px 20px 0 20px;
                padding: 10px 20px 10px 20px;
                flex: 1;
                border: 3px solid transparent;
                border-radius: 15px;
                cursor: pointer;
                background: rgba(255, 255, 255, 0.4);
                transition: all 0.3s ease-in-out;

                .public-label-container {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .public-label {
                        color: white;
                    }

                    .public-description {
                        font: italic 500 0.9em Lato;
                        color: white;
                    }
                }

                .empty-public-tags-container {
                    width: 100%;
                    margin: 10px 0;
                    padding: 15px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px dashed rgba(255, 255, 255, 0.4);
                    border-radius: 10px;
                    color: white;
                }

                .selected-public-tags-container {
                    margin: 10px 0;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    color: white;

                    .public-tags-container {
                        width: fit-content;
                        padding: 7px 20px;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        border-radius: 50px;
                        font: 600 1em Lato;
                        background: white;
                        color: $sugarbook-main-color;

                        .remove-selected-fantasy-icon {
                            cursor: pointer;
                            font-size: 15px;
                        }
                    }
                }
            }

            .private-bedroom-fantasies-container {
                margin: 20px 20px 0 20px;
                padding: 10px 20px 10px 20px;
                flex: 1;
                border: 3px solid transparent;
                border-radius: 15px;
                cursor: pointer;
                background: rgba(255, 255, 255, 0.4);
                transition: all 0.3s ease-in-out;

                .private-label-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .private-label {
                        color: white;
                    }

                    .private-lock-icon {
                        font-size: 15px;
                        color: white;
                    }

                    .private-description {
                        font: italic 500 0.9em Lato;
                        color: white;
                    }
                }

                .empty-private-tags-container {
                    width: 100%;
                    margin: 10px 0;
                    padding: 15px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px dashed rgba(255, 255, 255, 0.4);
                    border-radius: 10px;
                    color: white;
                }

                .selected-private-tags-container {
                    margin: 10px 0;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    color: white;

                    .private-tags-container {
                        width: fit-content;
                        padding: 7px 20px;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        border-radius: 50px;
                        font: 600 1em Lato;
                        background: white;
                        color: $sugarbook-main-color;

                        .remove-selected-fantasy-icon {
                            cursor: pointer;
                            font-size: 15px;
                        }
                    }
                }
            }

            // Utility CSS
            .selected-fantasy {
                border-color: white;
            }
        }

        .bedroom-fantasies-body-container {
            max-width: 820px;
            padding: 5% 20px;
            display: flex;
            // justify-content: center;
            flex-wrap: wrap;
            gap: 13px;

            .bedroom-fantasies-item {
                // width: 170px;
                padding: 7px 20px;
                border: 2px solid rgba(255, 255, 255, 0.4);
                border-radius: 50px;
                text-align: center;
                cursor: pointer;
                font: 600 1em Lato;
                color: white;
                transition: all 0.3s ease-in-out;
            }

            .selected {
                color: $sugarbook-main-color;
                background: white;
            }
        }
    }

    .bottom-container {
        height: 100px;
        width: calc(100% - 40px);
        padding: 10px 20px 0 20px;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .continue-button {
            padding: 17px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .full-width {
        width: calc(100% - 160px);
    }

    .partial-width {
        width: 65%;
    }

    .fix-width {
        width: 150px;
    }

    .fit-content {
        width: fit-content;
    }

    .horizontal {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

// Response Design
@media (min-width: 720px) {
    #details-signup-bedroom-fantasies-details-subcomponent {

        .top-container {

            .bedroom-fantasies-details-label {
                font: 600 2em Lato;
            }
        }
    }
}