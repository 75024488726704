#streamer-center-more-info-tab-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .header {
            padding-bottom: 10px;
            font: 600 1.8em Lato;
        }

        .text {
            font: 400 1em Lato;
        }

        .buttons-list-container {
            width: 100%;
            padding-top: 20px;
            margin-bottom: 30px;

            .button-container {
                height: 50px;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 25px;
                text-decoration: none;
                cursor: pointer;
                background-color: rgb(245, 241, 241);
                color: black;

                .button-label {
                    padding-left: 20px;
                    font: 500 1.2em Lato;
                }

                .suffix {
                    padding-right: 20px;
                    display: flex;
                }
            }
        }
    }
}