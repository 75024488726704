#blocked-members-empty-list-subcomponent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .blocked-members-icon-container {

        .blocked-members-icon {
            width: 80px;
        }
    }

    .no-blocked-text {
        margin-top: 15px;
        margin-bottom: 30px;
        padding: 0 20px;
        text-align: center;
        font: 500 1.3em/1.3 Lato;
        color: #7c7c7c;
    }
}