#overview-profile-info-subcomponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .profile-photo-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .default-circular-progress-bar {
            height: 180px !important;
            width: 180px !important;
            stroke: #ebebeb;
            color: #ebebeb;
        }

        .circular-progress-bar {
            height: 180px !important;
            width: 180px !important;
            position: absolute;
            bottom: 0;
            transform: rotate(-270deg) !important;
            color: #FFC700;
        }

        .profile-photo {
            height: 160px;
            width: 160px;

        }

        .profile-photo-absolute {
            position: absolute;
            bottom: 5px;
        }

        .profile-completed-percentage-container {
            padding: 5px 20px;
            position: absolute;
            bottom: -8px;
            border-radius: 50px;
            cursor: pointer;
            font: 600 1.2em Lato;
            background: #333333;
            color: white;
        }
    }

    .username-container {
        display: flex;
        align-items: center;
        gap: 3px;

        .name {
            font-size: 1.4em;
            font-weight: bold;
            color: rgb(51, 51, 51);
        }

        .premium-mini-badge {
            height: 15px;
        }

        .username-skeleton {
            height: 27px;
            width: 100px;
        }
    }

    .verified-profile-badge-container {
        display: flex;

        .verified-profile-badge {
            height: 20px;
        }
    }

    .badge-container {
        display: flex;
        gap: 5px;

        .premium-badge-container {

            .premium-badge {
                height: 20px;
            }
        }

        .levelling-badge-container {

            .levelling-badge {
                height: 20px;
                display: block;
            }
        }

        .special-badges-container {
            display: flex;
            gap: 3px;

            .special-badges {
                height: 20px;
                cursor: pointer;
            }
        }

        .badge-skeleton {
            height: 20px;
            width: 60px;
        }
    }
}