@import "../../../../global-style.scss";

#camera-microphone-settings-dialog {
    width: 300px;

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .advanced-camera-settings-switch-container {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .mobile-camera-toggle-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .choose-camera-label {
                font: 600 1em Lato;
            }

            .toggle-camera-button-group-container {
                width: 100%;
                display: flex;
                justify-content: center;

                .toggle-camera-button {
                    width: 50%;
                }

                .toggle-camera-button-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    .front-camera-label {
                        font: 600 0.9em Lato;
                    }

                    .rear-camera-label {
                        font: 600 0.9em Lato;
                    }
                }
            }
        }

        .advanced-camera-settings-container {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .camera-settings-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .camera-settings-label {}

                .select-camera-container {}
            }

            .microphone-settings-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .microphone-settings-label {}

                .select-microphone-container {}
            }
        }

        .save-button {
            padding: 13px 0;
            border-radius: 25px;
            cursor: pointer;
            text-align: center;
            font: 500 1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}