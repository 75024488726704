#view-tor-description-dialog {

    .max-width-container {
        max-width: 250px;
    }

    .padding-container {
        padding: 20px;

        .tor-name {
            font: 600 1.1em Lato;
        }

        .tor-description {
            font: 500 0.9em Lato;
        }
    }
}