#livestream-video-player-subcomponent,
#live-tc-player {
    position: relative;
    overflow: hidden;
    background-color: black;

    .dual-pk-gift-animation-overlay-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
    }

    .pk-select-contestant-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
    }
}

.live-video {
    width: 100%;
    display: block;

    >video {
        width: 100%;
        display: block;
        // padding-bottom: 56.25%;
        // object-fit: contain;
        // object-fit: cover;
    }
}

// Utility CSS
.desktop-video-player {
    height: calc(100% - 20px);
    margin: 10px 0;
    border-radius: 15px;
}

.mobile-video-player {
    height: 100%;
}

.co-anchor-mobile-video-player {
    height: fit-content;
}

.co-anchor-video-covered {
    max-height: 430px;

    >video {
        max-height: 430px;
    }
}

.maintain-aspect-ratio {
    height: 100%;
    padding-bottom: 56.25%;

    >video {
        height: 100%;
        padding-bottom: 56.25%;
    }
}


.video-covered {
    height: 100%;
    object-fit: cover;

    >video {
        height: 100%;
        object-fit: cover;
    }
}


// Responsive Design
@media (max-width: 720px) {
    #livestream-video-player-subcomponent {

        .live-video {
            // object-fit: cover;
        }
    }
}