@import "../../../../global-style.scss";

#pk-select-contestant-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;

    .mobile-pk-left-contestant-container,
    .mobile-pk-right-contestant-container {
        height: calc(100% - 10px);
        width: calc(50% - 10px);
        border: 5px solid transparent;
        transition: all 0.2s;
    }

    .mobile-dual-pk-reduced-height {
        height: calc(100% - 10px - 20px);
    }

    .desktop-pk-left-contestant-container {
        height: calc(100% - 10px);
        width: calc(50% - 10px);
        border: 5px solid transparent;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        transition: all 0.2s;
    }

    .desktop-pk-right-contestant-container {
        height: calc(100% - 10px);
        width: calc(50% - 10px);
        border: 5px solid transparent;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        transition: all 0.2s;
    }

    .dual-pk-contestant-1-container {
        width: calc(50% - 20px);
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        color: white;
        background: rgba(51, 51, 51, 0.4);
    }

    .dual-pk-contestant-2-container {
        width: calc(50% - 20px);
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
        color: white;
        background: rgba(51, 51, 51, 0.4);
    }

    .dual-pk-contestant-push-up {
        bottom: 20px;
    }

    .desktop-dual-pk-contestant-1-container,
    .desktop-dual-pk-contestant-2-container {
        bottom: 100px;
    }

    .dual-pk-contestant-win-badge {
        display: flex;
        animation: medalScaleUpDown 2s infinite;

        .pk-medal-icon {
            height: 15px;
        }
    }

    .dual-pk-contestant-profile-photo-container {
        display: flex;

        .dual-pk-contestant-profile-photo {
            height: 20px;
            width: 20px;
        }

        .desktop-pk-contestant-profile-photo {
            height: 30px;
            width: 30px;
        }
    }

    .dual-pk-contestant-username {
        width: calc(100% - 30px - 60px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font: 500 0.7em Lato;
    }

    .dual-pk-contestant-2-username {
        text-align: right;
    }

    .desktop-dual-pk-contestant-username {
        font: 500 1em Lato;
    }

    .pk-contestant-selected {
        border: 5px solid #e8b850;
    }

    .selected-info-modal {
        width: 100%;
        position: absolute;
        top: 100px;
        display: flex;
        justify-content: center;
        pointer-events: none;
        animation: fadeInOut 3s infinite;

        .selected-info-container {
            width: 40%;
            max-width: 180px;
            padding: 20px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            border-radius: 20px;
            background-color: #ffffffcc;

            .selected-info-label {
                text-align: center;
                font: 500 1em Lato;
                color: $sugarbook-main-color;

                .username-bold {
                    font-weight: 600;
                }
            }
        }
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes medalScaleUpDown {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}