#inbox-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    align-items: center;
    gap: 20px;

    .desktop-banner-container {}

    .max-width-container {
        height: 50%; // Can be any value, it will be overridden by flex-grow
        width: calc(100% - 40px);
        max-width: 1000px;
        margin-top: 20px; // Remove if desktop banner is ready
        margin-bottom: 20px;
        display: flex;
        flex-grow: 1;
        border: 1px solid #e5e7eb;
        border-radius: 25px;
        background-color: white;

        .tab-container {
            height: 100%;
            width: 400px;
            display: flex;
            flex-direction: column;

            .mobile-banner-container {
                width: 100%;
                display: none;
            }

            .embla {
                height: 100%;
                width: 100%;
                overflow-x: hidden;

                .embla__container {
                    height: 100%;
                    display: flex;

                    .embla__slide {
                        flex: 0 0 100%;
                        min-width: 0;
                    }
                }
            }
        }

        .conversation-container {
            width: calc(100% - 400px);
            display: flex;
            align-items: center;
            border-left: 1px solid #e5e7eb;

            .background {
                width: calc(100% - 40px);
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}

// Responsive Design
@media (max-width: 1000px) {
    #inbox-page {

        .max-width-container {

            .tab-container {
                width: 400px;
            }

            .conversation-container {
                width: calc(100% - 400px);
            }
        }
    }
}

@media (max-width: 850px) {
    #inbox-page {

        .max-width-container {
            width: calc(100% - 40px - 2px);

            .tab-container {
                width: 100%;
            }

            .conversation-container {
                display: none;
            }
        }
    }
}

@media (max-width: 720px) {
    #inbox-page {

        .desktop-banner-container {
            display: none;
        }

        .max-width-container {
            height: 100%;
            width: 100%;
            margin: 0px;
            border: none;
            border-radius: 0px;

            .tab-container {
                width: 100%;

                .mobile-banner-container {
                    display: block;
                }

                .swipeable-container {
                    // height: calc(100% - 48px - 106px);
                }
            }

            .conversation-container {
                display: none;
            }
        }
    }
}