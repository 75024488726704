#top-bar-network-status-subcomponent {
    width: 115px;
    display: flex;
    align-items: center;

    .signal-icon {
        color: white;
    }

    .upload-ping-text {
        font: 600 0.8em Lato;
        color: white;
    }

    .up-arrow-icon {
        color: white;
    }

    .download-ping-text {
        font: 600 0.8em Lato;
        color: white;
    }

    .down-arrow-icon {
        color: white;
    }

    // Utility CSS
    .signal-good {
        color: #42FF00;
    }

    .signal-poor {
        color: #FFFF00;
    }

    .signal-bad {
        color: #FF0000;
    }
}