#interactable-overlay-chat-input-subcomponent {
    height: 90px;
    padding: 0 10px;
    display: flex;
    border-top: 1px solid white;
    overflow-x: auto;

    .more-gifts-container {
        width: 60px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .more-gifts {
            height: 35px;
        }
    }

    .input-container {
        width: calc(100% - 60px);
        min-width: 235px;
        padding-left: 10px;
        display: flex;
        align-items: center;

        // Custom MUI Text Field CSS
        .input-field {
            width: 100%;
            padding: 10px 0 6px 25px;
            border-radius: 25px;
            background-color: rgba(239, 239, 239, 0.3);

            input {
                color: white;

                &::placeholder {
                    opacity: 1;
                }
            }
        }
    }

    .send-icon-container {
        padding-left: 10px;
        display: flex;
        align-items: center;

        .send-icon {
            height: 35px;
        }
    }

    .emoji-list-container {
        margin-left: 10px;
        display: flex;
        align-items: center;
        gap: 10px;

        .coin-icon-container {
            display: flex;
            cursor: pointer;

            .coin-icon {
                height: 45px;
            }
        }

        .blow-kiss-emoji-container {
            display: flex;
            cursor: pointer;

            .blow-kiss-emoji {
                height: 45px;
            }
        }

        .heart-eyes-emoji-container {
            display: flex;
            cursor: pointer;

            .heart-eyes-emoji {
                height: 45px;
            }
        }

        .thumbs-up-emoji-container {
            display: flex;
            cursor: pointer;

            .thumbs-up-emoji {
                height: 45px;
            }
        }

        .fire-emoji-container {
            display: flex;
            cursor: pointer;

            .fire-emoji {
                height: 45px;
            }
        }

        // Utility CSS
        .grey-emoji-icon {
            height: 45px;
            filter: grayscale(1);
            -webkit-filter: grayscale(1);
        }
    }
}