@import "../../../../global-style.scss";

#profile-rejected-info-dialog {

    .padding-container {
        padding: 40px 25px 25px 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        .profile-rejected-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .profile-rejected-icon-container {

                .profile-rejected-icon {
                    width: 75px;
                }
            }

            .profile-rejected-title {
                font: 600 1.2em Lato;
            }
        }

        .reason-container {
            display: flex;
            gap: 20px;

            .reason-title {
                width: 20%;
                font: 600 1.2em Lato;
            }

            .reason-description {
                width: 80%;
                color: #7C7C7C;
            }
        }

        .tips-container {
            display: flex;
            gap: 20px;

            .tips-title {
                width: 20%;
                font: 600 1.2em Lato;
            }


            .tips-description {
                width: 80%;
                color: #7C7C7C;
            }
        }

        .understand-button-container {
            width: 80%;
            margin-bottom: 20px;
            padding: 10px 0;
            border-radius: 25px;
            text-align: center;
            font: 600 1.2em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}