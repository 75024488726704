#video-call-profile-photo-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    background: rgb(33, 33, 33, 0.46);

    .profile-photo-overlay {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}