#upgrade-payment-card-details-subcomponent {

    .card-details-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .card-number-form-field-container {
            width: 100%;

            .input-container {
                width: 100%;

                .form-field {
                    width: 100%;
                }
            }
        }

        .second-row {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .label-container {
                display: flex;
                gap: 20px;

                .expiry-date-label {
                    flex: 2;
                }

                .cvv-label {
                    flex: 1;
                }
            }

            .form-container {
                display: flex;
                gap: 10px;

                .expiry-date-month-form-field-container {
                    flex: 1;

                    .form-field {
                        width: 100%;
                    }
                }

                .expiry-date-year-form-field-container {
                    flex: 1;

                    .form-field {
                        width: 100%;
                    }
                }

                .cvv-form-field-container {
                    flex: 1;
                }
            }
        }

        .third-row {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .label-container {}

            .form-container {
                display: flex;

                .first-name-form-field-container {
                    flex: 1;
                }

                .last-name-form-field-container {
                    flex: 1;
                }
            }
        }

        .country-form-field-container {
            width: 100%;

            .form-container {
                width: 100%;

                .dropdown-field {
                    width: 100%;
                }
            }
        }

        .country-error-label {
            text-align: center;
            color: red;
        }
    }
}

// Utility CSS
.select-output {
    display: flex;
    align-items: center;

    .flag-icon-container {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;

        .flag-icon {
            height: 25px;
            width: 25px;
        }
    }

    .country-name {
        padding-left: 10px;
        font: 500 1em Lato;
    }
}