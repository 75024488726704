@import "../../../../../global-style.scss";

#profile-view-floating-action-buttons-subcomponent {
    bottom: 15px; // Change to 75px if hunter mode is on
    display: flex;
    gap: 10px;

    .message-icon-container {
        height: 65px;
        width: 65px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
        cursor: pointer;
        background-color: white;

        .message-icon {
            width: 38px;
        }
    }

    .favorite-icon-container {
        height: 65px;
        width: 65px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
        cursor: pointer;
        background-color: white;

        .favorite-icon {
            width: 37px;
        }
    }

    .video-call-icon-container {
        height: 65px;
        width: 65px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
        cursor: pointer;
        background-color: white;

        .video-call-icon {
            width: 38px;
        }
    }

    .message-button-container {
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        // box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
        cursor: pointer;
        background-color: $sugarbook-main-color;

        .message-content-container {
            display: flex;
            align-items: center;
            gap: 5px;
            color: white;

            .message-icon {
                height: 13px;
            }
        }
    }

    .favorite-button-container {
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        // box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
        cursor: pointer;
        background-color: #F1F1F1;

        .favorite-content-container {
            display: flex;
            align-items: center;
            gap: 5px;
            color: white;

            .favorite-icon {
                height: 13px;
            }

            .favorite-label {
                white-space: nowrap;
                color: black;
            }
        }
    }

    .video-call-button-container {
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        // box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
        cursor: pointer;
        background-color: #F1F1F1;

        .video-call-content-container {
            display: flex;
            align-items: center;
            gap: 5px;
            color: white;

            .video-call-icon {
                height: 13px;
            }

            .video-call-label {
                white-space: nowrap;
                color: black;
            }
        }
    }
}

// Utility CSS
.push-up {
    bottom: 75px !important;
}

.fixed-position {
    width: 100%;
    position: fixed !important;
}

.flex-end {
    justify-content: flex-end;
    flex-grow: 1;
}

.center {
    justify-content: center;
}