@import "../../../../global-style.scss";

#deactivate-account-dialog {
    max-width: 340px;

    .padding-container {
        padding: 40px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .deactivate-image-container {

            .deactivate-image {
                width: 80px;
            }
        }

        .header {
            padding-top: 15px;
            text-align: center;
            font-size: 1.4em;
            font-weight: 600;
        }

        .text {
            padding-top: 15px;
            text-align: center;
            line-height: 1.2;
            font-size: 1em;
            font-weight: 500;
            color: #7C7C7C;
        }

        .confirm-button {
            height: 42px;
            width: 100%;
            margin-top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-transform: none;
            text-decoration: none;
            font-size: 1.1em;
            font-weight: 500;
            background-color: $sugarbook-main-color;
            color: white;
        }

        .cancel-button {
            height: 40px;
            width: 100%;
            margin-top: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $sugarbook-main-color;
            border-radius: 25px;
            text-transform: none;
            text-decoration: none;
            font-size: 1.1em;
            font-weight: 500;
            background-color: white;
            color: $sugarbook-main-color;
        }
    }
}