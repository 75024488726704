@import "../../../../global-style.scss";

#details-signup-terms-of-relationship-details-subcomponent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .terms-of-relationship-details-label {
            text-align: center;
            font: 900 2em Lato;
            color: white;

            .trade-mark-label {
                font: 800 0.4em Lato;
            }
        }

        .tor-description-label {
            padding: 0 10px;
            text-align: center;
            font: 500 0.9em Lato;
            color: white;

            .trade-mark-mini-label {
                font: 600 0.5em Lato;
            }
        }

        .tor-select-option-label {
            padding-top: 5px;
            font: 500 1em Lato;
            color: rgba(255, 255, 255, 0.5);
        }

        .tor-list-container {
            max-width: 820px;
            padding: 5% 20px;
            display: flex;
            // flex-direction: column;
            // justify-content: center;
            flex-wrap: wrap;
            gap: 10px;
            // overflow: auto;
            // mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
            // -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);
        }
    }

    .tor-description-container {
        height: 47px;
        width: 80%;
        min-height: 47px;
        padding: 10px 20px;
        border-radius: 10px;
        font-size: 13px;
        color: white;
        background: rgba(255, 255, 255, 0.2);
    }

    .bottom-container {
        height: 100px;
        width: calc(100% - 40px);
        padding: 10px 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .continue-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .skip-button {
            cursor: pointer;
            color: #988484;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }

        .tor-select-option-label {
            text-align: center;
            font: 500 1em Lato;
            color: rgba(255, 255, 255, 0.5);
        }
    }

    // Utility CSS
    .full-width {
        width: calc(100% - 160px);
    }

    .partial-width {
        width: 65%;
    }

    .fix-width {
        width: 150px;
    }

    .fit-content {
        width: fit-content;
    }

    .flex-start {
        justify-content: flex-start;
    }

    .justify-center {
        justify-content: center;
    }

    .tag-description-height {
        height: calc(100% - 100px - 10px - 67px);
    }

    .non-tag-description-height {
        height: calc(100% - 100px - 10px);
    }
}

// Response Design
@media (min-width: 720px) {
    #details-signup-terms-of-relationship-details-subcomponent {

        .top-container {

            .terms-of-relationship-details-label {
                font: 600 2em Lato;
            }

            .tor-description-label {
                font: 500 1em Lato;
            }
        }

        .tag-description-height {
            height: calc(100% - 100px - 10px - 67px);
        }

        .non-tag-description-height {
            height: calc(100% - 100px - 10px);
        }
    }
}