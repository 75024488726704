#profile-financial-information-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .financials-label {
            font: 600 1.5em Lato;
        }

        .financials-container {
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            border-radius: 20px;
            background: white;

            .financials-item-container {
                padding: 7px 15px;
                display: flex;
                align-items: center;
                gap: 7px;
                border: 1px solid #d8d4d4;
                border-radius: 50px;
                cursor: pointer;
                font: 600 1em Lato;
                background: #f0ecec;

                .lifestyle-budget-icon,
                .dating-budget-icon,
                .annual-income-icon,
                .networth-icon {
                    height: 20px;
                }
            }

            // Utility CSS
            .non-clickable {
                cursor: default;
            }
        }
    }
}

@media (max-width: 720px) {
    #profile-financial-information-subcomponent {

        .padding-container {

            .financials-container {

                .financials-item-container {
                    font: 600 0.9em Lato;
                }
            }
        }
    }
}