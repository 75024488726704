@import "../../../../global-style.scss";

#details-signup-annual-income-details-subcomponent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-container {
        height: calc(100% - 100px - 10px);
        overflow: auto;

        .annual-income-details-label {
            text-align: center;
            font: 900 2em Lato;
            color: white;
        }

        .annual-income-selector-container {
            padding-top: 50px;
            display: flex;
            gap: 10px;

            .fake-arrow-container {
                width: 50px;
            }

            .spacer-container {
                width: 20px;
            }

            .annual-income-container {
                height: 325px;
                width: 300px;
                display: flex;
                flex-direction: column;
                align-items: center;
                // gap: 30px;
                overflow-x: hidden;
                overflow-y: auto;
                scroll-behavior: smooth;
                -ms-overflow-style: none;
                /* IE and Edge */
                scrollbar-width: none;

                /* Firefox */
                /* Hide scrollbar for WebKit (Safari, Chrome) browsers */
                &::-webkit-scrollbar {
                    display: none;
                }

                mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
                -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);
            }

            .income-item {
                min-height: 65px;
                max-height: 65px;
                display: flex;
                align-items: center;
                cursor: pointer;
                font: 600 1.7em Lato;
                color: rgba(255, 255, 255, 0.4);
                transition: all 0.3s ease-in-out;
            }

            .selected {
                font: 600 2em Lato;
                color: white;
            }

            .arrow-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .arrow-icon {
                    cursor: pointer;
                    font-size: 50px;
                    color: white;
                }
            }

            // Utility CSS
            .reduce-width {
                width: 250px;
            }
        }
    }

    .bottom-container {
        height: 100px;
        width: calc(100% - 40px);
        padding: 10px 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .continue-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .skip-button {
            cursor: pointer;
            color: #988484;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .full-width {
        width: calc(100% - 160px);
    }

    .partial-width {
        width: 65%;
    }

    .fix-width {
        width: 150px;
    }

    .fit-content {
        width: fit-content;
    }
}

// Response Design
@media (min-width: 720px) {
    #details-signup-annual-income-details-subcomponent {

        .top-container {

            .annual-income-details-label {
                font: 600 2em Lato;
            }
        }
    }
}