#video-call-settings-tutorial-dialog {
    height: 100%;
    position: relative;
    background-color: rgba(51, 51, 51, 0.9);

    .header {
        height: 33px;
        padding: 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
                color: white;
            }
        }
    }

    .padding-container {
        height: calc(70% - 40px);
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .page-container {
            height: 21px;
            font: 400 1.1em Lato;
            color: white;
        }

        .live-title {
            font: 600 1.4em Lato;
            color: white;
        }

        .live-description {
            font: 500 1em Lato;
            color: white;
        }

        .live-background-container {
            height: 300px;
            width: 100%;
            display: flex;

            .arrow-back-icon-container {
                width: 10%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: white;
            }

            .live-background {
                width: 80%;
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
                object-fit: contain;
            }

            .arrow-forward-icon-container {
                width: 10%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: white;
            }
        }
    }

    .footer {
        width: calc(100% - 60px);
        position: absolute;
        bottom: 0;
        padding: 30px;
        display: flex;
        justify-content: flex-end;

        .done-button {
            cursor: pointer;
            font: 600 1.1em Lato;
            color: white;
        }
    }
}

// Utility CSS
.max-width-container {
    max-width: 400px;
}

// Responsive Design
@media (min-width: 720px) {
    #video-call-settings-tutorial-dialog {

        .footer {
            position: relative;
        }
    }
}