#profile-completion-page {
    height: 100%;

    .max-width-container {
        height: calc(100% - 10px);
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
        background-color: white;

        .padding-container {
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            .circular-progress-bar-container {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .default-circular-progress-bar {
                    height: 100px !important;
                    width: 100px !important;
                    stroke: #ebebeb;
                    color: #ebebeb;

                    .MuiCircularProgress-circle {
                        stroke-width: 1.5;
                    }
                }

                .circular-progress-bar {
                    height: 100px !important;
                    width: 100px !important;
                    position: absolute;
                    bottom: 0;
                    color: #FFC700;

                    .MuiCircularProgress-circle {
                        stroke-width: 1.5;
                    }
                }

                .profile-completion-percentage-label {
                    position: absolute;
                    font: 600 1.6em Lato;
                }
            }

            .profile-completion-title {
                text-align: center;
                font: 600 1.4em Lato;
            }

            .profile-completion-description {
                width: 250px;
                text-align: center;
                font: 500 1em Lato;
                color: #A2A2A2;
            }

            .buttons-list-container {
                width: 100%;
                max-width: 600px;
                padding-top: 20px;

                .button-container {
                    height: 50px;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 25px;
                    text-decoration: none;
                    cursor: pointer;
                    background-color: rgb(245, 241, 241);
                    color: black;

                    .button-label {
                        padding-left: 20px;
                        font: 600 1em Lato;

                        .trade-mark-label {
                            font: 600 0.5em Lato;
                        }
                    }

                    .suffix {
                        padding-right: 20px;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .individual-progress-container {
                            font: 500 1em Lato;
                            color: #A2A2A2;
                        }

                        .suffix-label {
                            padding-left: 5px;
                            font: 600 1em Lato;
                            color: rgb(113, 13, 13);
                        }
                    }
                }
            }
        }
    }
}

// Responsive Design
@media (min-width: 720px) {
    #profile-completion-page {

        .max-width-container {
            width: 80%;
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}