#details-signup-registration-navigation-bar-subcomponent {
    width: 0%;
    padding: 0px 50%;
    display: flex;
    justify-content: center;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    // gap: 20px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    /* Hide scrollbar for WebKit (Safari, Chrome) browsers */
    &::-webkit-scrollbar {
        display: none;
    }

    .navigation-item-container {
        min-width: 47px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;

        .navigation-item-icon-container {
            height: 55px;
            display: flex;
            align-items: center;

            .navigation-item-icon {
                font-size: 40px;
                color: white;
            }

            .registration-search-icon,
            .registration-income-icon,
            .registration-networth-icon,
            .registration-lifestyle-icon,
            .registration-relationship-status-icon,
            .registration-occupation-icon,
            .registration-dating-styles-icon,
            .registration-fantasies-icon,
            .registration-ethnicity-icon,
            .registration-smoke-icon,
            .registration-drink-icon,
            .registration-alcohol-icon,
            .registration-about-icon,
            .registration-looking-for-icon,
            .registration-livestreaming-icon,
            .registration-photo-icon {
                height: 15px;
                filter: brightness(0.5);
                opacity: 0.5;
            }
        }

        .active-index {
            transform: scale(1.3);
            transition: all 0.3s ease;

            .registration-search-icon,
            .registration-income-icon,
            .registration-networth-icon,
            .registration-lifestyle-icon,
            .registration-relationship-status-icon,
            .registration-occupation-icon,
            .registration-dating-styles-icon,
            .registration-fantasies-icon,
            .registration-ethnicity-icon,
            .registration-smoke-icon,
            .registration-drink-icon,
            .registration-alcohol-icon,
            .registration-about-icon,
            .registration-looking-for-icon,
            .registration-livestreaming-icon,
            .registration-photo-icon {
                height: 15px;
                filter: brightness(1);
                opacity: 1;
            }
        }

        .navigation-item-name {
            height: 15px;
            white-space: nowrap;
            font: 500 0.8em Lato;
            // letter-spacing: 1px;
            color: white;
        }

        .empty-navigation-item {
            height: 5px;
            width: 5px;
            border-radius: 50px;
            background-color: #ffffffa8;
        }
    }
}

// Utility CSS
.registration-navigation-bar-desktop-height {
    height: 150px;
}

.registration-navigation-bar-mobile-height {
    height: 70px;
}