#profile-dating-styles-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .dating-styles-label {
            font: 600 1.5em Lato;
        }

        .dating-styles-container {
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            border-radius: 20px;
            background: white;

            .dating-styles-item-container {
                padding: 7px 15px;
                border: 1px solid #d8d4d4;
                border-radius: 50px;
                cursor: pointer;
                font: 600 1em Lato;
                background: #f8f4f4;
            }

            // Utility CSS
            .non-clickable {
                cursor: default;
            }
        }
    }
}

@media (max-width: 720px) {
    #profile-dating-styles-subcomponent {

        .padding-container {

            .dating-styles-container {

                .dating-styles-item-container {
                    font: 600 0.9em Lato;
                }
            }
        }
    }
}