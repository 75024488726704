#profile-alcohol-preference-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .alcohol-preference-label {
            font: 600 1.5em Lato;
        }

        .alcohol-preference-container {
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            border-radius: 20px;
            background: white;

            .alcohol-item-container {
                padding: 4px 13px 4px 9px;
                display: flex;
                align-items: center;
                gap: 3px;
                border: 1px solid #d8d4d4;
                border-radius: 50px;
                cursor: pointer;
                background: #f0ecec;

                .alcohol-icon-container {
                    height: 25px;
                    display: flex;
                    align-items: center;

                    .alcohol-icon {
                        height: 100%;
                        filter: invert(0.8);
                    }

                    .whiskey-icon {
                        height: 80%;
                        filter: invert(0.8);
                    }
                }

                .alcohol-item {
                    font: 600 1em Lato;
                }
            }
        }

        // Utility CSS
        .non-clickable {
            cursor: default;
        }
    }
}

@media (max-width: 720px) {
    #profile-alcohol-preference-subcomponent {

        .padding-container {

            .alcohol-preference-container {

                .alcohol-item-container {

                    .alcohol-item {
                        font: 600 0.9em Lato;
                    }
                }
            }
        }
    }
}