#in-app-nofitication-element {

    .padding-container {
        display: flex;

        .profile-picture-container {
            width: 50px;
            display: flex;
            align-items: center;
        }

        .message-body-container {
            width: calc(100% - 50px - 30px);
            display: flex;
            flex-direction: column;
            gap: 5px;

            .username {
                font: 600 1em Lato;
                color: white;
            }

            .message-body {
                font: 500 0.8em Lato;
                color: white;
            }
        }

        .now-container {
            width: 30px;
            font: 500 0.7em Lato;
            color: white;
        }
    }
}