@import "../../../../../global-style.scss";

#navbar-navbar-mobile-view-subcomponent {
    height: 45px;
    // padding: 10px 0;
    display: none;
    justify-content: space-around;
    border-bottom: 1px solid lightgrey;

    .space-around {
        width: 100%;
        max-width: 1020px;
        padding: 0 12px;

        .mobile-view {
            height: 100%;
            display: flex;
            justify-content: space-between;

            .left-container {
                min-width: 50px;
                max-width: 100px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                // flex: 1;

                .sugarbook-logo-container {
                    display: flex;
                    align-items: center;

                    .sugarbook-logo {
                        height: 27px;
                        padding: 7px;
                    }
                }

                .burger-menu-button {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: left;
                    cursor: pointer;
                }

                .filter-icon-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 3px;
                    cursor: pointer;

                    .filter-icon {
                        width: 20px;
                    }

                    .filter-label {
                        font: 500 0.8em Lato;
                    }
                }

                .calendar-icon-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .calendar-icon {
                        width: 22px;
                    }
                }

                .back-icon-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .back-icon {
                        height: 15px;
                    }
                }

                .sb-coin-icon-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;

                    .sb-coin-icon {
                        width: 27px;
                    }
                }

                .diamond-icon-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .diamond-icon {
                        width: 33px;
                    }
                }
            }

            .middle-container {
                // width: calc(100% - 100px);
                display: flex;
                justify-content: center;
                flex-grow: 1;

                .sugarbook-logo-container {
                    display: flex;
                    align-items: center;

                    .sugarbook-logo {
                        height: 36px;
                        padding: 7px;
                    }
                }

                .profile-photo {
                    height: 30px;
                    width: 30px;
                }

                .middle-label {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    text-align: center;
                    font: 600 1.1em Lato;

                    .leaderboard-icon-container {
                        padding-right: 7px;
                        display: flex;

                        .leaderboard-icon {
                            width: 25px;
                        }
                    }

                    .livestreaming-achievement-badge-container {
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        .livestreaming-achievement-badge {
                            height: 13px;
                        }
                    }

                    .username {
                        // max-width: calc(100% - 40px - 20px - 66px);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font: 600 0.9em Lato;
                    }

                    .premium-mini-badge-container {
                        display: flex;
                        align-items: center;

                        .premium-mini-badge {
                            height: 13px;
                        }
                    }

                    .verified-profile-container {
                        display: flex;
                        align-items: center;

                        .verified-profile {
                            height: 20px;
                        }
                    }

                    .admin-badge-container {
                        height: 14px;
                        width: 50px;
                        padding: 3px 8px 3px 8px;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        border-radius: 25px;
                        background-color: rgba(205, 79, 79, .78);
                        color: white;

                        .person-icon {
                            font-size: 13px;
                        }

                        .admin-label {
                            display: flex;
                            align-items: center;
                            font: 500 0.7em Lato;
                        }
                    }

                    .verified-caller-container {
                        display: flex;
                        align-items: center;

                        .verified-caller {
                            height: 20px;
                        }
                    }

                    .special-badges-container {
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        .special-badges {
                            height: 20px;
                        }
                    }
                }
            }

            .right-container {
                min-width: 50px;
                // max-width: 100px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                // flex: 1;
                gap: 5px;

                .inbox-button-container {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .video-call-icon-container {
                        height: 40px;
                        width: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .video-call-icon {}
                    }
                }

                .games-button-container {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    .game-selection-button-container {
                        width: 43px;

                        .game-selection-button {
                            height: 35px;
                        }
                    }

                    .leaderboard-new-icon-container {

                        .leaderboard-new-icon {
                            height: 26px;
                        }
                    }
                }

                .leaderboard-new-icon-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .leaderboard-new-icon {
                        height: 26px;
                    }
                }

                .settings-icon-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .settings-icon {
                        height: 25px;
                    }
                }

                .info-icon-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .info-icon {
                        height: 25px;
                    }
                }

                .login-icon-button-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 3px;
                    cursor: pointer;

                    .login-icon-button {
                        display: flex;

                        .login-icon {
                            height: 20px;
                        }
                    }

                    .login-label {
                        width: 100%;
                        text-align: center;
                        font: 600 1em Lato;
                        color: $sugarbook-main-color;
                    }
                }

                .done-button {
                    font: 500 1.2em Lato;
                    cursor: pointer;
                    color: $sugarbook-main-color;
                }

                .sb-coin-icon-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;

                    .sb-coin-icon {
                        width: 27px;
                    }
                }

                .diamond-icon-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .diamond-icon {
                        height: 20px;
                    }
                }

                .calendar-icon-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .calendar-icon {
                        height: 20px;
                    }
                }
            }
        }
    }

    // Utility CSS
    .dropdown-logo {
        display: flex;
    }

    .no-decoration {
        text-decoration: none;
        color: #464646;
    }
}

// Utility CSS
.navbar-no-decoration {
    text-decoration: none;
    color: inherit;
}

.side-double-space {
    flex: 100 !important;
}

.middle-double-space {
    width: calc(100% - 200px) !important;

    .middle-label {

        .username {
            max-width: calc(100% - 40px - 20px) !important;
        }
    }
}

// Responsive Design
@media (max-width: 720px) {
    #navbar-navbar-mobile-view-subcomponent {
        display: flex;
    }
}