@import "../../../../global-style.scss";

#search-filter-dialog {
    height: 100%;
    position: relative;

    .body-container {
        height: calc(100% - 55px - 85px);
        padding-bottom: 85px;
        overflow: auto;
    }

    .apply-filter-button {
        height: 45px;
        width: calc(100% - 30px);
        margin: 25px 15px;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        cursor: pointer;
        text-transform: none;
        text-decoration: none;
        font: 600 1.2em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }
}