@import "../../../global-style.scss";

#upgrade-status-page {
    height: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        height: 100%;
        max-width: 500px;

        .padding-container {
            height: calc(100% - 70px);
            padding: 50px 20px 20px 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
        }

        .upgrade-success {

            .thank-you-label {
                text-align: center;
                font: 400 0.9em Lato;
                color: #7c7c7c;
            }

            .welcome-premium-label {
                text-align: center;
                font: 800 1.6em Lato;
                color: #333333;
            }

            .your-purchase-label {
                text-align: center;
                font: 600 1em Lato;
                color: #7c7c7c;
            }

            .premium-container {
                padding: 10px;
                display: flex;
                border-radius: 8px;
                background-color: #f2f2f2;

                .icon-container {
                    width: 80px;

                    .star-icon-container {
                        height: 50px;
                        width: 50px;
                        padding: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                        background-color: white;

                        .star-icon {
                            height: 50px;
                        }
                    }
                }

                .premium-description {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;

                    .months-premium-label {
                        font: 600 1em Lato;
                    }

                    .total-billed {
                        font: 500 0.9em Lato;
                        color: #7c7c7c;
                    }
                }
            }

            .transaction-reference {
                text-align: center;
                font: 400 0.9em Lato;
                color: #333333;
            }

            .discrete-note {
                text-align: center;
                font: 400 1em Lato;
                color: #7c7c7c;
            }

            .continue-button {
                width: calc(100% - 80px);
                padding: 10px 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1.3em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }
        }

        .upgrade-error {

            .payment-error-icon-container {
                display: flex;
                justify-content: center;

                .payment-error-icon {
                    height: 150px;
                }
            }

            .payment-unsuccessful-label {
                text-align: center;
                font: 600 1.5em Lato;
            }

            .payment-error-text {
                text-align: center;
                color: #7C7C7C;
            }

            .try-again-button {
                width: calc(100% - 80px);
                min-width: 170px;
                max-width: 300px;
                padding: 10px 40px;
                display: flex;
                justify-content: center;
                border-radius: 25px;
                cursor: pointer;
                font: 500 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }
        }
    }
}