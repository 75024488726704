#photo-permissions-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        max-width: 700px;
        margin: 0 auto;
        background-color: white;

        .photo-permission-list-container {
            height: calc(100% - 40px); // padding-top + padding-bottom
            padding: 20px;
            overflow: auto;
        }

        // Utility CSS
        .include-utility-height {
            height: calc(100% - 51px); // padding-top + padding-bottom + height of utility bar
        }
    }
}

// Responsive Design
@media (min-width: 700px) {
    #photo-permissions-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}