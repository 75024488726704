@import "../../../../../global-style.scss";

#streamer-center-overall-tab-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .diamond-img-container {
            margin-left: auto;
            margin-right: auto;

            .diamond-img {
                width: 65px;
            }
        }

        .diamond-stats-container {
            padding-top: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .diamond-stats-header {
                font: 400 1em Lato;
            }

            .diamond-stats-text {
                padding-top: 5px;
                font: 500 2em Lato;
            }
        }

        .diamond-stats-2nd-row-container {
            width: 100%;
            display: flex;
            justify-content: space-around;
        }

        .progress-bar-container {
            padding-top: 20px;
            padding-bottom: 15px;

            .diamond-count-labels-container {
                display: flex;
                justify-content: space-between;

                .start-diamond-label {
                    font: 400 0.9em Lato;
                }

                .end-diamond-label {
                    font: 400 0.9em Lato;
                }
            }

            .progress-bar {
                height: 30px;
                margin-top: 5px;
                margin-bottom: 10px;
                position: relative;
                display: grid;
                align-items: center;
                border-radius: 25px;
                background-color: #e9e9e9;

                .progress-bar-load {
                    height: 30px;
                    width: calc(0% - 40px);
                    position: absolute;
                    border-radius: 25px;
                    z-index: 10;
                    background: radial-gradient(91.46% 1100.34% at 100% 118.52%, #E0F79F 0%, #0EF4FF 45.83%, #47A8FE 100%);
                    transition: width 1s ease;
                }

                .progress-bar-value {
                    text-align: center;
                    z-index: 20;
                }
            }

            .your-progress-label {
                text-align: center;
                font: 400 0.9em Lato;
            }
        }

        .header {
            padding-bottom: 10px;
            font: 600 1.7em Lato;
        }

        .text {
            font-size: 1em;
        }

        .diamonds-list-container {
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;

            .diamond-container {
                height: 70px;
                margin-bottom: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                background-color: rgb(241, 241, 241);
                border-radius: 10px;

                .buy-diamond-img-container {
                    margin-left: 15px;

                    .diamond-img {
                        width: 30px;
                    }
                }

                .diamond-price-container {
                    flex: 1;

                    .price {
                        font: 600 1.1em Lato;
                    }

                    .diamond-description {
                        font: 400 0.8em Lato;
                    }
                }

                .claim-button {
                    height: 25px;
                    margin-right: 15px;
                    padding: 0 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    cursor: pointer;
                    font: 600 0.8em Lato;
                }
            }
        }

        .bottom-description-container {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            .claiming-text {
                padding-right: 5px;

                .policy-text {
                    text-decoration: underline;
                    color: black;
                }
            }
        }

        .coming-soon-container {
            display: flex;
            align-items: center;
            font: 600 1.2em Lato;
        }
    }

    .profile-stats-container {
        padding-bottom: 30px;
        border-top: 1px solid rgb(229, 229, 229);
        border-bottom: 1px solid rgb(229, 229, 229);
    }

    .button-container {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid rgb(229, 229, 229);
        text-decoration: none;

        .button {
            height: 45px;
            width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1em Lato;
            background-color: rgb(113, 13, 13);
            color: white;
        }
    }

    // Utility CSS
    .claimable {
        background-color: $sugarbook-main-color;
        color: white;
    }

    .claimed {
        background-color: #d8d8d8;
        color: rgb(187, 183, 183);
    }
}