#floating-game-launcher-subcomponent {
    display: flex;
    justify-content: flex-end;
    pointer-events: auto;

    .game-launcher-icon-container {
        height: 50px;
        width: 50px;
        border-radius: 50px;
        cursor: pointer;

        .chip-logo {
            height: 100%;
            width: 100%;
            border-radius: 50px;
            object-fit: cover;
        }
    }
}