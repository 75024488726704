#search-filter-basic-filters-subcomponent {

    .filter-padding-container {
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .subheader-container {
            display: flex;

            .subheader {
                font: 600 1.1em Lato;
            }
        }

        .switch-container {
            display: flex;
            justify-content: space-between;

            .label {
                display: flex;
                align-items: center;
                color: #676767;

                .premium-lock-container {
                    margin-left: 5px;
                }
            }

            // Utility CSS
            .desktop-label {
                font: 500 0.9em Lato;
            }
        }
    }

    // Utility CSS
    .no-gap {
        gap: 0;
    }
}