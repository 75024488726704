#last-stream-summary-last-stream-diamonds-progress-subcomponent {
    width: calc(100% - 40px);
    padding: 20px 20px 15px 20px;

    .diamond-count-labels-container {
        display: flex;
        justify-content: space-between;

        .start-diamond-label {
            font: 400 0.9em Lato;
        }

        .end-diamond-label {
            font: 400 0.9em Lato;
        }
    }

    .progress-bar {
        height: 30px;
        margin-top: 5px;
        margin-bottom: 10px;
        position: relative;
        display: grid;
        align-items: center;
        border-radius: 25px;
        background-color: #e9e9e9;

        .progress-bar-load {
            height: 30px;
            width: calc(0% - 40px);
            position: absolute;
            border-radius: 25px;
            z-index: 10;
            background: radial-gradient(91.46% 1100.34% at 100% 118.52%, #E0F79F 0%, #0EF4FF 45.83%, #47A8FE 100%);
            transition: width 1s ease;
        }

        .progress-bar-value {
            text-align: center;
            z-index: 20;
        }
    }

    .your-progress-label {
        text-align: center;
        font: 400 0.9em Lato;
    }
}