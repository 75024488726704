@import "../../../../global-style.scss";

#registration-about-me-content-dialog {
    height: 100%;
    min-width: 300px;
    margin-top: auto;

    .dialog-header {
        padding: 15px;
        display: flex;
        align-items: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .left-container {
            display: flex;
            align-items: center;
            flex: 1;

            .back-icon {
                cursor: pointer;
                font-size: 30px;
            }
        }

        .middle-container {
            flex: 10;
            text-align: center;
            font: 600 1.2em Lato;
        }

        .right-container {
            flex: 1;
        }
    }

    .padding-container {
        height: calc(100% - 60px - 40px);
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .search-text-field-container {
            height: 40px;
            padding: 5px 15px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            background: #F3F3F3;

            .search-icon {
                padding-right: 10px;
                color: #DCDBDB;
            }

            .search-text-field {
                height: 100%;
                width: 100%;
                padding: 0;
                margin: 0;
                border: 0;
                outline: none;
                font: 600 1em Lato;
                background: #F3F3F3;
            }

            .search-text-field::placeholder {
                color: #ACACAC;
            }
        }

        .sub-category-about-me-content {

            .sub-category-about-me-label {
                padding: 10px 5px;
                font: 600 1.2em Lato;
                color: black;
                transition: all 0.3s ease-in-out;
            }

            .sub-category-about-me-content-container {

                .about-me-content-padding-left {
                    padding-left: 15px;
                }
            }
        }

        .about-me-content-container {
            height: calc(100% - 50px);
            overflow: auto;

            .about-me-content {
                padding-right: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                .about-me-label {
                    padding: 10px 5px;
                    font: 600 1em Lato;
                    color: black;
                    transition: all 0.3s ease-in-out;
                }

                .about-me-tick-container {
                    display: flex;

                    .about-me-tick {
                        color: $sugarbook-main-color;
                    }
                }

                .selected-about-me-label {
                    color: $sugarbook-main-color;
                }
            }
        }
    }
}

// Utility CSS
.registration-about-me-content-overflow {

    .MuiPaper-root {
        height: 100%;
        max-height: 400px;
        // overflow: auto;
    }
}