@import "../../../../global-style.scss";

#survey-monkey-dialog {
    height: 530px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
                color: lightgrey;
            }
        }
    }

    .padding-container {
        height: calc(100% - 73px);
        padding: 20px 30px 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .survey-monkey-image-container {

            .survey-monkey-image {}
        }

        .survey-monkey-title {
            text-align: center;
            font: 600 1.1em Lato;
            color: white;
        }

        .survey-monkey-description {
            text-align: center;
            font: 500 1em Lato;
            color: white;
        }

        .continue-button {
            width: 80%;
            padding: 13px;
            border-radius: 50px;
            text-align: center;
            font: 500 1.3em Lato;
            background-color: $sugarbook-main-color;
            color: black;
        }

        .button-container {
            height: 20%;
            width: 100%;
        }
    }
}

.survey-max-width {

    .MuiDialog-paper {
        max-width: 350px;
    }
}