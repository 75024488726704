@import "../../../../global-style.scss";

#end-call-dialog {

    .padding-container {
        padding: 30px 30px 20px 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .text {
            padding-bottom: 10px;
            text-align: center;
            font: 500 1.1em Lato;
        }

        .end-call-button {
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            cursor: pointer;
            font: 600 1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }

        .cancel-button {
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            cursor: pointer;
            font: 600 1em Lato;
            background-color: white;
            color: $sugarbook-main-color;
        }
    }
}