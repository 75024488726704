@import "../../../../global-style.scss";

#co-anchor-card-subcomponent {
    cursor: pointer;
    // position: relative;

    .relative {
        position: relative;
    }

    .co-anchor-card-container {
        width: 100%;
        position: relative;
        padding-top: 147%;
        border-radius: 10px;

        .profile-image-container {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            border-radius: inherit;
            z-index: 10;

            .host-image-container {
                height: 100%;
                width: 100%;

                .profile-image {
                    height: 100%;
                    width: 100%;
                    border-radius: 10px;
                }
            }

            .co-host-image-container {
                // height: 150px;
                // width: 150px;
                height: 29%;
                width: 42%;
                position: absolute;
                bottom: 37px;
                right: 10px;
                padding: 5px;
                border-radius: 100px;
                background: linear-gradient(180deg,
                        #1F93FF 0%,
                        #FF1EA5 100%);
                // clip-path: polygon(0% 100%, 100% 0%, 0% 0%);

                .co-host-profile-image {
                    height: 100%;
                    width: 100%;
                    border-radius: 100px;
                }

                .pk-vs-icon-container {
                    width: 100%;
                    position: relative;
                    bottom: 15px;
                    display: flex;
                    justify-content: center;

                    .dual-pk-vs-icon {
                        width: 35%;
                    }
                }
            }

            .dual-pk-logo-container {
                width: 30%;
                position: absolute;
                top: 0;
                right: 0;
                padding: 5px;

                .dual-pk-logo {
                    width: 100%;
                }
            }
        }

        .card-details-container {
            height: calc(100% - 20px);
            width: calc(100% - 20px);
            position: absolute;
            top: 0;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 10px;
            z-index: 20;
            background: linear-gradient(186.69deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.3) 100%);

            .top-description-container {
                flex-grow: 1;
                text-decoration: none;

                .live {
                    display: flex;
                    gap: 5px;

                    .live-status {
                        height: 20px;
                        padding: 0 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 3em;
                        font: 800 0.7em Lato;
                        background-color: #ff1694;
                        color: #fff;
                    }

                    .viewer-count-container {
                        height: 20px;
                        min-width: 40px;
                        padding-left: 6px;
                        padding-right: 8px;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        border-radius: 3em;
                        background-color: rgba(196, 196, 196, 0.6);
                        color: white;

                        .eye-logo-container {
                            display: flex;

                            .eye-logo {
                                width: 16px;
                            }
                        }

                        .viewer-count {
                            padding-left: 2px;
                            font: 800 0.8em Lato;
                            color: white;
                        }
                    }

                    .top-right-profile-badge-container {
                        position: absolute;
                        top: 0;
                        right: 0;

                        .lgbtq-icon {
                            height: 25px;
                        }
                    }
                }
            }

            .bottom-description-container {
                display: flex;

                .left-detail-container {
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                    // flex-grow: 1;
                    width: calc(100% - 30px);

                    .badges-container {
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        .livestreaming-achievements-badge-container {
                            display: flex;
                            gap: 3px;

                            .livestreaming-achievements-badge {
                                height: 13px;
                            }
                        }

                        .special-badge-container {
                            display: flex;
                            gap: 3px;

                            .special-badge {
                                height: 15px;
                            }
                        }
                    }

                    .username-description {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        font: 600 1.1em Lato;
                        color: white;

                        .username {
                            max-width: 50%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .age {}

                        .status {
                            height: 10px;
                            width: 10px;
                            // margin: auto 0 3px 0;
                            border-radius: 50px;
                            background-color: rgb(28, 189, 32);
                        }

                        .verified-profile-badge-container {
                            display: flex;
                            align-items: center;

                            .verified-profile-badge {
                                height: 13px;
                            }
                        }

                        .premium-mini-badge-container {
                            display: flex;
                            align-items: center;

                            .premium-mini-badge {
                                height: 13px;
                            }
                        }
                    }

                    .location-description {
                        display: flex;
                        font: 400 0.8em Lato;
                        color: white;

                        .location {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .badge-container {
                            margin-left: 5px;
                            margin-bottom: 1px;
                            display: flex;
                            align-items: flex-end;

                            .badge {
                                height: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    // Utility CSS
    .dual-pk-border-gradient {
        padding: 5px;
        border-radius: 15px;
        background: linear-gradient(90deg, #FF1EA5, #ff409c4b 70%, #1F93FF) padding-box, linear-gradient(90deg, #FF1EA5, #ff409c4b 70%, #1F93FF) border-box;
        background-size: 600% 600%;
        animation: luminousGradientPosition 1s ease infinite;
        -webkit-animation: luminousGradientPosition 1s ease infinite;
    }
}