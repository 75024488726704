@import "../../../../global-style.scss";

#private-call-waiting-room-dialog {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.9);

    .padding-container {
        padding: 0 20px;

        .waiting-room-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
            color: white;

            .waiting-room-animation-container {

                .waiting-room-animation {
                    width: 80px;
                }
            }

            .not-enough-coins-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 30px;
            }

            .prep-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 30px;
            }

            .waiting-room-label {
                text-align: center;
                font: 600 1.4em Lato;
            }

            .not-enough-coins-container {
                display: flex;
                flex-direction: column;
                align-items: center;

                .not-enough-point-1 {}

                .not-enough-point-2 {
                    display: flex;

                    .coins-container {
                        display: flex;
                        align-items: center;

                        .coin-icon {
                            height: 15px;
                            margin-right: 3px;
                        }
                    }

                }

                .not-enough-point-3 {}
            }

            .sb-tips {
                text-align: center;
            }

            .text {
                text-align: center;
                font: 500 1em Lato;

                span {
                    color: red;
                }
            }

            .enter-button {
                height: 40px;
                width: 80px;
                padding: 5px 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .buy-coins-button {
                height: 40px;
                width: 80px;
                padding: 5px 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .end-call-button {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}