@import "../../../../global-style.scss";

#leaderboard-section-shared-subcomponent {

    .section-padding-container {
        padding: 20px 20px 0 20px;

        .section-container {
            padding-bottom: 20px;

            .header-container {
                display: flex;
                align-items: center;

                .header {
                    font: 600 1.4em Lato;
                }

                .info-icon-container {
                    padding-left: 5px;
                    display: flex;
                    align-items: center;

                    .info-icon {
                        color: red;
                    }
                }

                .see-all-button-container {
                    flex-grow: 1;

                    .see-all-button {
                        text-align: right;
                        cursor: pointer;
                        color: $sugarbook-main-color;
                    }
                }

                // Casino CSS
                .casino-see-all-button-container {
                    margin-left: 20px;
                    padding: 15px 20px;
                    position: relative;
                    background: linear-gradient(270deg, rgba(212, 5, 5, 1) 50%, rgba(212, 5, 5, 0) 100%);

                    .leaderboard-casino-chips {
                        height: 50px;
                        position: absolute;
                        bottom: 10px;
                    }

                    .see-all-button {
                        color: white;
                    }
                }
            }
        }
    }

    .padding-container {
        padding: 20px;
    }

    .casino-padding-container {
        padding: 20px 0 0 20px;
    }
}