#daily-check-in-dialog {

    .top-header-container {
        padding: 20px 30px 0 0;
        display: flex;
        justify-content: flex-end;

        .close-button {
            cursor: pointer;
        }
    }

    .padding-container {
        padding: 10px 40px 40px 40px;

        .daily-check-in-title {
            text-align: center;
            font: 700 1.5em/1.4 Lato;
        }

        .daily-check-in-description {
            padding-bottom: 10px;
            text-align: center;
            font: 400 0.9em Lato;
        }
    }
}

// Utility CSS
.max-width-container {
    max-width: 360px;
}