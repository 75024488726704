@import "../../../global-style.scss";

#deactivate-account-page {
    height: 100%;

    .max-width-container {
        height: calc(100% - 10px);
        width: 80%;
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        background-color: white;

        .padding-container {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .deactivating-description {
                padding-bottom: 20px;
                line-height: 1.3;
                font-size: 0.9em;
                color: #676767;
            }

            .reason-form-field-container {
                height: 50px;
                width: 100%;
                padding-bottom: 20px;
                margin-left: auto;
                margin-right: auto;

                .reason-form-field {
                    width: 100%;
                }
            }

            .improvement-description {
                padding-bottom: 10px;
                font-size: 0.8em;
                color: #676767;
            }

            .suggestion-form-field-container {
                height: 50px;
                width: 100%;
                padding-bottom: 20px;
                margin-left: auto;
                margin-right: auto;

                .suggestion-form-field {
                    width: 100%;
                }
            }

            .deactivate-account-button {
                height: 45px;
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                text-transform: none;
                text-decoration: none;
                font-size: 1.1em;
                font-weight: 500;
                background-color: $sugarbook-main-color;
                color: white;
                transition: all 0.5s;

                .button-spinner {
                    color: white;
                }
            }

            .disabled-button {
                border: 2px solid transparent;
                cursor: not-allowed;
                background-color: rgba(0, 0, 0, 0.12);
                color: rgba(0, 0, 0, 0.26);
            }

            .bottom-text {
                margin-top: 20px;
                padding: 15px;
                border-radius: 10px;
                line-height: 1.3;
                font-size: 0.8em;
                background-color: #fcf8e3;
                color: #8a6d3b;
            }
        }
    }
}

// Responsive Design
@media (min-width: 720px) {
    #deactivate-account-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}