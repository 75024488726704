@import "../../global-style.scss";

#join-page {
    height: 100%;

    .max-width-container {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        overflow: auto;

        .navbar-container {
            height: 30px;
            display: flex;
            align-items: center;

            .back-button {
                width: 50px;
                cursor: pointer;

                .signup-back-icon {
                    height: 30px;
                }
            }
        }

        .join-body-container {
            // height: calc(100% - 30px - 150px);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;

            .join-body-success-container {
                margin-bottom: 10%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1;
                gap: 30px;
            }

            .gender-select-description-container {

                .sugarbook-logo-container {

                    .sugarbook-white-logo {
                        // font: 600 1.5em Lato;
                        // color: white;
                    }
                }

                .where-romance-meets-finance-label {
                    font: 600 1.5em Lato;
                    color: white;
                }

                .signing-up-free-label {
                    padding-top: 50px;
                    font: 500 1em Lato;
                    color: white;
                }

                .where-romance-meets-finance-mobile-label {
                    font: 600 1.7em Lato;
                    color: white;
                }

                .signing-up-free-mobile-label {
                    font: 600 1.7em Lato;
                    color: white;
                }
            }

            .looking-for-select-description-container {
                .who-are-you-looking-for-label {
                    font: 600 1.5em Lato;
                    color: white;
                }
            }

            .i-am-container {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .i-am-label {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .i-am-a-label {
                        font: 600 1.2em Lato;
                        color: white;
                    }

                    .selected-gender {
                        text-decoration: underline;
                        text-transform: capitalize;
                        font: 600 1.3em Lato;
                        color: white;
                    }

                    .switch-gender-container {
                        padding-left: 5px;
                        display: flex;
                        gap: 5px;
                        cursor: pointer;

                        .switch-icon {
                            width: 15px;
                        }

                        .switch-gender-label {
                            font: 500 0.7em Lato;
                            color: #ffffffa2;
                        }
                    }
                }

                .non-binary-gender-options-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .i-am-a-label {
                        font: 600 1.2em Lato;
                        color: transparent;
                    }

                    .selected-gender {
                        text-decoration: underline;
                        text-transform: capitalize;
                        font: 600 1.3em Lato;
                        color: white;
                    }

                    .switch-role-container {
                        padding-left: 5px;
                        display: flex;
                        gap: 5px;
                        cursor: pointer;

                        .switch-icon {
                            width: 15px;
                        }

                        .switch-gender-label {
                            font: 500 0.7em Lato;
                            color: #ffffffa2;
                        }
                    }
                }

                .i-am-a-button-container {
                    display: flex;
                    flex-wrap: nowrap;
                    gap: 15px;

                    .man-label {
                        width: 125px;
                        padding: 11px 20px;
                        display: flex;
                        justify-content: center;
                        border: 2px solid rgba(255, 255, 255, 0.4);
                        border-radius: 50px;
                        cursor: pointer;
                        font: 600 1em Lato;
                        color: white;
                        transition: all 0.3s ease-in-out;
                    }

                    .woman-label {
                        width: 125px;
                        padding: 11px 20px;
                        display: flex;
                        justify-content: center;
                        border: 2px solid rgba(255, 255, 255, 0.4);
                        border-radius: 50px;
                        cursor: pointer;
                        font: 600 1em Lato;
                        color: white;
                        transition: all 0.3s ease-in-out;
                    }

                    .non-binary-label {
                        width: 125px;
                        padding: 11px 20px;
                        display: flex;
                        justify-content: center;
                        border: 2px solid rgba(255, 255, 255, 0.4);
                        border-radius: 50px;
                        cursor: pointer;
                        font: 600 1em Lato;
                        color: white;
                        transition: all 0.3s ease-in-out;
                    }

                    .selected {
                        color: $sugarbook-main-color;
                        background: white;
                    }
                }
            }

            .looking-for-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .looking-for-label-container {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .looking-for-label {
                        font: 600 1.2em Lato;
                        color: white;
                    }

                    .role-info {
                        // height: 100%;
                        display: flex;
                        cursor: pointer;

                        .help-icon {}
                    }
                }

                .looking-for-button-container {

                    .selection-container {
                        width: 100%;
                        display: flex;
                        gap: 15px;
                    }

                    .body-label {
                        width: 125px;
                        padding: 11px 20px;
                        display: flex;
                        justify-content: center;
                        border: 2px solid rgba(255, 255, 255, 0.4);
                        border-radius: 50px;
                        cursor: pointer;
                        font: 600 1em Lato;
                        color: white;
                        transition: all 0.3s ease-in-out;
                    }

                    .selected {
                        color: $sugarbook-main-color;
                        background: white;
                    }
                }
            }
        }

        .bottom-container {
            height: 100px;
            width: 100%;
            padding: 10px 0 0 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .continue-button {
                // margin-top: 30px;
                padding: 13px 80px;
                border-radius: 50px;
                cursor: pointer;
                text-align: center;
                font: 600 1em Lato;
                color: white;
                background-color: $sugarbook-main-color;
                transition: all 0.3s ease-in-out;
            }

            .disabled-button {
                cursor: not-allowed;
                background-color: #8C8283;
                color: $sugarbook-main-color;
            }
        }
    }

    // Utility CSS
    .full-width {
        width: calc(100% - 160px);
    }

    .partial-width {
        width: 20%;
    }
}

// Responsive Design
@media (max-width: 768px) {
    #join-page {

        .max-width-container {
            height: calc(100% - 50px);
            flex-direction: column;

            .join-body-container {

                .join-body-success-container {
                    margin-bottom: 50%;

                    .i-am-container,
                    .looking-for-container {
                        flex-direction: column;

                        .selection-container {
                            flex-direction: column;

                            .body-label {
                                width: calc(100% - 40px - 4px);
                            }
                        }

                        .i-am-a-button-container {
                            flex-direction: column;

                            .man-label,
                            .woman-label,
                            .non-binary-label {
                                width: calc(100% - 40px - 4px);
                            }
                        }
                    }

                    .continue-button {
                        width: calc(100% - 160px);
                        text-align: center;
                    }
                }
            }

            .bottom-container {
                justify-content: center;
            }
        }
    }
}