#chat-section-tip-request-rejected-element-subcomponent {
    max-width: 80%;
    padding: 5px 10px;
    margin: 0px 10px;
    display: flex;
    border-radius: 10px;

    .profile-picture-container {
        width: 45px;
        display: flex;
        align-items: center;

        .profile-picture {
            height: 30px;
            width: 30px;
        }
    }

    .chat-info-container {
        width: calc(100% - 45px);
        display: flex;
        align-items: center;

        .chat-info {

            .username {
                font: 500 0.9em/1.4 Lato;
                color: white;
            }

            .levelling-badge {
                height: 13px;
                margin-top: 3px;
                padding: 0 7px;
            }

            .joined-text {
                font: 500 0.9em/1.4 Lato;
                color: white;
            }
        }
    }
}