#photo-permissions-photo-permissions-list-subcomponent {
    height: 90px;
    display: flex;
    align-items: center;

    .profile-photo {
        height: 70px;
        width: 70px;
        margin-right: 15px;
    }

    .user-description-container {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 5px;

        .livestreaming-achievement-badge-container {
            display: flex;
            align-items: center;
            gap: 3px;

            .livestreaming-achievement-badge {
                height: 13px;
            }
        }

        .username {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font: 500 1.1em Lato;
        }

        .levelling-badge-container {
            display: flex;
            align-items: center;

            .levelling-badge {
                height: 13px;
            }
        }

        .misc-badge-container {
            display: flex;
            align-items: center;
            gap: 3px;

            .verified-profile-badge-container {
                display: flex;

                .verified-profile-badge {
                    height: 16px;
                }
            }

            .star-icon {
                font-size: 20px;
                color: rgba(244, 191, 37, 0.78);
            }

            .verified-caller-badge-container {
                display: flex;

                .verified-caller-badge {
                    height: 16px;
                }
            }
        }
    }

    .revoke-button {
        width: 100px;
        padding: 6px 0;
        border-radius: 25px;
        text-align: center;
        cursor: pointer;
        font: 500 1em Lato;
        background-color: rgb(237, 90, 96);
        color: white;
    }

    .approve-button {
        width: 100px;
        padding: 6px 0;
        border-radius: 25px;
        text-align: center;
        cursor: pointer;
        font: 500 1em Lato;
        background-color: rgb(0, 196, 95);
        color: white;
    }
}