@import "../../../../global-style.scss";

#geolocation-permission-denied-dialog {
    max-width: 300px;

    .location-permission-denied-top-container {
        padding: 20px 0;
        display: flex;
        justify-content: center;
        background-color: #FF0000;

        .location-permission-denied-icon-container {

            .location-permission-denied-icon {
                height: 80px;
            }
        }
    }

    .padding-container {
        padding: 20px 20px 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .geolocation-prompt-title {
            font: 600 1.5em Lato;
        }

        .geolocation-prompt-desc {
            text-align: center;
            font: 500 1em Lato;
        }

        .buttons-container {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .faq-button {
                width: 200px;
                padding: 10px 0;
                border-radius: 25px;
                text-align: center;
                cursor: pointer;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .close-button {
                width: 200px;
                border-radius: 25px;
                text-align: center;
                font: 600 1em Lato;
                cursor: pointer;
                background-color: white;
            }
        }
    }
}