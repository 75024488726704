@import "../../../global-style.scss";

#video-call-summary-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        max-width: 1000px;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: auto;
        background-color: white;

        .padding-container {
            padding: 50px 25px 50px 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .video-call-ended-text {
                font: 700 1.6em Lato;
            }

            .first-row {
                width: 100%;
                padding-top: 30px;
                display: flex;

                .standby-duration-container {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 1;

                    .standby-duration {
                        font: 600 1.4em Lato;
                    }

                    .standby-duration-label {
                        text-align: center;
                        font: 600 0.8em Lato;
                    }
                }

                .total-standby-diamonds-earned-container {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 1;

                    .total-standby-diamonds-earned {
                        font: 600 1.4em Lato;
                    }

                    .total-standby-diamonds-earned-label {
                        text-align: center;
                        font: 600 0.8em Lato;
                    }
                }
            }

            .second-row {
                width: 100%;
                padding-top: 30px;
                display: flex;

                .call-duration-container {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 1;

                    .call-duration {
                        font: 600 1.4em Lato;
                    }

                    .call-duration-label {
                        text-align: center;
                        font: 600 0.8em Lato;
                    }
                }

                .total-diamonds-earned-container {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 1;

                    .total-diamonds-earned {
                        font: 600 1.4em Lato;
                    }

                    .total-diamonds-earned-label {
                        text-align: center;
                        font: 600 0.8em Lato;
                    }
                }
            }
        }

        .diamonds-earned-padding-container {
            padding: 25px;

            .diamonds-earned-text {
                font: 700 1.6em Lato;
            }

            .diamonds-earned-category-container {
                padding-top: 30px;
                display: flex;
                justify-content: space-between;

                .diamonds-earned-category-label {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .category-label {
                        font: 600 0.9em Lato;
                    }
                }

                .diamonds-earned-category-output {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;

                    .diamonds-earned {
                        display: flex;
                        font: 600 0.9em Lato;

                        .diamond-icon-container {
                            padding-right: 7px;
                            display: flex;

                            .diamond-icon {
                                height: 15px;
                            }
                        }
                    }
                }
            }
        }

        .incoming-call-request-padding-container {
            padding: 25px 25px 125px 25px;

            .your-call-details-text {
                font: 700 1.6em Lato;
            }

            .expandable-container {

                .expand-header {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .incoming-call-request-username {
                        font: 600 1.3em Lato;
                    }
                }

                .diamonds-earned-category-container {
                    padding-top: 30px;
                    display: flex;
                    justify-content: space-between;

                    .diamonds-earned-category-label {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        .category-label {
                            font: 600 0.9em Lato;
                        }
                    }

                    .diamonds-earned-category-output {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 20px;

                        .diamonds-earned {
                            display: flex;
                            font: 600 0.9em Lato;

                            .diamond-icon-container {
                                padding-right: 7px;
                                display: flex;

                                .diamond-icon {
                                    height: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .continue-button-container {
            width: 100%;
            position: absolute;
            bottom: 30px;

            .continue-button {
                height: 40px;
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
                padding: 5px 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }
        }
    }
}

// Responsive Design
@media (min-width: 1000px) {
    #video-call-summary-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}