@import "../../../global-style.scss";

#signup-success-page {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    .padding-container {
        height: calc(100% - 40px);
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        .signup-success-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;

            .check-gif-container {

                .check-gif {
                    width: 100px;
                }
            }

            .signup-success-body-top-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5px;

                .thank-you-label {
                    font: 600 1.7em Lato;
                    color: white;
                }
            }

            .registered-sugarbook-description {
                max-width: 200px;
                font: 500 1em/1.4 Lato;
                text-align: center;
                color: white;
            }
        }

        .done-button {
            width: 100%;
            max-width: 400px;
            padding: 12px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            box-shadow: 0px 11px 13px -4px rgba(0, 0, 0, 0.77);
            cursor: pointer;
            text-decoration: none;
            text-transform: capitalize;
            font: 600 1.1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
            transition: all 0.5s;
        }
    }
}