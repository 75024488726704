#follows-followers-tab-subcomponent {
    height: 100%;

    .followers-list-container {
        height: 100%;

        >div {
            height: 100%;

            .infinite-scroller-container {}
        }
    }
}