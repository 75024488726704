@import "../../../../global-style.scss";

#complete-terms-of-relationship-dialog {

    .padding-container {
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .complete-tor-background-image-container {

            .complete-tor-background-image {
                width: 100%;
                max-width: 250px;
            }
        }

        .title {
            text-align: center;
            font: 700 1.3em Lato;
        }

        .description {
            text-align: center;
            font: 400 1em/1.4 Lato;
        }

        .got-it-button {
            height: 50px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1em Lato;
            background: $sugarbook-main-color;
            color: white;
        }
    }
}