@import "../../global-style.scss";

#error-404-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .error-label {
        font: 600 4em Lato;
        color: $sugarbook-main-color;
    }

    .error-description {
        text-align: center;
        font: 600 2em Lato;
    }

    .go-home-button {
        padding: 10px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        cursor: pointer;
        font: 600 1.3em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }
}