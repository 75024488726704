@import "../../../../global-style.scss";

#details-signup-photos-details-subcomponent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-container {
        height: calc(100% - 50px - 100px - 10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        overflow: auto;
        mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
        -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

        .photos-details-label {
            text-align: center;
            font: 900 2em Lato;
            color: white;
        }

        .photos-details-description {
            padding: 0 20px;
            text-align: center;
            font: 500 0.9em Lato;
            color: white;
        }

        .photos-body-container {
            max-width: 820px;
            position: relative;
            padding: 5% 0;
            display: flex;
            // flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px;

            .profile-img-button {
                position: relative;
                display: flex;
                justify-content: center;

                .select-image-container {
                    width: calc(100% - 80px);
                    max-width: 200px;
                    padding: 40px 40px 50px 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 18px;
                    border: 2px dashed rgba(244, 244, 244, 0.6);
                    border-radius: 15px;
                    color: white;

                    .placeholder-image-container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .add-icon {
                            padding-left: 65px;
                            font-size: 36px;
                        }

                        .select-image-icon {
                            width: 60px;
                        }
                    }


                    .select-image-label {
                        text-transform: none;
                        text-align: center;
                        font: 500 1.1em Lato;
                    }
                }

                .profile-img {
                    // height: 120px;
                    width: 100%;
                    max-width: 200px;
                    // padding: 30px 40px;
                    border-radius: 25px;
                    object-fit: cover;
                }

                .add-icon-container {
                    height: 30px;
                    width: 30px;
                    position: absolute;
                    bottom: 10px;
                    margin-left: 90px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    background-color: $sugarbook-main-color;

                    .add-icon {
                        font-size: 20px;
                        color: white;
                    }
                }
            }
        }
    }

    .photos-notice {
        height: 50px;
        padding: 0 20px;
        text-align: center;
        font: 500 1em Lato;
        color: white;
    }

    .bottom-container {
        height: 100px;
        width: calc(100% - 40px);
        padding: 10px 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .continue-button {
            padding: 13px 80px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: white;
            background-color: $sugarbook-main-color;
            transition: all 0.3s ease-in-out;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: #8C8283;
            color: $sugarbook-main-color;
        }
    }

    // Utility CSS
    .full-width {
        width: calc(100% - 160px);
    }

    .partial-width {
        width: 65%;
    }

    .fix-width {
        width: 150px;
    }

    .fit-content {
        width: fit-content;
    }
}

@media (min-width: 720px) {
    #details-signup-photos-details-subcomponent {

        .top-container {
            height: calc(90% - 50px - 100px - 10px);

            .photos-details-label {
                font: 600 2em Lato;
            }

            .photos-details-description {
                font: 500 1em Lato;
            }
        }
    }
}