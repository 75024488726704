@import "../../../../global-style.scss";

#details-signup-livestreaming-details-subcomponent {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

    .left-container {
        height: calc(100% - 40px);
        padding-top: 40px;

        .livestreaming-phone-overlay-container {
            position: relative;
            display: flex;
            justify-content: center;

            .registration-phone-overlay-background {
                width: 85%;
            }

            .livestreaming-sample-video-background {
                height: 500px;
                width: 100%;
                max-width: 400px;
                filter: blur(10px);
            }

            .livestreaming-sample-video {
                // height: 364px;
                // height: 320px;
                width: 85%;
                max-width: 195px;
                position: absolute;
                top: 75px;
                border-radius: 15px;
                object-fit: fill;
            }
        }
    }

    .right-container {
        height: 100%;
        // max-width: 350px;

        .top-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow: auto;
            mask-image: linear-gradient(to bottom, transparent 0%, black 1%, black 95%, transparent 100%);
            -webkit-mask-image: -webkit-linear-gradient(top, transparent 0%, black 1%, black 95%, transparent 100%);

            .livestreaming-details-label {
                text-align: center;
                font: 900 2em Lato;
                color: white;
            }

            .livestreaming-details-description {
                text-align: center;
                font: 500 0.9em Lato;
                color: white;
            }

            .livestreaming-phone-overlay-container {
                width: 100%;
                // max-width: 195px;
                position: relative;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                justify-content: center;

                .registration-phone-overlay-background {
                    // height: 317px;
                    width: 100%;
                }

                .black-background {
                    height: 364px;
                    width: 85%;
                    position: absolute;
                    top: 13px;
                    border-radius: 15px;
                    background: black;
                }

                .livestreaming-sample-video-background {
                    width: 100%;
                    max-height: 420px;
                    object-fit: fill;
                    filter: blur(10px);
                }

                .livestreaming-sample-video {
                    // height: 364px;
                    // height: 320px;
                    width: 85%;
                    max-width: 195px;
                    position: absolute;
                    top: 32px;
                    border-radius: 15px;
                    object-fit: fill;
                }
            }

            .livestreaming-body-container {
                max-width: 800px;
                padding: 5% 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 15px;

                .livestreaming-item {
                    // width: 100%;
                    padding: 6px 40px;
                    border: 2px solid rgba(255, 255, 255, 0.4);
                    border-radius: 50px;
                    text-align: center;
                    cursor: pointer;
                    font: 600 1em Lato;
                    color: white;
                    transition: all 0.3s ease-in-out;
                }

                .selected {
                    color: $sugarbook-main-color;
                    background: white;
                }
            }
        }

        .bottom-container {
            height: 100px;
            width: calc(100% - 40px);
            padding: 10px 20px 0 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .continue-button {
                padding: 13px 80px;
                border-radius: 50px;
                cursor: pointer;
                text-align: center;
                font: 600 1em Lato;
                color: white;
                background-color: $sugarbook-main-color;
                transition: all 0.3s ease-in-out;
            }

            .skip-button {
                cursor: pointer;
                color: #988484;
            }

            .disabled-button {
                cursor: not-allowed;
                background-color: #8C8283;
                color: $sugarbook-main-color;
            }
        }
    }

    // Utility CSS
    .horizontal {
        flex-direction: row;
        justify-content: center;
    }

    .vertical {
        flex-direction: column;
    }

    .apply-height {
        height: calc(100% - 100px - 10px);
    }

    .full-width {
        width: calc(100% - 160px);
    }

    .partial-width {
        width: calc(100% - 80px - 2px);
    }

    .fix-width {
        width: 230px;
    }

    .fit-content {
        width: fit-content;
    }
}

@media (min-width: 720px) {
    #details-signup-livestreaming-details-subcomponent {

        .right-container {

            .top-container {

                .livestreaming-details-label {
                    font: 600 2em Lato;
                }

                .livestreaming-details-description {
                    font: 500 1em Lato;
                }
            }
        }
    }
}