#default-bottom-navbar {
    height: calc(50px);
    padding-bottom: env(safe-area-inset-bottom); // For mobile devices
    display: none;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    z-index: 50;

    .icons-container {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .icon-container {
            display: flex;
            cursor: pointer;

            .link {
                color: inherit;
            }
        }

        .video-icon {
            height: 33px;
            width: 30px;
            display: flex;

            .link {

                .video-icon {
                    height: 30px;
                    width: 30px;
                }
            }
        }
    }
}

// Responsive Design
@media (max-width: 720px) {
    #default-bottom-navbar {
        display: inline;
    }
}