#daily-check-in-reward-shared-subcomponent {

    .exp-list-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .exp-container {
            height: 80px;
            padding: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .day {
                font: 600 0.8em Lato;
            }

            .exp-icon-container {
                height: 25px;
                width: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                font: 600 1em Lato;
                background: #D8D8D8;
                color: white;
            }

            .claimed {
                box-shadow: 0px 2px 3px rgb(133 0 0 / 40%);
                background: linear-gradient(90deg, rgba(245, 187, 25, 1) 0%, rgba(255, 55, 103, 1) 100%);
            }

            .check-icon {
                color: #4AC200;
            }

            .exp {
                font: 600 1em Lato;
                color: #FF3767;
            }
        }
    }

    .check-in-button {
        height: 45px;
        width: 100%;
        margin-top: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        text-transform: none;
        font: 600 1.1em Lato;
        background: linear-gradient(90deg, rgba(245, 187, 25, 1) 0%, rgba(255, 55, 103, 1) 100%);
        color: white;
        transition: all 0.5s;
    }

    .disabled-button {
        border: 2px solid transparent;
        cursor: not-allowed;
        background: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
    }
}