#verification-status-dialog {

    .lottie-overlay-container {
        padding: 50px 25px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 35px;
        // background-color: white;

        .general-success-lottie,
        .general-error-lottie {
            width: 100px;
        }

        .verification-status-label {
            text-align: center;
            font: 600 1.4em Lato;
            color: white;
        }
    }
}