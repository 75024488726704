@import "../../../../global-style.scss";

#levelling-daily-tasks-subcomponent {

    .padding-container {
        padding: 20px;

        .boost-container {

            .boost-label {
                padding-bottom: 10px;
                font: 600 1.4em Lato;
            }

            .boost-description {
                padding-bottom: 20px;
                font: 400 0.9em Lato;
            }

            .boost-task-list-container {

                .boost-task {
                    height: 55px;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    background-color: #F1F1F1;

                    .exp-icon-container {
                        height: 20px;
                        margin-left: 10px;
                        margin-right: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex: 12;
                        border-radius: 25px;
                        font: 600 0.7em Lato;
                        background: linear-gradient(90deg, rgba(245, 187, 25, 1) 0%, rgba(255, 55, 103, 1) 100%);
                        color: white;
                    }

                    .boost-description-container {
                        height: 80%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        flex: 68;

                        .boost-description-header {
                            font: 600 0.9em Lato;
                        }

                        .boost-description-text {
                            font: 400 0.7em Lato;
                            color: #D31616;
                        }
                    }

                    .claim-button {
                        height: 25px;
                        margin-right: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex: 20;
                        border-radius: 25px;
                        cursor: pointer;
                        text-transform: none;
                        font: 500 0.9em Lato;
                        background-color: $sugarbook-main-color;
                        color: white;
                    }

                    .disabled-button {
                        background-color: rgba(0, 0, 0, 0.12);
                        color: rgba(0, 0, 0, 0.26);
                    }
                }
            }
        }

        .lifetime-container {
            padding-top: 20px;

            .lifetime-label {
                padding-bottom: 10px;
                font: 600 1.4em Lato;
            }

            .lifetime-description {
                padding-bottom: 20px;
                font: 400 0.9em Lato;
            }

            .lifetime-task-list-container {

                .lifetime-task {
                    height: 55px;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    background-color: #F1F1F1;

                    .exp-icon-container {
                        height: 20px;
                        margin-left: 10px;
                        margin-right: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex: 10;
                        border-radius: 25px;
                        font: 600 0.7em Lato;
                        background: linear-gradient(90deg, rgba(245, 187, 25, 1) 0%, rgba(255, 55, 103, 1) 100%);
                        color: white;
                    }

                    .lifetime-description-container {
                        height: 80%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        flex: 75;

                        .lifetime-description-header {
                            font: 600 0.9em Lato;
                        }

                        .lifetime-description-text {
                            font: 400 0.7em Lato;
                            color: #D31616;
                        }
                    }
                }
            }
        }

        .badge-container {
            padding-top: 20px;

            .badge-label {
                padding-bottom: 10px;
                font: 600 1.4em Lato;
            }

            .badge-description {
                padding-bottom: 20px;
                font: 400 0.9em Lato;
            }

            .badge-list-container {
                padding-top: 10px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .badge-icon-container {

                    .badge-icon {
                        height: 17px;
                    }
                }
            }
        }
    }
}