#gifts-section-gifts-subcomponent {
    display: flex;
    flex: 1;

    .clickable-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .gifts-image-container {
            width: 100%;
            padding-bottom: 10px;
            position: relative;
            text-align: center;

            .gifts-image {
                height: 25px;
            }

            .grey-gifts-image {
                height: 25px;
                filter: grayscale(1);
                -webkit-filter: grayscale(1);
            }

            .gift-event-container {
                width: 100%;
                position: absolute;
                bottom: 6px;

                .event-label {
                    width: fit-content;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 2px 5px;
                    border-radius: 25px;
                    text-align: center;
                    font: 600 0.5em Lato;
                    background-color: #ff0000;
                    color: white;
                }
            }
        }

        .amount {
            font: 600 0.8em Lato;
            color: white;
        }
    }
}