#livestreaming-tab-events-tab-subcomponent {
    height: 100%;

    .events-tab-container {
        // height: 100%;
        min-height: 100%;

        .pk-icon-container {

            .pk-icon {
                height: 20px;
            }
        }
    }

    .embla-events-tab {
        // height: 100%;
        width: 100%;
        overflow: hidden;

        .event-embla__container {
            height: 100%;
            display: flex;

            .event-embla__slide {
                flex: 0 0 100%;
                min-width: 0;
            }
        }
    }

    .dummy-leaderboard {
        height: 10px;
    }
}

@media (max-width: 720px) {

    #livestreaming-tab-events-tab-subcomponent {

        .events-tab-container {
            height: unset;
        }
    }
}