@import "../../global-style.scss";

#report-page {

    .max-width-container {
        height: 100%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .form-container {

            .form-field {
                width: 100%;
            }
        }

        .additional-info-label {
            padding-top: 30px;
            padding-bottom: 15px;
            font: 600 1.0em Lato;
            color: #333;
        }

        .send-button {
            height: 45px;
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-transform: none;
            text-decoration: none;
            font-size: 1.1em;
            font-weight: 500;
            background-color: $sugarbook-main-color;
            color: white;
            transition: all 0.5s;
        }

        .disabled-button {
            border: 2px solid transparent;
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
        }
    }
}