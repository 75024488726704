#profile-achievement-badge-dialog {
    max-width: 350px;

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .padding-container {
        padding: 10px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .profile-badge-container {

            .profile-badge {
                height: 35px;
            }
        }

        .badge-description {
            line-height: 1.6;
            color: rgb(103, 103, 103);
        }
    }
}