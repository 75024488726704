#video-call-interactable-overlay-topbar-subcomponent {
    display: flex;

    .front-camera-placeholder {
        // height: 160px; // Uncomment for debugging
        width: 90px;
        // background-color: lightblue; // Uncomment for debugging
    }

    .callee-container {
        // width: calc(100% - 200px);
        padding-left: 10px;

        .username {
            max-width: 120px;
            font: 600 1em Lato;
            color: white;
        }

        .diamonds-container {
            padding: 10px 0 0 5px;
            display: flex;
            align-items: center;

            .diamonds-icon {
                height: 15px;
            }

            .diamonds-count-container {
                padding-left: 5px;
                display: flex;
                align-items: center;

                .diamonds-count {
                    font: 600 1em Lato;
                    color: white;
                }

                .diamonds-count-current-call {
                    padding-left: 5px;
                    font: 600 1em Lato;
                    color: white;
                }
            }
        }

        .coins-container {
            padding: 10px 0 0 5px;
            display: flex;
            align-items: center;

            .coins-icon {
                height: 18px;
            }

            .coins-count {
                padding-left: 5px;
                font: 600 1em Lato;
                color: white;
            }
        }
    }

    .right-container {
        display: flex;
        flex-direction: column;
        gap: 7px;
    }
}