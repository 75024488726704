#product-purchase-dialog-coins-tab-subcomponent {
    width: calc(100% - 1px);
}

// Utility CSS
.screen-height {
    height: 42vh;
}

.fix-height {
    height: 100%;
}