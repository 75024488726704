#live-page {
    height: 100%;
    overflow: auto;

    .max-width-container {
        max-width: 1000px;
        margin: 0 auto;
        // background-color: white;

        .padding-container {
            // height: calc(100% - 20px);
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            // overflow: auto;

            .header {
                font: 600 1.8em Lato;
                color: #710d0d;
            }

            .livestream-channel-list-container {
                height: 100%;

                >div {
                    height: 100%;

                    .infinite-scroller-container {
                        display: grid;
                        gap: 12px;
                    }

                    .multi-column {
                        grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));
                    }
                }

                .spinner-container {
                    height: 200px;
                }
            }
        }
    }
}