@import "../../../global-style.scss";

#livestream-ended-page {

    .padding-container {
        padding: 60px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;

        .livestream-ended-title {
            font: 600 1.5em Lato;
        }

        .back-button {
            height: 45px;
            width: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 500 1.2em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }

        .redirect-text {
            margin-top: 40px;
            font: 600 1.1em Lato;
        }

        .you-may-like-text {
            font: 600 1.4em Lato;
        }

        .live-list-container {
            width: 100%;
            padding-top: 10px;
            display: grid;
            gap: 12px;
            grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
        }
    }
}