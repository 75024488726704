#video-call-settings-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        max-width: 1000px;
        margin: 0 auto;
        background-color: white;

        .padding-container {
            padding: 20px;

            .video-call-label {
                font-size: 1.2em;
            }

            .buttons-list-container {
                width: 100%;
                padding-top: 20px;

                .button-container {
                    height: 50px;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-decoration: none;
                    cursor: pointer;
                    color: black;

                    .button-label-container {
                        display: flex;

                        .button-label {
                            font-size: 1.1em;
                            font-weight: 400;
                            color: #676767;
                        }

                        .icon-container {
                            padding-left: 7px;
                            display: flex;
                            align-items: center;

                            .icon {
                                width: 18px;
                            }
                        }
                    }

                    .suffix {
                        display: flex;
                        color: #676767;
                    }
                }
            }
        }
    }
}

// Responsive Design
@media (min-width: 1000px) {
    #video-call-settings-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}