@import "../../../global-style.scss";

#verify-phone-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 25px;

        .header {
            text-align: center;
            font: 600 1.5em Lato;
        }

        .subheader {
            text-align: center;
            font: 400 1em Lato;
        }

        .verification-code-container {
            display: flex;
            gap: 5px;

            .verification-code-text-field {
                font: 500 1.5em lato;
            }
        }

        .did-not-receive-container {
            display: flex;
            justify-content: center;
            gap: 5px;

            .did-not-receive-label {}

            .resend-button {
                cursor: pointer;
                font: 600 1em Lato;
                color: $sugarbook-main-color;
            }
        }

        .continue-button {
            height: 47px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-decoration: none;
            text-transform: none;
            cursor: pointer;
            font: 500 20px Lato;
            background-color: rgb(113, 13, 13);
            color: white;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
        }

        .update-contact-info-label {
            cursor: pointer;
            text-align: center;
            font: 600 1em Lato;
            color: $sugarbook-main-color;
        }
    }
}