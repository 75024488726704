#overview-page {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .moderation-container {
            width: 100%;
            margin-bottom: 20px;
        }

        .view-profile-button {
            margin: 15px 0;
            text-decoration: underline;
            cursor: pointer;
            font-size: 17px;
            color: #333;
        }

        .buttons-list-container {
            width: 100%;
            max-width: 600px;
            padding-top: 20px;

            .button-container {
                height: 50px;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 25px;
                text-decoration: none;
                cursor: pointer;
                background-color: rgb(245, 241, 241);
                color: black;

                .button-label {
                    padding-left: 20px;
                    font: 600 1em Lato;
                }

                .suffix {
                    padding-right: 20px;
                    display: flex;
                    align-items: center;

                    .diamond-icon {
                        height: 15px;
                    }

                    .live-coin {
                        width: 20px;
                    }

                    .suffix-label {
                        padding-left: 5px;
                        font: 600 1em Lato;
                        color: rgb(113, 13, 13);
                    }
                }
            }

            .sugarbook-verified-button {
                height: 46px;
                border: 2px solid #1DA1F3;
                background-color: #DCF2FF;

                .sugarbook-verified-label {
                    display: flex;
                    color: #1DA1F3;
                }
            }

            .sugarbook-verified-icon-color {
                color: #1DA1F3;
            }

            .upgrade-now-button {
                height: 46px;
                border: 2px solid #EAB923;
                background: #F4BF25;

                .upgrade-now-label {
                    display: flex;
                    gap: 10px;
                    color: white;

                    .premium-star-icon {
                        height: 18px;
                    }
                }

                .upgrade-now-icon-color {
                    color: #EAB923;
                }
            }
        }
    }
}