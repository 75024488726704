:root {
  /* Sugarbook main color */
  --sugarbook-main-color: #710d0d;

  /* App background color */
  --app-background-color: white;

  /* Desktop background color */
  --desktop-background-color: #f6f6f7;

  /* Octopus navbar */
  --octopus-navbar-color: #f7f7ff;

  /* Text Color */
  --sugarbook-main-text-color: #710d0d;
  --sugarbook-default-text-color: black;
  --sugarbook-grey-text-color: #7c7c7c;

  /* MUI Text Field Color */
  --mui-text-field-underline-border-color: 1px solid rgba(0, 0, 0, 0.42);
  --mui-text-field-outline-success-border-color: #7ED321;
  --mui-text-field-outline-background-color: #F6F6F6;
  /* MUI Icon Color */
  --mui-icon-default-color: rgba(0, 0, 0, 0.54);
  /* MUI Button Color */
  --mui-disabled-button-background-color: rgba(0, 0, 0, 0.12);
  --mui-disabled-button-color: #999999;

  /* Join Page */
  --join-page-border-color: #710d0d;
  --join-page-text-color: #710d0d;
}