#inbox-messages-tab-subcomponent {
    height: 100%;
    display: flex;
    flex-direction: column;

    .inbox-messages-list-container {
        height: 100%;
        overflow: auto;

        >div {
            height: 100%;

            .infinite-scroller-container {}
        }
    }
}

// Responsive Design
@media (max-width: 720px) {
    #inbox-messages-tab-subcomponent {

        .messages-list-container {
            height: 100%;
        }
    }
}