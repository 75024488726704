@import "../../../../global-style.scss";

#thank-you-for-purchase-dialog {
    min-width: 270px;
    max-width: 330px;

    .header {
        padding: 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .action-success-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .action-success-icon {
            height: 75px;
        }
    }

    .padding-container {
        padding: 40px 30px 30px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .thank-you-label {
            text-align: center;
            font: 600 1.5em Lato;
        }

        .description {
            text-align: center;
            font: 400 1em Lato;
        }

        .close-button {
            height: 45px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            font: 600 1.3em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }

        .social-media-button-container {
            height: 45px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 25px;
            cursor: pointer;

            .social-icon-container {
                display: flex;
                align-items: center;

                .social-icon {
                    height: 25px;
                }
            }

            .social-label {
                font: 600 1em Lato;
            }
        }

        .line-button {
            border: 2px solid rgb(0, 186, 0);
            color: rgb(0, 186, 0);
        }

        .telegram-button {
            border: 2px solid rgb(3, 155, 229);
            color: rgb(3, 155, 229);
        }
    }
}