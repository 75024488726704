#search-filter-advanced-filters-subcomponent {

    .filter-padding-container {
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .subheader {
            padding-bottom: 5px;
            font: 600 1.1em Lato;
        }

        .buttons-container {

            .button-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                .button-label-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .button-label {
                        color: #676767;
                    }
                }

                .icon-container {
                    display: flex;
                    align-items: center;

                    .selected-label {
                        padding-right: 20px;
                        font: 400 0.9em Lato;
                        color: #BABABA;
                    }

                    .icon {
                        color: #676767;
                    }
                }
            }

            // Utility CSS
            .dialog-button-height {
                height: 55px;
            }

            .page-button-height {
                height: 35px;
                font: 500 0.9em Lato;
            }
        }
    }
}