#topbar-permissions-status-subcomponent {
    padding-top: 10px;
    display: flex;

    .camera-icon-container {
        padding-left: 5px;

        .video-on {
            color: white;
        }

        .video-off {
            color: red;
        }
    }

    .microphone-icon-container {
        padding-left: 10px;
        
        .microphone-on {
            color: white;
        }

        .microphone-off {
            color: red;
        }
    }
}