#charged-message-popup-dialog {
    position: relative;

    .charged-message-enabled-background {
        width: 250px;
    }

    .charged-message-sd-background {
        width: 270px;
    }

    .charged-message-sb-enable-description {
        width: 155px;
        position: absolute;
        left: 20px;
        bottom: 50px;
        text-align: center;
        text-shadow: 0px 2.24612px 0px rgba(0, 0, 0, 0.25);
        font: 600 1em Lato;
        color: white;

        .bold {
            font-size: 1.3em;
        }

        .diamond-icon {
            width: 20px;
        }

        .diamond-label {
            font: 600 1.5em Lato;
            color: #1D632C;
        }
    }

    .charged-message-sb-disable-description {
        width: 200px;
        position: absolute;
        left: 30px;
        bottom: 90px;
        text-align: center;
        text-shadow: 0px 2.24612px 0px rgba(0, 0, 0, 0.25);
        font: 600 1em Lato;
        color: white;

        .bold {
            font: 600 1.5em Lato;
        }
    }

    .charged-message-sd-description {
        width: 190px;
        position: absolute;
        right: 45px;
        bottom: 70px;
        text-align: center;
        font: 600 0.9em Lato;

        .bold {
            font: 600 1.2em Lato;
        }

        .coin-icon {
            height: 15px;
        }
    }
}